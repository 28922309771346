<template>
	<svg version="1.1" id="new_x5F_figure" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	 y="0px" width="80%" viewBox="0 0 1125 1536" enable-background="new 0 0 1125 1536" xml:space="preserve" usemap="#ImageMap">
	<g id="human">
		<path d="M661.153,1410.352c-4.423,0.001-8.529-2.316-9.615-2.981c-7.278,4.452-12.671,0.126-14.04-1.177
			c-6.526,5.038-13.951,0.243-15.427-0.812c-8.189,4.011-15.831-2.512-17.327-3.908c-1.989,0.932-3.658,1.14-4.966,0.62
			c-0.945-0.376-1.442-1.041-1.664-1.429c-1.838-0.192-3.221-1.067-4.115-2.604c-5.823-10.005,11.428-46.016,12.583-48.402
			c5.732-43.636-5.573-68.454-5.688-68.7c-37.971-112.587-39.391-141.707-24.56-183.399c4.243-11.827,5.322-16.642,3.357-21.844
			c-2.141-5.877-2.515-20.152-2.91-35.267c-0.431-16.444-0.918-35.084-3.812-45.19c-9.325-32.882-18.675-111.39-24.262-158.296
			c-0.805-6.757-1.543-12.956-2.205-18.397c-7.274-59.097,25.652-139.082,25.985-139.882c6.604-30.032-2.118-133.133-3.315-146.785
			c-13.851,23-13.529,62.184-13.524,62.584c2.364,42.533-34.319,108.141-34.69,108.799c-18.274,27.849-24.478,53.11-24.478,54.504
			c0,7.743,1.695,26.487,1.713,26.676l-0.661,16.533c-0.093,1.27-0.365,3.349-0.709,5.98c-0.811,6.197-1.921,14.685-1.602,19.579
			c1.477,22.479,0.563,34.551-2.714,35.881c-0.858,0.348-1.645-0.091-2.068-0.493c-4.665-0.593-9.156-19.222-11.229-29.168
			c0.013,0.37,0.026,0.749,0.039,1.135c0.422,12.208,1.059,30.656-2.917,38.863c-1.792,3.725-3.664,5.398-5.563,5.001
			c-3.484-0.746-5.328-8.31-5.376-10.088c-0.98-35.271-2.702-40.727-3.338-41.5c-0.154,0.217-0.363,0.751-0.476,1.239
			c-2.456,34.145-5.324,48.573-10.221,51.388c-1.355,0.779-2.875,0.735-4.645-0.132c-4.757-2.233-2.368-35.942-1.251-49.056
			c-8.345,25.954-14.601,30.496-14.877,30.686c-3.446,2.402-5.898,2.966-7.495,1.715c-5.226-4.089,3.073-26.548,3.159-26.772
			c7.968-22.066,10.375-33.814,11.097-38.63c-13.271,25.183-23.281,24.528-23.71,24.484l-0.108-0.023
			c-1.951-0.642-3.239-1.979-3.829-3.975c-3.351-11.327,16.898-42.269,30.448-59.111c7.337-9.166,17.42-22.232,20.257-29.837
			c4.556-12.667,8.091-53.746,8.126-54.16c1.376-48.395,13.356-70.081,13.477-70.294c20.444-32.596,24.446-93.902,24.484-94.517
			l0.988-104.096c2.046-20.405,9.374-47.822,32.678-62.817c13.956-8.98,27.234-9.212,27.367-9.214
			c51.833-7.717,59.73-24.492,59.804-24.661c10.57-15.229,5.288-44.062,4.804-46.561c-8.858-7.364-12.474-25.442-12.883-27.628
			c-11.396-8.856-13.8-20.508-11.856-25.981c0.852-2.4,2.522-3.766,4.597-3.766c0.041,0,0.083,0,0.126,0.001
			c1.671,0.042,2.874-0.342,3.515-1.153c0.896-1.134,0.468-2.788,0.464-2.804c-3.082-24.878,1.49-44.804,13.574-59.292
			c17.834-21.382,46.201-23.249,46.486-23.265l10.594-0.001c0.312,0.017,28.654,1.883,46.477,23.254
			c12.086,14.493,16.655,34.428,13.582,59.252c-0.018,0.085-0.448,1.747,0.447,2.882c0.634,0.803,1.782,1.181,3.47,1.145
			c2.146-0.076,3.89,1.332,4.767,3.81c1.939,5.479-0.465,17.122-11.846,25.936c-0.41,2.189-4.032,20.27-12.883,27.627
			c-0.484,2.495-5.769,31.328,4.755,46.474c0.122,0.254,8.044,17.03,59.921,24.752c0.057-0.049,13.319,0.228,27.27,9.208
			c23.29,14.995,30.653,42.412,32.729,62.771l0.916,104.168c0.038,0.588,4.116,61.899,24.467,94.472
			c0.132,0.231,12.169,21.912,13.564,70.341c0.033,0.386,3.558,41.465,8.133,54.137c2.798,7.455,12.281,19.86,20.23,29.834
			c13.546,17.017,33.784,48.133,30.429,59.187c-0.597,1.966-1.886,3.277-3.833,3.899l-0.104,0.021
			c-0.437,0.041-10.378,0.712-23.7-24.473c0.74,4.836,3.182,16.608,11.164,38.625c0.338,0.934,8.203,22.885,3.06,26.818
			c-1.596,1.218-4.042,0.642-7.478-1.771c-0.271-0.188-6.483-4.726-14.875-30.71c1.12,13.093,3.531,46.848-1.16,49.086
			c-1.752,0.862-3.266,0.907-4.619,0.131c-4.925-2.826-7.805-17.255-10.271-51.465c-0.103-0.425-0.312-0.949-0.466-1.164
			c-0.636,0.768-2.361,6.209-3.343,41.501c-0.048,1.776-1.911,9.334-5.438,10.086c-1.912,0.415-3.786-1.275-5.572-5.002
			c-3.916-8.2-3.255-27.389-2.859-38.854c0.014-0.41,0.027-0.813,0.041-1.207c-2.084,9.927-6.607,28.627-11.217,29.232
			c-0.421,0.401-1.201,0.838-2.05,0.496c-3.262-1.313-4.177-13.387-2.722-35.884c0.266-4.861-0.809-13.354-1.593-19.556
			c-0.322-2.539-0.6-4.732-0.691-5.988l-0.684-16.483c0.02-0.254,1.709-19.042,1.709-26.741c0-1.395-6.203-26.665-24.46-54.475
			c-0.389-0.686-37.111-66.264-34.764-108.849c0.005-0.38,0.381-39.565-13.47-62.565c-1.207,13.653-10.003,116.766-3.269,146.87
			c0.307,0.718,33.177,80.702,25.927,139.8c-0.716,5.82-1.518,12.514-2.395,19.837c-5.931,49.503-14.894,124.311-24.103,156.854
			c-2.848,10.065-3.334,28.582-3.764,44.919c-0.4,15.248-0.778,29.65-2.959,35.537c-1.893,5.118-0.877,9.716,3.357,21.851
			c14.898,41.652,13.497,70.769-24.465,183.344c-0.134,0.296-11.349,25.115-5.703,68.753c1.16,2.392,18.42,38.367,12.59,48.39
			c-0.899,1.546-2.293,2.425-4.144,2.616c-0.229,0.39-0.742,1.065-1.712,1.439c-1.31,0.506-2.963,0.295-4.915-0.629
			c-1.499,1.398-9.136,7.913-17.309,3.906c-1.476,1.054-8.898,5.848-15.453,0.812c-1.374,1.296-6.807,5.636-14.098,1.177
			c-1.76,1.054-11.472,6.371-17.486-0.684c-0.008,0-0.017,0.001-0.025,0.001c-0.467,0-1.319-0.143-2.075-1.029
			c-2.679-3.148-2.979-14.204,10.79-48.98c0.428-2.98,7.974-55.309,12.222-65.915c8.366-20.843,2.185-78.062,0.621-88.464
			c-2.934-20.256-1.261-55.132,3.891-81.127c2.721-14.069-2.167-37.204-6.48-57.616c-1.47-6.958-2.858-13.53-3.819-19.191
			c-8.011-47.469-22.629-162.095-21.23-186.646c-1.2,0.683-3.574,1.739-6.767,1.739c-0.014,0-0.027,0-0.04,0l-0.165-0.028
			l-5.588-1.963c1.477,23.748-13.197,139.061-21.218,186.877c-0.96,5.586-2.333,12.052-3.787,18.896
			c-4.36,20.53-9.302,43.8-6.487,57.911c5.15,26.37,6.779,60.487,3.873,81.124c-1.848,12.652-7.543,68.154,0.632,88.468
			c4.267,10.677,11.774,62.952,12.198,65.918c13.742,34.828,13.449,45.868,10.78,48.994c-0.757,0.888-1.584,1.03-2.067,1.013
			C666.792,1409.451,663.911,1410.352,661.153,1410.352z M651.547,1406.183l0.271,0.183c0.43,0.29,10.601,7.003,16.715-0.514
			l0.194-0.239l0.302,0.066c0.023,0.003,0.766,0.134,1.444-0.686c1.617-1.957,3.784-10.552-10.999-48.003l-0.03-0.113
			c-0.078-0.55-7.891-55.101-12.144-65.744c-8.257-20.519-2.549-76.279-0.694-88.981c2.893-20.538,1.268-54.517-3.863-80.789
			c-2.855-14.312,2.109-37.688,6.489-58.312c1.452-6.835,2.823-13.291,3.779-18.856c8.104-48.31,23.009-165.545,21.151-187.392
			l-0.065-0.77l6.957,2.443c4.231-0.015,6.956-2.072,6.983-2.093l0.899-0.691l-0.096,1.129
			c-1.884,22.179,13.031,139.285,21.15,187.395c0.957,5.641,2.344,12.204,3.812,19.152c4.333,20.504,9.243,43.744,6.483,58.014
			c-5.133,25.901-6.802,60.633-3.883,80.79c1.569,10.443,7.768,67.931-0.683,88.981c-4.235,10.575-12.089,65.193-12.168,65.744
			l-0.03,0.113c-14.812,37.396-12.631,46.017-11.006,47.986c0.691,0.838,1.451,0.705,1.483,0.7l0.293-0.056l0.195,0.229
			c6.007,7.373,16.5,0.58,16.606,0.512l0.271-0.179l0.273,0.174c7.831,4.994,13.189-0.912,13.414-1.166l0.32-0.363l0.373,0.309
			c6.729,5.569,14.484-0.473,14.812-0.733l0.254-0.201l0.288,0.149c8.517,4.421,16.595-3.746,16.674-3.829l0.256-0.263l0.328,0.165
			c1.793,0.902,3.317,1.163,4.403,0.754c0.975-0.367,1.297-1.141,1.311-1.174l0.117-0.291l0.315-0.024
			c1.664-0.106,2.852-0.809,3.629-2.146c5.638-9.69-12.476-47.146-12.659-47.523l-0.047-0.154c-5.71-44,5.643-69.084,5.758-69.333
			c37.844-112.237,39.258-141.236,24.449-182.64c-4.257-12.198-5.337-17.163-3.353-22.531c2.123-5.731,2.499-20.053,2.897-35.216
			c0.451-17.196,0.919-34.979,3.8-45.164c9.188-32.468,18.146-107.229,24.072-156.701c0.878-7.323,1.68-14.019,2.396-19.839
			c7.217-58.834-25.553-138.579-25.885-139.378c-7.24-32.333,3.25-147.589,3.356-148.75l0.133-1.447l0.786,1.223
			c14.812,23.05,14.418,63.992,14.412,64.402c-2.332,42.317,34.248,107.639,34.618,108.294c17.51,26.67,24.606,52.498,24.606,54.995
			c0,7.744-1.694,26.576-1.711,26.766l0.685,16.402c0.089,1.213,0.364,3.395,0.685,5.92c0.789,6.239,1.87,14.783,1.599,19.74
			c-1.938,29.954,0.821,34.383,2.097,34.896c0.541,0.216,1.061-0.37,1.066-0.376l0.149-0.178h0.232
			c3.891,0,9.177-20.948,11.594-34.152l0.992,0.09c0,1.758-0.085,4.214-0.183,7.058c-0.392,11.373-1.048,30.408,2.763,38.387
			c1.072,2.238,2.73,4.833,4.461,4.456c2.591-0.553,4.594-7.155,4.646-9.136c1.175-42.229,3.344-42.443,4.27-42.535
			c0.918-0.085,1.339,1.264,1.525,2.078c2.418,33.464,5.249,48.144,9.783,50.745c1.057,0.605,2.228,0.555,3.686-0.163
			c3.664-1.749,2.055-33.28,0.18-52.745l0.977-0.193c8.939,29.438,15.82,34.595,15.889,34.644c2.056,1.443,4.816,2.924,6.296,1.796
			c3.366-2.574-0.294-17.12-3.393-25.684c-11.065-30.519-11.525-41.182-11.54-41.62l-0.073-2.264l1.02,2.021
			c13.104,25.991,23.186,26.366,23.932,26.375c1.602-0.53,2.623-1.583,3.119-3.218c3.244-10.688-16.836-41.415-30.255-58.272
			c-7.994-10.031-17.537-22.52-20.387-30.111c-4.622-12.801-8.157-54.011-8.192-54.426c-1.39-48.173-13.305-69.674-13.425-69.886
			c-20.475-32.771-24.568-94.331-24.607-94.949l-0.914-104.148c-2.051-20.095-9.318-47.206-32.274-61.986
			c-13.708-8.826-26.676-9.054-26.806-9.055c-51.753-7.7-60.134-23.997-60.69-25.215c-11.159-16.124-4.917-47.069-4.853-47.38
			l0.036-0.174l0.138-0.112c9.046-7.359,12.67-27.136,12.705-27.334l0.034-0.191l0.154-0.118
			c11.114-8.523,13.511-19.707,11.663-24.927c-0.734-2.073-2.058-3.168-3.798-3.145c-2.01,0.053-3.448-0.463-4.286-1.532
			c-1.204-1.535-0.658-3.601-0.635-3.688c3.023-24.464-1.468-44.139-13.364-58.41c-17.538-21.039-45.465-22.888-45.744-22.904
			l-10.537,0.001c-0.251,0.016-28.204,1.865-45.754,22.915c-11.895,14.267-16.389,33.932-13.356,58.451
			c0.01,0.017,0.553,2.074-0.651,3.608c-0.846,1.078-2.281,1.596-4.333,1.541c-1.653-0.053-3.028,1.056-3.753,3.099
			c-1.852,5.213,0.548,16.407,11.676,24.972l0.153,0.118l0.034,0.19c0.035,0.199,3.655,19.972,12.705,27.334l0.138,0.112l0.036,0.174
			c0.064,0.311,6.308,31.255-4.853,47.379c-0.554,1.216-8.908,17.516-60.622,25.21c-0.198,0.007-13.231,0.245-26.949,9.089
			c-22.935,14.787-30.156,41.879-32.177,62.002l-0.986,104.077c-0.039,0.644-4.059,62.204-24.624,94.994
			c-0.108,0.192-11.969,21.7-13.338,69.839c-0.037,0.443-3.584,41.653-8.185,54.447c-2.89,7.745-13.036,20.898-20.416,30.119
			c-14.417,17.92-33.339,47.823-30.27,58.201c0.492,1.664,1.513,2.74,3.12,3.288c0.768,0,10.873-0.376,23.927-26.374l1.008-2.007
			l-0.061,2.245c-0.012,0.436-0.418,11.029-11.465,41.624c-2.234,5.832-7.202,22.724-3.48,25.636c1.188,0.93,3.312,0.341,6.31-1.749
			c0.069-0.049,7.003-5.211,15.888-34.642l0.977,0.192c-1.872,19.464-3.456,50.993,0.266,52.74c1.476,0.723,2.654,0.776,3.714,0.166
			c4.505-2.589,7.325-17.27,9.732-50.669c0.198-0.888,0.606-2.244,1.535-2.153c0.925,0.091,3.09,0.306,4.265,42.535
			c0.055,2.045,1.981,8.579,4.585,9.137c1.382,0.292,2.91-1.249,4.454-4.457c3.868-7.985,3.211-27.021,2.818-38.395
			c-0.098-2.838-0.183-5.288-0.183-7.05l0.992-0.089c2.389,13.204,7.652,34.152,11.606,34.152h0.231l0.149,0.176
			c0.02,0.021,0.529,0.599,1.09,0.374c1.281-0.53,4.052-4.99,2.087-34.887c-0.325-4.993,0.792-13.536,1.607-19.773
			c0.329-2.514,0.613-4.684,0.702-5.908l0.662-16.452c-0.016-0.123-1.715-18.912-1.715-26.7c0-2.495,7.097-28.314,24.624-55.024
			c0.352-0.626,36.894-65.983,34.545-108.245c-0.006-0.432-0.344-41.374,14.469-64.424l0.787-1.224l0.132,1.449
			c0.105,1.16,10.502,116.408,3.401,148.665c-0.359,0.883-33.185,80.629-25.943,139.461c0.663,5.443,1.401,11.644,2.206,18.401
			c5.583,46.877,14.927,125.336,24.23,158.14c2.929,10.228,3.418,28.935,3.851,45.438c0.394,15.03,0.765,29.228,2.848,34.945
			c2.074,5.49,0.982,10.441-3.354,22.528c-14.74,41.438-13.309,70.441,24.545,182.69c0.096,0.196,11.542,25.282,5.743,69.283
			l-0.047,0.153c-0.183,0.377-18.282,37.863-12.653,47.535c0.773,1.33,1.953,2.027,3.605,2.134l0.325,0.021l0.112,0.306
			c0.011,0.027,0.314,0.787,1.263,1.154c1.087,0.421,2.623,0.16,4.448-0.748l0.327-0.163l0.255,0.263
			c0.081,0.082,8.163,8.254,16.691,3.828l0.288-0.148l0.254,0.202c0.327,0.259,8.084,6.308,14.785,0.731l0.375-0.312l0.321,0.368
			c0.221,0.254,5.524,6.158,13.356,1.165L651.547,1406.183z"/>
		<text transform="matrix(1 0 0 1 744.0449 1092.1406)" fill="#006837" font-family="'DINAlternate-Bold'" font-size="22.9692px">L</text>
		<text transform="matrix(1 0 0 1 606.0449 1092.1406)" fill="#006837" font-family="'DINAlternate-Bold'" font-size="22.9692px">R</text>
		<text transform="matrix(1 0 0 1 832.0449 593.1406)" fill="#006837" font-family="'DINAlternate-Bold'" font-size="22.9692px">L</text>
		<text transform="matrix(1 0 0 1 517.0449 593.1406)" fill="#006837" font-family="'DINAlternate-Bold'" font-size="22.9692px">R</text>
		<g>
			<path fill="#FFFFFF" d="M467.966,1123.126c-2.082-1.28-0.853-0.525-0.005-0.004c-6.642-4.085-10.916-11.702-16.105-17.336
				c-2.488-2.701-5.248-5.311-8.944-6.134c-1.165-0.26-3.919,0.097-4.608-0.548c-0.511-0.478-0.9-1.899-1.178-2.538
				c-0.469-1.079-0.907-2.171-1.322-3.272c-2.967-7.875-4.753-16.163-5.693-24.514c-0.568-5.052-0.84-10.084-1.789-15.092
				c-1.632-8.619-4.198-17.045-7.482-25.176c-1.292-3.2-2.474-7.054-4.451-9.897c-1.508-2.169-2.908-4.019-3.619-6.659
				c-1.068-3.965-0.581-8.572-0.869-12.671c-0.303-4.309-0.747-8.609-1.306-12.892c-1.081-8.285-2.492-16.548-4.391-24.686
				c-0.459-1.967-0.033-3.869,0.13-5.876c0.181-2.214,0.29-4.434,0.332-6.654c0.082-4.292-0.097-8.6-0.648-12.859
				c-1.007-7.784-5.667-14.77-9.785-21.269c-4.485-7.079-11.743-9.949-19.724-11.468c-4.198-0.799-8.467-1.205-12.735-1.365
				c-2.068-0.078-4.139-0.094-6.208-0.043c-1.255,0.031-1.598-0.073-2.59-0.824c-0.833-0.631-1.687-1.232-2.548-1.824
				c-3.491-2.397-7.107-4.613-10.731-6.803c-0.976-0.59-1.954-1.176-2.935-1.758c-0.489-0.291-1.712-0.692-1.966-1.171
				c-0.12-0.226-0.079-0.725-0.106-0.969c-0.413-3.767-0.825-7.534-1.238-11.301c-0.179-1.634-0.358-3.268-0.537-4.902
				c-0.023-0.209-0.182-0.735-0.103-0.937c0.089-0.225,0.701-0.479,0.937-0.642c0.845-0.588,1.618-1.271,2.283-2.059
				c1.649-1.954,2.32-4.535,3.137-6.914c1.153-3.357,2.19-6.756,3.107-10.185c3.957-14.789,5.906-33.015-2.639-46.69
				c-3.83-6.13-9.872-10.367-16.869-12.113c-2.678-0.668-9.721-1.504-14.903-0.834c-6.978,0.903-14.127,3.369-19.063,8.483
				c-5.338,5.531-7.805,13.011-8.835,20.486c-2.053,14.904,1.72,29.547,6.687,43.518c0.547,1.54,1.144,3.007,2.21,4.271
				c0.535,0.634,1.142,1.215,1.802,1.717c0.245,0.186,1.331,0.737,1.433,0.998c0.27,0.688-0.269,2.452-0.346,3.157
				c-0.439,4.008-0.878,8.015-1.317,12.023c-0.084,0.763,0.048,2.206-0.317,2.894c-0.254,0.479-1.477,0.881-1.966,1.171
				c-0.98,0.582-1.959,1.168-2.935,1.758c-1.84,1.112-3.673,2.235-5.492,3.38c-1.765,1.111-3.52,2.241-5.239,3.422
				c-0.765,0.525-1.523,1.059-2.267,1.614c-1.127,0.84-1.457,1.069-2.871,1.034c-4.303-0.107-8.62,0.061-12.9,0.522
				c-7.616,0.82-16.546,2.431-22.329,7.893c-2.725,2.573-4.59,6.197-6.485,9.394c-2.07,3.492-4.071,7.095-5.462,10.919
				c-1.409,3.875-1.664,8.095-1.849,12.184c-0.192,4.246-0.088,8.511,0.257,12.747c0.164,2.007,0.59,3.909,0.131,5.876
				c-0.497,2.129-0.953,4.268-1.382,6.412c-0.82,4.099-1.542,8.217-2.171,12.35c-1.296,8.507-2.206,17.074-2.508,25.677
				c-0.14,4.007-0.489,7.565-2.876,10.947c-1.408,1.996-2.454,3.894-3.48,6.18c-5.863,13.069-9.745,26.826-11.086,41.087
				c-0.748,7.955-1.915,15.779-4.236,23.446c-0.56,1.849-1.18,3.679-1.862,5.488c-0.373,0.991-0.764,1.976-1.182,2.95
				c-0.323,0.754-0.718,2.298-1.318,2.86c-0.689,0.645-3.442,0.289-4.607,0.548c-1.9,0.423-3.594,1.304-5.122,2.495
				c-6.264,4.886-10.334,12.15-15.951,17.706c-1.242,1.229-2.552,2.28-3.984,3.272c-1.178,0.817-2.501,1.822-2.549,3.398
				c-0.082,2.708,2.804,3.41,4.926,3.242c2.543-0.2,5.15-0.927,7.359-2.214c1.387-0.808,2.482-1.917,3.622-3.032
				c0.898-0.878,2.1-2.46,3.399-2.774c-0.635,2.798-2.346,5.535-3.657,8.052c-1.691,3.247-3.456,6.459-5.294,9.625
				c-1.449,2.496-4.593,6.084-3.275,9.099c2.391,5.471,7.636-1.395,9.293-3.64c1.405-1.904,2.642-3.933,3.995-5.874
				c1.164-1.67,2.385-3.776,4.052-5.002c0.007,1.787-0.755,3.674-1.297,5.348c-0.672,2.076-1.419,4.128-2.182,6.172
				c-1.212,3.242-3.654,7.703-2.76,11.226c0.512,2.02,2.6,3.449,4.572,2.337c2.049-1.155,3.073-3.98,3.954-6.013
				c2.097-4.838,3.592-9.904,5.67-14.747c0.216,4.294-0.639,8.566-0.792,12.846c-0.075,2.096-0.276,5.261,1.351,6.901
				c1.551,1.562,3.836,0.717,4.798-0.995c1.466-2.609,1.188-6.322,1.46-9.242c0.209-2.246,0.406-4.495,0.718-6.729
				c0.132-0.949,0.189-3.872,1.197-4.409c1.111,1.056,0.232,4.927,0.098,6.263c-0.246,2.466-0.511,5.043-0.163,7.515
				c0.319,2.265,2.26,5.154,4.722,3.116c1.585-1.312,2-3.935,2.235-5.851c0.269-2.192,0.219-4.423,0.417-6.624
				c0.277-3.08,0.665-6.15,0.945-9.23c0.134-1.474,0.695-2.657,1.221-4.045c0.919-2.427,1.766-4.887,2.472-7.385
				c0.706-2.497,1.389-5.127,1.383-7.74c-0.002-0.812-0.067-1.633-0.259-2.424c-0.119-0.488-0.681-1.303-0.675-1.765
				c0.014-1.063,1.071-2.694,1.469-3.645c2.546-6.094,5.395-12.074,8.361-17.974c7.026-13.977,14.783-27.628,19.582-42.595
				c2.553-7.963,3.619-16.191,5.737-24.25c1.919-7.304,4.434-14.484,7.13-21.535c1.01,3.898,2.021,7.796,3.031,11.694
				c0.772,2.976,2.061,6.2,2.35,9.265c0.329,3.482-0.048,7.177-0.071,10.67c-0.037,5.53-0.074,11.06-0.11,16.59
				c-0.02,3.054-0.431,6.392-0.063,9.431c0.153,1.258,0.891,2.505,1.301,3.72c0.804,2.384-0.632,6.022-1.091,8.521
				c-1.007,5.487-1.937,10.99-2.73,16.512c-3.201,22.267-1.835,45.157-0.357,67.536c1.474,22.315,4.993,44.328,10.825,65.917
				c2.521,9.331,5.318,18.575,5.542,28.31c0.19,8.264-1.679,16.354-3.623,24.329c-2.67,10.954-4.012,21.771-2.661,33.031
				c1.299,10.829,4.459,21.324,7.614,31.729c3.001,9.899,6.039,19.787,9.077,29.675c2.367,7.703,4.812,15.394,6.804,23.204
				c0.647,2.536,1.778,5.794,1.296,8.394c-0.389,2.097-3.218,10.714-3.485,11.964c-0.268,1.25-0.697,2.711-1.593,4.162
				c-0.91,1.445-2.35,2.897-4.02,4.509c-0.832,0.821-1.699,1.677-2.597,2.562c-0.436,0.45-0.879,0.908-1.328,1.374
				c-0.463,0.493-0.905,1.031-1.366,1.558c-0.839,1.141-1.721,2.339-2.218,3.874c-0.519,1.48-0.672,3.374,0.152,5.05
				c1.794,3.367,5.854,4.098,8.82,4.046c1.468-0.005,2.906,0.137,4.221,0.504c1.297,0.358,2.521,1.17,4.108,1.829
				c3.137,1.283,6.462,1.42,9.499,1.495c3.124-0.038,6.415-0.194,9.306-1.824c0.664-0.484,1.382-0.895,1.968-1.455
				c0.514-0.629,1.185-1.149,1.527-1.877c0.046-0.08,0.088-0.16,0.134-0.239c0.046,0.079,0.088,0.159,0.134,0.239
				c0.341,0.728,1.012,1.248,1.526,1.877c0.586,0.56,1.304,0.972,1.968,1.455c2.891,1.63,6.181,1.786,9.306,1.824
				c3.038-0.075,6.362-0.212,9.499-1.495c1.587-0.66,2.81-1.471,4.107-1.829c1.315-0.368,2.753-0.51,4.221-0.504
				c2.967,0.052,7.026-0.678,8.82-4.046c0.824-1.677,0.671-3.57,0.152-5.05c-0.498-1.536-1.379-2.733-2.218-3.874
				c-0.461-0.527-0.902-1.065-1.366-1.558c-0.45-0.465-0.893-0.923-1.329-1.374c-0.897-0.885-1.764-1.741-2.596-2.562
				c-1.67-1.612-3.11-3.063-4.02-4.509c-0.65-1.052-2.879-8.166-2.879-8.166c-1.203-3.631-2.409-8.26-2.085-10.638
				c0.47-3.44,1.472-6.836,2.398-10.17c1.834-6.604,3.892-13.146,5.905-19.698c3.018-9.823,6.038-19.646,9.014-29.481
				c3.114-10.291,6.2-20.68,7.433-31.396c1.251-10.881,0.024-21.402-2.559-31.991c-1.996-8.184-3.965-16.471-3.773-24.954
				c0.21-9.26,2.78-18.062,5.188-26.939c2.828-10.425,5.31-20.899,7.074-31.56c1.761-10.646,2.881-21.347,3.823-32.092
				c0.969-11.045,1.386-22.182,1.589-33.265c0.197-10.743,0.451-21.422-1.047-32.087c-0.739-5.262-1.676-10.494-2.696-15.708
				c-0.537-2.744-1.102-5.483-1.692-8.216c-0.268-1.243-0.542-2.485-0.821-3.727c-0.375-1.67-0.375-2.472-0.083-4.142
				c0.698-4,0.265-8.323,0.237-12.382c-0.056-8.236-0.116-16.472-0.176-24.708c-0.019-2.63,0.564-4.479,1.332-7.041l4.409-14.693
				l0.922-3.071c2.252,5.756,4.293,11.606,6.119,17.511c2.235,7.227,3.293,14.634,5.102,21.955
				c3.796,15.356,10.911,29.653,18.267,43.57c3.459,6.544,6.729,13.182,9.775,19.929c1.111,2.461,2.793,5.268,3.351,7.905
				c0.228,1.075-0.425,2.035-0.593,3.198c-0.218,1.517-0.074,3.062,0.173,4.566c0.799,4.883,2.605,9.536,4.351,14.14
				c0.7,1.847,0.641,3.98,0.85,5.943c0.313,2.933,0.692,5.883,0.79,8.833c0.078,2.336,0.158,4.722,1.06,6.919
				c1.003,2.441,4.031,4.113,5.544,0.937c1.15-2.415,0.726-5.868,0.463-8.463c-0.134-1.327-0.28-2.654-0.352-3.986
				c-0.041-0.757-0.367-2.636,0.226-3.28c1.056,0.465,1.165,3.448,1.3,4.372c0.324,2.218,0.52,4.453,0.728,6.684
				c0.274,2.938-0.001,6.659,1.464,9.289c0.909,1.632,3.009,2.502,4.595,1.173c1.776-1.488,1.621-4.68,1.566-6.749
				c-0.116-4.393-1.028-8.77-0.806-13.176c2.082,4.842,3.575,9.91,5.671,14.748c0.886,2.045,1.967,5.102,4.145,6.127
				c2.049,0.964,4.019-0.653,4.42-2.663c0.722-3.614-1.668-7.982-2.906-11.301c-1.263-3.386-3.386-7.552-3.374-11.233
				c2.982,2.194,4.983,6.383,7.022,9.406c1.638,2.429,5.59,9.16,9.22,6.48c3.338-2.464-0.133-7.01-1.631-9.537
				c-1.768-2.982-3.44-6.026-5.066-9.087c-1.572-2.96-3.68-6.214-4.43-9.521c2.342,0.567,4.201,3.879,6.127,5.235
				c1.998,1.407,4.418,2.213,6.814,2.605c1.747,0.286,3.799,0.559,5.251-0.701C471.663,1126.925,470.018,1124.387,467.966,1123.126z
				 M317.874,1265.964c-0.325,5.537-1.242,11.033-1.696,16.562c-0.656,7.991-0.578,16.009-0.897,24.017
				c-0.198,4.969-0.204,9.94-0.001,14.909c0.228,5.589,0.926,11.14,1.191,16.722c0.602,12.678-0.782,25.395-1.996,37.998
				c-1.124-11.497-2.349-23.065-2.138-34.633c0.104-5.692,0.819-11.339,1.143-17.019c0.334-5.865,0.403-11.748,0.163-17.618
				c-0.381-9.4-0.233-18.814-1.138-28.188c-0.317-3.289-0.762-6.561-1.146-9.842c-0.368-3.14-0.803-6.646-0.106-9.771
				c0.685-3.07,1.979-5.896,2.388-9.073c0.421-3.275,0.339-6.614,0-9.893c-0.774-7.48,0.092-14.698,0.832-22.151
				c0.608,5.696,1.723,11.613,1.338,17.354c-0.362,5.403-1.63,10.446-0.673,15.9c0.384,2.187,1.052,4.304,1.923,6.346
				C318.17,1260.177,318.038,1263.175,317.874,1265.964z"/>
			<g>
				<path fill="#8299A6" d="M458.226,1127.82c-0.007,0.001-0.018,0.003-0.025,0.004c0.003-0.001,0.007,0,0.01,0
					C458.216,1127.823,458.22,1127.821,458.226,1127.82z"/>
				<path fill="#8299A6" d="M302.74,1248.836c-5.326-5.194-14.649-4.236-19.914,0.406c0.629-0.169,1.247-0.364,1.867-0.563
					C290.274,1246.454,297.155,1247.027,302.74,1248.836z M314.326,865.857c-0.006-0.012-0.012-0.025-0.017-0.036
					c0.004,0.008,0.007,0.016,0.01,0.024C314.321,865.85,314.324,865.853,314.326,865.857z M301.905,932.557
					c-0.549-1.917-0.942-2.988-0.942-2.988s0.186,1.121,0.512,3.084c0.343,1.956,0.699,4.773,1.132,8.132
					c0.405,3.36,0.696,7.31,0.638,11.475c-0.073,2.083-0.285,4.197-0.781,6.253c-0.555,2.025-1.485,4.077-2.439,6.079
					c-1.97,3.998-4.35,7.825-6.838,11.197c-2.486,3.369-5.11,6.323-7.781,8.299c-1.335,0.976-2.643,1.761-3.905,2.194
					c-1.256,0.451-2.443,0.521-3.392,0.369c-0.948-0.165-1.665-0.481-2.12-0.748c-0.46-0.261-0.687-0.437-0.687-0.437
					s0.199,0.204,0.626,0.53c0.425,0.32,1.1,0.778,2.097,1.048c0.99,0.266,2.288,0.321,3.688-0.028
					c1.399-0.337,2.903-0.998,4.361-1.966c2.93-1.922,5.865-4.771,8.579-8.13c2.701-3.375,5.217-7.248,7.28-11.388
					c1.037-2.074,1.936-4.204,2.557-6.496c0.547-2.295,0.714-4.585,0.695-6.758c-0.031-4.354-0.725-8.32-1.337-11.687
					C303.24,937.219,302.43,934.477,301.905,932.557z M300.443,1434.459c-1.306-0.173-2.523-0.622-3.418-1.452
					c-0.907-0.818-1.462-2.012-1.766-3.286c-0.647-2.597-0.347-5.332-0.01-8.049c0.705-5.312,1.963-10.273,2.803-14.58
					c0.862-4.315,1.07-7.999,1.092-10.552c0.02-1.28-0.038-2.283-0.083-2.966c-0.038-0.682-0.058-1.046-0.058-1.046
					s-0.017,0.364-0.048,1.047c-0.016,0.682-0.088,1.681-0.191,2.947c-0.179,2.538-0.767,6.116-1.785,10.337
					c-0.984,4.232-2.607,9.087-3.409,14.605c-0.361,2.661-0.784,5.666-0.08,8.684c0.334,1.495,1.001,3.069,2.246,4.236
					c1.231,1.177,2.876,1.788,4.441,2.005c1.591,0.213,3.114,0.079,4.627-0.146c0.8-0.112,1.399-0.247,2.213-0.435
					c0.841-0.191,1.635-0.661,2.263-1.242c1.275-1.199,1.814-2.781,2.122-4.228c0.304-1.473,0.374-2.921,0.349-4.326
					c-0.04-2.814-0.504-5.465-1.036-7.939c-0.27-1.237-0.573-2.43-0.899-3.576c-0.303-1.153-0.748-2.259-0.987-3.243
					c-0.489-2.076-0.781-4.069-1.06-5.812c-0.241-1.757-0.451-3.288-0.623-4.549c-0.319-2.53-0.501-3.975-0.501-3.975
					s0.009,1.459,0.125,4.008c0.078,1.275,0.173,2.822,0.282,4.597c0.145,1.778,0.276,3.776,0.71,5.972
					c0.231,1.15,0.602,2.178,0.845,3.318c0.267,1.133,0.51,2.314,0.718,3.533c0.415,2.437,0.706,5.033,0.737,7.683
					c0.005,1.323-0.087,2.663-0.372,3.94c-0.278,1.266-0.782,2.502-1.64,3.262c-0.427,0.374-0.913,0.669-1.489,0.786
					c-0.592,0.128-1.412,0.298-2.05,0.368C303.158,1434.567,301.744,1434.661,300.443,1434.459z M314.312,1258.805
					c0.01-0.005,0.019-0.01,0.029-0.015c-0.018,0.008-0.031,0.014-0.047,0.021C314.3,1258.809,314.306,1258.808,314.312,1258.805z
					 M231.941,1032.939c-0.362,0.246-0.762,0.433-1.174,0.603c-0.391,0.218-0.857,0.261-1.283,0.399
					c-0.456,0.033-0.897,0.177-1.362,0.142c-0.46-0.049-0.92,0.064-1.37-0.045c-0.446-0.083-0.897-0.08-1.317-0.167
					c-0.416-0.105-0.82-0.182-1.2-0.252c-0.76-0.139-1.376-0.368-1.841-0.452c-0.459-0.104-0.736-0.149-0.736-0.149
					s0.194,0.188,0.558,0.494c0.376,0.283,0.878,0.775,1.609,1.149c0.716,0.411,1.597,0.833,2.644,1.071
					c0.504,0.169,1.08,0.186,1.647,0.235c0.569,0.057,1.167-0.073,1.756-0.115c0.566-0.19,1.167-0.301,1.68-0.599
					c0.544-0.24,1.004-0.593,1.426-0.949c0.429-0.345,0.765-0.763,1.063-1.152c0.295-0.396,0.532-0.792,0.706-1.178
					c0.747-1.521,0.698-2.657,0.698-2.657s-0.592,0.937-1.623,2.035C233.32,1031.909,232.665,1032.46,231.941,1032.939z
					 M350.525,986.65c-0.949,0.152-2.135,0.082-3.392-0.369c-1.262-0.434-2.569-1.218-3.905-2.194
					c-2.672-1.976-5.295-4.93-7.781-8.299c-2.488-3.372-4.868-7.198-6.838-11.197c-0.954-2.002-1.883-4.054-2.439-6.079
					c-0.496-2.056-0.709-4.17-0.781-6.253c-0.058-4.165,0.233-8.115,0.638-11.475c0.433-3.359,0.789-6.176,1.132-8.132
					c0.326-1.963,0.512-3.084,0.512-3.084s-0.392,1.071-0.942,2.988c-0.525,1.92-1.334,4.662-1.945,8.035
					c-0.612,3.367-1.306,7.333-1.337,11.687c-0.019,2.173,0.148,4.463,0.695,6.758c0.621,2.292,1.52,4.422,2.557,6.496
					c2.063,4.139,4.579,8.013,7.28,11.388c2.714,3.359,5.648,6.208,8.579,8.13c1.458,0.967,2.961,1.629,4.36,1.966
					c1.4,0.348,2.699,0.293,3.688,0.028c0.997-0.27,1.672-0.727,2.097-1.048c0.427-0.326,0.626-0.53,0.626-0.53
					s-0.227,0.176-0.687,0.437C352.19,986.169,351.473,986.485,350.525,986.65z M314.991,999.338
					c-0.088-3.627-0.279-6.65-0.358-8.766c-0.12-2.116-0.189-3.325-0.189-3.325s-0.069,1.209-0.189,3.325
					c-0.079,2.116-0.27,5.138-0.358,8.766c-0.086,3.627-0.248,7.859-0.31,12.393c-0.083,4.534-0.097,5.469-0.118,10.305
					c0.02,4.836,0.033,7.722,0.116,12.256c0.058,4.534,0.224,8.766,0.31,12.393c0.087,3.627,0.28,6.65,0.359,8.766
					c0.121,2.116,0.19,3.325,0.19,3.325s0.069-1.209,0.19-3.325c0.079-2.116,0.272-5.139,0.359-8.766
					c0.085-3.627,0.252-7.859,0.31-12.393c0.083-4.534,0.096-7.419,0.116-12.256c-0.021-4.836-0.035-5.771-0.118-10.305
					C315.24,1007.196,315.077,1002.965,314.991,999.338z M404.4,1033.619c-0.38,0.07-0.785,0.147-1.2,0.252
					c-0.421,0.086-0.871,0.084-1.317,0.167c-0.45,0.109-0.91-0.004-1.37,0.045c-0.465,0.035-0.906-0.11-1.362-0.142
					c-0.425-0.138-0.892-0.181-1.283-0.399c-0.412-0.17-0.812-0.357-1.174-0.603c-0.724-0.479-1.378-1.03-1.881-1.589
					c-1.031-1.097-1.623-2.035-1.623-2.035s-0.049,1.136,0.698,2.657c0.174,0.386,0.411,0.782,0.706,1.178
					c0.298,0.388,0.635,0.807,1.063,1.152c0.422,0.357,0.882,0.709,1.426,0.949c0.513,0.298,1.114,0.409,1.68,0.599
					c0.589,0.042,1.187,0.173,1.756,0.115c0.567-0.049,1.144-0.066,1.647-0.235c1.047-0.238,1.928-0.66,2.644-1.071
					c0.732-0.375,1.233-0.866,1.609-1.149c0.364-0.306,0.558-0.494,0.558-0.494s-0.277,0.046-0.736,0.149
					C405.776,1033.251,405.16,1033.48,404.4,1033.619z M326.149,1248.836c5.585-1.809,12.466-2.382,18.047-0.157
					c0.62,0.199,1.238,0.395,1.867,0.563C340.798,1244.6,331.475,1243.642,326.149,1248.836z M330.124,1396.522
					c-0.103-1.266-0.175-2.265-0.191-2.947c-0.031-0.683-0.048-1.047-0.048-1.047s-0.02,0.364-0.058,1.046
					c-0.045,0.683-0.103,1.686-0.083,2.966c0.021,2.553,0.23,6.237,1.092,10.552c0.84,4.307,2.097,9.268,2.803,14.58
					c0.337,2.717,0.637,5.453-0.01,8.049c-0.304,1.274-0.858,2.468-1.766,3.286c-0.896,0.83-2.112,1.279-3.418,1.452
					c-1.301,0.202-2.715,0.107-4.066-0.075c-0.638-0.07-1.459-0.24-2.05-0.368c-0.576-0.117-1.062-0.412-1.489-0.786
					c-0.857-0.76-1.361-1.996-1.64-3.262c-0.285-1.277-0.377-2.618-0.372-3.94c0.031-2.649,0.322-5.246,0.737-7.683
					c0.208-1.219,0.451-2.4,0.718-3.533c0.243-1.14,0.614-2.168,0.845-3.318c0.435-2.196,0.565-4.194,0.71-5.972
					c0.109-1.775,0.204-3.323,0.282-4.597c0.116-2.55,0.125-4.008,0.125-4.008s-0.182,1.446-0.501,3.975
					c-0.173,1.261-0.383,2.793-0.623,4.549c-0.279,1.744-0.571,3.736-1.06,5.812c-0.239,0.984-0.684,2.09-0.987,3.243
					c-0.326,1.146-0.629,2.339-0.899,3.576c-0.533,2.474-0.996,5.125-1.036,7.939c-0.025,1.405,0.045,2.853,0.349,4.326
					c0.308,1.447,0.846,3.029,2.122,4.228c0.627,0.581,1.422,1.051,2.263,1.242c0.814,0.188,1.413,0.323,2.213,0.435
					c1.512,0.225,3.036,0.358,4.627,0.146c1.565-0.216,3.21-0.828,4.441-2.005c1.245-1.167,1.912-2.74,2.246-4.236
					c0.703-3.018,0.281-6.023-0.08-8.684c-0.803-5.518-2.426-10.373-3.409-14.605C330.891,1402.638,330.303,1399.06,330.124,1396.522
					z M325.18,870.054c0.673,0.096,1.057,0.152,1.057,0.152s-0.338-0.189-0.931-0.518c-0.601-0.315-1.461-0.786-2.567-1.16
					c-0.549-0.195-1.138-0.453-1.792-0.596c-0.649-0.162-1.332-0.375-2.055-0.495c-0.725-0.102-1.471-0.272-2.241-0.327
					c-0.77-0.039-1.553-0.131-2.338-0.126c-1.569,0.02-3.142,0.109-4.586,0.399c-0.728,0.095-1.412,0.311-2.065,0.462
					c-0.647,0.189-1.255,0.369-1.8,0.588c-2.208,0.81-3.462,1.774-3.462,1.774s1.514-0.36,3.745-0.695
					c1.117-0.164,2.414-0.327,3.805-0.43c0.694-0.07,1.413-0.088,2.142-0.119c0.727-0.057,1.469-0.028,2.209-0.029
					c1.482-0.014,2.959,0.103,4.352,0.205c0.695,0.077,1.368,0.151,2.005,0.221c0.644,0.055,1.239,0.183,1.801,0.253
					c0.555,0.1,1.086,0.127,1.535,0.231C324.451,869.924,324.85,869.995,325.18,870.054z M467.966,1123.126
					c-2.082-1.28-0.853-0.525-0.005-0.004c-6.642-4.085-10.916-11.702-16.105-17.336c-2.488-2.701-5.248-5.311-8.944-6.134
					c-1.165-0.26-3.919,0.097-4.608-0.548c-0.511-0.478-0.9-1.899-1.178-2.538c-0.469-1.079-0.907-2.171-1.322-3.272
					c-2.967-7.875-4.753-16.163-5.693-24.514c-0.568-5.052-0.84-10.084-1.789-15.092c-1.632-8.619-4.198-17.045-7.482-25.176
					c-1.292-3.2-2.474-7.054-4.451-9.897c-1.508-2.169-2.908-4.019-3.619-6.659c-1.068-3.965-0.581-8.572-0.869-12.671
					c-0.303-4.309-0.747-8.609-1.306-12.892c-1.081-8.285-2.492-16.548-4.391-24.686c-0.459-1.967-0.033-3.869,0.13-5.876
					c0.181-2.214,0.29-4.434,0.332-6.654c0.082-4.292-0.097-8.6-0.648-12.859c-1.007-7.784-5.667-14.77-9.785-21.269
					c-4.485-7.079-11.743-9.949-19.724-11.468c-4.198-0.799-8.467-1.205-12.735-1.365c-2.068-0.078-4.139-0.094-6.208-0.043
					c-1.255,0.031-1.598-0.073-2.59-0.824c-0.833-0.631-1.687-1.232-2.548-1.824c-3.491-2.397-7.107-4.613-10.731-6.803
					c-0.976-0.59-1.954-1.176-2.935-1.758c-0.489-0.291-1.712-0.692-1.966-1.171c-0.12-0.226-0.079-0.725-0.106-0.969
					c-0.413-3.767-0.825-7.534-1.238-11.301c-0.179-1.634-0.358-3.268-0.537-4.902c-0.023-0.209-0.182-0.735-0.103-0.937
					c0.089-0.225,0.701-0.479,0.937-0.642c0.845-0.588,1.618-1.271,2.283-2.059c1.649-1.954,2.32-4.535,3.137-6.914
					c1.153-3.357,2.19-6.756,3.107-10.185c3.957-14.789,5.906-33.015-2.639-46.69c-3.83-6.13-9.872-10.367-16.87-12.113
					c-2.678-0.668-9.721-1.504-14.903-0.834c-6.978,0.903-14.127,3.369-19.063,8.483c-5.338,5.531-7.805,13.011-8.835,20.486
					c-2.053,14.904,1.72,29.547,6.687,43.518c0.547,1.54,1.144,3.007,2.21,4.271c0.535,0.634,1.142,1.215,1.802,1.717
					c0.245,0.186,1.331,0.737,1.433,0.998c0.27,0.688-0.269,2.452-0.346,3.157c-0.439,4.008-0.878,8.015-1.317,12.023
					c-0.084,0.763,0.048,2.206-0.317,2.894c-0.254,0.479-1.477,0.881-1.966,1.171c-0.98,0.582-1.959,1.168-2.935,1.758
					c-1.84,1.112-3.673,2.235-5.492,3.38c-1.765,1.111-3.52,2.241-5.239,3.422c-0.765,0.525-1.523,1.059-2.267,1.614
					c-1.127,0.84-1.457,1.069-2.871,1.034c-4.304-0.107-8.62,0.061-12.9,0.522c-7.616,0.82-16.546,2.431-22.329,7.893
					c-2.725,2.573-4.59,6.197-6.485,9.394c-2.07,3.492-4.071,7.095-5.462,10.919c-1.409,3.875-1.664,8.095-1.849,12.184
					c-0.192,4.246-0.088,8.511,0.257,12.747c0.164,2.007,0.59,3.909,0.131,5.876c-0.497,2.129-0.953,4.268-1.382,6.412
					c-0.82,4.099-1.542,8.217-2.171,12.35c-1.296,8.507-2.206,17.074-2.508,25.677c-0.141,4.007-0.489,7.565-2.876,10.947
					c-1.408,1.996-2.454,3.894-3.48,6.18c-5.863,13.069-9.745,26.826-11.086,41.087c-0.748,7.955-1.915,15.779-4.236,23.446
					c-0.56,1.849-1.18,3.679-1.862,5.488c-0.373,0.991-0.764,1.976-1.182,2.95c-0.323,0.754-0.718,2.298-1.318,2.86
					c-0.689,0.645-3.442,0.289-4.607,0.548c-1.9,0.423-3.594,1.304-5.122,2.495c-6.264,4.886-10.334,12.15-15.951,17.706
					c-1.242,1.229-2.552,2.28-3.984,3.272c-1.178,0.817-2.501,1.822-2.549,3.398c-0.082,2.708,2.804,3.41,4.926,3.242
					c2.543-0.2,5.15-0.927,7.359-2.214c1.387-0.808,2.482-1.917,3.622-3.032c0.898-0.878,2.1-2.46,3.399-2.774
					c-0.635,2.798-2.346,5.535-3.657,8.052c-1.691,3.247-3.456,6.459-5.294,9.625c-1.449,2.496-4.593,6.084-3.275,9.099
					c2.391,5.471,7.636-1.395,9.293-3.64c1.405-1.904,2.642-3.933,3.995-5.874c1.164-1.67,2.385-3.776,4.052-5.002
					c0.007,1.787-0.755,3.674-1.297,5.348c-0.672,2.076-1.419,4.128-2.182,6.172c-1.212,3.242-3.654,7.703-2.76,11.226
					c0.512,2.02,2.6,3.449,4.572,2.337c2.049-1.155,3.073-3.98,3.954-6.013c2.097-4.838,3.592-9.904,5.67-14.747
					c0.216,4.294-0.639,8.566-0.792,12.846c-0.075,2.096-0.276,5.261,1.351,6.901c1.551,1.562,3.836,0.717,4.798-0.995
					c1.466-2.609,1.188-6.322,1.46-9.242c0.209-2.246,0.406-4.495,0.718-6.729c0.132-0.949,0.189-3.872,1.197-4.409
					c1.111,1.056,0.232,4.927,0.098,6.263c-0.246,2.466-0.511,5.043-0.163,7.515c0.319,2.265,2.26,5.154,4.722,3.116
					c1.585-1.312,2-3.935,2.235-5.851c0.269-2.192,0.219-4.423,0.417-6.624c0.277-3.08,0.665-6.15,0.945-9.23
					c0.134-1.474,0.695-2.657,1.221-4.045c0.919-2.427,1.766-4.887,2.472-7.385c0.706-2.497,1.389-5.127,1.383-7.74
					c-0.002-0.812-0.067-1.633-0.259-2.424c-0.119-0.488-0.681-1.303-0.675-1.765c0.014-1.063,1.071-2.694,1.469-3.645
					c2.546-6.094,5.395-12.074,8.361-17.974c7.026-13.977,14.783-27.628,19.582-42.595c2.553-7.963,3.619-16.191,5.737-24.25
					c1.919-7.304,4.434-14.484,7.13-21.535c1.01,3.898,2.021,7.796,3.031,11.694c0.772,2.976,2.061,6.2,2.35,9.265
					c0.329,3.482-0.048,7.177-0.071,10.67c-0.037,5.53-0.074,11.06-0.11,16.59c-0.02,3.054-0.431,6.392-0.063,9.431
					c0.153,1.258,0.891,2.505,1.301,3.72c0.804,2.384-0.632,6.022-1.091,8.521c-1.007,5.487-1.937,10.99-2.73,16.512
					c-3.201,22.267-1.835,45.157-0.357,67.536c1.474,22.315,4.993,44.328,10.825,65.917c2.521,9.331,5.318,18.575,5.542,28.31
					c0.19,8.264-1.679,16.354-3.623,24.329c-2.67,10.954-4.012,21.771-2.661,33.031c1.299,10.829,4.459,21.324,7.614,31.729
					c3.001,9.899,6.039,19.787,9.077,29.675c2.367,7.703,4.812,15.394,6.804,23.204c0.647,2.536,1.778,5.794,1.296,8.394
					c-0.389,2.097-3.218,10.714-3.485,11.964c-0.268,1.25-0.697,2.711-1.593,4.162c-0.91,1.445-2.35,2.897-4.02,4.509
					c-0.832,0.821-1.699,1.677-2.596,2.562c-0.436,0.45-0.879,0.908-1.329,1.374c-0.463,0.493-0.905,1.031-1.366,1.558
					c-0.839,1.141-1.721,2.339-2.218,3.874c-0.519,1.48-0.672,3.374,0.152,5.05c1.794,3.367,5.854,4.098,8.82,4.046
					c1.468-0.005,2.906,0.137,4.221,0.504c1.297,0.358,2.521,1.17,4.108,1.829c3.137,1.283,6.462,1.42,9.499,1.495
					c3.124-0.038,6.415-0.194,9.306-1.824c0.664-0.484,1.382-0.895,1.968-1.455c0.514-0.629,1.185-1.149,1.527-1.877
					c0.046-0.08,0.088-0.16,0.134-0.239c0.046,0.079,0.088,0.159,0.134,0.239c0.341,0.728,1.012,1.248,1.526,1.877
					c0.586,0.56,1.304,0.972,1.968,1.455c2.891,1.63,6.181,1.786,9.306,1.824c3.038-0.075,6.362-0.212,9.499-1.495
					c1.587-0.66,2.81-1.471,4.107-1.829c1.315-0.368,2.753-0.51,4.221-0.504c2.967,0.052,7.026-0.678,8.82-4.046
					c0.824-1.677,0.671-3.57,0.152-5.05c-0.498-1.536-1.379-2.733-2.218-3.874c-0.461-0.527-0.902-1.065-1.366-1.558
					c-0.45-0.465-0.893-0.923-1.328-1.374c-0.897-0.885-1.764-1.741-2.596-2.562c-1.67-1.612-3.11-3.063-4.02-4.509
					c-0.65-1.052-2.879-8.166-2.879-8.166c-1.203-3.631-2.409-8.26-2.085-10.638c0.47-3.44,1.472-6.836,2.398-10.17
					c1.834-6.604,3.892-13.146,5.905-19.698c3.018-9.823,6.038-19.646,9.014-29.481c3.114-10.291,6.2-20.68,7.433-31.396
					c1.251-10.881,0.024-21.402-2.559-31.991c-1.996-8.184-3.965-16.471-3.773-24.954c0.21-9.26,2.78-18.062,5.188-26.939
					c2.828-10.425,5.31-20.899,7.074-31.56c1.761-10.646,2.881-21.347,3.823-32.092c0.969-11.045,1.386-22.182,1.589-33.265
					c0.197-10.743,0.451-21.422-1.047-32.087c-0.739-5.262-1.676-10.494-2.696-15.708c-0.537-2.744-1.102-5.483-1.692-8.216
					c-0.268-1.243-0.542-2.485-0.821-3.727c-0.375-1.67-0.375-2.472-0.083-4.142c0.698-4,0.265-8.323,0.237-12.382
					c-0.056-8.236-0.116-16.472-0.176-24.708c-0.019-2.63,0.564-4.479,1.332-7.041l4.409-14.693l0.922-3.071
					c2.252,5.756,4.293,11.606,6.119,17.511c2.235,7.227,3.293,14.634,5.102,21.955c3.796,15.356,10.911,29.653,18.267,43.57
					c3.459,6.544,6.729,13.182,9.775,19.929c1.111,2.461,2.793,5.268,3.351,7.905c0.228,1.075-0.425,2.035-0.593,3.198
					c-0.218,1.517-0.074,3.062,0.173,4.566c0.799,4.883,2.605,9.536,4.351,14.14c0.7,1.847,0.641,3.98,0.85,5.943
					c0.313,2.933,0.692,5.883,0.79,8.833c0.078,2.336,0.158,4.722,1.06,6.919c1.003,2.441,4.031,4.113,5.544,0.937
					c1.15-2.415,0.726-5.868,0.463-8.463c-0.134-1.327-0.28-2.654-0.352-3.986c-0.041-0.757-0.367-2.636,0.226-3.28
					c1.056,0.465,1.165,3.448,1.3,4.372c0.324,2.218,0.52,4.453,0.728,6.684c0.274,2.938-0.001,6.659,1.464,9.289
					c0.909,1.632,3.009,2.502,4.595,1.173c1.776-1.488,1.621-4.68,1.566-6.749c-0.116-4.393-1.028-8.77-0.806-13.176
					c2.082,4.842,3.575,9.91,5.671,14.748c0.886,2.045,1.967,5.102,4.145,6.127c2.049,0.964,4.019-0.653,4.42-2.663
					c0.722-3.614-1.668-7.982-2.906-11.301c-1.263-3.386-3.386-7.552-3.374-11.233c2.982,2.194,4.983,6.383,7.022,9.406
					c1.638,2.429,5.589,9.16,9.22,6.48c3.338-2.464-0.133-7.01-1.631-9.537c-1.768-2.982-3.44-6.026-5.066-9.087
					c-1.572-2.96-3.68-6.214-4.43-9.521c2.342,0.567,4.201,3.879,6.127,5.235c1.998,1.407,4.418,2.213,6.813,2.605
					c1.747,0.286,3.799,0.559,5.251-0.701C471.663,1126.925,470.018,1124.387,467.966,1123.126z M314.475,1376.172
					c-1.124-11.497-2.349-23.065-2.138-34.633c0.104-5.692,0.819-11.339,1.143-17.019c0.334-5.865,0.403-11.748,0.163-17.618
					c-0.381-9.4-0.233-18.814-1.138-28.188c-0.317-3.289-0.762-6.561-1.146-9.842c-0.368-3.14-0.803-6.646-0.106-9.771
					c0.685-3.07,1.979-5.896,2.388-9.073c0.421-3.275,0.339-6.614,0-9.893c-0.774-7.48,0.092-14.698,0.832-22.151
					c0.608,5.696,1.723,11.613,1.338,17.354c-0.362,5.403-1.63,10.446-0.673,15.9c0.384,2.187,1.052,4.304,1.923,6.346
					c1.107,2.595,0.975,5.593,0.811,8.382c-0.325,5.537-1.242,11.033-1.696,16.562c-0.656,7.991-0.578,16.009-0.897,24.017
					c-0.198,4.969-0.204,9.94-0.001,14.909c0.228,5.589,0.926,11.14,1.191,16.722
					C317.072,1350.852,315.689,1363.569,314.475,1376.172z M467.296,1127.82c0.091-0.016,0.121-0.021-0.016,0.004
					c-0.989,0.176-0.287,0.05-0.01,0c-1.834,0.322-4.089-0.336-5.79-0.941c-3.051-1.083-4.853-3.292-7.206-5.385
					c-1.055-0.938-4.561-3.242-5.043-0.431c-0.168,0.978,0.514,2.323,0.858,3.195c2.435,6.174,5.932,12.053,9.333,17.739
					c0.849,1.418,2.176,3.029,2.294,4.727c0.274,3.919-3.669,0.015-4.5-1.031c-3.236-4.074-5.539-9.213-9.409-12.743
					c-1.645-1.5-3.653-1.376-3.63,1.161c0.015,1.649,0.583,3.338,1.06,4.896c0.904,2.948,2.003,5.833,3.082,8.719
					c0.766,2.048,1.666,4.148,2.056,6.305c0.328,1.813-0.473,4.929-2.481,2.581c-1.548-1.811-2.393-4.465-3.308-6.637
					c-1.223-2.903-2.166-5.93-3.266-8.881c-0.47-1.261-1.473-5.902-3.364-5.983c-2.363-0.101-1.794,4.768-1.744,6.014
					c0.119,2.95,0.499,5.877,0.703,8.819c0.098,1.418,0.72,5.736-0.752,6.787c-2.303,1.644-2.556-5.33-2.63-6.201
					c-0.155-1.807-0.331-3.613-0.51-5.417c-0.152-1.529-0.758-12.697-4.745-10.005c-1.945,1.313-1.159,5.407-0.984,7.29
					c0.205,2.213,0.439,4.392,0.34,6.617c-0.064,1.429-0.888,4.979-2.265,2.121c-1.195-2.478-0.967-5.368-1.149-8.032
					c-0.223-3.256-0.618-6.499-0.955-9.744c-0.126-1.211-0.104-2.445-0.472-3.589c-1.293-4.013-3.016-7.826-4.007-11.953
					c-0.81-3.375,0.727-6.849-0.572-10.767c-7.617-21.948-20.628-41.546-27.898-63.621c-1.9-5.769-3.107-11.627-4.371-17.554
					c-1.266-5.931-3.16-11.729-5.273-17.408c-1.721-4.625-3.779-9.093-5.648-13.654c-0.438-1.069-0.183-1.831-0.005-3.004
					c0.079-0.521,4.345-31.344,3.288-31.682c-1.058-0.338-6.034,34.594-6.292,36.091c-0.016-0.051-0.032-0.102-0.047-0.152
					c-2.249,6.957-4.498,13.914-6.747,20.871c-0.151,0.466-0.254,1.005-0.467,1.446c-0.123,0.254-0.492,2.606-0.486,3.39
					c0.039,4.503,0.078,9.006,0.116,13.509c0.051,5.87,0.315,11.739,0.291,17.608c-0.004,0.888,0.007,1.777-0.011,2.664
					c-0.035,1.703-0.636,3.28-0.757,4.93c-0.12,1.641,0.634,3.568,0.98,5.206c0.504,2.383,0.988,4.771,1.453,7.162
					c1.803,9.292,3.599,18.76,4.041,28.234c0.442,9.473-0.057,19.116-0.424,28.588c-0.366,9.462-0.814,18.918-1.665,28.35
					c-1.732,19.2-4.658,38.113-9.666,56.748c-2.412,8.976-5.313,17.922-6.147,27.225c-0.461,5.147-0.209,10.458,0.535,15.556
					c1.361,9.328,4.552,18.277,5.712,27.639c1.149,9.276,0.325,18.505-1.509,27.635c-2.561,12.746-6.757,25.193-10.47,37.647
					c-2.784,9.336-5.631,18.658-8.307,28.025c-1.106,3.872-2.219,7.764-3.054,11.706c-0.493,2.327-1.179,4.886-0.753,7.262
					c0.52,2.902,5.354,15.7,6.1,16.916c1.101,1.755,2.691,3.334,4.341,4.945c0.815,0.81,1.663,1.653,2.542,2.526
					c0.431,0.449,0.87,0.906,1.315,1.37c0.418,0.449,0.804,0.928,1.218,1.4c1.511,1.892,2.754,4.469,1.782,6.433
					c-0.488,0.954-1.494,1.701-2.699,2.141c-1.216,0.459-2.503,0.563-4.043,0.573c-1.57-0.007-3.218,0.126-4.872,0.584
					c-1.684,0.479-3.081,1.396-4.355,1.898c-2.623,1.065-5.656,1.653-8.665,1.707c-2.9-0.038-5.466-0.583-7.884-1.467
					c-3.304-1.207-5.527-9.912-3.576-20.56c0.09-0.494-0.316-7.671-0.316-7.671c0.002-3.862-0.014-7.724,0.024-11.587
					c0.153-15.774,2.937-31.38,3.554-47.14c0.371-9.464-0.726-18.755-1.058-28.193c-0.233-6.593,0.028-13.154,0.317-19.737
					c0.365-8.315,0.38-16.579,1.251-24.872c0.49-4.671,1.236-9.377,1.116-14.086c-0.039-1.533-0.061-3.274-0.509-4.75
					c-0.601-1.98-1.655-3.769-2.143-5.817c-1.096-4.602-0.641-9.25,0.043-13.872c0.678-4.581,0.147-9.169-0.377-13.738
					c-1.038-9.042-1.829-17.99-2.029-27.093c-0.281-12.787,0.032-25.594-0.131-38.387c-0.06-4.693-0.119-9.387-0.179-14.081
					l-0.078-6.129c-0.01-0.786-0.365-2.528-0.057-3.303c0.528,0.563,1.182,1.176,1.997,1.81c0.876,0.681,1.918,1.368,3.108,2.02
					c0.606,0.298,1.237,0.609,1.891,0.931c0.675,0.266,1.372,0.541,2.089,0.823c0.732,0.231,1.483,0.469,2.252,0.712
					c0.775,0.205,1.577,0.374,2.389,0.559c1.635,0.287,3.312,0.602,5.084,0.698c1.829,0.177,3.349,0.135,5.195,0.167
					c1.767-0.106,3.529-0.185,5.259-0.494c0.867-0.102,1.72-0.264,2.553-0.505c0.834-0.218,1.661-0.394,2.449-0.702
					c3.186-1.04,5.959-2.663,7.948-4.45c1.008-0.878,1.834-1.787,2.466-2.659c0.666-0.843,1.115-1.671,1.458-2.35
					c0.676-1.372,0.825-2.229,0.825-2.229s-0.414,0.761-1.378,1.865c-0.481,0.549-1.089,1.19-1.855,1.836
					c-0.748,0.661-1.666,1.316-2.697,1.951c-2.075,1.26-4.695,2.323-7.634,2.966c-0.719,0.207-1.49,0.287-2.254,0.409
					c-0.761,0.133-1.54,0.228-2.337,0.278c-1.573,0.195-3.204,0.151-4.822,0.178c-1.537-0.059-3.413-0.061-4.902-0.256
					c-1.558-0.122-3.126-0.333-4.619-0.579c-0.741-0.148-1.469-0.293-2.182-0.435c-0.701-0.177-1.387-0.351-2.055-0.521
					c-1.312-0.404-2.538-0.815-3.643-1.265c-1.083-0.485-2.069-0.949-2.9-1.431c-0.819-0.495-1.529-0.943-2.063-1.363
					c-0.251-0.19-0.464-0.362-0.654-0.521c-0.07-0.242-0.449-5.366-0.444-7.766c-0.037-2.4-0.22-4.961-0.314-7.521
					c-0.204-5.121-0.362-10.241-0.585-14.082c-0.195-3.84-0.325-6.401-0.325-6.401h-0.26c0,0-0.13,2.56-0.325,6.401
					c-0.223,3.84-0.381,8.961-0.585,14.082c-0.095,2.56-0.277,5.121-0.314,7.521c0.005,2.4-0.523,7.874-1.068,8.287
					c-0.534,0.42-1.245,0.868-2.063,1.363c-0.831,0.483-1.816,0.947-2.9,1.431c-1.106,0.45-2.331,0.86-3.643,1.265
					c-0.668,0.169-1.354,0.343-2.055,0.521c-0.713,0.142-1.441,0.287-2.182,0.435c-1.493,0.246-3.061,0.457-4.619,0.579
					c-1.488,0.195-3.365,0.197-4.902,0.256c-1.617-0.027-3.249,0.017-4.822-0.178c-0.797-0.049-1.575-0.145-2.337-0.278
					c-0.764-0.121-1.536-0.202-2.254-0.409c-2.939-0.643-5.559-1.706-7.634-2.966c-1.03-0.635-1.949-1.29-2.697-1.951
					c-0.766-0.646-1.374-1.286-1.855-1.836c-0.964-1.103-1.378-1.865-1.378-1.865s0.148,0.857,0.825,2.229
					c0.343,0.679,0.793,1.507,1.458,2.35c0.632,0.872,1.458,1.781,2.466,2.659c1.989,1.787,4.762,3.41,7.948,4.45
					c0.788,0.308,1.615,0.484,2.449,0.702c0.832,0.242,1.686,0.404,2.553,0.505c1.73,0.309,3.492,0.388,5.259,0.494
					c1.846-0.031,3.367,0.011,5.195-0.167c1.772-0.097,3.449-0.411,5.084-0.698c0.813-0.185,1.614-0.354,2.39-0.559
					c0.768-0.243,1.52-0.481,2.252-0.712c0.717-0.283,1.414-0.557,2.089-0.823c0.653-0.322,1.285-0.632,1.891-0.931
					c1.19-0.652,2.232-1.339,3.108-2.02c0.849-0.661,1.532-1.302,2.07-1.883c0.047,0.444-0.082,1.249-0.085,1.491
					c-0.017,1.347-0.034,2.693-0.051,4.04c-0.091,7.176-0.182,14.353-0.273,21.529c-0.146,11.492,0.161,22.995-0.078,34.482
					c-0.155,7.452-0.58,14.834-1.376,22.246c-0.408,3.795-0.89,7.59-1.143,11.4c-0.252,3.793,0.187,7.411,0.448,11.181
					c0.26,3.766,0.121,7.569-0.809,11.242c-0.718,2.838-1.887,5.192-2.087,8.142c-0.526,7.758,1.383,15.711,1.832,23.457
					c0.349,6.017,0.249,12.043,0.526,18.061c0.246,5.344,0.453,10.679,0.34,16.031c-0.163,7.741-1.202,15.433-1.261,23.173
					c-0.061,7.903,0.519,15.807,1.301,23.666c0.775,7.793,1.933,15.591,2.43,23.407c0.212,3.333,0.081,6.694,0.061,10.03
					c-0.018,3.102-0.004,6.204-0.001,9.306c0,0-0.459,6.013-0.49,7.642c0,0,3.132,16.818-3.089,20.56
					c-2.206,1.327-5.472,1.429-8.372,1.467c-3.009-0.054-5.97-0.642-8.594-1.707c-1.274-0.502-2.671-1.42-4.355-1.898
					c-1.653-0.457-3.302-0.591-4.872-0.584c-1.541-0.01-2.827-0.114-4.043-0.573c-1.204-0.441-2.21-1.188-2.699-2.141
					c-0.972-1.964,0.271-4.541,1.782-6.433c0.414-0.471,0.8-0.95,1.218-1.4c0.445-0.464,0.884-0.921,1.315-1.37
					c0.878-0.873,1.727-1.716,2.542-2.526c1.651-1.611,3.24-3.19,4.341-4.945c0.722-1.175,5.409-13.664,6.05-16.559
					c0.415-1.873,0.065-3.865-0.285-5.722c-1.897-10.057-5.263-19.861-8.184-29.659c-1.891-6.343-3.797-12.681-5.674-19.028
					c-4.474-15.136-9.888-30.61-10.398-46.527c-0.264-8.242,1.025-16.217,2.992-24.195c1.845-7.485,3.65-14.961,3.912-22.7
					c0.438-12.902-3.576-25.028-6.84-37.364c-4.241-16.028-7.054-32.424-8.598-48.93c-1.534-16.398-2.299-32.829-2.549-49.296
					c-0.126-8.27-0.051-16.364,0.932-24.589c0.996-8.34,2.361-16.64,3.877-24.899c0.238-1.298,0.873-2.951,0.786-4.26
					c-0.108-1.615-1.123-3.611-1.762-5.077c0.073,0.213,0.203,0.418,0.298,0.622c0.022-9.729,0.045-19.458,0.067-29.187
					c0.005-2.115,0.01-4.23,0.015-6.346c0.001-0.41-0.29-1.711-0.408-1.99c-0.701-1.654-1.035-3.579-1.534-5.302
					c-0.87-3.006-1.739-6.011-2.609-9.017c-0.733-2.535-1.567-5.062-2.205-7.623c-0.444-1.779-4.131-36.758-5.432-36.609
					c-1.301,0.15,2.69,32.357,2.807,33.622c0.16,1.737-0.144,2.439-0.867,4.107c-0.911,2.102-1.791,4.219-2.648,6.343
					c-2.236,5.549-4.21,11.215-5.797,16.984c-1.582,5.75-2.399,11.67-4.024,17.413c-3.396,12.007-8.714,23.446-14.3,34.57
					c-5.554,11.06-11.015,22.171-15.079,33.884c-1.056,3.269,0.277,4.454,0.152,6.483c-0.323,5.233-2.456,10.529-4.375,15.357
					c-0.468,1.178-0.576,1.936-0.702,3.233c-0.355,3.659-0.831,7.308-1.081,10.977c-0.109,1.598-0.051,3.208-0.287,4.795
					c-0.155,1.045-0.406,3.513-1.431,4.159c-1.976,1.245-1.718-4.406-1.701-5.336c0.058-3.074,0.908-6.232,0.447-9.302
					c-0.311-2.067-2.315-3.713-3.865-1.645c-1.521,2.028-1.562,5.432-1.819,7.832c-0.247,2.305-0.46,4.616-0.658,6.926
					c-0.061,0.708-0.859,8.831-2.908,5.792c-0.635-0.942-0.543-2.579-0.559-3.648c-0.027-1.866,0.171-3.712,0.343-5.567
					c0.272-2.939,0.644-5.968,0.444-8.925c-0.065-0.966-0.132-2.505-1.189-2.958c-2.222-0.954-3.503,4.754-3.921,5.875
					c-1.213,3.251-2.263,6.576-3.656,9.756c-0.609,1.389-2.242,6.848-4.261,6.704c-3.71-0.264,2.706-14.653,3.203-16.108
					c0.574-1.681,3.487-8.03,1.011-9.419c-1.266-0.71-2.604,0.681-3.384,1.512c-2.295,2.446-4.079,5.413-5.934,8.189
					c-0.811,1.214-1.659,2.388-2.55,3.544c-0.772,1.001-1.975,2.939-3.348,3.186c-3.388,0.608,0.134-5.325,0.645-6.172
					c1.586-2.63,3.09-5.314,4.562-8.009c1.995-3.653,4.237-7.45,5.462-11.451c0.496-1.62,0.387-3.475-1.987-3.028
					c-3.129,0.589-5.071,4.595-7.766,6.11c-2.028,1.141-5.142,2.249-7.529,1.945c-4.502-0.573,1.738-4.332,2.767-5.238
					c6.196-5.45,10.261-12.941,16.505-18.324c2.741-2.362,5.913-2.931,9.545-2.858c0.297,0.006,1.273-0.936,1.248-1.779
					c4.463-8.663,7.046-18.403,8.495-28.001c0.977-6.469,1.319-12.944,2.682-19.36c2.037-9.594,5.354-18.947,9.44-27.852
					c0.806-1.756,1.534-3.582,2.766-5.068c1.477-1.781,2.542-3.843,3.301-6.021c0.599-1.72,1.071-3.058,1.085-4.741
					c0.021-2.517,0.097-5.033,0.226-7.546c0.515-10.063,1.949-20.088,3.702-30.004c0.438-2.477,0.915-4.947,1.446-7.405
					c0.409-1.891,0.728-3.095,0.506-4.96c-0.582-4.889-0.797-9.846-0.631-14.768c0.158-4.665,0.474-9.31,2.281-13.659
					c1.858-4.472,4.385-8.664,6.949-12.759c2.451-3.914,5.012-6.671,9.291-8.603c4.277-1.931,8.97-2.922,13.598-3.555
					c10.963-1.499,16.27-0.518,18.636-2.585c2.669-1.989,5.467-3.818,8.255-5.634c1.653-1.077,3.318-2.134,4.988-3.184
					c1.328-0.834,3.358-1.598,4.4-2.728c0.543-0.589,0.497-0.998,0.575-1.86c0.504-5.575,1.009-11.149,1.513-16.724
					c1.19-2.632-2.067-2.988-3.931-6.793c0.155,0.318,0.486,0.999-0.007-0.011c-0.094-0.195-0.069-0.145-0.01-0.024
					c-1.074-2.234-1.742-4.847-2.479-7.208c-1.368-4.379-2.572-8.815-3.512-13.306c-1.763-8.428-2.224-16.897-0.73-25.409
					c1.282-7.302,4.205-14.56,10.18-19.261c5.461-4.296,12.452-5.643,19.25-5.825c6.388-0.171,13.319,1.403,18.547,5.157
					c6.297,4.522,9.498,11.78,10.903,19.209c1.6,8.462,1.267,16.967-0.427,25.381c-1.398,6.944-3.159,14.195-5.921,20.753
					c-1.652,3.922-4.95,4.341-4.209,7.303c0.503,5.563,1.007,11.126,1.51,16.689c0.078,0.858,0.031,1.346,0.581,1.93
					c1.058,1.124,3.087,1.903,4.4,2.728c1.462,0.918,2.918,1.845,4.368,2.782c3.001,1.938,6.01,3.9,8.875,6.035
					c0.902,1.092,2.608,1.742,11.635,1.912c8.609,0.162,19.506,1.609,26.14,7.754c3.299,3.056,5.559,7.84,7.701,11.74
					c2.166,3.943,4.166,7.996,4.718,12.505c0.595,4.856,0.711,9.762,0.496,14.647c-0.108,2.463-0.301,4.922-0.595,7.37
					c-0.225,1.874,0.004,3.073,0.42,4.96c2.168,9.829,3.81,19.85,4.768,29.872c0.479,5.013,0.755,10.046,0.801,15.082
					c0.032,3.515,2.04,7.891,4.337,10.763c2.613,3.267,4.11,7.991,5.654,11.863c1.847,4.634,3.472,9.361,4.816,14.166
					c2.077,7.426,3,14.846,3.878,22.46c1.253,10.871,3.97,22.021,9.004,31.792c0.766,3.017,6.614,1.219,10.356,4.268
					c4.72,3.846,8.198,9.159,12.159,13.731c1.9,2.193,3.891,4.383,6.215,6.14C466.305,1124.4,470.714,1127.182,467.296,1127.82z"/>
			</g>
		</g>
	</g>
	<g id="area">
		<ellipse data-name='head' id="parts_arr0" @click="parts0" cx="681.442" cy="214" rx="99.5" ry="117"/>
		<path data-name='R_arm' id="parts_arr1" @click="parts1" d="M598,312c0,0-97-12-104,79s2,102-5,135s-32,62-33,79s-2,89-7,101
			s-37,59-49,76s-35,90,42,125s82-73,82-95s-14-47-3-70s37-68,40-87s14-58,11-216S598,312,598,312z"/>
		<path data-name='L_arm' id="parts_arr2" @click="parts2" d="M768.226,312c0,0,97-12,104,79s-2,102,5,135s32,62,33,79s2,89,7,101
			s37,59,49,76s35,90-42,125s-82-73-82-95s14-47,3-70s-37-68-40-87s-14-58-11-216S768.226,312,768.226,312z"/>
		<path data-name='mune' id="parts_arr3" @click="parts3" d="M681,347c0,0,63.294-9.887,70.647-25.443c0,0,24.353-3.557,23.353,33.443
			s17,76,0,163s-163,79-183,0s2-163,0-176s12.619-17.59,12.619-17.59S638,343,681,347z"/>
		<path data-name='hara' id="parts_arr4" @click="parts4" d="M681,600c0,0,63.294-9.887,70.647-25.443c0,0,24.353-3.557,23.353,33.443
			s17,76,0,163s-163,79-183,0s2-163,0-176s12.619-17.59,12.619-17.59S638,596,681,600z"/>
		<path data-name='R_foot' id="parts_arr5" @click="parts5" d="M563,1389c0,0,22-16,21-70s-40-105-39-151s20-70,11-151s-12-101-22-153
			s6-119,16-148c0,0,8,132,125,141c0,0,14,62,0,139s-26,101-13,136s-5,105,0,127s25,155,19,178S505,1444,563,1389z"/>
		<path data-name='L_foot' id="parts_arr6" @click="parts6" d="M799.946,1389c0,0-22-16-21-70s40-105,39-151s-20-70-11-151s12-101,22-153
			s-6-119-16-148c0,0-8,132-125,141c0,0-14,62,0,139s26,101,13,136s5,105,0,127s-25,155-19,178S857.946,1444,799.946,1389z"/>
		<path data-name='back' id="parts_arr7" @click="parts7" d="M351.104,1160h-73.32c-16.687,0-30.34-13.653-30.34-30.34V921.34
			c0-16.687,13.653-30.34,30.34-30.34h73.32c16.687,0,30.34,13.653,30.34,30.34v208.32C381.444,1146.347,367.791,1160,351.104,1160z"
			/>
	</g>
	<g id="Wire">
		<path fill="#C1272D" d="M681.481,332.5c-1.021-0.018-2.023-0.017-3.031-0.051l0.104-2.998c0.972,0.034,1.964,0.075,2.926,0.049
			c0.983,0,1.965-0.018,2.942-0.052l0.105,2.998C683.516,332.481,682.5,332.5,681.481,332.5z M672.391,332.03
			c-2.009-0.209-4.033-0.492-6.018-0.84l0.52-2.955c1.915,0.337,3.87,0.61,5.809,0.812L672.391,332.03z M690.589,332.021
			l-0.314-2.983c1.935-0.204,3.889-0.479,5.809-0.818l0.521,2.954C694.616,331.525,692.593,331.81,690.589,332.021z M660.42,329.93
			c-1.953-0.485-3.92-1.046-5.846-1.668l0.922-2.854c1.86,0.601,3.761,1.143,5.646,1.611L660.42,329.93z M702.547,329.91
			l-0.727-2.911c1.885-0.47,3.781-1.013,5.635-1.613l0.924,2.854C706.46,328.861,704.498,329.423,702.547,329.91z M648.86,326.201
			c-1.862-0.744-3.731-1.564-5.557-2.438l1.295-2.706c1.766,0.845,3.573,1.638,5.375,2.357L648.86,326.201z M714.089,326.174
			l-1.115-2.785c1.799-0.72,3.608-1.515,5.378-2.364l1.297,2.705C717.819,324.607,715.949,325.43,714.089,326.174z M637.924,320.97
			c-1.745-0.979-3.49-2.031-5.187-3.129l1.629-2.519c1.645,1.064,3.335,2.083,5.024,3.031L637.924,320.97z M725.038,320.926
			l-1.471-2.614c1.7-0.957,3.396-1.982,5.037-3.046l1.633,2.517C728.543,318.881,726.793,319.939,725.038,320.926z M627.747,314.391
			c-1.617-1.192-3.222-2.452-4.77-3.745l1.923-2.303c1.502,1.254,3.059,2.476,4.628,3.633L627.747,314.391z M735.231,314.321
			l-1.785-2.412c1.565-1.158,3.121-2.383,4.625-3.64l1.924,2.302C738.446,311.866,736.844,313.127,735.231,314.321z M618.438,306.63
			c-1.456-1.366-2.903-2.801-4.301-4.267l2.172-2.07c1.358,1.425,2.766,2.821,4.182,4.148L618.438,306.63z M744.531,306.55
			l-2.055-2.186c1.422-1.336,2.827-2.733,4.177-4.153l2.174,2.067C747.438,303.738,745.993,305.175,744.531,306.55z M610.081,297.865
			c-1.304-1.527-2.584-3.111-3.807-4.708l2.383-1.823c1.19,1.555,2.437,3.097,3.705,4.584L610.081,297.865z M752.88,297.776
			l-2.283-1.945c1.262-1.482,2.507-3.026,3.701-4.588l2.383,1.821C755.456,294.668,754.177,296.253,752.88,297.776z M602.722,288.256
			c-1.136-1.654-2.244-3.362-3.295-5.076l2.558-1.568c1.023,1.67,2.104,3.335,3.21,4.947L602.722,288.256z M760.228,288.16
			l-2.475-1.695c1.099-1.604,2.178-3.27,3.206-4.95l2.559,1.566C762.462,284.805,761.355,286.513,760.228,288.16z M596.392,277.947
			c-0.955-1.745-1.889-3.553-2.774-5.375l2.699-1.312c0.864,1.779,1.774,3.544,2.706,5.246L596.392,277.947z M766.548,277.844
			l-2.633-1.438c0.938-1.717,1.847-3.483,2.701-5.248l2.699,1.308C768.44,274.275,767.509,276.084,766.548,277.844z M591.11,267.07
			c-0.788-1.842-1.543-3.731-2.245-5.614l2.811-1.048c0.686,1.838,1.424,3.683,2.192,5.482L591.11,267.07z M771.819,266.963
			l-2.76-1.178c0.767-1.795,1.503-3.641,2.188-5.484l2.812,1.045C773.357,263.234,772.604,265.125,771.819,266.963z M586.886,255.745
			c-0.612-1.909-1.188-3.859-1.714-5.796l2.896-0.784c0.513,1.892,1.075,3.798,1.675,5.664L586.886,255.745z M776.032,255.634
			l-2.857-0.915c0.596-1.86,1.157-3.766,1.67-5.665l2.896,0.781C777.217,251.779,776.642,253.73,776.032,255.634z M583.724,244.083
			c-0.438-1.959-0.835-3.953-1.184-5.926l2.955-0.521c0.34,1.929,0.729,3.878,1.156,5.793L583.724,244.083z M779.184,243.968
			l-2.928-0.65c0.424-1.908,0.811-3.857,1.151-5.795l2.955,0.52C780.014,240.023,779.617,242.017,779.184,243.968z M581.623,232.186
			c-0.259-1.976-0.479-3.996-0.654-6.004l2.988-0.261c0.172,1.966,0.387,3.942,0.641,5.875L581.623,232.186z M781.274,232.069
			l-2.975-0.388c0.254-1.947,0.468-3.924,0.636-5.875l2.988,0.258C781.752,228.059,781.534,230.079,781.274,232.069z
			 M580.575,220.154c-0.087-2.005-0.132-4.037-0.134-6.038l3-0.003c0.002,1.958,0.046,3.947,0.132,5.911L580.575,220.154z
			 M782.312,220.038l-2.998-0.129c0.084-1.956,0.127-3.943,0.127-5.909v-0.23h3V214C782.441,216.008,782.397,218.04,782.312,220.038z
			 M583.562,208.205l-2.998-0.126c0.084-1.999,0.213-4.026,0.384-6.026l2.988,0.254C583.771,204.264,583.645,206.249,583.562,208.205
			z M779.307,207.862c-0.088-1.963-0.219-3.947-0.391-5.896l2.988-0.264c0.176,1.993,0.31,4.02,0.398,6.027L779.307,207.862z
			 M584.567,196.431l-2.975-0.385c0.257-1.985,0.562-3.995,0.907-5.972l2.955,0.516C585.117,192.524,584.818,194.489,584.567,196.431
			z M778.271,196.092c-0.258-1.949-0.562-3.914-0.902-5.84l2.953-0.523c0.35,1.969,0.66,3.978,0.924,5.97L778.271,196.092z
			 M586.603,184.794l-2.93-0.648c0.434-1.957,0.917-3.932,1.438-5.869l2.896,0.779C587.499,180.95,587.025,182.88,586.603,184.794z
			 M776.205,184.459c-0.428-1.912-0.906-3.841-1.42-5.734l2.895-0.787c0.526,1.937,1.015,3.91,1.453,5.865L776.205,184.459z
			 M589.672,173.39l-2.857-0.912c0.609-1.912,1.272-3.834,1.969-5.714l2.812,1.042C590.916,169.643,590.269,171.521,589.672,173.39z
			 M773.107,173.064c-0.603-1.872-1.255-3.749-1.939-5.578l2.811-1.051c0.7,1.873,1.368,3.794,1.984,5.71L773.107,173.064z
			 M593.778,162.319l-2.76-1.174c0.789-1.857,1.63-3.709,2.499-5.507l2.701,1.306C595.37,158.698,594.55,160.506,593.778,162.319z
			 M768.971,162.006c-0.777-1.813-1.604-3.62-2.454-5.37l2.697-1.312c0.872,1.792,1.718,3.643,2.515,5.5L768.971,162.006z
			 M598.913,151.693l-2.635-1.435c0.965-1.772,1.982-3.535,3.024-5.24l2.561,1.564C600.847,148.246,599.854,149.965,598.913,151.693z
			 M763.807,151.394c-0.949-1.731-1.947-3.447-2.965-5.102l2.555-1.572c1.044,1.696,2.067,3.456,3.041,5.23L763.807,151.394z
			 M605.065,141.629l-2.477-1.692c1.141-1.67,2.332-3.321,3.543-4.908l2.385,1.819C607.339,138.394,606.177,140.002,605.065,141.629z
			 M757.623,141.347c-1.11-1.617-2.276-3.222-3.465-4.771l2.381-1.826c1.22,1.59,2.417,3.238,3.557,4.897L757.623,141.347z
			 M612.214,132.256l-2.285-1.943c1.309-1.539,2.669-3.054,4.046-4.505l2.176,2.064C614.812,129.283,613.487,130.758,612.214,132.256
			z M750.447,131.996c-1.276-1.492-2.605-2.962-3.951-4.371l2.17-2.072c1.383,1.448,2.75,2.96,4.061,4.493L750.447,131.996z
			 M620.324,123.715l-2.057-2.185c1.473-1.386,2.997-2.741,4.53-4.026l1.928,2.299C623.235,121.052,621.755,122.368,620.324,123.715z
			 M742.312,123.481c-1.438-1.344-2.923-2.655-4.415-3.896l1.92-2.306c1.535,1.278,3.064,2.627,4.544,4.011L742.312,123.481z
			 M629.346,116.159l-1.787-2.41c1.627-1.206,3.306-2.373,4.99-3.468l1.635,2.515C632.551,113.857,630.924,114.989,629.346,116.159z
			 M733.264,115.957c-1.578-1.162-3.207-2.286-4.841-3.34l1.627-2.521c1.685,1.087,3.364,2.246,4.993,3.444L733.264,115.957z
			 M639.217,109.745l-1.473-2.613c1.765-0.995,3.576-1.941,5.385-2.812l1.301,2.703C642.68,107.865,640.926,108.781,639.217,109.745z
			 M723.394,109.592c-1.708-0.956-3.461-1.863-5.211-2.698l1.291-2.708c1.809,0.862,3.62,1.8,5.385,2.788L723.394,109.592z
			 M649.806,104.652l-1.119-2.783c1.876-0.754,3.796-1.451,5.706-2.073l0.928,2.853C653.475,103.25,651.619,103.924,649.806,104.652z
			 M712.805,104.543c-1.815-0.723-3.674-1.391-5.524-1.985l0.918-2.856c1.915,0.615,3.838,1.307,5.716,2.054L712.805,104.543z
			 M660.953,101.029l-0.73-2.91c1.959-0.491,3.957-0.919,5.94-1.272l0.525,2.953C664.774,100.141,662.844,100.554,660.953,101.029z
			 M701.631,100.954c-1.9-0.469-3.835-0.875-5.75-1.208l0.514-2.956c1.983,0.345,3.987,0.767,5.955,1.252L701.631,100.954z
			 M672.496,98.975l-0.318-2.983c2.004-0.213,4.043-0.359,6.061-0.433l0.109,2.998C676.399,98.628,674.431,98.769,672.496,98.975z
			 M690.07,98.942c-1.938-0.199-3.906-0.333-5.853-0.396l0.098-2.998c2.016,0.066,4.056,0.204,6.062,0.41L690.07,98.942z"/>
		<path fill="#39B54A" d="M592.456,309.538c-1.458,0-3.104,0.497-4.816,1.941l0,0c1.812,0.009,3.429,0.054,4.816,0.115
			L592.456,309.538L592.456,309.538z M594.446,309.843l-0.114,1.849c2.107,0.129,3.388,0.274,3.627,0.303
			c0.001,0,0.003,0,0.005,0.001c0,0,0,0,0,0c0.001,0,0.003,0,0.004,0.001c0,0,0,0,0,0c0.001,0,0.002,0,0.003,0s0.001,0,0.002,0
			c0.005,0.001,0.009,0.001,0.012,0.001c0.001,0,0.002,0,0.003,0s0.001,0,0.002,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0.001,0c0,0,0,0,0,0c0.001,0,0.002,0,0.003,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0l0,0l0,0
			C598,312,596.6,310.502,594.446,309.843L594.446,309.843z M584.928,311.494c-0.555,0.009-1.126,0.021-1.708,0.038
			c-0.008,0-0.016,0-0.024,0.001c-0.005,0-0.011,0-0.017,0c-0.009,0-0.018,0-0.027,0.001c-0.005,0-0.012,0-0.017,0.001
			c-0.01,0-0.02,0.001-0.03,0.001c-0.004,0-0.007,0-0.011,0c-1.324,0.039-2.714,0.1-4.163,0.188l0.181,2.994
			c1.946-0.118,3.918-0.193,5.862-0.224L584.928,311.494L584.928,311.494z M572.953,312.22c-0.859,0.091-1.73,0.192-2.615,0.304
			c-0.01,0.001-0.021,0.003-0.031,0.004c-0.002,0-0.008,0.001-0.011,0.001c-0.013,0.002-0.027,0.003-0.04,0.005l0,0
			c-0.015,0.002-0.03,0.004-0.045,0.006c-0.002,0,0,0-0.002,0c-1.052,0.134-2.121,0.284-3.205,0.451l0.456,2.965
			c1.905-0.293,3.86-0.547,5.81-0.753L572.953,312.22L572.953,312.22z M561.097,314.05c-1.576,0.323-3.173,0.685-4.784,1.089
			l-0.001,0c-0.012,0.003-0.024,0.006-0.037,0.009c-0.005,0.001-0.009,0.002-0.014,0.004c-0.01,0.002-0.019,0.005-0.029,0.007
			c-0.007,0.002-0.021,0.005-0.028,0.007c-0.002,0.001-0.005,0.001-0.007,0.002c-0.313,0.079-0.63,0.16-0.944,0.242l0.758,2.903
			c1.854-0.484,3.768-0.93,5.689-1.324L561.097,314.05L561.097,314.05z M549.495,317.089c-0.161,0.052-0.322,0.104-0.483,0.157
			c-0.002,0.001-0.001,0-0.003,0.001c-0.014,0.005-0.028,0.009-0.042,0.014l-0.001,0c-0.014,0.005-0.028,0.009-0.042,0.014
			l-0.003,0.001c-0.014,0.005-0.028,0.009-0.042,0.014l-0.005,0.002c-0.011,0.004-0.022,0.007-0.034,0.011
			c-0.002,0.001-0.008,0.003-0.011,0.004c-0.012,0.004-0.024,0.008-0.036,0.012c-0.005,0.002-0.006,0.002-0.011,0.004
			c-0.011,0.004-0.021,0.007-0.032,0.011c-0.002,0.001-0.005,0.002-0.007,0.002c-0.013,0.004-0.025,0.008-0.038,0.013
			c-0.002,0.001-0.01,0.003-0.012,0.004c-0.009,0.003-0.018,0.006-0.027,0.009c-0.005,0.002-0.008,0.003-0.013,0.004
			c-0.012,0.004-0.024,0.008-0.036,0.012c-0.002,0.001-0.009,0.003-0.011,0.004c-0.01,0.003-0.02,0.007-0.03,0.01
			c-0.005,0.002-0.005,0.002-0.01,0.003c-0.012,0.004-0.024,0.008-0.036,0.012c-0.002,0.001-0.011,0.004-0.014,0.005
			c-0.009,0.003-0.018,0.006-0.026,0.009c-0.005,0.002-0.009,0.003-0.013,0.004c-0.011,0.004-0.021,0.007-0.032,0.011
			c-0.007,0.002-0.013,0.004-0.02,0.007c-0.007,0.002-0.014,0.005-0.021,0.007c-0.005,0.002-0.013,0.004-0.018,0.006
			c-0.011,0.004-0.021,0.007-0.032,0.011c-0.005,0.002-0.01,0.004-0.015,0.005c-0.009,0.003-0.018,0.006-0.026,0.009
			c-0.005,0.002-0.009,0.003-0.014,0.005c-0.011,0.004-0.021,0.007-0.032,0.011c-0.005,0.002-0.014,0.005-0.019,0.006
			c-0.006,0.002-0.012,0.004-0.018,0.006c-0.01,0.003-0.023,0.008-0.033,0.011c-0.006,0.002-0.012,0.004-0.018,0.006
			c-0.005,0.002-0.016,0.005-0.021,0.007c-0.006,0.002-0.011,0.004-0.017,0.006c-0.012,0.004-0.024,0.008-0.036,0.012
			c-0.004,0.001-0.008,0.003-0.013,0.004c-0.01,0.003-0.014,0.005-0.023,0.008c-0.005,0.002-0.011,0.004-0.017,0.006
			c-0.01,0.003-0.021,0.007-0.031,0.011c-0.006,0.002-0.012,0.004-0.018,0.006c-0.005,0.002-0.017,0.006-0.021,0.007
			c-0.005,0.002-0.011,0.004-0.016,0.006c-0.01,0.003-0.025,0.008-0.035,0.012c-0.005,0.002-0.01,0.003-0.015,0.005
			c-0.007,0.002-0.015,0.005-0.022,0.007c-0.006,0.002-0.013,0.004-0.019,0.007c-0.01,0.003-0.019,0.006-0.028,0.01
			c-0.007,0.002-0.014,0.005-0.021,0.007c-0.005,0.002-0.013,0.005-0.018,0.006c-0.01,0.003-0.019,0.006-0.028,0.01
			c-0.007,0.003-0.015,0.005-0.022,0.008c-0.006,0.002-0.012,0.004-0.018,0.006c-0.007,0.003-0.015,0.005-0.022,0.008
			c-0.004,0.002-0.009,0.003-0.013,0.005c-0.012,0.004-0.028,0.009-0.04,0.014c-0.003,0.001-0.007,0.002-0.01,0.004
			c-0.027,0.009-0.052,0.018-0.079,0.027c-0.003,0.001-0.005,0.002-0.008,0.003c-0.027,0.009-0.054,0.019-0.081,0.028
			c-0.002,0.001-0.004,0.002-0.007,0.002c-0.029,0.01-0.052,0.018-0.081,0.028c-0.002,0.001-0.004,0.002-0.007,0.002
			c-0.026,0.009-0.05,0.018-0.077,0.027c-0.004,0.002-0.009,0.003-0.013,0.005c-0.01,0.003-0.022,0.008-0.031,0.011
			c-0.007,0.003-0.015,0.005-0.022,0.008c-0.007,0.003-0.013,0.005-0.021,0.007c-0.005,0.002-0.01,0.003-0.015,0.005
			c-0.012,0.004-0.025,0.009-0.037,0.013c-0.005,0.002-0.01,0.004-0.015,0.005c-0.007,0.003-0.012,0.004-0.019,0.007
			c-0.007,0.002-0.014,0.005-0.021,0.007c-0.007,0.003-0.011,0.004-0.019,0.007c-0.01,0.004-0.021,0.007-0.032,0.011
			c-0.007,0.003-0.011,0.004-0.019,0.007c-0.005,0.002-0.011,0.004-0.016,0.005c-0.012,0.004-0.025,0.009-0.037,0.013
			c-0.005,0.002-0.011,0.004-0.016,0.006c-0.007,0.003-0.011,0.004-0.018,0.007c-0.008,0.003-0.015,0.005-0.022,0.008
			c-0.005,0.002-0.011,0.004-0.016,0.006c-0.011,0.004-0.021,0.008-0.032,0.011c-0.005,0.002-0.011,0.004-0.016,0.005
			c-0.009,0.003-0.018,0.006-0.027,0.01c-0.005,0.002-0.007,0.002-0.012,0.004c-0.012,0.004-0.023,0.008-0.035,0.012
			c-0.005,0.002-0.009,0.003-0.014,0.005c-0.01,0.003-0.02,0.007-0.029,0.01c-0.002,0.001-0.008,0.003-0.01,0.004
			c-0.012,0.004-0.023,0.008-0.036,0.013c-0.004,0.002-0.009,0.003-0.014,0.005c-0.008,0.003-0.017,0.006-0.025,0.009
			c-0.007,0.003-0.013,0.005-0.021,0.007c-0.009,0.003-0.019,0.007-0.028,0.01c-0.005,0.002-0.009,0.003-0.014,0.005
			c-0.011,0.004-0.021,0.008-0.032,0.012c-0.002,0.001-0.003,0.001-0.005,0.002c-0.013,0.005-0.025,0.009-0.038,0.014
			c-0.005,0.002-0.005,0.002-0.01,0.003c-0.013,0.005-0.026,0.009-0.04,0.014v0c-0.014,0.005-0.027,0.01-0.041,0.015
			c-0.002,0.001-0.004,0.001-0.006,0.002c-0.028,0.01-0.055,0.02-0.083,0.03c-0.002,0.001-0.002,0.001-0.005,0.002
			c-0.014,0.005-0.027,0.01-0.041,0.015l-0.002,0.001c-0.013,0.005-0.026,0.009-0.039,0.014c-0.002,0.001-0.004,0.001-0.006,0.002
			c-0.028,0.01-0.057,0.021-0.085,0.031l0,0c-0.78,0.283-1.561,0.577-2.34,0.884l1.097,2.792c1.773-0.696,3.616-1.355,5.477-1.956
			L549.495,317.089L549.495,317.089z M538.335,321.482c-0.587,0.277-1.174,0.562-1.759,0.854c-0.002,0.001,0.001,0-0.001,0.001
			c-0.026,0.013-0.052,0.026-0.078,0.039c-0.005,0.002-0.004,0.002-0.009,0.005c-0.025,0.012-0.049,0.025-0.074,0.037
			c-0.005,0.002-0.006,0.003-0.011,0.005c-0.01,0.005-0.02,0.01-0.03,0.015c-0.002,0.001-0.006,0.003-0.008,0.004
			c-0.011,0.006-0.022,0.011-0.034,0.017c-0.004,0.002-0.011,0.006-0.016,0.008c-0.007,0.004-0.014,0.007-0.021,0.011
			c-0.005,0.002-0.011,0.005-0.016,0.008c-0.01,0.005-0.021,0.01-0.031,0.016c-0.007,0.004-0.011,0.006-0.018,0.009
			c-0.006,0.003-0.012,0.006-0.018,0.009c-0.007,0.004-0.012,0.006-0.019,0.01c-0.004,0.002-0.009,0.005-0.014,0.007
			c-0.012,0.006-0.028,0.014-0.04,0.02c-0.003,0.001-0.006,0.003-0.009,0.005c-0.029,0.015-0.054,0.027-0.082,0.042
			c0,0-0.001,0-0.001,0.001c-1.021,0.519-2.034,1.06-3.044,1.627l1.469,2.616c1.655-0.93,3.385-1.821,5.143-2.649L538.335,321.482
			L538.335,321.482z M527.887,327.361c-0.519,0.346-1.038,0.701-1.552,1.062c0,0,0,0-0.001,0.001
			c-0.027,0.019-0.052,0.036-0.079,0.055c-0.001,0.001-0.002,0.002-0.004,0.003c-0.027,0.019-0.05,0.035-0.077,0.055
			c-0.001,0.001-0.002,0.002-0.003,0.003c-0.009,0.006-0.016,0.011-0.025,0.018c-0.001,0.001-0.002,0.002-0.003,0.002
			c-0.025,0.018-0.049,0.035-0.074,0.053c-0.003,0.002-0.006,0.005-0.009,0.007c-0.007,0.005-0.016,0.011-0.023,0.016
			c0,0-0.001,0.001-0.001,0.001c-0.016,0.011-0.033,0.023-0.049,0.035c-0.002,0.001-0.004,0.003-0.005,0.004
			c-0.009,0.006-0.015,0.01-0.023,0.017c-0.002,0.002-0.005,0.003-0.007,0.005c-0.014,0.01-0.031,0.022-0.044,0.032
			c-0.002,0.001-0.004,0.003-0.006,0.004c-0.007,0.005-0.013,0.009-0.02,0.014c-0.004,0.003-0.009,0.007-0.014,0.01
			c-0.007,0.005-0.011,0.008-0.018,0.013c-0.005,0.003-0.01,0.007-0.015,0.01c-0.011,0.008-0.022,0.016-0.034,0.024
			c-0.004,0.003-0.007,0.005-0.011,0.008c-0.007,0.005-0.014,0.01-0.021,0.015c-0.004,0.003-0.008,0.006-0.012,0.009
			c-0.009,0.006-0.019,0.014-0.028,0.02c-0.006,0.005-0.013,0.009-0.019,0.014c-0.007,0.005-0.011,0.008-0.018,0.013
			c-0.005,0.004-0.01,0.007-0.015,0.011c-0.007,0.005-0.012,0.008-0.018,0.013c-0.006,0.004-0.011,0.008-0.017,0.012
			c-0.009,0.006-0.02,0.014-0.029,0.021c-0.004,0.003-0.009,0.007-0.014,0.01c-0.004,0.003-0.015,0.011-0.019,0.014
			c-0.004,0.003-0.009,0.006-0.013,0.009c-0.014,0.01-0.025,0.018-0.039,0.028c-0.002,0.002-0.005,0.004-0.008,0.006
			c-0.007,0.005-0.013,0.009-0.02,0.014c-0.004,0.003-0.009,0.007-0.014,0.01c-0.007,0.005-0.011,0.008-0.018,0.013
			c-0.005,0.004-0.011,0.008-0.016,0.012c-0.012,0.008-0.02,0.014-0.031,0.022c-0.004,0.003-0.009,0.007-0.014,0.01
			c-0.004,0.003-0.014,0.01-0.019,0.014c-0.004,0.003-0.008,0.006-0.012,0.009c-0.014,0.01-0.027,0.019-0.04,0.029
			c-0.002,0.002-0.004,0.003-0.007,0.005c-0.007,0.005-0.013,0.009-0.02,0.014c-0.004,0.003-0.009,0.006-0.013,0.009
			c-0.006,0.005-0.013,0.009-0.02,0.014c-0.004,0.003-0.008,0.006-0.012,0.009c-0.011,0.008-0.023,0.017-0.034,0.025
			c-0.004,0.003-0.008,0.006-0.012,0.009c-0.007,0.005-0.013,0.009-0.019,0.014c-0.005,0.004-0.01,0.007-0.015,0.01
			c-0.013,0.01-0.026,0.019-0.04,0.029c-0.001,0.001-0.003,0.002-0.005,0.003c-0.007,0.005-0.016,0.011-0.022,0.016
			c-0.002,0.002-0.005,0.004-0.008,0.006c-0.007,0.005-0.016,0.011-0.022,0.016c-0.001,0.001-0.003,0.002-0.005,0.004
			c-0.013,0.01-0.031,0.023-0.045,0.033c-0.002,0.002-0.004,0.003-0.006,0.005c-0.009,0.007-0.014,0.01-0.023,0.017
			c-0.002,0.002-0.005,0.004-0.008,0.006c-0.024,0.018-0.048,0.036-0.073,0.054c-0.002,0.001-0.003,0.002-0.005,0.004
			c-0.027,0.02-0.05,0.037-0.077,0.057c0,0-0.001,0.001-0.001,0.001c-0.009,0.007-0.018,0.013-0.026,0.019c0,0,0,0-0.001,0
			c-0.027,0.02-0.053,0.039-0.08,0.059c0,0,0,0,0,0c-0.572,0.425-1.14,0.859-1.704,1.304l1.857,2.356
			c1.49-1.174,3.058-2.311,4.663-3.38L527.887,327.361L527.887,327.361z M518.477,334.786c-0.706,0.658-1.397,1.33-2.083,2.025
			c-0.001,0.001-0.003,0.003-0.004,0.004c-0.024,0.025-0.043,0.044-0.068,0.069c-0.001,0.001-0.002,0.003-0.004,0.004
			c-0.008,0.008-0.012,0.013-0.021,0.021c-0.002,0.002-0.004,0.005-0.007,0.007c-0.021,0.021-0.043,0.044-0.063,0.064
			c-0.003,0.003-0.006,0.006-0.009,0.009c-0.006,0.006-0.011,0.012-0.018,0.018c-0.003,0.004-0.007,0.008-0.011,0.011
			c-0.006,0.006-0.011,0.011-0.018,0.018c-0.003,0.003-0.006,0.006-0.009,0.009c-0.01,0.01-0.021,0.022-0.031,0.032
			c-0.004,0.004-0.009,0.009-0.013,0.013c-0.004,0.004-0.011,0.012-0.015,0.016c-0.005,0.005-0.01,0.01-0.015,0.015
			c-0.006,0.006-0.008,0.008-0.014,0.015c-0.009,0.009-0.018,0.018-0.026,0.027c-0.004,0.004-0.008,0.008-0.012,0.012
			c-0.006,0.006-0.012,0.012-0.018,0.018c-0.004,0.004-0.009,0.009-0.013,0.013c-0.006,0.006-0.013,0.013-0.019,0.019
			c-0.004,0.004-0.006,0.006-0.01,0.011c-0.01,0.01-0.019,0.02-0.029,0.029c-0.002,0.002-0.004,0.004-0.006,0.006
			c-0.008,0.008-0.016,0.016-0.023,0.024c-0.004,0.004-0.005,0.005-0.009,0.009c-0.008,0.009-0.017,0.017-0.025,0.026l-0.004,0.004
			c-0.01,0.011-0.021,0.022-0.031,0.033l0,0.001c-0.011,0.011-0.021,0.021-0.031,0.032l-0.002,0.002
			c-0.522,0.541-1.038,1.093-1.547,1.657l2.225,2.012c1.276-1.411,2.632-2.788,4.03-4.092L518.477,334.786L518.477,334.786z
			 M510.439,343.676c-0.896,1.179-1.767,2.401-2.608,3.667c-0.001,0.002-0.002,0.003-0.003,0.005
			c-0.007,0.01-0.012,0.017-0.019,0.028c-0.001,0.001-0.002,0.003-0.003,0.005c-0.012,0.018-0.022,0.033-0.034,0.051
			c-0.002,0.003-0.004,0.006-0.006,0.009c-0.003,0.005-0.009,0.013-0.012,0.018c-0.004,0.006-0.008,0.013-0.013,0.019
			c-0.003,0.005-0.007,0.01-0.01,0.015c-0.005,0.008-0.01,0.016-0.015,0.023c-0.002,0.003-0.006,0.01-0.008,0.012
			c-0.016,0.025-0.033,0.049-0.049,0.074l-0.002,0.003c-0.008,0.012-0.016,0.024-0.024,0.036l-0.002,0.003
			c-0.208,0.316-0.414,0.635-0.619,0.957l2.532,1.609c1.028-1.618,2.132-3.206,3.282-4.719L510.439,343.676L510.439,343.676z
			 M504.007,353.79c-0.561,1.065-1.103,2.156-1.626,3.275c0,0.001-0.001,0.002-0.001,0.003c-0.004,0.009-0.01,0.022-0.015,0.031
			c-0.001,0.003-0.003,0.006-0.004,0.009c-0.004,0.009-0.009,0.019-0.013,0.028c-0.002,0.003-0.003,0.007-0.005,0.01
			c-0.004,0.009-0.009,0.02-0.014,0.029c-0.001,0.002-0.002,0.004-0.002,0.005c-0.016,0.034-0.028,0.061-0.044,0.095
			c-0.002,0.004-0.004,0.009-0.006,0.013c-0.004,0.009-0.006,0.013-0.011,0.022c-0.002,0.006-0.005,0.012-0.008,0.018
			c-0.002,0.006-0.007,0.016-0.01,0.023c-0.002,0.005-0.005,0.011-0.007,0.016c-0.003,0.006-0.008,0.018-0.011,0.024
			c-0.002,0.005-0.004,0.01-0.007,0.015c-0.004,0.009-0.007,0.015-0.011,0.024c-0.002,0.006-0.005,0.011-0.008,0.017
			c-0.003,0.006-0.007,0.016-0.01,0.022c-0.004,0.009-0.008,0.017-0.012,0.026c-0.004,0.009-0.009,0.019-0.013,0.029
			c-0.003,0.008-0.007,0.015-0.011,0.023c-0.003,0.006-0.005,0.012-0.008,0.018c-0.003,0.007-0.007,0.015-0.01,0.022
			c-0.003,0.006-0.006,0.012-0.009,0.019c-0.003,0.008-0.007,0.015-0.01,0.023c-0.003,0.006-0.005,0.011-0.008,0.017
			c-0.004,0.008-0.008,0.017-0.012,0.025c-0.001,0.003-0.005,0.012-0.007,0.015c-0.004,0.009-0.008,0.019-0.013,0.028
			c-0.003,0.006-0.003,0.006-0.006,0.012c-0.006,0.013-0.012,0.027-0.018,0.04c-0.001,0.003-0.003,0.007-0.004,0.01
			c-0.005,0.01-0.009,0.02-0.014,0.03c-0.002,0.006-0.003,0.008-0.006,0.014c-0.004,0.009-0.008,0.018-0.012,0.026
			c-0.003,0.006-0.004,0.01-0.007,0.016c-0.004,0.008-0.008,0.017-0.011,0.025c-0.003,0.006-0.004,0.009-0.007,0.015
			c-0.004,0.009-0.008,0.018-0.012,0.026c-0.001,0.003-0.005,0.012-0.007,0.015c-0.005,0.011-0.01,0.022-0.015,0.033
			c-0.001,0.003-0.001,0.003-0.003,0.006c-0.013,0.028-0.025,0.055-0.038,0.083c-0.001,0.003-0.003,0.008-0.005,0.011
			c-0.004,0.01-0.009,0.019-0.013,0.029c-0.001,0.003-0.005,0.011-0.006,0.014c-0.004,0.009-0.008,0.018-0.012,0.028
			c-0.003,0.006-0.003,0.007-0.006,0.013c-0.004,0.01-0.008,0.019-0.013,0.029c-0.001,0.003-0.004,0.009-0.006,0.013
			c-0.005,0.011-0.01,0.022-0.014,0.033c-0.001,0.003-0.002,0.005-0.004,0.008c-0.012,0.028-0.025,0.055-0.037,0.083
			c-0.001,0.003-0.003,0.007-0.004,0.01c-0.004,0.01-0.009,0.021-0.014,0.031c-0.001,0.003-0.004,0.01-0.006,0.013
			c-0.004,0.009-0.008,0.019-0.013,0.028c-0.002,0.006-0.003,0.007-0.006,0.013c-0.004,0.01-0.009,0.02-0.013,0.03
			c-0.001,0.003-0.004,0.008-0.005,0.012c-0.005,0.012-0.011,0.024-0.016,0.036c-0.001,0.003-0.001,0.002-0.002,0.005
			c-0.006,0.014-0.013,0.028-0.019,0.042l-0.001,0.002c-0.006,0.013-0.012,0.026-0.018,0.039c-0.001,0.003-0.002,0.006-0.004,0.009
			c-0.004,0.011-0.009,0.021-0.014,0.032c-0.001,0.003-0.004,0.009-0.005,0.012c-0.004,0.01-0.008,0.02-0.013,0.03
			c-0.003,0.006-0.003,0.007-0.006,0.013c-0.004,0.009-0.008,0.018-0.012,0.028c-0.001,0.003-0.005,0.011-0.006,0.014
			c-0.005,0.011-0.01,0.022-0.015,0.033c-0.001,0.003-0.001,0.004-0.003,0.007c-0.013,0.028-0.025,0.056-0.037,0.084l-0.003,0.008
			c-0.005,0.011-0.01,0.023-0.015,0.034c-0.001,0.003-0.003,0.008-0.005,0.011c-0.004,0.01-0.009,0.02-0.013,0.03
			c-0.002,0.006-0.003,0.007-0.005,0.013c-0.004,0.01-0.009,0.02-0.013,0.03c-0.001,0.003-0.004,0.008-0.005,0.011
			c-0.005,0.012-0.011,0.025-0.017,0.037c-0.001,0.003,0,0-0.001,0.004c-0.006,0.014-0.012,0.028-0.018,0.042
			c-0.001,0.003-0.002,0.005-0.003,0.008c-0.005,0.011-0.01,0.022-0.015,0.034c-0.001,0.003-0.004,0.009-0.005,0.012
			c-0.004,0.01-0.009,0.02-0.013,0.03c-0.001,0.003-0.004,0.009-0.005,0.012c-0.005,0.01-0.009,0.021-0.014,0.031l-0.002,0.006
			l2.754,1.189c0.767-1.776,1.604-3.528,2.488-5.205L504.007,353.79L504.007,353.79z M499.245,364.793
			c-0.643,1.844-1.239,3.755-1.781,5.727l2.893,0.796c0.518-1.881,1.097-3.744,1.721-5.536L499.245,364.793L499.245,364.793z
			 M496.054,376.352c-0.025,0.119-0.05,0.239-0.075,0.359v0.002c-0.006,0.029-0.012,0.057-0.018,0.086
			c0,0.004-0.001,0.006-0.002,0.009c-0.005,0.027-0.011,0.054-0.016,0.08c-0.001,0.004-0.002,0.008-0.002,0.012
			c-0.005,0.023-0.01,0.047-0.014,0.07c-0.002,0.008-0.004,0.018-0.005,0.026c-0.004,0.02-0.008,0.039-0.012,0.059
			c-0.002,0.012-0.005,0.025-0.007,0.036c-0.003,0.016-0.006,0.031-0.009,0.047c-0.003,0.016-0.007,0.036-0.01,0.051
			c-0.002,0.012-0.005,0.024-0.007,0.036c-0.005,0.023-0.008,0.039-0.013,0.062c-0.001,0.008-0.003,0.017-0.005,0.025
			c-0.004,0.023-0.01,0.051-0.015,0.074c-0.001,0.005-0.002,0.01-0.003,0.016c-0.222,1.115-0.428,2.245-0.619,3.397
			c0,0.005-0.001,0.01-0.002,0.014c-0.004,0.024-0.008,0.049-0.012,0.073c-0.001,0.008-0.003,0.017-0.004,0.025
			c-0.003,0.016-0.005,0.029-0.007,0.045c-0.003,0.018-0.006,0.036-0.009,0.054c-0.002,0.012-0.004,0.025-0.006,0.037
			c-0.003,0.02-0.006,0.041-0.01,0.061c-0.001,0.008-0.004,0.023-0.005,0.031c-0.004,0.022-0.007,0.045-0.011,0.067
			c-0.001,0.008-0.002,0.018-0.004,0.026c-0.004,0.024-0.008,0.048-0.012,0.072c0,0.004-0.002,0.014-0.003,0.018
			c-0.004,0.027-0.009,0.055-0.013,0.082c0,0.004,0,0.003-0.001,0.007c-0.045,0.281-0.089,0.564-0.132,0.847l2.966,0.45
			c0.294-1.944,0.643-3.875,1.034-5.74L496.054,376.352L496.054,376.352z M494.246,388.209c-0.018,0.18-0.036,0.363-0.053,0.544
			c0,0.002,0,0.004,0,0.006c-0.003,0.03-0.005,0.053-0.008,0.083c-0.001,0.012-0.002,0.024-0.003,0.035
			c0,0.009-0.002,0.026-0.003,0.034c-0.002,0.021-0.004,0.043-0.006,0.064c0,0.009-0.001,0.013-0.002,0.021
			c-0.002,0.025-0.004,0.051-0.007,0.076l0,0.003c-0.059,0.636-0.113,1.278-0.163,1.924l0,0l0,0
			c-0.002,0.033-0.005,0.066-0.007,0.098l0,0.005c-0.064,0.838-0.127,1.67-0.189,2.494c-0.015,0.202-0.03,0.398-0.044,0.599
			l2.992,0.222c0.078-1.052,0.159-2.115,0.241-3.189c0.071-0.919,0.151-1.827,0.24-2.724L494.246,388.209L494.246,388.209z
			 M493.328,400.187c-0.142,2.038-0.274,4.033-0.399,5.986l2.994,0.191c0.125-1.948,0.257-3.937,0.398-5.97L493.328,400.187
			L493.328,400.187z M492.564,412.16c-0.072,1.241-0.141,2.465-0.207,3.67c0,0.009-0.001,0.012-0.001,0.022
			c-0.001,0.021-0.002,0.042-0.003,0.062c-0.001,0.019-0.001,0.029-0.002,0.048c0,0.006,0,0.011-0.001,0.017
			c-0.04,0.729-0.078,1.454-0.115,2.17l2.996,0.156c0.101-1.943,0.21-3.933,0.329-5.971L492.564,412.16L492.564,412.16z
			 M491.942,424.143c-0.039,0.864-0.077,1.718-0.112,2.562c0,0.004,0,0.007,0,0.012c-0.001,0.023-0.002,0.047-0.003,0.071
			c0,0.013-0.001,0.018-0.001,0.031c0,0.018-0.001,0.035-0.002,0.052c-0.026,0.618-0.051,1.228-0.075,1.835
			c0,0.012-0.001,0.025-0.001,0.037c0,0.013-0.001,0.029-0.001,0.041c-0.001,0.022-0.002,0.045-0.003,0.067c0,0.004,0,0.003,0,0.007
			c-0.017,0.429-0.034,0.856-0.05,1.28l2.998,0.115c0.074-1.938,0.157-3.929,0.25-5.975L491.942,424.143L491.942,424.143z
			 M491.485,436.134c-0.063,2.058-0.117,4.06-0.16,5.996l2.999,0.068c0.044-1.93,0.097-3.919,0.16-5.972L491.485,436.134
			L491.485,436.134z M491.214,448.129c-0.008,0.573-0.015,1.135-0.022,1.697c0,0.042-0.001,0.084-0.001,0.127
			c0,0.003,0,0.004,0,0.008c0,0.045-0.001,0.091-0.001,0.136v0.002c-0.016,1.376-0.026,2.72-0.033,4.032l3,0.016
			c0.01-1.922,0.029-3.912,0.058-5.974L491.214,448.129L491.214,448.129z M494.151,460.118l-3,0.011
			c0.008,2.08,0.025,4.077,0.049,5.998l3-0.038C494.176,464.177,494.159,462.19,494.151,460.118L494.151,460.118z M494.3,472.065
			l-3,0.062c0.042,2.05,0.09,4.006,0.139,5.888c0.001,0.036,0.002,0.072,0.003,0.107l2.999-0.079
			C494.392,476.135,494.343,474.146,494.3,472.065L494.3,472.065z M494.606,484.038l-2.999,0.083c0.04,1.439,0.078,2.833,0.111,4.191
			c0,0.01,0,0.017,0.001,0.027c0,0.001,0,0.003,0,0.004c0,0.012,0,0.027,0.001,0.038c0,0.004,0,0.009,0,0.013
			c0.015,0.582,0.028,1.154,0.041,1.724l2.999-0.066C494.718,488.125,494.665,486.128,494.606,484.038L494.606,484.038z
			 M494.85,496.101l-3,0.016c0.009,1.6,0.003,3.161-0.023,4.705c0,0.001,0,0.002,0,0.004c0,0.03-0.001,0.055-0.001,0.085
			c0,0.004,0,0.007,0,0.011c0,0.025-0.001,0.051-0.001,0.075c0,0.007,0,0.015,0,0.022c0,0.023,0,0.045-0.001,0.068
			c0,0.009,0,0.019,0,0.028c0,0.019-0.001,0.039-0.001,0.059c0,0.015,0,0.03,0,0.045c0,0.012,0,0.024-0.001,0.036
			c-0.005,0.288-0.012,0.574-0.019,0.861l2.999,0.074C494.845,500.297,494.862,498.306,494.85,496.101L494.85,496.101z
			 M491.53,508.109c-0.109,1.631-0.251,3.268-0.431,4.93c0,0.003-0.001,0.006-0.001,0.008c-0.003,0.026-0.006,0.053-0.009,0.079
			c-0.001,0.008-0.001,0.015-0.002,0.023c-0.002,0.021-0.004,0.042-0.007,0.063c-0.001,0.014-0.003,0.027-0.004,0.041
			c-0.001,0.014-0.003,0.029-0.005,0.043c-0.029,0.261-0.059,0.523-0.089,0.786l2.979,0.349c0.238-2.034,0.422-4.036,0.562-6.121
			L491.53,508.109L491.53,508.109z M490.137,520.021c-0.241,1.444-0.513,2.923-0.82,4.447v0.001
			c-0.003,0.014-0.006,0.028-0.009,0.043c-0.001,0.006-0.001,0.008-0.003,0.014c-0.001,0.008-0.003,0.017-0.005,0.025
			c-0.002,0.01-0.004,0.019-0.006,0.029c-0.001,0.005-0.002,0.01-0.003,0.015c-0.088,0.437-0.179,0.876-0.273,1.32l2.935,0.621
			c0.433-2.047,0.818-4.073,1.143-6.022L490.137,520.021L490.137,520.021z M487.596,531.746c-0.246,0.893-0.504,1.782-0.773,2.667
			v0.001c-0.033,0.109-0.067,0.219-0.1,0.328c-0.002,0.006-0.004,0.012-0.005,0.018c-0.008,0.025-0.016,0.05-0.023,0.075
			c-0.002,0.006-0.004,0.013-0.006,0.019c-0.007,0.024-0.015,0.048-0.022,0.072c-0.002,0.008-0.005,0.017-0.008,0.025
			c-0.007,0.022-0.014,0.044-0.021,0.066c-0.003,0.01-0.005,0.017-0.008,0.027c-0.006,0.021-0.013,0.041-0.019,0.062
			c-0.003,0.01-0.007,0.021-0.01,0.031c-0.006,0.02-0.013,0.041-0.019,0.061c-0.003,0.01-0.006,0.019-0.009,0.029
			c-0.007,0.022-0.014,0.044-0.021,0.066c-0.002,0.008-0.005,0.015-0.007,0.023c-0.007,0.024-0.015,0.049-0.023,0.073
			c-0.001,0.006-0.004,0.013-0.005,0.018c-0.01,0.03-0.02,0.061-0.029,0.091v0c-0.005,0.015-0.01,0.031-0.015,0.047
			c-0.001,0.002-0.002,0.005-0.002,0.007c-0.009,0.029-0.018,0.058-0.027,0.087l0,0.001c-0.01,0.031-0.02,0.062-0.029,0.093v0
			c-0.185,0.585-0.375,1.168-0.569,1.75l2.846,0.949c0.662-1.985,1.267-3.966,1.797-5.89L487.596,531.746L487.596,531.746z
			 M483.814,543.129c-0.27,0.7-0.546,1.402-0.826,2.096c0,0.001,0,0.001,0,0.002c-0.01,0.025-0.021,0.053-0.031,0.077
			c-0.002,0.005-0.004,0.009-0.006,0.014c-0.009,0.023-0.019,0.046-0.028,0.069c-0.003,0.007-0.006,0.015-0.009,0.022
			c-0.008,0.021-0.017,0.041-0.025,0.062c-0.004,0.01-0.008,0.02-0.012,0.03c-0.007,0.017-0.015,0.038-0.022,0.055
			c-0.005,0.012-0.01,0.025-0.015,0.037c-0.006,0.015-0.014,0.033-0.02,0.048c-0.288,0.709-0.581,1.415-0.878,2.118
			c-0.007,0.017-0.016,0.037-0.023,0.054c-0.004,0.01-0.008,0.02-0.012,0.029c-0.009,0.021-0.018,0.042-0.026,0.062
			c-0.002,0.006-0.005,0.012-0.008,0.018c-0.01,0.024-0.021,0.05-0.031,0.074c0,0.001-0.001,0.002-0.001,0.003
			c-0.097,0.229-0.195,0.458-0.293,0.686l2.756,1.185c0.825-1.92,1.602-3.825,2.31-5.662L483.814,543.129L483.814,543.129z
			 M479.089,554.158c-0.005,0.012-0.011,0.024-0.017,0.036c-0.004,0.009-0.009,0.02-0.014,0.029c-0.004,0.01-0.009,0.02-0.014,0.03
			c-0.012,0.026-0.023,0.049-0.035,0.074c0,0.001-0.001,0.002-0.001,0.003c-0.627,1.337-1.266,2.665-1.911,3.976
			c0,0.001-0.001,0.003-0.002,0.004c-0.012,0.025-0.024,0.048-0.036,0.073c-0.003,0.007-0.007,0.014-0.011,0.021
			c-0.008,0.016-0.016,0.032-0.023,0.048c-0.008,0.016-0.016,0.032-0.023,0.048c-0.003,0.005-0.003,0.006-0.006,0.011
			c-0.172,0.351-0.346,0.7-0.52,1.049l2.685,1.338c0.952-1.91,1.817-3.699,2.645-5.47L479.089,554.158L479.089,554.158z
			 M473.75,564.903c-0.209,0.401-0.418,0.8-0.627,1.198c-0.009,0.017-0.016,0.032-0.025,0.049c-0.004,0.008-0.009,0.017-0.013,0.026
			c-0.012,0.022-0.024,0.045-0.036,0.067c-0.001,0.002-0.002,0.005-0.004,0.007c-0.013,0.026-0.026,0.05-0.04,0.076
			c-0.001,0.003-0.003,0.006-0.005,0.009c-0.688,1.308-1.375,2.599-2.057,3.873l2.645,1.416c0.935-1.747,1.879-3.525,2.822-5.334
			L473.75,564.903L473.75,564.903z M468.101,575.492c-0.141,0.262-0.281,0.521-0.421,0.782c-0.338,0.629-0.674,1.254-1.005,1.873
			c-0.476,0.889-0.944,1.769-1.402,2.637l2.653,1.401c0.906-1.715,1.85-3.473,2.817-5.27L468.101,575.492L468.101,575.492z
			 M462.523,586.118c-0.933,1.868-1.795,3.674-2.568,5.419l2.742,1.216c0.712-1.605,1.533-3.337,2.51-5.295L462.523,586.118
			L462.523,586.118z M457.738,597.111c-0.535,1.559-0.962,3.055-1.263,4.481c0,0,0,0.001,0,0.001
			c-0.006,0.027-0.011,0.053-0.017,0.08c0,0.001,0,0.002,0,0.004c-0.003,0.016-0.007,0.032-0.01,0.048
			c0,0.002-0.001,0.004-0.001,0.006c-0.005,0.025-0.011,0.052-0.016,0.076c0,0.002,0,0.004-0.001,0.005
			c-0.003,0.016-0.006,0.03-0.009,0.046c0,0.002-0.001,0.004-0.001,0.007c-0.001,0.008-0.003,0.017-0.005,0.024
			c0,0.002,0,0.004-0.001,0.006c-0.003,0.015-0.006,0.029-0.009,0.043c0,0.004-0.001,0.008-0.002,0.012
			c-0.002,0.011-0.005,0.024-0.007,0.035c-0.001,0.005-0.002,0.01-0.002,0.015c-0.001,0.007-0.003,0.014-0.004,0.021
			c-0.001,0.005-0.002,0.01-0.003,0.016c-0.002,0.011-0.004,0.021-0.006,0.032c-0.001,0.006-0.002,0.011-0.003,0.017
			c-0.001,0.006-0.002,0.013-0.003,0.018c-0.002,0.01-0.004,0.02-0.006,0.03c-0.001,0.007-0.002,0.011-0.003,0.018
			c-0.001,0.007-0.002,0.014-0.004,0.02c-0.001,0.007-0.003,0.015-0.004,0.022c-0.001,0.008-0.003,0.017-0.005,0.025
			c-0.001,0.006-0.002,0.011-0.003,0.016c-0.002,0.011-0.004,0.022-0.006,0.032c-0.001,0.004-0.001,0.006-0.002,0.011
			c-0.002,0.01-0.004,0.019-0.005,0.029c-0.001,0.003-0.001,0.006-0.002,0.009c-0.002,0.012-0.004,0.024-0.006,0.036
			c-0.001,0.003-0.001,0.006-0.002,0.009c-0.002,0.013-0.004,0.025-0.007,0.038c0,0.001,0,0,0,0.001
			c-0.002,0.013-0.005,0.026-0.007,0.039c0,0.002,0,0.003,0,0.005c-0.002,0.013-0.005,0.027-0.007,0.04l0,0.001
			c-0.005,0.028-0.01,0.056-0.015,0.085v0.001c-0.02,0.114-0.039,0.228-0.057,0.342l2.963,0.47c0.251-1.587,0.704-3.32,1.382-5.299
			L457.738,597.111L457.738,597.111z M455.808,608.888c-0.053,1.228-0.107,2.586-0.162,4.057c0,0.01,0,0.017-0.001,0.028
			c0,0.001,0,0.002,0,0.003c-0.023,0.617-0.046,1.252-0.07,1.907l2.998,0.108c0.08-2.222,0.157-4.229,0.232-5.973L455.808,608.888
			L455.808,608.888z M455.368,620.884c-0.04,1.184-0.081,2.406-0.123,3.662c-0.02,0.578-0.039,1.163-0.059,1.755
			c-0.007,0.191-0.013,0.385-0.02,0.578l2.998,0.101l0.078-2.333l0.123-3.661L455.368,620.884L455.368,620.884z M454.963,632.877
			c-0.052,1.516-0.106,3.058-0.162,4.624c-0.017,0.455-0.033,0.912-0.049,1.371l2.998,0.109c0.074-2.038,0.144-4.043,0.211-6
			L454.963,632.877L454.963,632.877z M454.527,644.868c-0.007,0.174-0.013,0.343-0.02,0.517c-0.05,1.292-0.102,2.592-0.155,3.895
			c-0.022,0.526-0.043,1.055-0.066,1.583l2.998,0.126c0.084-2.013,0.165-4.018,0.242-6.005L454.527,644.868L454.527,644.868z
			 M454.024,656.859c-0.092,2.004-0.188,4.009-0.29,5.994l2.997,0.152c0.101-1.991,0.197-3.997,0.29-6.008L454.024,656.859
			L454.024,656.859z M453.412,668.844c-0.023,0.412-0.047,0.819-0.071,1.228c0,0.006-0.001,0.012-0.001,0.018
			c-0.001,0.018-0.001,0.03-0.002,0.048c-0.076,1.29-0.154,2.567-0.234,3.826l0,0.005c-0.001,0.017-0.002,0.034-0.003,0.05
			c-0.001,0.025-0.003,0.052-0.005,0.077c0,0,0,0,0,0.001c-0.016,0.248-0.031,0.489-0.047,0.736l2.994,0.195
			c0.128-1.965,0.25-3.974,0.366-6.013L453.412,668.844L453.412,668.844z M452.627,680.82c-0.081,1.063-0.164,2.11-0.249,3.134
			c0,0.006-0.001,0.012-0.001,0.018c0,0.004-0.001,0.013-0.001,0.017c-0.001,0.012-0.002,0.025-0.003,0.037
			c0,0.005-0.001,0.009-0.001,0.014c-0.078,0.938-0.158,1.859-0.24,2.759l2.988,0.272c0.17-1.872,0.338-3.899,0.5-6.025
			L452.627,680.82L452.627,680.82z M451.523,692.769c-0.035,0.305-0.07,0.607-0.105,0.905c0,0.001,0,0.003,0,0.004
			c-0.002,0.015-0.004,0.034-0.006,0.049c0,0.002,0,0.003,0,0.005c-0.002,0.015-0.003,0.027-0.005,0.042
			c-0.001,0.008-0.002,0.016-0.003,0.024c0,0.007-0.001,0.013-0.002,0.02c-0.001,0.012-0.002,0.024-0.004,0.036
			c0,0.002-0.001,0.006-0.001,0.008c-0.001,0.011-0.003,0.023-0.004,0.034c0,0.004-0.001,0.01-0.001,0.014
			c-0.001,0.011-0.003,0.022-0.004,0.033c0,0.004-0.001,0.008-0.001,0.011c-0.001,0.012-0.003,0.025-0.004,0.037l0,0.003
			c-0.207,1.706-0.424,3.288-0.653,4.725l2.962,0.471c0.281-1.766,0.555-3.811,0.815-6.078L451.523,692.769L451.523,692.769z
			 M449.492,704.587c-0.08,0.268-0.161,0.52-0.243,0.756c0,0,0,0,0,0.001c-0.002,0.006-0.004,0.013-0.006,0.019
			c0,0.002-0.001,0.003-0.001,0.005c-0.001,0.004-0.003,0.008-0.004,0.013c-0.001,0.004-0.003,0.007-0.004,0.011
			c-0.001,0.003-0.001,0.004-0.002,0.006c-0.001,0.005-0.003,0.01-0.005,0.015c0,0.002-0.001,0.004-0.001,0.005
			c-0.002,0.005-0.004,0.011-0.006,0.016c0,0.002,0,0.001-0.001,0.003c-0.002,0.006-0.004,0.012-0.007,0.018v0.001
			c-0.069,0.193-0.139,0.375-0.21,0.545l0,0l0,0c-0.449,1.078-1.117,2.439-1.968,4.036l2.647,1.412
			c0.927-1.738,1.61-3.142,2.09-4.293c0.209-0.503,0.405-1.062,0.599-1.712L449.492,704.587L449.492,704.587z M444.088,715.267
			c-0.532,0.91-1.096,1.858-1.687,2.841c0,0.001-0.002,0.004-0.003,0.005c-0.006,0.01-0.012,0.019-0.018,0.029
			c-0.001,0.003-0.003,0.005-0.005,0.008c-0.005,0.009-0.01,0.018-0.016,0.026c-0.001,0.003-0.005,0.008-0.007,0.011
			c-0.005,0.009-0.011,0.018-0.016,0.026c-0.003,0.004-0.004,0.007-0.007,0.012c-0.005,0.008-0.01,0.017-0.015,0.025
			c-0.002,0.004-0.004,0.007-0.007,0.011c-0.005,0.008-0.01,0.017-0.015,0.025c-0.015,0.025-0.031,0.052-0.046,0.077
			c0,0,0,0.001-0.001,0.001c-0.402,0.667-0.815,1.348-1.241,2.045l2.56,1.565c1.143-1.87,2.19-3.617,3.113-5.194L444.088,715.267
			L444.088,715.267z M437.841,725.513c-0.033,0.052-0.062,0.1-0.095,0.152c-0.001,0.002-0.002,0.003-0.003,0.005
			c-0.009,0.015-0.021,0.034-0.031,0.049c-0.991,1.577-2.021,3.201-3.083,4.86l2.527,1.617c1.113-1.739,2.191-3.44,3.227-5.088
			L437.841,725.513L437.841,725.513z M431.377,735.623c-0.862,1.328-1.737,2.669-2.621,4.019c-0.219,0.334-0.44,0.671-0.66,1.006
			l2.508,1.646c1.112-1.694,2.211-3.376,3.289-5.038L431.377,735.623L431.377,735.623z M424.792,745.656
			c-0.566,0.854-1.133,1.708-1.701,2.561c-0.011,0.017-0.021,0.031-0.032,0.048c-0.019,0.028-0.038,0.056-0.056,0.084
			c-0.02,0.03-0.042,0.062-0.062,0.092c-0.011,0.017-0.023,0.035-0.035,0.052c-0.024,0.036-0.047,0.07-0.07,0.106
			c-0.011,0.017-0.022,0.034-0.034,0.05c-0.026,0.039-0.051,0.077-0.077,0.116c-0.008,0.012-0.016,0.023-0.023,0.035
			c-0.025,0.038-0.052,0.078-0.077,0.116c-0.01,0.015-0.02,0.029-0.029,0.044c-0.026,0.039-0.052,0.078-0.078,0.118
			c-0.008,0.013-0.017,0.025-0.025,0.038c-0.025,0.038-0.05,0.075-0.075,0.112c-0.011,0.016-0.022,0.032-0.033,0.049
			c-0.021,0.032-0.042,0.062-0.062,0.094c-0.018,0.026-0.035,0.052-0.053,0.078c-0.012,0.019-0.023,0.036-0.036,0.054
			c-0.255,0.383-0.511,0.766-0.767,1.148l2.494,1.667c1.11-1.661,2.224-3.333,3.332-5.004L424.792,745.656L424.792,745.656z
			 M418.123,755.632c-0.637,0.946-1.271,1.885-1.9,2.815c-0.001,0.001-0.002,0.003-0.003,0.004c-0.013,0.02-0.029,0.042-0.042,0.062
			c-0.475,0.702-0.947,1.398-1.415,2.089l2.482,1.684c1.105-1.63,2.23-3.293,3.366-4.979L418.123,755.632L418.123,755.632z
			 M411.385,765.564c-0.104,0.152-0.208,0.304-0.311,0.455c-0.895,1.309-1.77,2.585-2.619,3.819c-0.155,0.226-0.31,0.45-0.463,0.674
			l2.471,1.701c1.092-1.586,2.228-3.243,3.398-4.956L411.385,765.564L411.385,765.564z M404.582,775.448
			c-0.304,0.438-0.603,0.869-0.896,1.29c-0.905,1.301-1.755,2.517-2.539,3.633l2.456,1.724c1.04-1.482,2.196-3.137,3.444-4.936
			L404.582,775.448L404.582,775.448z M397.883,785.396c-0.673,1.203-1.36,2.544-2.043,4.01c-0.003,0.007-0.007,0.015-0.01,0.022
			c-0.002,0.005-0.005,0.012-0.008,0.017c-0.005,0.01-0.01,0.02-0.014,0.031c-0.002,0.003-0.004,0.009-0.006,0.012
			c-0.006,0.013-0.012,0.026-0.018,0.039c0,0.002-0.001,0.002-0.002,0.004c-0.006,0.014-0.013,0.028-0.019,0.042l-0.001,0.002
			c-0.181,0.39-0.361,0.789-0.541,1.196l2.744,1.212c0.821-1.859,1.674-3.582,2.535-5.12L397.883,785.396L397.883,785.396z
			 M393.002,796.342c-0.182,0.506-0.361,1.02-0.538,1.542c0,0.002-0.001,0.004-0.002,0.006c-0.004,0.013-0.009,0.026-0.013,0.039
			c-0.001,0.002-0.001,0.004-0.002,0.006c-0.003,0.01-0.007,0.02-0.01,0.03c-0.001,0.004-0.003,0.011-0.005,0.015
			c-0.003,0.01-0.007,0.02-0.01,0.029c-0.002,0.006-0.005,0.013-0.007,0.02c-0.001,0.005-0.003,0.009-0.005,0.014
			c-0.004,0.013-0.007,0.022-0.012,0.035c-0.001,0.004-0.003,0.009-0.004,0.013c-0.004,0.013-0.009,0.028-0.014,0.041
			c-0.001,0.002-0.001,0.004-0.002,0.006c-0.003,0.009-0.006,0.017-0.009,0.025c0,0,0,0,0,0.001c-0.128,0.383-0.255,0.768-0.381,1.16
			c0,0.001,0,0.001-0.001,0.002c-0.008,0.027-0.016,0.05-0.024,0.077c-0.001,0.005-0.003,0.009-0.004,0.014
			c-0.003,0.009-0.007,0.023-0.01,0.032c-0.002,0.007-0.004,0.014-0.006,0.021c-0.002,0.007-0.005,0.014-0.007,0.021
			c-0.003,0.01-0.006,0.019-0.009,0.029c-0.001,0.004-0.003,0.01-0.004,0.014c-0.004,0.012-0.008,0.024-0.011,0.036
			c-0.001,0.005-0.001,0.003-0.003,0.008c-0.003,0.011-0.007,0.022-0.01,0.033c-0.001,0.002-0.002,0.006-0.003,0.008
			c-0.004,0.013-0.008,0.026-0.012,0.039c0,0.002-0.001,0.005-0.002,0.007c-0.004,0.014-0.009,0.028-0.013,0.042v0
			c-0.243,0.767-0.48,1.549-0.711,2.346l2.882,0.834c0.543-1.878,1.145-3.739,1.788-5.53L393.002,796.342L393.002,796.342z
			 M389.662,807.86c-0.097,0.429-0.191,0.862-0.284,1.297c-0.001,0.005-0.002,0.009-0.003,0.014
			c-0.001,0.008-0.003,0.016-0.005,0.025c-0.002,0.008-0.003,0.016-0.005,0.024c-0.002,0.009-0.004,0.018-0.006,0.026
			c-0.001,0.005-0.003,0.015-0.004,0.02c-0.001,0.005-0.002,0.01-0.003,0.014c-0.006,0.028-0.011,0.054-0.017,0.082
			c0,0.001,0,0.003-0.001,0.004c-0.3,1.43-0.574,2.89-0.818,4.382l2.96,0.486c0.312-1.904,0.687-3.826,1.112-5.714L389.662,807.86
			L389.662,807.86z M387.723,819.696c-0.064,0.627-0.123,1.259-0.175,1.894v0.001c-0.001,0.014-0.002,0.028-0.003,0.042
			c0,0.003,0,0.004,0,0.007c-0.002,0.028-0.004,0.056-0.007,0.084c0,0.006,0,0.009-0.001,0.015c-0.001,0.009-0.002,0.019-0.002,0.028
			c0,0.006-0.001,0.009-0.001,0.015c-0.001,0.013-0.002,0.025-0.003,0.038c0,0.006-0.001,0.014-0.001,0.019
			c0,0.007-0.001,0.013-0.001,0.02c0,0.006-0.001,0.016-0.002,0.022c0,0.006-0.001,0.011-0.001,0.017
			c-0.001,0.014-0.002,0.027-0.003,0.041c0,0.006-0.001,0.012-0.001,0.018c0,0.009-0.001,0.016-0.002,0.025
			c0,0.004,0,0.008-0.001,0.012c0,0.008-0.001,0.021-0.002,0.029c0,0.001,0,0.002,0,0.003c-0.051,0.657-0.096,1.315-0.134,1.98
			c0,0.001,0,0.001,0,0.002c-0.002,0.043-0.005,0.089-0.007,0.132c0,0.003,0,0.005,0,0.008c-0.002,0.034-0.003,0.063-0.005,0.097
			c0,0.001,0,0.002,0,0.003c0,0.009-0.001,0.02-0.001,0.029c0,0.004,0,0.008-0.001,0.012c-0.001,0.029-0.003,0.059-0.004,0.088
			c0,0.004,0,0.008-0.001,0.012c0,0.009,0,0.016-0.001,0.024c0,0.007-0.001,0.013-0.001,0.02c0,0.009-0.001,0.021-0.002,0.029
			c0,0.009-0.001,0.018-0.001,0.027c0,0.009,0,0.013-0.001,0.022c0,0.006,0,0.012-0.001,0.019c0,0.006-0.001,0.016-0.001,0.022
			c0,0.009-0.001,0.019-0.001,0.028c0,0.006-0.001,0.014-0.001,0.02c0,0.01-0.001,0.021-0.001,0.032c0,0.006-0.001,0.012-0.001,0.017
			c0,0.009-0.001,0.018-0.001,0.028c0,0.003,0,0.01,0,0.013c-0.001,0.014-0.001,0.028-0.002,0.042c0,0.003,0,0.005,0,0.008
			c-0.001,0.012-0.001,0.023-0.002,0.035c0,0.006,0,0.008,0,0.013c0,0.011-0.001,0.022-0.002,0.034c0,0.003,0,0.006,0,0.009
			c0,0.014-0.001,0.028-0.002,0.041c0,0.006,0,0.007,0,0.013c0,0.01-0.001,0.021-0.001,0.031c0,0.006,0,0.007,0,0.013
			c-0.001,0.029-0.003,0.058-0.004,0.087c0,0.003,0,0.002,0,0.005c0,0.015-0.001,0.03-0.002,0.044l0,0
			c-0.009,0.205-0.018,0.411-0.026,0.617l2.998,0.118c0.075-1.914,0.214-3.865,0.412-5.797L387.723,819.696L387.723,819.696z
			 M390.256,831.601l-2.999,0.078c0.051,1.977,0.169,3.975,0.36,5.988l2.986-0.284C390.423,835.485,390.306,833.539,390.256,831.601
			L390.256,831.601z M391.355,843.119l-2.958,0.497c0.305,1.813,0.674,3.634,1.112,5.457c0,0.003,0.001,0.006,0.002,0.009
			c0.002,0.01,0.005,0.021,0.007,0.031c0.002,0.006,0.003,0.011,0.004,0.017c0.002,0.008,0.004,0.016,0.006,0.025
			c0.001,0.006,0.003,0.011,0.004,0.017c0.001,0.007,0.003,0.014,0.005,0.021c0.023,0.098,0.048,0.197,0.072,0.295l2.913-0.717
			C392.068,846.924,391.675,845.022,391.355,843.119L391.355,843.119z M394.117,854.315l-2.849,0.941
			c0.007,0.022,0.014,0.042,0.021,0.064c0.002,0.006,0.004,0.012,0.006,0.018s0.004,0.013,0.006,0.019
			c0.002,0.008,0.005,0.016,0.008,0.024c0.002,0.006,0.004,0.011,0.006,0.017c0.002,0.008,0.005,0.015,0.007,0.023
			c0.002,0.006,0.004,0.013,0.006,0.019c0.003,0.008,0.005,0.017,0.008,0.025c0.002,0.006,0.003,0.009,0.005,0.015
			c0.009,0.027,0.018,0.054,0.027,0.081c0.002,0.006,0.002,0.009,0.005,0.015c0.003,0.009,0.006,0.017,0.009,0.026
			c0.002,0.006,0.003,0.011,0.006,0.017c0.002,0.008,0.005,0.016,0.008,0.024c0.002,0.006,0.003,0.009,0.005,0.015
			c0.003,0.01,0.006,0.02,0.01,0.029c0.001,0.003,0.003,0.009,0.004,0.012c0.009,0.027,0.019,0.054,0.028,0.082
			c0.002,0.006,0.002,0.008,0.004,0.014c0.003,0.009,0.006,0.018,0.009,0.027c0.002,0.006,0.004,0.011,0.006,0.017
			c0.002,0.008,0.005,0.015,0.008,0.023c0.002,0.006,0.004,0.012,0.006,0.018c0.003,0.008,0.005,0.016,0.008,0.023
			c0.002,0.006,0.004,0.012,0.006,0.018c0.003,0.008,0.006,0.016,0.008,0.025c0.002,0.006,0.003,0.01,0.005,0.016
			c0.004,0.013,0.009,0.025,0.013,0.038c0.002,0.006,0.005,0.015,0.007,0.021c0.002,0.007,0.005,0.013,0.007,0.02
			c0.003,0.009,0.004,0.013,0.008,0.022c0.002,0.006,0.004,0.012,0.006,0.018c0.002,0.006,0.006,0.016,0.008,0.022
			c0.002,0.006,0.004,0.012,0.006,0.017c0.003,0.009,0.005,0.015,0.008,0.024c0.001,0.004,0.003,0.009,0.004,0.013
			c0.011,0.031,0.02,0.057,0.03,0.088c0.002,0.005,0.003,0.01,0.005,0.015c0.003,0.009,0.006,0.018,0.009,0.027
			c0.001,0.004,0.002,0.007,0.004,0.011c0.004,0.012,0.005,0.016,0.01,0.028c0.001,0.004,0.002,0.007,0.004,0.01
			c0.003,0.009,0.007,0.021,0.01,0.03c0,0.002,0.001,0.003,0.001,0.005c0.017,0.049,0.033,0.093,0.05,0.142c0,0.001,0,0.001,0,0.002
			c0.004,0.012,0.007,0.021,0.012,0.033c0,0.002,0.001,0.005,0.002,0.007c0.446,1.274,0.928,2.542,1.451,3.81
			c0.002,0.005,0.004,0.011,0.007,0.016c0.002,0.006,0.006,0.015,0.009,0.021c0.003,0.007,0.005,0.013,0.008,0.02
			c0.002,0.006,0.005,0.013,0.008,0.019c0.004,0.011,0.009,0.023,0.014,0.034l0.001,0.004c0.054,0.131,0.109,0.262,0.164,0.393
			l2.765-1.163C395.398,857.951,394.718,856.136,394.117,854.315L394.117,854.315z M398.581,864.922l-2.664,1.381
			c0.045,0.088,0.094,0.18,0.14,0.268c0.001,0.003,0.003,0.005,0.004,0.008c0.005,0.009,0.01,0.018,0.015,0.027
			c0.002,0.004,0.004,0.007,0.006,0.011c0.003,0.006,0.009,0.018,0.013,0.024c0.003,0.006,0.006,0.012,0.009,0.018
			c0.003,0.006,0.007,0.013,0.01,0.02c0.003,0.007,0.007,0.013,0.011,0.02c0.003,0.006,0.006,0.012,0.009,0.018
			c0.005,0.01,0.01,0.02,0.016,0.03c0.001,0.003,0.002,0.005,0.004,0.008c0.014,0.027,0.028,0.053,0.042,0.08l0.002,0.005
			c0.006,0.011,0.012,0.023,0.018,0.034c0.001,0.003,0.003,0.006,0.005,0.009c0.006,0.011,0.011,0.021,0.017,0.032
			c0.001,0.003,0.001,0.003,0.003,0.006c0.006,0.011,0.012,0.023,0.018,0.034c0.002,0.003,0.002,0.004,0.003,0.007
			c0.116,0.217,0.232,0.434,0.35,0.651c0.001,0.003-0.001-0.002,0,0c0.044,0.082,0.089,0.163,0.133,0.245l0,0
			c0.007,0.013,0.014,0.026,0.021,0.04l0.001,0.002c0.007,0.013,0.014,0.025,0.021,0.038c0.001,0.003,0,0,0.002,0.003
			c0.029,0.054,0.059,0.108,0.089,0.161l0.002,0.004c0.006,0.011,0.013,0.023,0.019,0.034c0.001,0.003,0.003,0.005,0.004,0.008
			c0.006,0.01,0.011,0.021,0.017,0.031c0.002,0.003,0.004,0.007,0.006,0.01c0.005,0.01,0.011,0.02,0.017,0.03
			c0.002,0.003,0.003,0.005,0.005,0.008c0.005,0.01,0.011,0.02,0.017,0.03c0.001,0.003,0.005,0.009,0.006,0.012
			c0.005,0.009,0.01,0.018,0.016,0.028c0.003,0.006,0.003,0.005,0.006,0.011c0.004,0.008,0.009,0.017,0.014,0.025
			c0.005,0.009,0.01,0.018,0.015,0.027c0.004,0.008,0.009,0.016,0.014,0.025c0.005,0.009,0.006,0.012,0.012,0.021
			c0.003,0.005,0.005,0.01,0.008,0.015c0.005,0.009,0.009,0.016,0.014,0.025c0.002,0.004,0.005,0.008,0.007,0.013
			c0.005,0.009,0.01,0.018,0.015,0.027c0.001,0.003,0.003,0.006,0.005,0.009c0.005,0.009,0.01,0.018,0.016,0.027
			c0.002,0.004,0.004,0.008,0.006,0.011c0.005,0.009,0.011,0.019,0.016,0.028c0.001,0.002,0.002,0.003,0.002,0.005
			c0.557,0.989,1.136,1.966,1.747,2.946l2.545-1.588C400.419,868.3,399.459,866.616,398.581,864.922L398.581,864.922z
			 M404.675,874.691l-2.413,1.782c0.62,0.839,1.262,1.674,1.928,2.503v0c0.019,0.024,0.038,0.047,0.057,0.071
			c0.002,0.003,0.005,0.006,0.007,0.009c0.007,0.009,0.015,0.018,0.022,0.027c0.002,0.003,0.007,0.009,0.009,0.012
			c0.006,0.007,0.012,0.015,0.018,0.022c0.005,0.006,0.009,0.011,0.014,0.017c0.005,0.007,0.011,0.013,0.016,0.02
			c0.004,0.006,0.007,0.009,0.012,0.015c0.005,0.006,0.01,0.013,0.016,0.019c0.004,0.006,0.009,0.012,0.014,0.017
			c0.005,0.006,0.01,0.013,0.015,0.019c0.004,0.006,0.009,0.011,0.013,0.016c0.005,0.006,0.01,0.012,0.014,0.018
			c0.005,0.006,0.011,0.013,0.015,0.019c0.005,0.006,0.01,0.012,0.015,0.018c0.005,0.006,0.01,0.012,0.015,0.018
			c0.004,0.005,0.008,0.01,0.012,0.015c0.007,0.009,0.011,0.014,0.019,0.022c0.002,0.004,0.005,0.007,0.008,0.011
			c0.007,0.009,0.013,0.016,0.021,0.025c0.002,0.003,0.004,0.005,0.006,0.008c0.007,0.009,0.016,0.02,0.023,0.028
			c0,0.001,0.001,0.002,0.002,0.003c0.112,0.138,0.223,0.273,0.337,0.411c0,0,0,0,0.001,0.001c0.365,0.442,0.738,0.884,1.116,1.323
			l2.272-1.959C407.018,877.739,405.805,876.222,404.675,874.691L404.675,874.691z M412.211,883.438l-2.125,2.118
			c0.833,0.835,1.691,1.662,2.578,2.481c0,0,0,0,0.001,0.001c0.015,0.014,0.03,0.028,0.045,0.042
			c0.574,0.529,1.159,1.055,1.756,1.578l1.976-2.257C414.979,886.12,413.555,884.786,412.211,883.438L412.211,883.438z
			 M420.932,891.082l-1.829,2.378c1.554,1.194,3.171,2.364,4.864,3.513l1.685-2.482C424.032,893.392,422.444,892.245,420.932,891.082
			L420.932,891.082z M430.568,897.634l-1.548,2.57c1.585,0.954,3.221,1.887,4.917,2.802c0,0,0,0,0,0
			c0.017,0.009,0.032,0.017,0.049,0.027c0.012,0.006,0.024,0.013,0.036,0.019l0.006,0.003c0.013,0.007,0.025,0.014,0.039,0.021
			c0.004,0.002,0,0,0.005,0.003c0.056,0.03,0.111,0.06,0.167,0.09l1.417-2.644C433.919,899.594,432.208,898.622,430.568,897.634
			L430.568,897.634z M440.887,903.172l-1.293,2.707c0.691,0.33,1.39,0.657,2.098,0.981c0.004,0.002,0.003,0.002,0.008,0.004
			c0.048,0.022,0.096,0.044,0.144,0.066c0.004,0.002,0.01,0.005,0.015,0.007c0.019,0.008,0.037,0.017,0.056,0.025
			c0.004,0.002,0.013,0.006,0.017,0.008c0.023,0.01,0.046,0.021,0.069,0.031l0,0c0.02,0.009,0.039,0.018,0.058,0.026
			c0.004,0.002,0.008,0.004,0.012,0.005c0.016,0.007,0.032,0.014,0.047,0.021c0.004,0.002,0.008,0.004,0.013,0.006
			c0.019,0.009,0.037,0.017,0.056,0.025c0,0,0,0,0,0c0.041,0.018,0.081,0.037,0.122,0.055c0.002,0.001,0.003,0.001,0.005,0.002
			c0.02,0.009,0.039,0.017,0.058,0.026c0,0,0.001,0,0.002,0.001c0.062,0.028,0.123,0.055,0.184,0.083c0,0,0,0,0.001,0
			c0.846,0.379,1.684,0.74,2.512,1.084l1.151-2.771c-0.979-0.407-1.982-0.843-2.979-1.297
			C442.446,903.907,441.661,903.542,440.887,903.172L440.887,903.172z M451.644,907.614l-0.962,2.842
			c0.016,0.005,0.032,0.011,0.047,0.016c0.002,0.001,0.004,0.002,0.007,0.002c0.016,0.005,0.03,0.01,0.046,0.016
			c0.006,0.002,0.012,0.004,0.017,0.006c0.012,0.004,0.023,0.008,0.035,0.012c0.006,0.002,0.014,0.005,0.02,0.007
			c0.013,0.004,0.024,0.008,0.037,0.012c0.005,0.002,0.01,0.003,0.016,0.005c0.018,0.006,0.034,0.012,0.052,0.018
			c0.002,0.001,0.004,0.001,0.006,0.002c0.016,0.005,0.031,0.01,0.046,0.015c0.005,0.002,0.011,0.004,0.017,0.006
			c0.012,0.004,0.023,0.008,0.036,0.012c0.007,0.002,0.013,0.004,0.02,0.007c0.012,0.004,0.023,0.008,0.035,0.012
			c0.006,0.002,0.011,0.004,0.017,0.005c0.018,0.006,0.035,0.011,0.052,0.017c0.002,0.001,0.004,0.002,0.007,0.002
			c0.016,0.005,0.031,0.01,0.047,0.015c0.005,0.002,0.01,0.003,0.015,0.005c0.013,0.004,0.025,0.008,0.037,0.012
			c0.006,0.002,0.012,0.004,0.018,0.006c0.014,0.005,0.028,0.009,0.042,0.014c0.003,0.001,0.007,0.002,0.011,0.004
			c0.036,0.012,0.073,0.024,0.108,0.036c0.003,0.001,0.006,0.002,0.009,0.003c0.015,0.005,0.029,0.009,0.043,0.014
			c0.005,0.002,0.01,0.003,0.015,0.005c0.015,0.005,0.03,0.01,0.045,0.015c0.002,0.001,0.004,0.001,0.006,0.002
			c0.037,0.012,0.074,0.024,0.111,0.036c0.001,0,0.002,0.001,0.004,0.001c0.017,0.006,0.034,0.011,0.052,0.017
			c0.001,0.001,0.003,0.001,0.005,0.002c1.442,0.465,2.855,0.875,4.24,1.232c0.006,0.002,0.013,0.003,0.019,0.005
			c0.01,0.003,0.02,0.005,0.03,0.008c0.007,0.002,0.015,0.004,0.022,0.006c0.009,0.002,0.017,0.004,0.025,0.007
			c0.111,0.028,0.222,0.057,0.333,0.084l0.729-2.91C455.368,908.794,453.511,908.246,451.644,907.614L451.644,907.614z
			 M462.759,910.372l-0.445,2.967c2.041,0.306,4.014,0.489,5.922,0.555c0.016,0.001,0.032,0.001,0.048,0.002l0.101-2.998
			C466.564,910.836,464.671,910.66,462.759,910.372L462.759,910.372z M479.428,909.746c-1.756,0.454-3.591,0.781-5.454,0.97
			l0.304,2.984c1.928-0.197,3.786-0.522,5.569-0.966c0,0,0,0,0.001,0c0.032-0.008,0.061-0.015,0.093-0.023
			c0.001,0,0.002-0.001,0.004-0.001c0.011-0.003,0.018-0.005,0.029-0.007c0.002,0,0.004-0.001,0.006-0.002
			c0.03-0.007,0.056-0.014,0.085-0.022c0.004-0.001,0.008-0.002,0.012-0.003c0.008-0.002,0.017-0.004,0.024-0.006
			c0.004-0.001,0.008-0.002,0.012-0.003c0.016-0.004,0.029-0.007,0.045-0.012c0.004-0.001,0.008-0.002,0.012-0.003
			c0.003-0.001,0.006-0.001,0.009-0.002L479.428,909.746L479.428,909.746z M489.5,905.331c-1.561,1.003-3.196,1.882-4.863,2.614
			l1.206,2.747c0.562-0.247,1.117-0.507,1.663-0.779l0.002-0.001c0.027-0.014,0.055-0.027,0.082-0.041l0.002-0.001
			c0.027-0.013,0.053-0.027,0.08-0.04c0.002-0.001,0.003-0.002,0.006-0.003c0.012-0.006,0.024-0.012,0.036-0.018
			c0.002-0.001,0.002-0.001,0.004-0.002c0.012-0.006,0.024-0.012,0.037-0.019c0.002-0.001,0.008-0.004,0.01-0.005
			c0.01-0.005,0.02-0.01,0.029-0.015c0.002-0.001,0.006-0.003,0.008-0.004c0.012-0.006,0.023-0.012,0.035-0.018
			c0.005-0.002,0.009-0.004,0.013-0.007c0.008-0.004,0.016-0.008,0.023-0.012c0.005-0.002,0.011-0.005,0.015-0.008
			c0.011-0.005,0.021-0.011,0.032-0.016c0.007-0.004,0.008-0.004,0.016-0.008c0.007-0.004,0.013-0.007,0.02-0.01
			c0.007-0.004,0.01-0.005,0.017-0.009c0.01-0.005,0.02-0.01,0.03-0.016c0.007-0.004,0.01-0.005,0.017-0.009
			c0.006-0.003,0.012-0.006,0.018-0.009c0.023-0.012,0.046-0.024,0.069-0.036c0.004-0.002,0.008-0.004,0.012-0.006
			c0.007-0.004,0.016-0.008,0.023-0.012c0,0,0.001-0.001,0.002-0.001c0.016-0.009,0.034-0.018,0.05-0.026
			c0.002-0.001,0.004-0.002,0.006-0.003c0.028-0.015,0.053-0.028,0.081-0.042c0,0,0.001-0.001,0.002-0.001
			c0.972-0.513,1.919-1.068,2.839-1.659L489.5,905.331L489.5,905.331z M497.928,898.031c-1.275,1.432-2.617,2.763-3.986,3.956
			l1.97,2.262c1.497-1.303,2.914-2.716,4.257-4.223L497.928,898.031L497.928,898.031z M504.57,888.779
			c-0.991,1.692-2.037,3.311-3.108,4.811l2.442,1.743c0.902-1.264,1.764-2.573,2.588-3.923c0,0,0.001-0.001,0.001-0.001
			c0.139-0.227,0.277-0.457,0.414-0.686c0.001-0.002,0.003-0.005,0.004-0.007c0.006-0.01,0.01-0.016,0.016-0.026
			c0-0.001,0.001-0.003,0.002-0.004c0.016-0.026,0.031-0.053,0.047-0.079c0.002-0.003,0.004-0.007,0.006-0.01
			c0.014-0.024,0.03-0.05,0.044-0.074c0.002-0.004,0.004-0.008,0.007-0.012c0.003-0.005,0.009-0.015,0.012-0.02
			c0.003-0.006,0.007-0.011,0.01-0.017c0.005-0.01,0.013-0.023,0.019-0.033c0.003-0.005,0.006-0.011,0.009-0.016
			c0.004-0.007,0.007-0.012,0.011-0.019c0.003-0.005,0.006-0.011,0.01-0.016c0.004-0.007,0.006-0.011,0.011-0.018
			c0.004-0.007,0.009-0.015,0.013-0.022c0.004-0.007,0.012-0.02,0.016-0.027c0.003-0.006,0.006-0.011,0.01-0.016
			c0.001-0.002,0.004-0.008,0.006-0.01L504.57,888.779L504.57,888.779z M509.666,878.389c-0.754,1.833-1.554,3.616-2.375,5.3
			l2.696,1.316c0.299-0.612,0.591-1.23,0.878-1.852l0.001-0.003c0.012-0.026,0.024-0.052,0.036-0.078
			c0.002-0.005,0.003-0.007,0.005-0.012c0.011-0.024,0.022-0.048,0.033-0.072c0.002-0.005,0.006-0.013,0.009-0.018
			c0.009-0.02,0.019-0.041,0.028-0.061c0.003-0.008,0.008-0.019,0.012-0.027c0.009-0.019,0.018-0.039,0.026-0.058
			c0.003-0.008,0.009-0.02,0.012-0.027c0.008-0.018,0.017-0.036,0.025-0.055c0.005-0.01,0.011-0.023,0.015-0.034
			c0.007-0.015,0.014-0.031,0.021-0.047c0.007-0.016,0.014-0.031,0.021-0.047c0.006-0.013,0.012-0.026,0.018-0.039
			c0.008-0.018,0.017-0.037,0.025-0.055c0.002-0.005,0.005-0.011,0.007-0.016c0.27-0.599,0.534-1.201,0.792-1.808c0,0,0,0,0-0.001
			c0.009-0.021,0.018-0.042,0.027-0.063c0.005-0.011,0.01-0.022,0.014-0.034c0.008-0.019,0.015-0.034,0.022-0.053
			c0.005-0.012,0.01-0.025,0.016-0.037c0.006-0.013,0.011-0.026,0.017-0.04c0.007-0.018,0.015-0.035,0.022-0.053
			c0.004-0.011,0.009-0.021,0.013-0.032c0.008-0.019,0.017-0.039,0.025-0.058c0.003-0.008,0.006-0.016,0.01-0.024
			c0.01-0.023,0.02-0.046,0.029-0.069c0.002-0.005,0.003-0.008,0.006-0.013c0.097-0.23,0.192-0.46,0.287-0.691L509.666,878.389
			L509.666,878.389z M513.531,867.356c-0.567,1.908-1.171,3.784-1.797,5.576l2.833,0.989c0.452-1.295,0.882-2.601,1.292-3.914
			c0.001-0.003,0,0,0.001-0.003c0.008-0.027,0.017-0.054,0.025-0.081c0.002-0.006,0.002-0.006,0.004-0.012
			c0.008-0.025,0.016-0.051,0.023-0.076c0.002-0.006,0.004-0.012,0.005-0.018c0.007-0.023,0.014-0.045,0.021-0.068
			c0.003-0.008,0.006-0.019,0.009-0.027c0.006-0.019,0.012-0.039,0.018-0.058c0.004-0.011,0.009-0.027,0.012-0.039
			c0.005-0.016,0.01-0.031,0.015-0.047c0.003-0.011,0.008-0.028,0.012-0.039c0.005-0.016,0.01-0.032,0.015-0.048
			c0.005-0.017,0.01-0.035,0.016-0.052c0.004-0.012,0.007-0.024,0.011-0.036c0.007-0.023,0.012-0.038,0.019-0.061
			c0.002-0.007,0.004-0.014,0.006-0.021c0.113-0.37,0.225-0.741,0.335-1.113L513.531,867.356L513.531,867.356z M516.423,855.952
			c-0.417,1.949-0.868,3.879-1.337,5.737l2.909,0.735c0.046-0.182,0.092-0.364,0.137-0.546l0.001-0.005
			c0.006-0.026,0.013-0.051,0.019-0.077c0.002-0.009,0.004-0.015,0.006-0.023c0.005-0.021,0.011-0.042,0.016-0.063
			c0.003-0.014,0.006-0.023,0.009-0.037c0.003-0.015,0.007-0.029,0.011-0.044c0.013-0.052,0.025-0.103,0.038-0.154
			c0-0.001,0.001-0.003,0.001-0.004c0.402-1.632,0.775-3.262,1.124-4.889L516.423,855.952L516.423,855.952z M518.524,844.329
			c-0.295,1.968-0.618,3.93-0.961,5.832l2.952,0.532c0.014-0.076,0.027-0.15,0.041-0.226c0.001-0.008,0.003-0.016,0.004-0.024
			c0.003-0.017,0.007-0.038,0.01-0.055c0.003-0.016,0.006-0.032,0.009-0.048c0.002-0.014,0.004-0.026,0.007-0.04
			c0.004-0.021,0.007-0.041,0.011-0.062c0.001-0.008,0.003-0.016,0.004-0.024c0.004-0.025,0.009-0.051,0.013-0.076
			c0-0.003,0.002-0.011,0.002-0.014c0.005-0.028,0.01-0.056,0.015-0.084c0-0.003,0-0.003,0.001-0.006
			c0.313-1.774,0.599-3.532,0.858-5.263L518.524,844.329L518.524,844.329z M519.958,832.578c-0.188,1.972-0.403,3.952-0.639,5.886
			l2.978,0.363c0.249-2.047,0.463-4.041,0.647-5.964L519.958,832.578L519.958,832.578z M520.789,820.757
			c-0.087,1.951-0.203,3.942-0.341,5.916l2.993,0.21c0.15-2.137,0.263-4.143,0.346-5.992L520.789,820.757L520.789,820.757z
			 M523.913,808.901l-2.996,0.164c0.055,1.004,0.083,1.991,0.083,2.935c0,0.828-0.007,1.781-0.025,2.845l3,0.051
			c0.018-1.014,0.025-1.928,0.026-2.733c0-0.004,0-0.005,0-0.008c0-0.025,0-0.049,0-0.073c0-0.002,0-0.004,0-0.006
			c0-0.018,0-0.036,0-0.055c0-0.001,0,0,0-0.001c0-0.006,0-0.013,0-0.019l0,0c0-0.014,0-0.028,0-0.042c0-0.004,0-0.008,0-0.012
			c0-0.013,0-0.026,0-0.039c0-0.001,0-0.002,0-0.004c0-0.014,0-0.028,0-0.041c0-0.004,0-0.008,0-0.012c0-0.012,0-0.024,0-0.036
			c0-0.004,0-0.005,0-0.01c0-0.013,0-0.026,0-0.038c0-0.004,0-0.009,0-0.013c0-0.011,0-0.022,0-0.033c0-0.005,0-0.012,0-0.017
			c0-0.01,0-0.021,0-0.031c0-0.005,0-0.013,0-0.019c0-0.008,0-0.016,0-0.024c0-0.009,0-0.018,0-0.028c0-0.008,0-0.016,0-0.025
			c0-0.007,0-0.014,0-0.02c0-0.008,0-0.016,0-0.024c0-0.009,0-0.017,0-0.027c0-0.009,0-0.018,0-0.026c0-0.007,0-0.014,0-0.021
			c0-0.006,0-0.012,0-0.018c0-0.016,0-0.032,0-0.048c0-0.002,0-0.004,0-0.006c0-0.01,0-0.019,0-0.028c0-0.003,0-0.006,0-0.009
			c-0.007-0.547-0.022-1.092-0.045-1.642c0-0.003,0-0.006,0-0.009c0-0.01-0.001-0.017-0.001-0.027c0-0.011-0.001-0.023-0.001-0.034
			c0-0.005,0-0.012,0-0.017c0-0.01-0.001-0.019-0.001-0.029c0-0.005,0-0.008,0-0.013c0-0.015-0.001-0.029-0.002-0.044v0
			C523.935,809.323,523.924,809.112,523.913,808.901L523.913,808.901z M522.366,797.009l-2.95,0.548
			c0.394,2.122,0.702,4.011,0.94,5.776l2.973-0.402c-0.25-1.843-0.556-3.702-0.899-5.573c0-0.003-0.001-0.006-0.001-0.008
			c-0.001-0.008-0.003-0.016-0.004-0.025c-0.001-0.007-0.002-0.014-0.004-0.02c-0.001-0.007-0.002-0.014-0.004-0.021
			c-0.002-0.014-0.005-0.027-0.007-0.041v-0.001C522.395,797.164,522.38,797.086,522.366,797.009L522.366,797.009z M519.94,785.257
			l-2.935,0.621c0.184,0.871,0.37,1.74,0.556,2.608c0.233,1.089,0.465,2.175,0.693,3.257l2.936-0.618
			c-0.219-1.044-0.443-2.091-0.667-3.141c-0.009-0.042-0.018-0.084-0.027-0.126c-0.053-0.247-0.105-0.494-0.158-0.742
			c-0.001-0.004-0.001-0.006-0.002-0.01c-0.006-0.029-0.012-0.057-0.018-0.086C520.19,786.434,520.064,785.845,519.94,785.257
			L519.94,785.257z M517.732,773.463l-2.968,0.44c0.276,1.862,0.615,3.836,1.035,6.036l2.947-0.562
			c-0.047-0.245-0.093-0.489-0.139-0.734c0-0.002-0.001-0.005-0.001-0.008c-0.005-0.027-0.01-0.053-0.015-0.08
			c-0.001-0.007-0.002-0.013-0.003-0.02c-0.004-0.022-0.008-0.043-0.012-0.065c-0.002-0.011-0.004-0.022-0.006-0.034
			c-0.003-0.019-0.007-0.038-0.01-0.057c-0.002-0.013-0.005-0.026-0.007-0.038c-0.003-0.016-0.006-0.033-0.009-0.049
			c-0.003-0.015-0.005-0.029-0.008-0.044c-0.003-0.015-0.005-0.029-0.008-0.043c-0.004-0.02-0.007-0.039-0.011-0.059
			c-0.001-0.006-0.002-0.015-0.003-0.02c-0.005-0.027-0.01-0.054-0.015-0.081c0-0.004-0.001-0.01-0.002-0.014
			C518.215,776.51,517.958,774.987,517.732,773.463L517.732,773.463z M513.785,761.497c-0.01,1.987,0.076,4.093,0.256,6.258
			l2.989-0.249c-0.045-0.546-0.085-1.094-0.119-1.64c0,0,0-0.001,0-0.001c-0.003-0.047-0.006-0.093-0.009-0.139
			c0-0.001,0-0.002,0-0.002c-0.001-0.015-0.001-0.028-0.002-0.042c0-0.002,0-0.004,0-0.005c-0.001-0.029-0.003-0.059-0.005-0.088
			c0-0.003,0-0.006,0-0.009c-0.001-0.013-0.001-0.024-0.002-0.037c0-0.003,0-0.005,0-0.008c-0.001-0.016-0.002-0.028-0.002-0.044
			c0-0.001,0-0.001,0-0.002c0-0.013-0.001-0.028-0.002-0.041c0-0.003,0-0.006,0-0.009c-0.001-0.013-0.001-0.024-0.002-0.037
			c0-0.003,0-0.005,0-0.008c-0.001-0.015-0.002-0.029-0.002-0.044c0-0.001,0-0.002,0-0.002c0-0.015-0.001-0.027-0.002-0.041
			c0-0.003,0-0.005,0-0.008c0-0.013-0.001-0.029-0.002-0.042c0,0,0-0.001,0-0.001c0-0.016-0.001-0.03-0.002-0.046
			c0-0.001,0-0.001,0-0.002c0-0.015-0.001-0.028-0.002-0.043c0-0.002,0-0.004,0-0.006c0-0.013-0.001-0.028-0.002-0.042
			c0-0.001,0-0.002,0-0.003c-0.001-0.03-0.003-0.06-0.004-0.091c0-0.001,0-0.002,0-0.004c-0.058-1.17-0.086-2.341-0.08-3.508
			L513.785,761.497L513.785,761.497z M515.326,748.887c-0.517,2.019-0.908,4.134-1.163,6.287l2.979,0.353
			c0.035-0.292,0.072-0.584,0.112-0.876c0-0.001,0-0.003,0.001-0.004c0.004-0.029,0.008-0.06,0.012-0.089c0-0.002,0-0.003,0-0.005
			c0.002-0.013,0.003-0.026,0.005-0.039c0-0.002,0.001-0.005,0.001-0.007c0.002-0.014,0.004-0.028,0.006-0.042c0,0,0-0.001,0-0.001
			c0.002-0.014,0.004-0.03,0.006-0.044c0-0.002,0.001-0.004,0.001-0.007c0.002-0.011,0.003-0.024,0.005-0.035
			c0-0.004,0.001-0.008,0.001-0.012c0.001-0.011,0.003-0.025,0.005-0.036c0-0.002,0-0.004,0-0.006
			c0.002-0.013,0.004-0.028,0.006-0.041c0-0.005,0.001-0.009,0.002-0.014c0.001-0.01,0.002-0.019,0.004-0.029
			c0.001-0.005,0.001-0.009,0.002-0.013c0.002-0.012,0.003-0.022,0.005-0.033c0-0.003,0.001-0.006,0.001-0.009
			c0.002-0.013,0.004-0.027,0.006-0.04c0-0.004,0.001-0.009,0.002-0.014c0.001-0.01,0.003-0.018,0.004-0.028
			c0-0.005,0.001-0.01,0.002-0.015c0.001-0.011,0.003-0.022,0.005-0.034c0-0.002,0-0.005,0.001-0.007
			c0.002-0.013,0.004-0.026,0.006-0.039c0.001-0.006,0.002-0.011,0.002-0.017c0.001-0.009,0.002-0.016,0.004-0.025
			c0.001-0.006,0.002-0.012,0.003-0.017c0.001-0.009,0.002-0.018,0.004-0.027c0.001-0.007,0.002-0.014,0.003-0.021
			c0.001-0.01,0.003-0.021,0.004-0.031c0.001-0.006,0.002-0.012,0.003-0.019c0.001-0.007,0.002-0.016,0.003-0.023
			c0.001-0.006,0.002-0.013,0.003-0.019c0.001-0.009,0.003-0.016,0.004-0.025c0.001-0.008,0.003-0.017,0.004-0.025
			c0.002-0.01,0.003-0.017,0.004-0.028c0.001-0.006,0.002-0.012,0.003-0.018c0.001-0.009,0.002-0.016,0.004-0.024
			c0.001-0.006,0.002-0.011,0.003-0.017c0.001-0.009,0.003-0.019,0.004-0.028c0.001-0.008,0.002-0.015,0.003-0.023
			c0.001-0.009,0.003-0.018,0.004-0.027c0.001-0.006,0.002-0.013,0.003-0.02c0.001-0.007,0.002-0.015,0.003-0.022
			c0.001-0.006,0.002-0.013,0.003-0.02c0.001-0.009,0.003-0.018,0.004-0.027c0.001-0.009,0.003-0.018,0.004-0.027
			c0.001-0.009,0.002-0.014,0.004-0.022c0.001-0.007,0.002-0.015,0.003-0.022c0.001-0.006,0.002-0.013,0.003-0.019
			c0.001-0.007,0.002-0.014,0.003-0.021c0.001-0.009,0.002-0.015,0.004-0.024c0.001-0.01,0.003-0.019,0.005-0.029
			c0.001-0.009,0.002-0.016,0.004-0.024c0.001-0.006,0.002-0.013,0.003-0.019c0.001-0.009,0.002-0.013,0.004-0.022
			c0.001-0.007,0.002-0.014,0.003-0.021c0.001-0.007,0.002-0.012,0.003-0.02c0.002-0.013,0.004-0.025,0.006-0.038
			c0.001-0.006,0.002-0.011,0.003-0.017c0.001-0.008,0.002-0.015,0.004-0.023c0.001-0.006,0.002-0.012,0.003-0.018
			c0.001-0.007,0.002-0.015,0.004-0.022c0.001-0.007,0.002-0.012,0.003-0.019c0.002-0.013,0.004-0.026,0.007-0.039
			c0.001-0.006,0.001-0.01,0.002-0.016c0.001-0.008,0.003-0.015,0.004-0.023c0.001-0.006,0.002-0.012,0.003-0.018
			c0.001-0.007,0.002-0.015,0.004-0.022c0.001-0.009,0.003-0.017,0.004-0.026c0.002-0.011,0.004-0.021,0.005-0.032
			c0.001-0.006,0.002-0.011,0.003-0.017c0.001-0.008,0.002-0.016,0.004-0.024c0.001-0.006,0.002-0.012,0.003-0.018
			c0.001-0.007,0.002-0.014,0.004-0.022c0.001-0.007,0.003-0.017,0.004-0.025c0.002-0.01,0.004-0.021,0.006-0.031
			c0.001-0.007,0.002-0.013,0.003-0.021c0.001-0.007,0.002-0.013,0.004-0.02c0.001-0.007,0.002-0.014,0.004-0.021
			c0.001-0.007,0.002-0.014,0.003-0.02c0.002-0.01,0.004-0.023,0.006-0.033c0.001-0.007,0.002-0.014,0.004-0.022
			c0.001-0.007,0.002-0.014,0.003-0.021c0.001-0.007,0.002-0.013,0.004-0.02c0.001-0.009,0.003-0.016,0.004-0.024
			c0.001-0.005,0.002-0.011,0.003-0.016c0.002-0.013,0.005-0.026,0.007-0.038c0.001-0.005,0.001-0.01,0.002-0.015
			c0.001-0.009,0.003-0.017,0.005-0.026c0.001-0.006,0.002-0.012,0.003-0.018c0.001-0.009,0.003-0.017,0.004-0.025
			c0.001-0.005,0.002-0.011,0.003-0.016c0.002-0.013,0.004-0.025,0.007-0.038c0.001-0.005,0.002-0.01,0.003-0.015
			c0.001-0.009,0.003-0.016,0.005-0.025c0.001-0.006,0.002-0.012,0.003-0.019c0.001-0.009,0.003-0.016,0.005-0.025
			c0.001-0.005,0.002-0.011,0.003-0.016c0.002-0.013,0.005-0.025,0.007-0.038c0.001-0.005,0.002-0.01,0.003-0.015
			c0.001-0.009,0.003-0.019,0.005-0.027c0.001-0.005,0.002-0.01,0.003-0.015c0.002-0.009,0.004-0.021,0.006-0.029
			c0.001-0.004,0.001-0.008,0.002-0.013c0.002-0.013,0.005-0.026,0.008-0.039c0-0.005,0.001-0.01,0.002-0.014
			c0.002-0.009,0.003-0.018,0.005-0.026c0.001-0.005,0.002-0.011,0.003-0.016c0.002-0.009,0.004-0.02,0.006-0.029
			c0.001-0.004,0.001-0.009,0.002-0.013c0.002-0.013,0.005-0.026,0.008-0.038c0.001-0.005,0.002-0.011,0.003-0.016
			c0.002-0.009,0.003-0.016,0.005-0.025c0.001-0.006,0.002-0.012,0.004-0.017c0.001-0.009,0.003-0.018,0.005-0.026
			c0.001-0.005,0.002-0.01,0.003-0.015c0.003-0.013,0.005-0.026,0.008-0.039c0.001-0.005,0.002-0.01,0.003-0.015
			c0.002-0.009,0.003-0.016,0.005-0.025c0.001-0.006,0.002-0.012,0.004-0.019c0.001-0.009,0.003-0.017,0.005-0.025
			c0.001-0.005,0.002-0.01,0.003-0.016c0.002-0.013,0.005-0.025,0.007-0.038c0.001-0.005,0.002-0.011,0.003-0.017
			c0.002-0.009,0.003-0.016,0.005-0.025c0.001-0.006,0.002-0.011,0.003-0.017c0.002-0.01,0.003-0.018,0.005-0.028
			c0.001-0.005,0.002-0.009,0.003-0.014c0.003-0.013,0.005-0.026,0.008-0.038c0.001-0.005,0.002-0.011,0.003-0.016
			c0.001-0.007,0.003-0.017,0.005-0.024c0.001-0.006,0.002-0.012,0.004-0.018c0.002-0.01,0.003-0.018,0.006-0.028
			c0-0.004,0.001-0.008,0.002-0.012c0.003-0.013,0.005-0.026,0.008-0.038c0.001-0.006,0.002-0.012,0.003-0.017
			c0.001-0.007,0.003-0.016,0.005-0.024c0.001-0.006,0.002-0.012,0.004-0.017c0.002-0.009,0.003-0.017,0.005-0.026
			c0.001-0.007,0.003-0.014,0.004-0.021c0.002-0.011,0.005-0.022,0.007-0.033c0.001-0.006,0.002-0.011,0.003-0.017
			c0.001-0.007,0.003-0.016,0.005-0.023c0.001-0.006,0.003-0.013,0.004-0.019c0.002-0.009,0.004-0.018,0.005-0.026
			c0.001-0.006,0.002-0.011,0.004-0.016c0.002-0.011,0.005-0.024,0.007-0.035c0.001-0.006,0.003-0.013,0.004-0.019
			c0.001-0.007,0.003-0.016,0.005-0.023c0.001-0.006,0.002-0.011,0.004-0.017c0.002-0.01,0.004-0.018,0.006-0.028
			c0.001-0.006,0.003-0.013,0.004-0.019c0.002-0.011,0.004-0.02,0.007-0.031c0.001-0.007,0.003-0.014,0.004-0.021
			c0.001-0.006,0.003-0.013,0.004-0.019c0.001-0.007,0.003-0.014,0.005-0.021c0.001-0.007,0.003-0.016,0.005-0.023
			c0.002-0.01,0.004-0.019,0.007-0.029c0.001-0.009,0.003-0.016,0.005-0.024c0.001-0.008,0.003-0.015,0.005-0.022
			c0.001-0.006,0.002-0.011,0.004-0.017c0.001-0.008,0.003-0.016,0.005-0.024c0.001-0.006,0.002-0.009,0.003-0.015
			c0.004-0.016,0.007-0.032,0.011-0.048c0.001-0.003,0.002-0.008,0.003-0.011c0.002-0.01,0.004-0.019,0.006-0.028
			c0-0.003,0.001-0.005,0.002-0.008c0.002-0.01,0.004-0.02,0.007-0.03l0-0.001c0.005-0.022,0.01-0.044,0.015-0.067l0-0.001
			c0.002-0.01,0.004-0.02,0.007-0.031c0-0.003,0.001-0.004,0.001-0.007c0.002-0.01,0.005-0.02,0.007-0.031l0-0.001
			c0.008-0.034,0.016-0.067,0.023-0.101l0-0.003c0.011-0.045,0.021-0.09,0.032-0.136v0c0.053-0.222,0.108-0.444,0.165-0.666
			L515.326,748.887L515.326,748.887z M520.004,737.192c-0.603,1.219-1.174,2.392-1.71,3.514c-0.328,0.685-0.642,1.387-0.933,2.086
			l2.77,1.153c0.104-0.251,0.211-0.501,0.322-0.752c0.001-0.003,0.003-0.007,0.004-0.01c0.002-0.004,0.003-0.007,0.005-0.011
			c0.003-0.008,0.007-0.016,0.01-0.024c0-0.001,0.001-0.003,0.002-0.004c0.004-0.01,0.009-0.02,0.013-0.03l0-0.002
			c0.165-0.371,0.335-0.742,0.512-1.112l0,0c0.531-1.11,1.097-2.271,1.693-3.479L520.004,737.192L520.004,737.192z M525.459,726.444
			c-0.951,1.842-1.87,3.63-2.747,5.355l2.674,1.36c0.481-0.946,0.975-1.911,1.48-2.895c0.003-0.007,0.007-0.014,0.011-0.021
			c0.001-0.002,0.002-0.005,0.003-0.007c0.314-0.612,0.632-1.229,0.954-1.854c0.097-0.187,0.193-0.375,0.291-0.563L525.459,726.444
			L525.459,726.444z M530.995,715.786l-1.079,2.073l-1.692,3.252l2.662,1.384c0.414-0.797,0.833-1.601,1.254-2.411
			c0.251-0.483,0.503-0.966,0.756-1.453c0.252-0.485,0.506-0.971,0.76-1.46L530.995,715.786L530.995,715.786z M536.504,705.143
			c-0.917,1.785-1.834,3.562-2.747,5.323l2.664,1.38c0.082-0.158,0.164-0.317,0.246-0.475c0.012-0.022,0.021-0.041,0.033-0.063
			c0.006-0.011,0.012-0.023,0.018-0.034c0.256-0.495,0.512-0.989,0.769-1.486c0.01-0.019,0.02-0.039,0.03-0.058
			c0.01-0.02,0.022-0.043,0.033-0.063c0.024-0.047,0.048-0.094,0.073-0.141c0.001-0.002,0.001-0.003,0.002-0.005
			c0.399-0.772,0.798-1.546,1.197-2.322c0.007-0.014,0.013-0.026,0.021-0.04c0.016-0.031,0.033-0.063,0.049-0.095
			c0.095-0.185,0.188-0.365,0.282-0.55L536.504,705.143L536.504,705.143z M541.909,694.471c-0.884,1.776-1.781,3.559-2.685,5.342
			l2.676,1.356c0.014-0.028,0.029-0.057,0.043-0.085c0.005-0.01,0.01-0.02,0.015-0.031c0.014-0.026,0.026-0.051,0.04-0.077
			c0.023-0.047,0.047-0.094,0.071-0.141c0.001-0.002,0,0,0.001-0.002c0.651-1.286,1.298-2.572,1.94-3.855l0-0.001
			c0.025-0.05,0.05-0.101,0.075-0.151c0.005-0.01,0.012-0.024,0.017-0.034c0.019-0.038,0.038-0.076,0.057-0.114
			c0.011-0.022,0.022-0.045,0.034-0.067c0.012-0.024,0.024-0.049,0.036-0.073c0.122-0.244,0.243-0.487,0.364-0.73L541.909,694.471
			L541.909,694.471z M547.117,683.735c-0.803,1.707-1.668,3.516-2.572,5.377l2.699,1.311c0.11-0.226,0.219-0.452,0.329-0.678
			c0.002-0.004,0.003-0.007,0.005-0.011c0.012-0.024,0.023-0.047,0.035-0.071c0.003-0.008,0.008-0.017,0.012-0.025
			c0.007-0.015,0.014-0.029,0.021-0.044c0.008-0.018,0.018-0.037,0.026-0.054c0.005-0.011,0.011-0.022,0.016-0.033
			c0.229-0.473,0.454-0.942,0.68-1.412c0-0.001,0.001-0.001,0.001-0.002c0.014-0.029,0.028-0.059,0.042-0.089
			c0.002-0.004,0.004-0.008,0.005-0.011c0.012-0.024,0.023-0.048,0.035-0.072c0.004-0.008,0.008-0.017,0.012-0.025
			c0.007-0.014,0.016-0.032,0.022-0.046c0.009-0.018,0.017-0.035,0.025-0.053c0.004-0.008,0.008-0.016,0.012-0.024
			c0.01-0.02,0.02-0.041,0.029-0.061c0.004-0.01,0.009-0.019,0.013-0.028c0.01-0.021,0.021-0.042,0.03-0.063
			c0.002-0.004,0.004-0.008,0.006-0.012c0.012-0.025,0.023-0.049,0.035-0.074l0.001-0.003c0.404-0.845,0.802-1.686,1.195-2.521
			L547.117,683.735L547.117,683.735z M551.979,672.894c-0.722,1.711-1.521,3.54-2.373,5.435l2.736,1.23
			c0.844-1.876,1.647-3.714,2.401-5.499L551.979,672.894L551.979,672.894z M556.15,661.919c-0.546,1.683-1.206,3.535-1.96,5.504
			l2.801,1.074c0.759-1.979,1.435-3.869,2.013-5.652L556.15,661.919L556.15,661.919z M558.7,650.638
			c-0.286,1.598-0.491,2.799-0.664,3.894c-0.093,0.591-0.214,1.228-0.36,1.893l2.931,0.643c0.073-0.333,0.14-0.657,0.202-0.978
			c0-0.003,0.001-0.005,0.001-0.008c0.001-0.006,0.002-0.013,0.003-0.019c0.001-0.004,0.002-0.008,0.002-0.012
			c0.001-0.005,0.002-0.01,0.003-0.015c0.001-0.006,0.002-0.012,0.003-0.017c0.001-0.005,0.001-0.008,0.002-0.013
			c0.001-0.007,0.002-0.014,0.004-0.021c0-0.004,0.001-0.005,0.001-0.008c0.061-0.322,0.116-0.637,0.165-0.945v0
			c0.001-0.01,0.003-0.021,0.005-0.031l0,0c0.001-0.009,0.002-0.017,0.004-0.026c0-0.001,0-0.002,0.001-0.003
			c0.001-0.009,0.002-0.017,0.004-0.026c0,0,0,0,0,0c0.001-0.008,0.003-0.016,0.004-0.025c0-0.002,0.001-0.004,0.001-0.007
			c0.001-0.007,0.002-0.014,0.003-0.021c0-0.003,0.001-0.005,0.001-0.008c0.001-0.007,0.002-0.013,0.003-0.02
			c0-0.003,0.001-0.006,0.001-0.01c0.001-0.005,0.002-0.012,0.003-0.016c0-0.004,0.001-0.008,0.001-0.012
			c0.001-0.005,0.002-0.011,0.003-0.016c0-0.005,0.001-0.009,0.002-0.014c0.001-0.005,0.001-0.01,0.002-0.015
			c0-0.005,0.001-0.01,0.002-0.015c0-0.002,0.001-0.007,0.001-0.01c0.001-0.007,0.002-0.015,0.003-0.022c0-0.002,0-0.002,0-0.004
			c0.001-0.007,0.002-0.015,0.003-0.022c0.001-0.004,0.001-0.007,0.002-0.01c0.001-0.007,0.002-0.014,0.003-0.021v-0.002
			c0.009-0.055,0.018-0.11,0.027-0.166c0-0.002,0-0.003,0.001-0.006c0.001-0.008,0.002-0.015,0.004-0.023c0-0.002,0-0.002,0-0.004
			c0.004-0.027,0.009-0.053,0.013-0.08c0-0.004,0-0.005,0.001-0.008c0.001-0.006,0.002-0.012,0.003-0.018
			c0-0.004,0.001-0.01,0.002-0.013c0.001-0.006,0.002-0.012,0.003-0.017c0-0.002,0-0.003,0.001-0.005
			c0.001-0.008,0.002-0.016,0.004-0.024c0-0.001,0-0.002,0-0.003c0.003-0.018,0.006-0.037,0.009-0.055v-0.001
			c0.001-0.009,0.003-0.018,0.004-0.027c0-0.001,0-0.002,0-0.003c0.001-0.008,0.002-0.016,0.004-0.023
			c0-0.004,0.001-0.006,0.001-0.01c0.001-0.006,0.002-0.013,0.003-0.019c0.001-0.004,0.001-0.006,0.002-0.01
			c0.001-0.007,0.002-0.014,0.003-0.021c0-0.001,0-0.004,0-0.005c0.001-0.007,0.002-0.014,0.004-0.021
			c0-0.004,0.001-0.007,0.001-0.01c0.001-0.007,0.002-0.013,0.003-0.02c0-0.004,0.001-0.006,0.001-0.01
			c0.001-0.005,0.002-0.01,0.002-0.015c0.001-0.007,0.002-0.012,0.003-0.019c0-0.003,0.001-0.007,0.001-0.01
			c0.001-0.005,0.001-0.01,0.002-0.014c0.001-0.005,0.002-0.01,0.002-0.015c0.001-0.006,0.002-0.013,0.003-0.018
			c0-0.002,0-0.005,0.001-0.007c0.001-0.008,0.003-0.017,0.004-0.025c0-0.001,0-0.002,0-0.003c0.001-0.008,0.002-0.016,0.004-0.025
			c0-0.002,0-0.004,0.001-0.006c0.001-0.007,0.002-0.015,0.004-0.022c0-0.003,0-0.005,0.001-0.008
			c0.001-0.007,0.002-0.014,0.004-0.021c0-0.002,0-0.005,0.001-0.007c0.001-0.007,0.002-0.016,0.004-0.023c0-0.002,0-0.003,0-0.005
			c0.001-0.007,0.003-0.017,0.004-0.025c0-0.002,0-0.003,0-0.005c0.001-0.007,0.003-0.017,0.004-0.024c0-0.002,0-0.005,0.001-0.007
			c0.001-0.008,0.002-0.015,0.004-0.023c0-0.001,0-0.002,0-0.003c0.001-0.008,0.002-0.016,0.004-0.024
			c0-0.003,0.001-0.006,0.001-0.008c0.001-0.007,0.002-0.013,0.003-0.021c0-0.003,0.001-0.005,0.001-0.008
			c0.001-0.007,0.002-0.014,0.003-0.021c0-0.003,0.001-0.005,0.001-0.007c0.003-0.018,0.006-0.035,0.009-0.053
			c0-0.001,0-0.003,0.001-0.004c0.001-0.007,0.003-0.016,0.004-0.024c0-0.003,0.001-0.005,0.001-0.007
			c0.001-0.006,0.001-0.011,0.002-0.017c0.001-0.005,0.002-0.009,0.002-0.014c0.003-0.017,0.006-0.034,0.009-0.052
			c0-0.001,0-0.003,0-0.004c0.003-0.018,0.006-0.036,0.01-0.055c0-0.002,0-0.004,0-0.006c0.012-0.068,0.023-0.135,0.035-0.203
			c0-0.001,0-0.002,0-0.003c0.001-0.009,0.003-0.018,0.005-0.026c0-0.002,0-0.003,0-0.005c0.021-0.118,0.041-0.237,0.062-0.356
			c0-0.001,0-0.003,0-0.004c0.003-0.02,0.006-0.038,0.01-0.058c0,0,0,0,0-0.001c0.001-0.01,0.003-0.018,0.005-0.028
			c0-0.001,0-0.002,0-0.003c0.001-0.009,0.002-0.015,0.004-0.024c0.001-0.003,0.001-0.007,0.002-0.01
			c0.001-0.009,0.002-0.015,0.004-0.024c0-0.001,0-0.001,0-0.002c0.002-0.01,0.003-0.019,0.005-0.029c0-0.001,0-0.002,0-0.002
			c0.001-0.009,0.003-0.017,0.005-0.026c0-0.002,0-0.003,0-0.005c0.001-0.008,0.003-0.016,0.004-0.023
			c0-0.003,0.001-0.007,0.001-0.01c0.001-0.004,0.001-0.007,0.002-0.01c0.001-0.006,0.002-0.013,0.003-0.019
			c0.001-0.005,0.001-0.008,0.002-0.013c0.077-0.443,0.158-0.897,0.241-1.364L558.7,650.638L558.7,650.638z M560.806,638.852
			c-0.361,2.134-0.708,4.083-1.032,5.878l2.952,0.534c0.163-0.901,0.331-1.84,0.504-2.822c0-0.002,0.001-0.007,0.002-0.009
			c0.002-0.011,0.003-0.022,0.005-0.032c0.001-0.007,0.002-0.014,0.004-0.021c0-0.003,0.001-0.006,0.001-0.009
			c0.17-0.963,0.344-1.968,0.521-3.016L560.806,638.852L560.806,638.852z M562.629,627.075c-0.277,1.975-0.569,3.959-0.87,5.899
			l2.965,0.459c0.022-0.143,0.044-0.288,0.067-0.433c0.164-1.067,0.33-2.174,0.497-3.32c0-0.002,0-0.004,0.001-0.007
			c0-0.005,0.001-0.01,0.002-0.015c0.103-0.706,0.206-1.429,0.31-2.167L562.629,627.075L562.629,627.075z M564.129,615.249
			c-0.229,1.999-0.468,3.991-0.713,5.922l2.976,0.377c0.24-1.892,0.479-3.876,0.717-5.958L564.129,615.249L564.129,615.249z
			 M565.356,603.379c-0.192,2.057-0.386,4.036-0.583,5.941l2.984,0.308c0.047-0.459,0.095-0.923,0.142-1.39
			c0.104-1.03,0.207-2.082,0.31-3.155c0.045-0.471,0.09-0.946,0.134-1.425L565.356,603.379L565.356,603.379z M566.36,591.475
			c-0.156,2.05-0.314,4.035-0.477,5.957l2.99,0.252c0.163-1.934,0.322-3.92,0.479-5.982L566.36,591.475L566.36,591.475z
			 M567.178,579.547c-0.126,2.043-0.255,4.031-0.388,5.965l2.993,0.205c0-0.004,0-0.005,0-0.01c0.001-0.022,0.003-0.043,0.004-0.065
			c0.001-0.018,0.003-0.041,0.004-0.059c0,0,0-0.001,0-0.001c0.05-0.734,0.099-1.47,0.148-2.22c0-0.003,0-0.006,0-0.008
			c0.001-0.018,0.002-0.029,0.003-0.047c0.001-0.017,0.002-0.034,0.003-0.051c0.001-0.018,0.002-0.032,0.003-0.05
			c0.001-0.016,0.002-0.032,0.003-0.048c0-0.009,0.001-0.019,0.001-0.029c0.071-1.084,0.141-2.183,0.21-3.299
			c0-0.005,0-0.006,0-0.011c0.001-0.02,0.002-0.039,0.004-0.059c0-0.009,0.001-0.02,0.001-0.029L567.178,579.547L567.178,579.547z
			 M567.839,567.598c-0.101,2.041-0.205,4.033-0.312,5.98l2.995,0.166c0.108-1.956,0.212-3.945,0.314-5.997L567.839,567.598
			L567.839,567.598z M568.366,555.646c-0.08,2.034-0.162,4.025-0.248,5.976l2.997,0.132c0.048-1.1,0.096-2.213,0.142-3.338v-0.001
			c0.001-0.028,0.002-0.057,0.003-0.085c0.001-0.022,0.002-0.047,0.003-0.07c0,0,0,0,0-0.001c0.034-0.826,0.067-1.656,0.1-2.496
			L568.366,555.646L568.366,555.646z M568.777,543.681c-0.061,2.03-0.125,4.021-0.191,5.976l2.998,0.103
			c0.067-1.959,0.131-3.956,0.192-5.99L568.777,543.681L568.777,543.681z M569.086,531.703c-0.044,2.031-0.092,4.027-0.143,5.99
			l2.999,0.077c0.026-0.997,0.051-2.002,0.075-3.017c0-0.017,0.001-0.035,0.001-0.052v-0.001c0.012-0.525,0.024-1.052,0.037-1.581
			c0-0.026,0.001-0.069,0.002-0.095c0-0.003,0-0.007,0-0.01c0.009-0.411,0.019-0.832,0.028-1.246L569.086,531.703L569.086,531.703z
			 M569.304,519.732c-0.03,2.028-0.062,4.025-0.099,5.991l3,0.055c0.029-1.599,0.057-3.219,0.082-4.859c0-0.007,0-0.002,0-0.009
			c0-0.016,0-0.032,0-0.048c0.005-0.362,0.011-0.721,0.017-1.085L569.304,519.732L569.304,519.732z M569.442,507.748
			c-0.017,2.024-0.037,4.018-0.059,5.985l3,0.034c0.023-1.97,0.042-3.968,0.06-5.995L569.442,507.748L569.442,507.748z
			 M569.506,495.773c-0.005,2.023-0.013,4.019-0.023,5.989l3,0.016c0.009-1.799,0.017-3.635,0.022-5.477
			c0-0.173,0.001-0.347,0.001-0.521L569.506,495.773L569.506,495.773z M572.504,483.78l-3,0.008c0.006,2.022,0.009,4.02,0.009,5.993
			l3-0.001c0-0.461,0-0.923,0-1.386c0-0.016,0-0.02,0-0.037c0-0.001,0-0.003,0-0.004c-0.001-1.049-0.002-2.103-0.005-3.167
			C572.507,484.72,572.506,484.25,572.504,483.78L572.504,483.78z M572.442,471.778l-3,0.023c0.016,2.021,0.028,4.018,0.039,5.993
			l3-0.016c-0.002-0.438-0.005-0.884-0.007-1.324C572.464,474.909,572.454,473.351,572.442,471.778L572.442,471.778z
			 M572.324,459.779l-3,0.036c0.024,2.019,0.046,4.016,0.065,5.993l3-0.03c-0.003-0.329-0.006-0.658-0.01-0.988
			c-0.013-1.29-0.027-2.592-0.043-3.9C572.333,460.52,572.329,460.15,572.324,459.779L572.324,459.779z M572.154,447.783l-3,0.048
			c0.033,2.017,0.063,4.015,0.091,5.992l3-0.042c-0.001-0.092-0.003-0.199-0.004-0.291c0-0.019,0-0.039-0.001-0.058
			c0-0.018,0-0.027,0-0.045C572.213,451.536,572.185,449.668,572.154,447.783L572.154,447.783z M571.942,435.763l-3,0.049
			c0.018,1.073,0.037,2.154,0.058,3.244l0.052,2.782l3-0.054c-0.008-0.431-0.016-0.864-0.024-1.297
			c-0.009-0.499-0.018-0.986-0.028-1.488c-0.009-0.49-0.019-0.985-0.027-1.472C571.962,436.937,571.952,436.349,571.942,435.763
			L571.942,435.763z M571.804,423.769l-3,0.019c0.012,1.969,0.031,3.972,0.054,6.008l3-0.035c-0.022-1.972-0.041-3.912-0.053-5.821
			C571.805,423.881,571.805,423.826,571.804,423.769L571.804,423.769z M568.796,411.746c-0.011,1.969-0.015,3.974-0.014,6.018
			l3-0.002c-0.001-2.035,0.003-4.039,0.014-5.999L568.796,411.746L568.796,411.746z M568.942,399.712
			c-0.038,1.964-0.069,3.97-0.094,6.018l3,0.036c0.025-2.041,0.056-4.04,0.094-5.997L568.942,399.712L568.942,399.712z
			 M569.272,387.666c-0.071,1.96-0.134,3.967-0.19,6.022l2.999,0.082c0.047-1.716,0.099-3.397,0.156-5.045
			c0-0.004,0-0.014,0.001-0.018c0-0.01,0-0.021,0.001-0.031c0.01-0.302,0.021-0.602,0.032-0.901L569.272,387.666L569.272,387.666z
			 M569.829,375.611c-0.112,1.96-0.216,3.989-0.31,6.03l2.997,0.138c0.079-1.717,0.164-3.394,0.254-5.033
			c0.001-0.011,0.001-0.02,0.002-0.031c0-0.008,0.001-0.016,0.001-0.023c0.017-0.305,0.034-0.607,0.051-0.91L569.829,375.611
			L569.829,375.611z M570.671,363.561c-0.164,1.949-0.319,3.976-0.462,6.025l2.993,0.208c0.144-2.061,0.296-4.055,0.458-5.981
			L570.671,363.561L570.671,363.561z M571.894,351.517c-0.235,1.936-0.459,3.963-0.667,6.024l2.985,0.301
			c0.037-0.37,0.076-0.741,0.113-1.106c0-0.002,0-0.004,0.001-0.006c0.001-0.015,0.003-0.027,0.004-0.042
			c0.173-1.659,0.354-3.262,0.542-4.81L571.894,351.517L571.894,351.517z M573.664,339.481c-0.338,1.912-0.664,3.936-0.968,6.016
			l2.968,0.433c0.305-2.089,0.624-4.062,0.954-5.926L573.664,339.481L573.664,339.481z M576.312,327.493
			c-0.513,1.859-1.007,3.873-1.467,5.985l2.931,0.64c0.101-0.46,0.202-0.914,0.304-1.358c0-0.003,0.001-0.005,0.002-0.008
			c0.003-0.013,0.005-0.022,0.008-0.035c0.001-0.006,0.003-0.012,0.004-0.018c0.001-0.008,0.004-0.018,0.006-0.026
			c0.001-0.008,0.003-0.015,0.005-0.023c0.001-0.006,0.001-0.007,0.002-0.012c0.357-1.546,0.723-2.992,1.097-4.347L576.312,327.493
			L576.312,327.493z M580.768,315.674c-0.905,1.702-1.77,3.678-2.572,5.871l2.818,1.03c0.51-1.396,1.029-2.651,1.552-3.779
			c0.001-0.002,0.002-0.004,0.003-0.006c0.002-0.006,0.005-0.011,0.008-0.017c0.001-0.002,0.002-0.005,0.003-0.007
			c0.001-0.003,0.002-0.006,0.004-0.009c0.001-0.003,0.002-0.005,0.004-0.008c0.002-0.004,0.004-0.009,0.006-0.013
			c0.001-0.003,0.003-0.006,0.005-0.01c0.001-0.003,0.002-0.005,0.004-0.008c0.002-0.005,0.004-0.009,0.006-0.014
			c0.001-0.002,0.002-0.004,0.003-0.005c0.002-0.004,0.004-0.01,0.006-0.014c0-0.001,0.001-0.003,0.002-0.004
			c0.003-0.006,0.005-0.011,0.008-0.017c0-0.001,0.001-0.002,0.001-0.003c0.261-0.557,0.524-1.082,0.787-1.578L580.768,315.674
			L580.768,315.674z M587.025,311.477c-0.289,0-0.584,0.001-0.882,0.003l0.72,0.716c0.054-0.055,0.108-0.108,0.163-0.161v0
			c0.206-0.2,0.41-0.384,0.614-0.556l0,0C587.437,311.478,587.233,311.477,587.025,311.477L587.025,311.477z"/>
		<path fill="#39B54A" d="M773.771,309.538c-0.006,0-0.012,0-0.018,0c-0.002,0-0.004,0-0.006,0c-0.004,0-0.008,0-0.012,0
			c-0.002,0-0.005,0-0.007,0c-0.004,0-0.007,0-0.011,0c-0.002,0-0.004,0-0.007,0c-0.004,0-0.007,0-0.012,0c-0.002,0-0.004,0-0.006,0
			c-0.004,0-0.008,0-0.013,0c-0.001,0-0.003,0-0.005,0c-0.006,0-0.012,0-0.018,0c-0.001,0-0.002,0-0.003,0c-0.004,0-0.009,0-0.014,0
			c-0.002,0-0.004,0-0.006,0c-0.004,0-0.008,0-0.011,0c-0.002,0-0.005,0-0.007,0c-0.004,0-0.007,0-0.011,0c-0.002,0-0.005,0-0.007,0
			c-0.004,0-0.007,0-0.011,0c-0.002,0-0.004,0-0.006,0c-0.005,0-0.01,0-0.015,0c-0.001,0-0.002,0-0.003,0
			c-0.006,0-0.011,0-0.017,0.001c-0.002,0-0.004,0-0.006,0c-0.004,0-0.008,0-0.012,0c-0.002,0-0.004,0-0.006,0
			c-0.004,0-0.008,0-0.012,0c-0.002,0-0.004,0-0.006,0c-0.004,0-0.007,0-0.011,0c-0.002,0-0.004,0-0.006,0c-0.004,0-0.008,0-0.012,0
			c-0.002,0-0.004,0-0.006,0c-0.006,0-0.012,0.001-0.017,0.001c-0.001,0-0.002,0-0.003,0c-0.005,0-0.009,0-0.014,0.001
			c-0.002,0-0.004,0-0.007,0c-0.003,0-0.007,0-0.011,0.001c-0.002,0-0.004,0-0.006,0c-0.003,0-0.007,0-0.011,0.001
			c-0.002,0-0.005,0-0.007,0c-0.004,0-0.008,0-0.012,0.001c-0.002,0-0.004,0-0.006,0c-0.004,0-0.008,0.001-0.013,0.001
			c-0.001,0-0.003,0-0.004,0c-0.006,0-0.011,0.001-0.017,0.001c-0.002,0-0.004,0-0.006,0c-0.004,0-0.008,0-0.011,0.001
			c-0.002,0-0.005,0-0.007,0.001c-0.003,0-0.007,0-0.01,0.001c-0.002,0-0.005,0-0.007,0.001c-0.004,0-0.007,0-0.011,0.001
			c-0.002,0-0.004,0-0.006,0c-0.004,0-0.008,0.001-0.013,0.001c-0.001,0-0.003,0-0.004,0c-0.006,0-0.012,0.001-0.018,0.001
			c0,0,0,0-0.001,0c-0.005,0-0.011,0.001-0.016,0.001c-0.001,0-0.003,0-0.005,0c-0.004,0-0.008,0.001-0.012,0.001
			c-0.002,0-0.004,0-0.006,0.001c-0.003,0-0.007,0.001-0.011,0.001c-0.002,0-0.004,0-0.007,0.001c-0.003,0-0.007,0.001-0.01,0.001
			c-0.002,0-0.004,0-0.007,0.001c-0.004,0-0.008,0.001-0.012,0.001c-0.001,0-0.003,0-0.005,0c-0.005,0.001-0.011,0.001-0.017,0.002
			c-0.001,0-0.002,0-0.003,0c-0.004,0-0.009,0.001-0.013,0.001c-0.002,0-0.004,0-0.006,0.001c-0.003,0-0.007,0.001-0.011,0.001
			c-0.002,0-0.004,0-0.007,0.001c-0.003,0-0.007,0.001-0.011,0.001c-0.002,0-0.004,0-0.006,0.001c-0.004,0-0.008,0.001-0.012,0.001
			c-0.002,0-0.004,0-0.005,0.001c-0.012,0.001-0.022,0.003-0.034,0.004c-0.001,0-0.003,0-0.004,0.001
			c-0.004,0-0.008,0.001-0.013,0.001c-0.002,0-0.003,0-0.005,0.001c-0.004,0-0.007,0.001-0.011,0.001c-0.002,0-0.004,0-0.006,0.001
			c-0.004,0-0.008,0.001-0.012,0.001c-0.001,0-0.003,0-0.005,0.001c-0.004,0.001-0.009,0.001-0.013,0.002c-0.001,0-0.002,0-0.004,0
			c-0.005,0.001-0.011,0.001-0.017,0.002c0,0-0.001,0-0.002,0c-0.004,0.001-0.009,0.001-0.014,0.002
			c-0.002,0-0.004,0.001-0.006,0.001c-0.004,0-0.007,0.001-0.011,0.001c-0.002,0-0.004,0.001-0.006,0.001
			c-0.004,0.001-0.007,0.001-0.011,0.001c-0.002,0-0.004,0.001-0.006,0.001c-0.004,0-0.007,0.001-0.011,0.001
			c-0.001,0-0.003,0.001-0.005,0.001c-0.005,0.001-0.01,0.001-0.015,0.002c0,0-0.001,0-0.002,0c-0.006,0.001-0.011,0.002-0.017,0.002
			c-0.001,0-0.002,0-0.003,0c-0.004,0.001-0.009,0.001-0.013,0.002c-0.002,0-0.004,0.001-0.006,0.001
			c-0.003,0.001-0.007,0.001-0.011,0.002c-0.001,0-0.003,0.001-0.005,0.001c-0.004,0.001-0.008,0.001-0.012,0.002
			c-0.001,0-0.003,0-0.005,0.001c-0.004,0.001-0.008,0.001-0.013,0.002c-0.001,0-0.002,0-0.004,0.001
			c-0.005,0.001-0.011,0.002-0.017,0.003l0,0c-0.005,0.001-0.011,0.002-0.017,0.003c-0.001,0-0.003,0-0.004,0.001
			c-0.004,0.001-0.008,0.001-0.012,0.002c-0.002,0-0.003,0.001-0.006,0.001c-0.003,0.001-0.007,0.001-0.011,0.002
			c-0.002,0-0.004,0.001-0.006,0.001c-0.003,0.001-0.007,0.001-0.011,0.002c-0.001,0-0.003,0.001-0.005,0.001
			c-0.005,0.001-0.01,0.002-0.015,0.003c0,0,0,0-0.001,0c-0.005,0.001-0.011,0.002-0.017,0.003c-0.001,0-0.003,0-0.004,0.001
			c-0.004,0.001-0.008,0.001-0.012,0.002c-0.002,0-0.005,0.001-0.007,0.001c-0.003,0.001-0.006,0.001-0.009,0.002
			c-0.002,0-0.005,0.001-0.007,0.001c-0.003,0.001-0.006,0.001-0.01,0.002c-0.002,0-0.004,0.001-0.006,0.001
			c-0.004,0.001-0.007,0.001-0.011,0.002c-0.001,0-0.003,0.001-0.005,0.001c-0.011,0.002-0.022,0.004-0.033,0.006
			c-0.001,0-0.003,0-0.004,0.001c-0.004,0.001-0.009,0.002-0.013,0.002c-0.001,0-0.003,0.001-0.005,0.001
			c-0.004,0.001-0.007,0.001-0.012,0.002c-0.001,0-0.003,0.001-0.005,0.001c-0.003,0.001-0.008,0.002-0.012,0.002
			c-0.001,0-0.003,0.001-0.004,0.001c-0.004,0.001-0.008,0.002-0.013,0.003c-0.001,0-0.002,0-0.004,0.001
			c-0.005,0.001-0.011,0.002-0.016,0.003c-0.001,0-0.001,0-0.002,0c-0.004,0.001-0.009,0.002-0.014,0.003
			c-0.001,0-0.003,0.001-0.004,0.001c-0.004,0.001-0.008,0.002-0.012,0.003c-0.002,0-0.003,0.001-0.005,0.001
			c-0.004,0.001-0.007,0.002-0.011,0.002c-0.002,0-0.003,0.001-0.005,0.001c-0.004,0.001-0.008,0.002-0.012,0.003
			c-0.001,0-0.002,0.001-0.004,0.001c-0.011,0.002-0.021,0.005-0.033,0.007c-0.001,0-0.001,0-0.003,0.001
			c-0.004,0.001-0.008,0.002-0.013,0.003c-0.002,0-0.003,0.001-0.005,0.001c-0.004,0.001-0.008,0.002-0.012,0.003
			c-0.001,0-0.003,0.001-0.004,0.001c-0.004,0.001-0.008,0.002-0.012,0.003c-0.001,0-0.003,0.001-0.004,0.001
			c-0.004,0.001-0.009,0.002-0.014,0.003c0,0-0.001,0-0.002,0c-0.011,0.003-0.022,0.005-0.033,0.008c-0.001,0-0.001,0-0.002,0.001
			c-0.004,0.001-0.009,0.002-0.013,0.003c-0.001,0-0.003,0.001-0.004,0.001c-0.004,0.001-0.008,0.002-0.012,0.003
			c-0.001,0-0.002,0.001-0.004,0.001c-0.004,0.001-0.008,0.002-0.013,0.003c-0.001,0-0.002,0.001-0.003,0.001
			c-0.011,0.003-0.022,0.005-0.033,0.008c0,0,0,0,0,0c-0.005,0.001-0.01,0.003-0.015,0.004c-0.001,0-0.002,0-0.003,0.001
			c-0.004,0.001-0.008,0.002-0.013,0.003c-0.001,0-0.003,0.001-0.004,0.001c-0.004,0.001-0.008,0.002-0.012,0.003
			c-0.001,0-0.002,0.001-0.004,0.001c-0.004,0.001-0.009,0.002-0.014,0.004c0,0-0.001,0-0.002,0.001
			c-0.011,0.003-0.021,0.006-0.032,0.008c-0.001,0-0.002,0.001-0.003,0.001c-0.004,0.001-0.008,0.002-0.013,0.003
			c-0.001,0-0.002,0.001-0.003,0.001c-0.004,0.001-0.008,0.002-0.013,0.003c-0.001,0-0.002,0.001-0.003,0.001
			c-0.004,0.001-0.009,0.002-0.013,0.004c-0.001,0-0.002,0-0.002,0.001c-0.005,0.001-0.011,0.003-0.016,0.004c0,0,0,0,0,0
			c-0.011,0.003-0.022,0.006-0.033,0.009c0,0-0.001,0-0.001,0c-0.005,0.001-0.01,0.003-0.015,0.004c0,0-0.001,0-0.002,0.001
			c-0.004,0.001-0.009,0.003-0.013,0.004c-0.001,0-0.002,0.001-0.003,0.001c-0.021,0.006-0.043,0.012-0.064,0.018c0,0,0,0-0.001,0
			c-0.005,0.002-0.01,0.003-0.015,0.005c0,0,0,0-0.001,0c-0.005,0.002-0.01,0.003-0.015,0.005c0,0,0,0-0.001,0
			c-0.016,0.005-0.032,0.01-0.048,0.014c0,0-0.001,0-0.001,0c-0.005,0.001-0.009,0.003-0.014,0.004c-0.001,0-0.002,0.001-0.003,0.001
			c-0.004,0.001-0.008,0.003-0.013,0.004c-0.001,0-0.002,0.001-0.002,0.001c-0.005,0.001-0.009,0.003-0.014,0.004
			c0,0-0.001,0-0.001,0c-0.016,0.005-0.032,0.01-0.048,0.015c0,0,0,0,0,0c-0.005,0.002-0.01,0.003-0.015,0.005
			c-0.001,0-0.002,0.001-0.002,0.001c-0.004,0.001-0.009,0.003-0.014,0.004c0,0-0.001,0-0.001,0c-0.016,0.005-0.031,0.01-0.047,0.016
			c0,0,0,0,0,0c-0.005,0.002-0.01,0.003-0.014,0.005c-0.001,0-0.002,0.001-0.002,0.001c-0.004,0.001-0.009,0.003-0.013,0.004
			c-0.001,0-0.002,0.001-0.003,0.001c-0.004,0.001-0.008,0.003-0.013,0.004c-0.001,0-0.001,0-0.002,0.001
			c-0.016,0.005-0.031,0.011-0.046,0.016c0,0,0,0,0,0c-0.005,0.002-0.01,0.003-0.015,0.005c0,0,0,0-0.001,0
			c-0.005,0.002-0.009,0.003-0.014,0.005c0,0-0.001,0-0.001,0c-0.021,0.007-0.041,0.015-0.062,0.022c0,0,0,0,0,0
			c-0.005,0.002-0.01,0.004-0.015,0.005c0,0,0,0-0.001,0c-0.005,0.002-0.01,0.003-0.014,0.005c0,0-0.001,0-0.001,0
			c-0.02,0.008-0.041,0.015-0.061,0.023c0,0,0,0-0.001,0c-0.005,0.002-0.01,0.004-0.015,0.005c0,0,0,0,0,0
			c-0.005,0.002-0.01,0.004-0.015,0.006c0,0,0,0,0,0c-1.477,0.564-2.529,1.475-2.908,1.834l0.116,0.122
			c0.662-0.072,2.513-0.254,5.274-0.375V309.538L773.771,309.538L773.771,309.538z M773.906,309.539l0.086,2.045
			c0.783-0.033,1.636-0.061,2.553-0.08c0.01,0,0.02,0,0.03-0.001c0.001,0,0.003,0,0.004,0c0.115-0.002,0.23-0.005,0.347-0.007l0,0
			c0.533-0.009,1.087-0.016,1.66-0.019l0,0C776.927,310.08,775.33,309.57,773.906,309.539L773.906,309.539z M788.181,321.965
			l-2.825,1.01c0.534,1.494,1.058,3.143,1.568,4.964c0.001,0.005,0.002,0.009,0.004,0.014c0.002,0.009,0.005,0.018,0.008,0.027
			c0.002,0.01,0.005,0.019,0.008,0.029c0.001,0.005,0.003,0.01,0.004,0.014c0.003,0.011,0.006,0.023,0.009,0.035
			c0.001,0.005,0.003,0.01,0.004,0.014c0.003,0.011,0.007,0.026,0.01,0.037c0.001,0.003,0.001,0.006,0.002,0.009
			c0.004,0.015,0.008,0.03,0.012,0.045c0,0,0,0.001,0,0.001c0.004,0.016,0.008,0.031,0.013,0.047c0,0.001,0,0.001,0,0.002
			c0.045,0.164,0.09,0.327,0.135,0.494l2.896-0.785C789.452,325.781,788.829,323.777,788.181,321.965L788.181,321.965z
			 M791.474,333.904l-2.933,0.63c0.006,0.028,0.012,0.054,0.018,0.081c0.001,0.004,0.002,0.008,0.002,0.011
			c0.001,0.006,0.004,0.017,0.005,0.023c0.38,1.774,0.748,3.668,1.101,5.688c0.002,0.011,0.004,0.023,0.006,0.034
			c0,0.003,0.001,0.006,0.001,0.008c0.002,0.014,0.005,0.03,0.007,0.044l2.956-0.516
			C792.272,337.816,791.881,335.796,791.474,333.904L791.474,333.904z M793.592,345.925l-2.969,0.428
			c0.104,0.722,0.207,1.458,0.307,2.208c0,0.001,0,0.002,0,0.002c0.008,0.06,0.016,0.122,0.024,0.182c0,0,0,0,0,0.001
			c0.01,0.076,0.02,0.145,0.029,0.222c0.001,0.006,0.001,0.011,0.002,0.017c0.001,0.008,0.002,0.02,0.004,0.028
			c0,0.005,0.001,0.009,0.001,0.014c0.002,0.013,0.003,0.025,0.005,0.038c0,0.004,0.001,0.008,0.001,0.013
			c0.001,0.011,0.002,0.017,0.004,0.027c0,0.005,0.001,0.009,0.001,0.014c0.001,0.011,0.004,0.027,0.005,0.038
			c0.001,0.005,0.001,0.01,0.002,0.015c0.001,0.008,0.002,0.016,0.003,0.023c0.001,0.009,0.002,0.017,0.003,0.026
			c0.001,0.013,0.002,0.019,0.004,0.032c0,0.002,0,0.005,0,0.007c0.001,0.011,0.002,0.017,0.004,0.028
			c0.001,0.008,0.002,0.015,0.003,0.022c0,0.003,0.001,0.008,0.001,0.011c0.002,0.017,0.004,0.034,0.006,0.051
			c0,0.003,0.001,0.005,0.001,0.008c0.001,0.009,0.003,0.019,0.004,0.028l0,0.004c0.004,0.031,0.008,0.062,0.012,0.093v0
			c0.002,0.02,0.005,0.04,0.008,0.06l0,0.004c0.116,0.891,0.229,1.8,0.341,2.729l2.979-0.357
			C794.136,349.875,793.87,347.851,793.592,345.925L793.592,345.925z M795.042,357.966l-2.985,0.298
			c0.038,0.379,0.075,0.762,0.112,1.147c0.15,1.562,0.295,3.174,0.432,4.832l2.99-0.248
			C795.421,361.939,795.236,359.911,795.042,357.966L795.042,357.966z M796.046,370.013l-2.993,0.206
			c0.025,0.362,0.049,0.723,0.073,1.089c0,0.006,0.001,0.012,0.001,0.019c0,0.011,0.002,0.029,0.002,0.039
			c0.061,0.923,0.12,1.858,0.177,2.808v0.001c0.001,0.017,0.002,0.035,0.003,0.052c0.039,0.654,0.078,1.317,0.115,1.984l2.995-0.168
			C796.307,373.998,796.181,371.969,796.046,370.013L796.046,370.013z M796.727,382.069l-2.997,0.135
			c0.021,0.454,0.041,0.912,0.061,1.372c0,0.004,0,0.008,0,0.012c0.001,0.024,0.002,0.051,0.003,0.075c0,0.008,0,0.016,0.001,0.024
			c0,0.016,0.001,0.029,0.002,0.044c0.062,1.46,0.12,2.948,0.174,4.465l2.998-0.106C796.896,386.032,796.815,384.025,796.727,382.069
			L796.727,382.069z M797.155,394.113l-2.999,0.08c0.018,0.657,0.034,1.32,0.05,1.987v0.001c0,0.023,0.001,0.046,0.002,0.069
			c0,0.022,0,0.034,0.001,0.056c0,0.007,0,0.013,0,0.02c0.03,1.239,0.056,2.496,0.081,3.77c0,0.001,0,0.002,0,0.002
			c0,0.027,0.001,0.052,0.001,0.079c0,0.005,0,0.01,0,0.015l3-0.056C797.253,398.084,797.208,396.077,797.155,394.113
			L797.155,394.113z M797.382,406.162l-3,0.035c0.011,0.976,0.021,1.969,0.029,2.964c0,0.004,0,0.008,0,0.013
			c0,0.014,0,0.032,0,0.047c0.008,0.981,0.014,1.971,0.02,2.971l3-0.015C797.422,410.132,797.405,408.128,797.382,406.162
			L797.382,406.162z M794.443,418.188c-0.002,1.966-0.01,3.968-0.023,6.004l3,0.02c0.014-2.042,0.022-4.049,0.024-6.021
			L794.443,418.188L794.443,418.188z M794.363,430.189c-0.016,1.351-0.035,2.71-0.056,4.091c0,0.013,0,0.026,0,0.039
			c0,0.006,0,0.016,0,0.021c-0.009,0.613-0.02,1.228-0.03,1.847l3,0.05c0.034-2.036,0.063-4.04,0.087-6.013L794.363,430.189
			L794.363,430.189z M794.167,442.212c-0.018,1-0.035,1.995-0.052,2.986c-0.017,1.011-0.034,2.011-0.049,3.013l3,0.048
			c0.032-1.978,0.065-3.975,0.102-5.993L794.167,442.212L794.167,442.212z M793.975,454.209c-0.014,1.006-0.027,2.007-0.041,3.003
			c-0.013,0.999-0.025,2.007-0.037,2.995l3,0.036c0.023-1.977,0.049-3.974,0.077-5.992L793.975,454.209L793.975,454.209z
			 M793.832,466.207c-0.018,1.874-0.034,3.729-0.048,5.565c-0.001,0.146-0.002,0.289-0.003,0.435l3,0.022
			c0.015-1.976,0.032-3.973,0.051-5.993L793.832,466.207L793.832,466.207z M793.743,478.207c0,0.109-0.001,0.213-0.001,0.322
			c-0.008,1.571-0.014,3.128-0.019,4.67c-0.001,0.338-0.002,0.672-0.003,1.009l3,0.008c0.005-1.974,0.013-3.972,0.023-5.993
			L793.743,478.207L793.743,478.207z M796.713,490.208h-3c0,0.513,0,1.03,0,1.54c0,0.737,0.001,1.471,0.002,2.201
			c0,0.016,0,0.032,0,0.048c0,0.005,0,0.011,0,0.016c0.001,0.741,0.003,1.461,0.005,2.195l3-0.008
			C796.715,494.227,796.713,492.23,796.713,490.208L796.713,490.208z M796.746,502.187l-3,0.017c0.002,0.327,0.004,0.652,0.006,0.977
			c0,0.005,0,0.011,0,0.016c0,0.015,0,0.051,0,0.066c0,0.009,0,0.018,0,0.027c0.01,1.65,0.022,3.292,0.036,4.906l3-0.026
			C796.771,506.203,796.757,504.209,796.746,502.187L796.746,502.187z M796.848,514.164l-3,0.035
			c0.011,0.919,0.022,1.831,0.034,2.738c0,0.007,0,0.025,0,0.032c0,0.013,0,0.027,0,0.04c0.014,1.079,0.029,2.135,0.045,3.197
			l3-0.045C796.898,518.191,796.872,516.192,796.848,514.164L796.848,514.164z M797.028,526.148l-3,0.055
			c0.007,0.41,0.015,0.818,0.023,1.226c0,0.007,0,0.01,0,0.016c0,0.002,0,0.004,0,0.006c0.031,1.601,0.063,3.179,0.098,4.739l3-0.066
			C797.106,530.164,797.065,528.172,797.028,526.148L797.028,526.148z M797.294,538.122l-2.999,0.078
			c0.034,1.303,0.069,2.592,0.105,3.865v0.001c0.001,0.021,0.001,0.042,0.002,0.063c0.02,0.693,0.04,1.382,0.061,2.067l2.999-0.091
			C797.403,542.146,797.347,540.152,797.294,538.122L797.294,538.122z M797.656,550.087l-2.999,0.104
			c0.013,0.381,0.027,0.761,0.04,1.139l0,0.007c0.001,0.027,0.002,0.054,0.003,0.081c0.057,1.612,0.117,3.209,0.178,4.771
			l2.998-0.118C797.8,554.115,797.726,552.121,797.656,550.087L797.656,550.087z M798.127,562.05l-2.997,0.133
			c0.09,2.039,0.185,4.034,0.282,5.984l2.996-0.149C798.311,566.073,798.217,564.084,798.127,562.05L798.127,562.05z
			 M798.723,574.001l-2.995,0.167c0.053,0.955,0.107,1.898,0.162,2.83c0.062,1.068,0.126,2.122,0.191,3.161l2.994-0.186
			C798.954,578.033,798.837,576.042,798.723,574.001L798.723,574.001z M799.465,585.939l-2.993,0.206
			c0.094,1.354,0.188,2.678,0.285,3.98c0.05,0.675,0.1,1.343,0.15,2.004l2.991-0.229
			C799.751,589.972,799.606,587.985,799.465,585.939L799.465,585.939z M800.378,597.855l-2.989,0.253
			c0.006,0.075,0.012,0.141,0.019,0.216c0.168,1.984,0.34,3.901,0.514,5.754l2.987-0.28
			C800.729,601.886,800.552,599.906,800.378,597.855L800.378,597.855z M801.497,609.745l-2.984,0.31
			c0.023,0.217,0.045,0.434,0.068,0.649c0.184,1.743,0.369,3.425,0.554,5.045c0.01,0.088,0.021,0.176,0.03,0.264l2.98-0.343
			C801.928,613.779,801.711,611.806,801.497,609.745L801.497,609.745z M802.863,621.588l-2.976,0.38
			c0.254,1.993,0.508,3.882,0.76,5.677c0.013,0.092,0.026,0.184,0.039,0.275l2.97-0.419
			C803.384,625.568,803.117,623.579,802.863,621.588L802.863,621.588z M804.531,633.389l-2.964,0.462
			c0.29,1.857,0.573,3.594,0.848,5.225c0.001,0.004,0.001,0.004,0.001,0.008c0.004,0.024,0.008,0.048,0.012,0.072
			c0.001,0.008,0.003,0.017,0.004,0.025c0.001,0.005,0.001,0.011,0.002,0.016c0.033,0.193,0.065,0.385,0.098,0.576l2.958-0.504
			C805.149,637.265,804.835,635.342,804.531,633.389L804.531,633.389z M806.527,645.144l-2.952,0.535
			c0.117,0.643,0.23,1.265,0.341,1.869c0.027,0.148,0.054,0.294,0.08,0.44c0.041,0.22,0.08,0.437,0.119,0.652
			c0.189,1.034,0.367,2.012,0.532,2.942l2.954-0.525c-0.201-1.129-0.419-2.327-0.654-3.609L806.527,645.144L806.527,645.144z
			 M808.636,656.81l-2.924,0.671c0,0.001,0.001,0.004,0.001,0.005c0.002,0.01,0.004,0.02,0.007,0.029
			c0,0.003,0.001,0.006,0.002,0.009c0.003,0.012,0.005,0.024,0.008,0.036c0.001,0.005,0.002,0.008,0.003,0.014
			c0.002,0.008,0.004,0.016,0.005,0.023c0.001,0.005,0.003,0.012,0.004,0.017c0.001,0.005,0.002,0.011,0.003,0.017
			c0.006,0.025,0.012,0.05,0.018,0.075c0.001,0.004,0.002,0.008,0.003,0.012c0.002,0.009,0.004,0.016,0.006,0.026
			c0,0.002,0.001,0.003,0.001,0.004c0.007,0.03,0.014,0.059,0.021,0.089c0,0.001,0,0.002,0,0.002
			c0.285,1.197,0.636,2.469,1.047,3.807c0.001,0.002,0.001,0.005,0.002,0.007c0.009,0.031,0.018,0.059,0.027,0.09
			c0.002,0.006,0.003,0.011,0.005,0.017c0.002,0.007,0.004,0.012,0.006,0.019c0.003,0.01,0.006,0.019,0.009,0.029
			c0.001,0.004,0.002,0.007,0.003,0.012c0.004,0.014,0.009,0.028,0.013,0.043c0,0.001,0.001,0.005,0.002,0.007
			c0.004,0.013,0.008,0.026,0.012,0.039c0.001,0.003,0.001,0.003,0.002,0.006c0.137,0.439,0.28,0.884,0.429,1.337l2.85-0.939
			C809.536,660.283,809.008,658.432,808.636,656.81L808.636,656.81z M812.188,667.816l-2.798,1.082
			c0.432,1.116,0.889,2.26,1.37,3.428c0,0.002,0.001,0.004,0.002,0.006c0.011,0.027,0.022,0.054,0.033,0.081
			c0.001,0.002,0.002,0.005,0.003,0.007c0.005,0.013,0.011,0.025,0.016,0.038c0.001,0.002,0.001,0.003,0.002,0.005
			c0.005,0.011,0.01,0.023,0.014,0.034c0.002,0.005,0.004,0.009,0.006,0.014c0.004,0.01,0.009,0.021,0.013,0.032
			c0.001,0.004,0.003,0.008,0.005,0.011c0.003,0.009,0.007,0.018,0.011,0.027c0.003,0.007,0.005,0.013,0.008,0.02
			c0.004,0.009,0.007,0.018,0.011,0.027c0.001,0.004,0.004,0.011,0.006,0.014c0.003,0.008,0.007,0.017,0.01,0.025
			c0.003,0.007,0.006,0.014,0.009,0.021c0.003,0.007,0.006,0.015,0.009,0.022c0.003,0.009,0.007,0.016,0.01,0.025
			c0.001,0.003,0.003,0.007,0.004,0.01c0.004,0.011,0.009,0.022,0.013,0.033c0.002,0.004,0.003,0.008,0.005,0.011
			c0.005,0.012,0.009,0.021,0.014,0.033c0.001,0.001,0.001,0.002,0.001,0.004c0.006,0.014,0.011,0.026,0.017,0.04c0,0,0,0,0,0
			c0.217,0.524,0.44,1.056,0.667,1.589l2.762-1.172C813.596,671.361,812.848,669.522,812.188,667.816L812.188,667.816z
			 M816.794,678.715l-2.734,1.234c0.125,0.279,0.252,0.558,0.38,0.838c0,0.002-0.001-0.001,0,0.001
			c0.013,0.027,0.025,0.055,0.037,0.082c0.001,0.002,0.002,0.004,0.003,0.006c0.012,0.026,0.023,0.052,0.035,0.078
			c0.001,0.002,0.003,0.007,0.004,0.009c0.011,0.024,0.022,0.049,0.033,0.073c0.002,0.004,0.003,0.006,0.004,0.01
			c0.01,0.022,0.021,0.044,0.03,0.067c0.003,0.006,0.005,0.011,0.008,0.017c0.01,0.021,0.02,0.042,0.029,0.063
			c0.004,0.01,0.008,0.018,0.012,0.028c0.008,0.017,0.016,0.034,0.023,0.051c0.006,0.013,0.012,0.027,0.019,0.04
			c0.007,0.016,0.015,0.033,0.022,0.049c0.006,0.013,0.012,0.026,0.018,0.039c0.004,0.009,0.008,0.019,0.013,0.028
			c0.01,0.021,0.018,0.039,0.027,0.06c0.004,0.008,0.007,0.016,0.011,0.024c0.01,0.023,0.022,0.049,0.033,0.071
			c0.001,0.003,0.002,0.005,0.004,0.008c0.012,0.027,0.024,0.054,0.037,0.081c0.002,0.004,0.003,0.008,0.005,0.011
			c0.256,0.559,0.516,1.121,0.778,1.686c0.002,0.005,0.004,0.01,0.007,0.015c0.011,0.023,0.02,0.042,0.03,0.065
			c0.004,0.01,0.009,0.02,0.014,0.029c0.006,0.013,0.013,0.028,0.019,0.041c0.008,0.017,0.016,0.035,0.024,0.052
			c0.004,0.01,0.009,0.019,0.013,0.029c0.01,0.021,0.02,0.042,0.03,0.064c0.003,0.006,0.007,0.015,0.009,0.02
			c0.012,0.024,0.023,0.049,0.034,0.073c0.001,0.002,0.003,0.007,0.004,0.009c0.013,0.027,0.025,0.053,0.037,0.08
			c0.001,0.002,0,0.001,0.001,0.003c0.24,0.514,0.482,1.03,0.726,1.549l2.713-1.279C818.405,682.244,817.566,680.426,816.794,678.715
			L816.794,678.715z M821.866,689.495l-2.697,1.313c0.867,1.782,1.754,3.579,2.653,5.383l2.685-1.338
			C823.612,693.057,822.729,691.268,821.866,689.495L821.866,689.495z M827.194,700.193l-2.675,1.357
			c0.251,0.495,0.503,0.99,0.755,1.485c0.008,0.016,0.016,0.032,0.024,0.048c0.016,0.03,0.031,0.061,0.047,0.092
			c0.632,1.241,1.269,2.483,1.903,3.719l2.668-1.371C829.007,703.751,828.097,701.972,827.194,700.193L827.194,700.193z
			 M832.665,710.845l-2.664,1.38c0.919,1.774,1.832,3.529,2.734,5.263c0.011,0.021,0.021,0.042,0.033,0.063l2.661-1.385
			C834.518,714.414,833.594,712.638,832.665,710.845L832.665,710.845z M838.199,721.49l-2.662,1.384
			c0.944,1.817,1.868,3.597,2.761,5.328l2.666-1.376C840.069,725.092,839.145,723.31,838.199,721.49L838.199,721.49z
			 M843.708,732.182l-2.674,1.359c0.58,1.142,1.141,2.255,1.681,3.335c0.345,0.691,0.682,1.368,1.008,2.031l2.691-1.326
			C845.611,735.95,844.726,734.184,843.708,732.182L843.708,732.182z M849.039,743.215l-2.781,1.125
			c0.2,0.494,0.389,0.989,0.567,1.484l0.001,0.002c0.016,0.044,0.031,0.087,0.046,0.13c0.001,0.003,0.002,0.006,0.003,0.008
			c0.003,0.01,0.007,0.019,0.01,0.029c0,0.001,0.001,0.004,0.002,0.005c0.01,0.03,0.021,0.06,0.031,0.089
			c0.001,0.004,0.004,0.01,0.005,0.015c0.002,0.008,0.005,0.015,0.008,0.023c0.002,0.006,0.004,0.011,0.006,0.017
			c0.006,0.017,0.012,0.034,0.018,0.052c0,0.001,0.001,0.004,0.002,0.005c0.002,0.007,0.005,0.015,0.008,0.022
			c0.002,0.006,0.004,0.011,0.006,0.017c0.002,0.007,0.005,0.014,0.007,0.022c0.002,0.007,0.005,0.013,0.007,0.02
			c0.002,0.006,0.004,0.012,0.006,0.019c0.004,0.013,0.007,0.022,0.012,0.034c0.002,0.006,0.004,0.011,0.006,0.017
			c0.003,0.009,0.006,0.018,0.009,0.026c0.001,0.005,0.003,0.009,0.005,0.014c0.003,0.01,0.006,0.019,0.01,0.029
			c0.001,0.004,0.002,0.007,0.004,0.011c0.01,0.028,0.019,0.055,0.028,0.083c0.001,0.002,0.001,0.004,0.002,0.006
			c0.004,0.013,0.009,0.025,0.013,0.038c0,0.001,0.001,0.003,0.001,0.005c0.015,0.042,0.03,0.089,0.044,0.131c0,0,0,0,0,0
			c0.374,1.114,0.695,2.23,0.971,3.351l2.914-0.715C850.499,747.241,849.835,745.183,849.039,743.215L849.039,743.215z
			 M852.115,755.624l-2.982,0.327c0.012,0.109,0.023,0.217,0.035,0.327l0,0c0.193,1.882,0.278,3.771,0.275,5.662l3,0.005
			C852.446,759.772,852.336,757.645,852.115,755.624L852.115,755.624z M849.159,767.931c-0.176,1.985-0.426,3.971-0.729,5.955
			l2.966,0.451c0.333-2.184,0.578-4.193,0.751-6.141L849.159,767.931L849.159,767.931z M847.4,779.796
			c-0.023,0.122-0.047,0.244-0.071,0.365c0,0.003-0.001,0.005-0.001,0.008c-0.005,0.026-0.01,0.051-0.015,0.077
			c-0.001,0.006-0.002,0.013-0.004,0.019c-0.004,0.023-0.009,0.046-0.014,0.069c-0.001,0.009-0.003,0.017-0.005,0.026
			c-0.004,0.02-0.007,0.038-0.012,0.059c-0.002,0.014-0.005,0.028-0.008,0.042c-0.003,0.015-0.006,0.032-0.009,0.046
			c-0.004,0.02-0.008,0.041-0.012,0.061c0,0.003-0.001,0.005-0.001,0.008c-0.17,0.865-0.346,1.73-0.524,2.593l0,0.002
			c-0.004,0.019-0.008,0.039-0.012,0.058c-0.003,0.013-0.005,0.026-0.008,0.039c-0.002,0.012-0.005,0.025-0.007,0.037
			c-0.004,0.02-0.009,0.042-0.013,0.062c-0.002,0.009-0.003,0.017-0.005,0.026c-0.005,0.025-0.01,0.048-0.015,0.072
			c-0.001,0.004-0.001,0.008-0.002,0.012c-0.006,0.029-0.012,0.058-0.018,0.086c0,0,0,0.001,0,0.001
			c-0.146,0.704-0.294,1.406-0.443,2.108l2.935,0.623c0.37-1.741,0.803-3.807,1.213-5.932L847.4,779.796L847.4,779.796z
			 M844.949,791.544c-0.081,0.386-0.161,0.771-0.24,1.156c0,0.001,0,0.001,0,0.002c-0.004,0.021-0.009,0.042-0.013,0.064
			c-0.004,0.019-0.007,0.037-0.011,0.056c-0.002,0.009-0.004,0.018-0.005,0.026c-0.005,0.026-0.011,0.052-0.016,0.078
			c0,0.001-0.001,0.005-0.001,0.006c-0.006,0.03-0.012,0.059-0.018,0.089c0,0.001,0,0.001,0,0.002
			c-0.303,1.476-0.593,2.945-0.86,4.406l2.951,0.54c0.306-1.673,0.672-3.519,1.152-5.811L844.949,791.544L844.949,791.544z
			 M842.84,803.354c-0.024,0.188-0.048,0.377-0.072,0.565c0,0.001,0,0.002,0,0.004c-0.001,0.012-0.003,0.024-0.004,0.037
			c-0.001,0.005-0.001,0.011-0.002,0.017c-0.001,0.008-0.001,0.015-0.002,0.022c-0.004,0.029-0.007,0.055-0.011,0.084
			c0,0.001,0,0.001,0,0.002c-0.138,1.126-0.251,2.244-0.338,3.358c0,0.005,0,0.009-0.001,0.014c0,0.01-0.001,0.021-0.002,0.031
			c0,0.01-0.001,0.019-0.002,0.028c0,0.005-0.001,0.011-0.001,0.017c0,0.009-0.001,0.017-0.002,0.026c0,0.004,0,0.01-0.001,0.014
			c-0.002,0.028-0.004,0.057-0.006,0.085c0,0.001,0,0.004,0,0.006c-0.041,0.557-0.075,1.112-0.102,1.665l2.997,0.144
			c0.086-1.797,0.258-3.671,0.527-5.729L842.84,803.354L842.84,803.354z M845.259,815.263l-3,0.058
			c0.01,0.53,0.023,1.086,0.039,1.665c0.037,1.326,0.088,2.773,0.162,4.334l2.997-0.14
			C845.362,819.162,845.296,817.172,845.259,815.263L845.259,815.263z M845.809,827.092l-2.992,0.216
			c0.042,0.583,0.087,1.176,0.135,1.778c0,0.008,0.001,0.015,0.002,0.023c0,0.005,0,0.006,0,0.011
			c0.001,0.013,0.002,0.027,0.003,0.04c0,0.002,0,0.005,0,0.007c0.001,0.013,0.002,0.027,0.003,0.04c0,0.002,0,0.004,0,0.006
			c0.054,0.673,0.111,1.357,0.173,2.052c0,0.002,0,0.003,0,0.005c0.001,0.013,0.002,0.025,0.003,0.038
			c0.001,0.01,0.001,0.019,0.002,0.028c0,0.002,0,0.005,0.001,0.007c0.057,0.639,0.117,1.289,0.181,1.945l2.986-0.291
			C846.119,831.047,845.95,829.06,845.809,827.092L845.809,827.092z M846.958,838.883l-2.977,0.368
			c0.24,1.939,0.511,3.925,0.817,5.945l2.966-0.45C847.473,842.828,847.202,840.855,846.958,838.883L846.958,838.883z
			 M848.738,850.575l-2.951,0.539c0.219,1.198,0.451,2.404,0.697,3.614c0,0.002,0,0.003,0.001,0.005
			c0.006,0.028,0.011,0.057,0.017,0.085c0.002,0.008,0.004,0.017,0.005,0.026c0.004,0.02,0.007,0.032,0.011,0.052
			c0.003,0.016,0.006,0.031,0.01,0.047c0.003,0.014,0.006,0.03,0.009,0.045c0.004,0.019,0.008,0.038,0.012,0.057
			c0.002,0.009,0.004,0.019,0.006,0.028c0.004,0.021,0.008,0.042,0.013,0.063c0.002,0.009,0.004,0.022,0.006,0.03
			c0.005,0.023,0.01,0.046,0.014,0.07c0.002,0.009,0.002,0.01,0.004,0.018c0.005,0.025,0.01,0.049,0.015,0.074
			c0.001,0.006,0.002,0.01,0.003,0.015c0.005,0.026,0.011,0.052,0.016,0.078c0.001,0.006,0.002,0.009,0.003,0.015
			c0.006,0.027,0.011,0.054,0.017,0.081c0.001,0.006,0.001,0.005,0.002,0.01c0.006,0.028,0.012,0.057,0.018,0.085
			c0,0.003,0-0.001,0,0.002c0.006,0.03,0.012,0.06,0.019,0.089c0,0.003,0,0.002,0.001,0.004c0.006,0.03,0.013,0.06,0.019,0.09
			l0,0.001c0.019,0.092,0.039,0.183,0.058,0.275l0.001,0.003c0.013,0.06,0.025,0.12,0.038,0.18l0.001,0.005
			c0.006,0.029,0.013,0.059,0.019,0.089l0.001,0.004c0.006,0.029,0.013,0.059,0.019,0.088l0.001,0.006
			c0.006,0.029,0.012,0.057,0.019,0.086c0,0.003,0,0.002,0.001,0.005c0.006,0.027,0.012,0.054,0.017,0.081
			c0.002,0.009,0.002,0.01,0.004,0.018c0.006,0.025,0.011,0.051,0.017,0.076c0.002,0.009,0.002,0.009,0.004,0.018
			c0.005,0.024,0.01,0.048,0.016,0.072c0.001,0.009,0.003,0.015,0.005,0.023c0.004,0.021,0.009,0.042,0.014,0.063
			c0.002,0.011,0.004,0.022,0.007,0.033c0.004,0.019,0.008,0.038,0.013,0.057c0.001,0.006,0.003,0.013,0.004,0.019l2.932-0.636
			C849.484,854.482,849.096,852.535,848.738,850.575L848.738,850.575z M851.244,862.095l-2.906,0.743
			c0.224,0.876,0.456,1.751,0.696,2.625l0,0.002c0.008,0.029,0.016,0.058,0.024,0.087c0.001,0.003,0.001,0.003,0.001,0.006
			c0.008,0.027,0.015,0.054,0.022,0.081c0.002,0.006,0.001,0.003,0.003,0.009c0.007,0.027,0.015,0.055,0.022,0.082
			c0.001,0.003,0.002,0.008,0.003,0.011c0.007,0.026,0.014,0.051,0.021,0.077c0.001,0.006,0.003,0.01,0.004,0.016
			c0.006,0.024,0.013,0.047,0.02,0.071c0.002,0.006,0.004,0.013,0.005,0.019c0.007,0.025,0.014,0.05,0.021,0.075
			c0.001,0.006,0.003,0.013,0.005,0.018c0.006,0.021,0.012,0.042,0.018,0.062c0.003,0.011,0.006,0.023,0.01,0.034
			c0.005,0.02,0.011,0.04,0.017,0.06c0.002,0.009,0.004,0.017,0.007,0.025c0.006,0.022,0.013,0.044,0.019,0.066
			c0.002,0.009,0.006,0.022,0.008,0.031c0.005,0.018,0.01,0.035,0.015,0.053c0.004,0.014,0.007,0.026,0.011,0.04
			c0.004,0.017,0.009,0.034,0.014,0.05c0.004,0.014,0.008,0.029,0.012,0.043c0.004,0.014,0.008,0.029,0.012,0.043
			c0.004,0.014,0.008,0.03,0.012,0.044c0.005,0.016,0.009,0.032,0.014,0.047c0.004,0.014,0.009,0.033,0.014,0.048
			c0.003,0.012,0.006,0.024,0.01,0.036c0.006,0.02,0.012,0.04,0.018,0.06c0.003,0.01,0.006,0.02,0.008,0.031
			c0.006,0.02,0.01,0.035,0.016,0.054c0.004,0.013,0.008,0.026,0.011,0.039c0.006,0.02,0.011,0.037,0.016,0.057
			c0.002,0.007,0.004,0.015,0.006,0.022c0.009,0.031,0.017,0.058,0.026,0.089c0,0,0,0.001,0,0.001
			c0.017,0.057,0.033,0.113,0.049,0.17v0c0.135,0.466,0.272,0.933,0.412,1.397l2.873-0.863
			C852.266,865.93,851.737,864.025,851.244,862.095L851.244,862.095z M854.629,873.325l-2.829,0.999
			c0.491,1.391,1.007,2.768,1.549,4.129c0.001,0.003,0.001,0.002,0.002,0.005c0.01,0.026,0.021,0.052,0.031,0.078
			c0.001,0.003,0.004,0.012,0.006,0.014c0.009,0.024,0.019,0.048,0.028,0.071c0.002,0.005,0.004,0.011,0.007,0.017
			c0.008,0.022,0.017,0.043,0.026,0.065c0.003,0.008,0.006,0.014,0.009,0.022c0.009,0.021,0.018,0.043,0.026,0.065
			c0.004,0.011,0.008,0.019,0.012,0.029c0.006,0.016,0.013,0.032,0.019,0.048c0.006,0.016,0.012,0.031,0.019,0.047
			c0.005,0.012,0.009,0.024,0.014,0.036c0.008,0.019,0.018,0.043,0.025,0.062c0.001,0.004,0.003,0.008,0.005,0.012
			c0.122,0.3,0.246,0.603,0.37,0.902l2.77-1.153C855.994,877.032,855.291,875.199,854.629,873.325L854.629,873.325z M859.117,884.059
			l-2.689,1.33c0.747,1.511,1.532,2.986,2.358,4.423c0.002,0.004,0.005,0.009,0.007,0.013c0.013,0.022,0.025,0.044,0.038,0.066
			c0.005,0.009,0.01,0.017,0.015,0.026c0.009,0.015,0.018,0.03,0.026,0.044c0.008,0.014,0.016,0.028,0.024,0.042
			c0.007,0.012,0.015,0.026,0.022,0.038c0.009,0.016,0.018,0.032,0.027,0.048c0.006,0.01,0.011,0.019,0.017,0.029
			c0.011,0.018,0.021,0.037,0.032,0.055c0.004,0.007,0.007,0.013,0.012,0.02c0.013,0.022,0.025,0.043,0.038,0.065
			c0.003,0.005,0.005,0.009,0.008,0.014c0.013,0.023,0.027,0.046,0.041,0.069c0.001,0.002,0.003,0.006,0.005,0.008
			c0.015,0.025,0.029,0.05,0.044,0.075l0,0c0.047,0.08,0.094,0.16,0.142,0.24l2.58-1.531
			C860.916,887.534,859.992,885.828,859.117,884.059L859.117,884.059z M865.001,893.92l-2.429,1.76
			c1.187,1.638,2.443,3.197,3.771,4.663l2.223-2.014C867.34,896.976,866.141,895.492,865.001,893.92L865.001,893.92z
			 M872.585,902.246l-1.948,2.282c1.506,1.286,3.091,2.462,4.76,3.513c0.002,0.001,0.005,0.003,0.007,0.004
			c0.011,0.007,0.026,0.017,0.038,0.024c0.004,0.003,0.009,0.006,0.013,0.008s0.004,0.003,0.009,0.005l1.595-2.541
			C875.524,904.579,874.019,903.47,872.585,902.246L872.585,902.246z M881.948,908.1l-1.174,2.761
			c1.644,0.699,3.362,1.289,5.148,1.758c0.004,0.001,0.007,0.002,0.011,0.003c0.008,0.002,0.018,0.005,0.025,0.007
			c0.005,0.001,0.01,0.002,0.015,0.004c0.011,0.003,0.026,0.007,0.037,0.01c0.007,0.002,0.015,0.004,0.022,0.006
			c0.005,0.001,0.012,0.003,0.018,0.005c0.011,0.003,0.022,0.006,0.033,0.008c0.005,0.001,0.006,0.002,0.011,0.003
			c0.012,0.003,0.024,0.006,0.037,0.009c0.003,0.001,0.005,0.001,0.008,0.002c0.106,0.027,0.213,0.054,0.32,0.081l0.719-2.913
			C885.389,909.401,883.629,908.814,881.948,908.1L881.948,908.1z M892.645,910.755l-0.273,2.988
			c0.819,0.075,1.652,0.127,2.498,0.155h0c0.021,0.001,0.045,0.001,0.067,0.002c0.003,0,0.007,0,0.01,0
			c0.019,0,0.04,0.001,0.058,0.002c0.004,0,0.008,0,0.012,0c0.012,0,0.021,0.001,0.033,0.001c0.003,0,0.007,0,0.011,0
			c0.018,0,0.038,0.001,0.056,0.002c0.007,0,0.014,0,0.02,0c0.012,0,0.033,0.001,0.045,0.001c0.008,0,0.017,0,0.024,0.001
			c0.012,0,0.023,0.001,0.035,0.001c0.011,0,0.021,0,0.032,0.001c0.006,0,0.021,0,0.027,0.001c0.014,0,0.028,0.001,0.042,0.001
			c0.009,0,0.016,0,0.024,0.001c0.011,0,0.021,0,0.031,0.001c0.012,0,0.02,0,0.032,0.001c0.011,0,0.022,0,0.034,0.001
			c0.009,0,0.013,0,0.022,0c0.015,0,0.03,0.001,0.045,0.001c0.006,0,0.016,0,0.021,0c0.016,0,0.031,0.001,0.046,0.001
			c0.006,0,0.013,0,0.019,0c0.014,0,0.027,0,0.041,0.001c0.003,0,0.013,0,0.016,0c0.016,0,0.032,0,0.048,0.001
			c0.003,0,0.013,0,0.017,0c0.017,0,0.034,0,0.05,0.001c0.003,0,0.012,0,0.015,0c0.018,0,0.035,0,0.052,0.001
			c0.003,0,0.009,0,0.012,0c0.016,0,0.032,0,0.047,0c0.003,0,0.008,0,0.011,0c0.018,0,0.035,0,0.053,0c0.006,0,0.008,0,0.015,0
			c0.017,0,0.035,0,0.052,0c0.003,0,0.009,0,0.012,0c0.019,0,0.037,0,0.055,0c0.003,0,0.004,0,0.007,0c0.018,0,0.035,0,0.052,0
			c0.006,0,0.004,0,0.01,0c0.019,0,0.037,0,0.055,0c0.006,0,0.006,0,0.012,0c0.018,0,0.036,0,0.055,0c0.003,0,0.006,0,0.009,0
			c0.02,0,0.039,0,0.058,0c0.019,0,0.037,0,0.056,0c0.006,0,0.012,0,0.018,0c0.013,0,0.026,0,0.039,0c0.006,0,0.013,0,0.019,0
			c0.014,0,0.028,0,0.042,0c0.004,0,0.01,0,0.014,0c0.017,0,0.035,0,0.052,0c0.008,0,0.016,0,0.024,0c0.01,0,0.021,0,0.031,0
			c0.009,0,0.019,0,0.027,0c0.009,0,0.017,0,0.026,0c0.039,0,0.077-0.001,0.115-0.001c0.004,0,0.008,0,0.013,0
			c0.014,0,0.027,0,0.041,0c0.003,0,0.006,0,0.008,0c0.487-0.006,0.979-0.019,1.474-0.04l-0.127-2.997
			c-0.626,0.027-1.256,0.04-1.872,0.04C895.111,910.922,893.858,910.866,892.645,910.755L892.645,910.755z M909.458,909.144
			c-1.896,0.488-3.776,0.88-5.591,1.167l0.468,2.963c1.179-0.186,2.38-0.413,3.604-0.683c0,0,0.001,0,0.002,0
			c0.017-0.004,0.035-0.008,0.051-0.011c0.001,0,0.001,0,0.002,0c0.07-0.016,0.141-0.031,0.211-0.047
			c0.003-0.001,0.006-0.001,0.009-0.002c0.013-0.003,0.027-0.006,0.041-0.009c0.005-0.001,0.01-0.002,0.015-0.003
			c0.013-0.003,0.025-0.006,0.039-0.009c0.004-0.001,0.007-0.002,0.011-0.003c0.014-0.003,0.028-0.006,0.042-0.01
			c0.003-0.001,0.006-0.001,0.01-0.002c0.034-0.008,0.069-0.016,0.104-0.024c0.003-0.001,0.007-0.002,0.01-0.002
			c0.014-0.003,0.027-0.006,0.041-0.009c0.005-0.001,0.01-0.002,0.015-0.004c0.011-0.003,0.023-0.005,0.035-0.008
			c0.006-0.001,0.013-0.003,0.019-0.004c0.011-0.003,0.021-0.005,0.033-0.008c0.006-0.001,0.012-0.003,0.019-0.004
			c0.011-0.003,0.022-0.005,0.034-0.008c0.006-0.001,0.011-0.003,0.017-0.004c0.012-0.003,0.025-0.006,0.038-0.009
			c0.004-0.001,0.009-0.002,0.014-0.003c0.017-0.004,0.033-0.008,0.05-0.012c0.006-0.001,0.011-0.003,0.017-0.004
			c0.011-0.003,0.022-0.005,0.033-0.008c0.008-0.002,0.017-0.004,0.024-0.006c0.008-0.002,0.017-0.004,0.025-0.006
			c0.009-0.002,0.019-0.004,0.028-0.007c0.007-0.002,0.014-0.003,0.021-0.005c0.009-0.002,0.019-0.005,0.028-0.007
			c0.007-0.002,0.015-0.004,0.022-0.005c0.01-0.002,0.02-0.005,0.029-0.007c0.006-0.001,0.013-0.003,0.019-0.005
			c0.01-0.002,0.021-0.005,0.031-0.007c0.004-0.001,0.008-0.002,0.013-0.003c0.027-0.006,0.054-0.013,0.081-0.019l0.001,0
			c0.013-0.003,0.026-0.006,0.04-0.01c0.003-0.001,0.006-0.001,0.009-0.002c0.013-0.003,0.025-0.006,0.038-0.009
			c0.004-0.001,0.008-0.002,0.012-0.003c0.013-0.003,0.026-0.006,0.039-0.009c0.003-0.001,0.005-0.001,0.008-0.002
			c0.014-0.003,0.027-0.007,0.041-0.01c0.002,0,0.003-0.001,0.005-0.001c0.092-0.022,0.185-0.045,0.277-0.068c0,0,0,0,0.001,0
			c0.188-0.047,0.376-0.094,0.565-0.143L909.458,909.144L909.458,909.144z M920.387,905.406c-1.828,0.769-3.65,1.467-5.415,2.075
			l0.977,2.837c0.113-0.039,0.227-0.078,0.341-0.118c0,0,0,0,0.001,0c0.012-0.004,0.023-0.008,0.036-0.012
			c0.005-0.002,0.01-0.004,0.015-0.005c0.011-0.004,0.022-0.008,0.033-0.011c0.005-0.002,0.011-0.004,0.017-0.006
			c0.017-0.006,0.034-0.012,0.051-0.018c0.009-0.003,0.018-0.006,0.027-0.009c0.008-0.003,0.016-0.006,0.024-0.009
			c0.009-0.003,0.019-0.007,0.028-0.01c0.007-0.003,0.015-0.005,0.022-0.008c0.017-0.006,0.034-0.012,0.051-0.018
			c0.001,0,0.002-0.001,0.003-0.001c0.018-0.006,0.035-0.012,0.053-0.019c0.002-0.001,0.004-0.002,0.007-0.002
			c1.529-0.542,3.089-1.144,4.681-1.81c0.005-0.002,0.01-0.004,0.015-0.006c0.013-0.006,0.025-0.011,0.039-0.016
			c0.007-0.003,0.014-0.006,0.021-0.009c0.01-0.004,0.02-0.008,0.03-0.013c0.02-0.008,0.041-0.017,0.061-0.025
			c0.001-0.001,0.002-0.001,0.003-0.002c0.015-0.006,0.029-0.012,0.044-0.018l0,0L920.387,905.406L920.387,905.406z M930.939,900.326
			c-1.687,0.911-3.444,1.808-5.223,2.665l1.302,2.703c1.342-0.647,2.644-1.301,3.923-1.971c0.003-0.002,0.006-0.003,0.01-0.005
			c0.048-0.025,0.096-0.05,0.143-0.075c0.007-0.003,0.014-0.007,0.02-0.01c0.018-0.009,0.042-0.022,0.059-0.031
			c0.012-0.006,0.024-0.013,0.037-0.019c0.013-0.007,0.026-0.014,0.039-0.021c0.011-0.005,0.021-0.011,0.032-0.016
			c0.017-0.009,0.037-0.019,0.054-0.029c0.013-0.007,0.026-0.014,0.039-0.02c0.008-0.005,0.025-0.013,0.034-0.018
			c0.017-0.009,0.034-0.018,0.051-0.027c0.013-0.007,0.02-0.01,0.033-0.017c0.016-0.008,0.032-0.017,0.047-0.025
			c0.004-0.002,0.021-0.011,0.025-0.013c0.021-0.011,0.043-0.023,0.065-0.035c0.008-0.005,0.012-0.007,0.021-0.011
			c0.019-0.01,0.037-0.02,0.055-0.029c0.009-0.005,0.009-0.005,0.018-0.01c0.023-0.012,0.046-0.025,0.069-0.037
			c0.004-0.002,0.012-0.006,0.016-0.009c0.05-0.027,0.101-0.054,0.151-0.081l0.004-0.002c0.135-0.072,0.269-0.145,0.403-0.217
			L930.939,900.326L930.939,900.326z M940.914,894.26c-1.574,1.078-3.223,2.141-4.899,3.159l1.557,2.564
			c0.354-0.215,0.705-0.431,1.053-0.648l0.004-0.003c0.012-0.008,0.024-0.015,0.037-0.023c0.004-0.002,0.002-0.002,0.006-0.004
			c0.011-0.007,0.022-0.014,0.033-0.021c0.004-0.002,0.012-0.008,0.016-0.01c0.009-0.005,0.018-0.011,0.026-0.016
			c0.015-0.01,0.026-0.017,0.042-0.026c0.003-0.002,0.007-0.004,0.01-0.006c0.012-0.007,0.022-0.014,0.034-0.021
			c0.004-0.003,0.009-0.006,0.014-0.009c0.012-0.007,0.022-0.014,0.034-0.021c0.002-0.002,0.005-0.004,0.008-0.005
			c0.016-0.01,0.021-0.013,0.037-0.023c0.003-0.002,0.005-0.003,0.008-0.005c0.016-0.01,0.027-0.017,0.042-0.027c0,0,0,0,0.001,0
			c1.132-0.71,2.23-1.428,3.305-2.158c0.003-0.002,0.006-0.004,0.009-0.006c0.015-0.01,0.021-0.014,0.035-0.024
			c0.002-0.002,0.005-0.004,0.008-0.005c0.011-0.007,0.021-0.015,0.033-0.022c0.003-0.003,0.007-0.005,0.011-0.007
			c0.015-0.01,0.026-0.018,0.042-0.028c0,0,0,0,0,0c0.015-0.01,0.025-0.017,0.04-0.027c0.009-0.006,0.018-0.012,0.027-0.018
			c0.003-0.003,0.009-0.006,0.013-0.009c0.009-0.006,0.018-0.012,0.027-0.018c0.003-0.002,0.009-0.006,0.013-0.009
			c0.01-0.007,0.021-0.014,0.031-0.021l0.004-0.003c0.012-0.008,0.024-0.016,0.036-0.024L940.914,894.26L940.914,894.26z
			 M950.094,887.128c-1.426,1.26-2.931,2.505-4.472,3.701l1.839,2.37c1.033-0.802,2.036-1.613,3.008-2.432
			c0.003-0.003,0,0,0.003-0.002c0.01-0.009,0.021-0.018,0.031-0.026c0.003-0.003,0.003-0.003,0.006-0.005
			c0.009-0.008,0.018-0.015,0.026-0.023c0.006-0.005,0.005-0.004,0.011-0.009c0.009-0.007,0.017-0.014,0.025-0.021
			c0.006-0.005,0.006-0.005,0.012-0.01c0.007-0.006,0.015-0.012,0.021-0.018c0.006-0.005,0.011-0.009,0.017-0.015
			c0.007-0.005,0.013-0.011,0.02-0.016c0.006-0.005,0.015-0.013,0.021-0.018c0.004-0.004,0.008-0.007,0.012-0.01
			c0.01-0.008,0.018-0.015,0.027-0.023c0.003-0.003,0.007-0.006,0.01-0.009c0.013-0.011,0.021-0.017,0.033-0.028
			c0.001-0.001,0.001-0.001,0.002-0.002c0.013-0.011,0.023-0.02,0.036-0.031c0,0,0.001-0.001,0.001-0.001
			c0.436-0.371,0.869-0.747,1.293-1.121L950.094,887.128L950.094,887.128z M958.216,878.888c-1.235,1.447-2.551,2.88-3.912,4.259
			l2.135,2.107c0.671-0.68,1.325-1.365,1.96-2.055l0.002-0.003c0.01-0.01,0.02-0.021,0.029-0.032l0.003-0.003
			c0.009-0.01,0.018-0.019,0.027-0.029c0.002-0.003,0.003-0.004,0.006-0.007c0.008-0.009,0.016-0.018,0.024-0.027
			c0.003-0.003,0.005-0.006,0.008-0.009c0.007-0.007,0.014-0.015,0.021-0.022c0.005-0.006,0.007-0.008,0.012-0.014
			c0.006-0.007,0.013-0.014,0.019-0.02c0.005-0.006,0.008-0.009,0.014-0.015c0.005-0.006,0.011-0.012,0.017-0.018
			c0.005-0.006,0.009-0.01,0.015-0.016c0.006-0.007,0.012-0.013,0.018-0.02c0.002-0.003,0.011-0.012,0.013-0.015
			c0.005-0.006,0.011-0.011,0.016-0.017c0.005-0.006,0.012-0.012,0.017-0.018c0.004-0.005,0.009-0.01,0.014-0.015
			c0.008-0.009,0.011-0.012,0.019-0.021c0.003-0.004,0.007-0.008,0.011-0.012c0.008-0.008,0.012-0.013,0.02-0.022
			c0.004-0.005,0.009-0.009,0.013-0.014c0.007-0.009,0.012-0.013,0.02-0.022c0.002-0.002,0.004-0.005,0.007-0.008
			c0.008-0.008,0.017-0.019,0.025-0.027c0.001-0.001,0.002-0.003,0.004-0.004c0.01-0.011,0.017-0.019,0.027-0.03
			c0,0,0-0.001,0.001-0.001c0.01-0.011,0.02-0.022,0.03-0.033c0,0,0,0,0,0c0.564-0.622,1.112-1.244,1.648-1.872L958.216,878.888
			L958.216,878.888z M965.009,869.577c-1,1.622-2.081,3.231-3.214,4.783l2.423,1.769c1.2-1.643,2.313-3.303,3.345-4.978
			L965.009,869.577L965.009,869.577z M970.246,859.331c-0.732,1.768-1.544,3.526-2.414,5.227l2.671,1.365
			c0.547-1.071,1.064-2.146,1.552-3.224c0.001-0.003,0.001-0.003,0.003-0.006c0.005-0.012,0.011-0.024,0.016-0.036
			c0.001-0.003,0.001-0.002,0.002-0.005c0.006-0.013,0.012-0.026,0.018-0.039c0.001-0.003,0,0,0.001-0.003
			c0.012-0.026,0.024-0.053,0.036-0.079c0.004-0.009,0.005-0.012,0.009-0.021c0.003-0.006,0.006-0.013,0.009-0.02
			c0.003-0.006,0.007-0.014,0.009-0.021c0.003-0.006,0.005-0.012,0.008-0.018c0.003-0.006,0.007-0.016,0.01-0.022
			c0.002-0.006,0.005-0.011,0.007-0.017c0.004-0.009,0.007-0.015,0.011-0.025c0.001-0.002,0.002-0.004,0.003-0.006
			c0.283-0.634,0.556-1.269,0.819-1.905L970.246,859.331L970.246,859.331z M973.8,848.373c-0.45,1.871-0.976,3.739-1.563,5.551
			l2.854,0.925l0.002-0.006c0.004-0.012,0.008-0.025,0.012-0.037c0.001-0.003,0-0.001,0.001-0.004
			c0.005-0.014,0.009-0.029,0.014-0.043v0c0.533-1.653,1.005-3.308,1.419-4.962v0c0.003-0.014,0.007-0.028,0.011-0.042
			c0-0.003,0-0.003,0.001-0.006c0.002-0.011,0.005-0.022,0.008-0.033c0-0.003,0.002-0.011,0.003-0.014
			c0.002-0.01,0.005-0.02,0.007-0.029c0.001-0.006,0.001-0.007,0.003-0.013c0.003-0.011,0.005-0.021,0.008-0.032
			c0.003-0.012,0.005-0.023,0.008-0.035c0.001-0.004,0.002-0.007,0.003-0.011c0.002-0.009,0.005-0.02,0.007-0.029
			c0-0.003,0.001-0.006,0.002-0.01c0.003-0.012,0.005-0.021,0.008-0.033c0,0,0-0.001,0-0.001c0.035-0.144,0.07-0.289,0.105-0.433
			L973.8,848.373L973.8,848.373z M975.66,836.974c-0.173,1.927-0.417,3.858-0.722,5.739l2.961,0.482
			c0.08-0.492,0.155-0.983,0.226-1.474c0,0,0-0.001,0-0.002c0.001-0.012,0.003-0.023,0.005-0.035c0-0.001,0-0.003,0-0.004
			c0.005-0.033,0.01-0.067,0.014-0.1c0-0.003,0.001-0.006,0.001-0.009c0.001-0.009,0.002-0.016,0.003-0.025
			c0.001-0.007,0.002-0.013,0.003-0.02c0.001-0.009,0.002-0.013,0.003-0.022c0.001-0.008,0.002-0.016,0.003-0.024
			c0.001-0.006,0.001-0.01,0.002-0.016c0.002-0.014,0.004-0.028,0.006-0.041c0.001-0.006,0-0.005,0.001-0.011
			c0.001-0.011,0.003-0.023,0.005-0.034c0-0.003,0.001-0.007,0.001-0.01c0.001-0.012,0.003-0.024,0.005-0.036
			c0-0.003,0-0.005,0.001-0.009c0.19-1.366,0.345-2.728,0.467-4.082L975.66,836.974L975.66,836.974z M978.911,825.254l-2.997,0.132
			c0.085,1.939,0.107,3.892,0.067,5.802l2.999,0.064c0.017-0.782,0.023-1.561,0.02-2.336c0-0.002,0-0.003,0-0.005
			c0-0.012,0-0.019,0-0.031c0-0.004,0-0.007,0-0.01c0-0.021,0-0.037,0-0.058c0-0.002,0-0.003,0-0.005c0-0.009,0-0.02,0-0.029
			c0-0.004,0-0.008,0-0.012c0-0.009,0-0.017,0-0.026c0-0.005,0-0.01,0-0.015c0-0.018,0-0.03,0-0.048c0-0.004,0-0.009,0-0.013
			c0-0.009,0-0.017,0-0.025c0-0.005,0-0.01,0-0.015c0-0.009,0-0.015,0-0.024c0-0.007,0-0.014,0-0.02c0-0.012,0-0.027,0-0.039
			c0-0.006,0-0.012,0-0.018c0-0.009,0-0.014,0-0.023c0-0.007,0-0.014,0-0.021c0-0.006,0-0.013,0-0.019c0-0.007,0-0.014,0-0.021
			c0-0.012,0-0.028,0-0.04c0-0.006,0-0.011,0-0.017c0-0.009,0-0.015,0-0.024c0-0.005,0-0.01,0-0.015c0-0.009,0-0.017,0-0.025
			c0-0.004,0-0.008,0-0.012c0-0.032-0.001-0.062-0.001-0.094c0-0.001,0-0.001,0-0.002c0-0.012,0-0.022,0-0.034c0,0,0-0.001,0-0.001
			c0-0.023,0-0.042-0.001-0.065c0-0.002,0-0.003,0-0.005c0-0.012,0-0.02,0-0.032c0-0.001,0-0.003,0-0.004
			C978.979,827.124,978.951,826.186,978.911,825.254L978.911,825.254z M977.641,813.329l-2.958,0.498
			c0.322,1.913,0.589,3.853,0.793,5.764l2.983-0.319c-0.081-0.759-0.17-1.512-0.268-2.259l0-0.002c-0.004-0.03-0.008-0.06-0.012-0.09
			c0-0.003,0-0.001,0-0.004c-0.004-0.03-0.008-0.059-0.012-0.089c0-0.003,0-0.003-0.001-0.006c-0.004-0.03-0.008-0.059-0.012-0.089
			l0-0.004c-0.004-0.03-0.008-0.06-0.012-0.089c0-0.003,0-0.002,0-0.004c-0.004-0.03-0.008-0.059-0.012-0.089l0-0.002
			c-0.004-0.029-0.008-0.058-0.012-0.087c0-0.003,0-0.006-0.001-0.009c-0.004-0.028-0.008-0.056-0.012-0.083
			c0-0.003-0.001-0.008-0.001-0.011c-0.003-0.027-0.007-0.054-0.011-0.081c-0.001-0.005-0.001-0.01-0.002-0.015
			c-0.003-0.026-0.007-0.053-0.011-0.079c0-0.003-0.001-0.01-0.002-0.013c-0.003-0.027-0.007-0.054-0.011-0.081
			c0-0.005-0.001-0.007-0.001-0.013c-0.004-0.026-0.007-0.051-0.011-0.077c0-0.005-0.001-0.011-0.002-0.016
			c-0.003-0.025-0.007-0.05-0.011-0.075c0-0.005-0.001-0.012-0.002-0.017c-0.003-0.026-0.007-0.051-0.011-0.077
			c-0.001-0.005-0.001-0.01-0.002-0.015c-0.003-0.025-0.007-0.051-0.011-0.077c-0.001-0.005-0.002-0.013-0.002-0.018
			c-0.004-0.025-0.007-0.05-0.011-0.075c-0.001-0.008-0.001-0.011-0.002-0.019c-0.003-0.024-0.007-0.048-0.01-0.071
			c-0.001-0.005-0.003-0.018-0.003-0.023c-0.003-0.022-0.007-0.045-0.01-0.067c-0.001-0.008-0.002-0.017-0.003-0.026
			c-0.003-0.021-0.006-0.043-0.01-0.064c-0.001-0.008-0.003-0.02-0.004-0.028c-0.003-0.022-0.006-0.043-0.01-0.065
			c-0.001-0.008-0.002-0.018-0.004-0.026c-0.003-0.022-0.006-0.044-0.01-0.066c-0.001-0.008-0.003-0.019-0.004-0.027
			c-0.003-0.022-0.006-0.044-0.01-0.065c-0.001-0.008-0.003-0.02-0.004-0.028c-0.003-0.02-0.006-0.04-0.009-0.06
			c-0.002-0.011-0.004-0.024-0.005-0.035c-0.003-0.018-0.005-0.036-0.008-0.053c-0.002-0.013-0.004-0.028-0.006-0.041
			c-0.002-0.017-0.005-0.033-0.007-0.05c-0.002-0.016-0.004-0.028-0.007-0.044c-0.002-0.016-0.005-0.032-0.007-0.048
			c-0.002-0.013-0.005-0.032-0.007-0.046c-0.002-0.015-0.004-0.031-0.007-0.046c-0.002-0.016-0.005-0.032-0.007-0.048
			c-0.002-0.013-0.004-0.027-0.006-0.04c-0.002-0.016-0.005-0.035-0.008-0.051c-0.002-0.012-0.004-0.025-0.006-0.037
			c-0.002-0.016-0.006-0.036-0.008-0.052c-0.002-0.013-0.004-0.027-0.006-0.04c-0.003-0.019-0.005-0.036-0.009-0.055
			c-0.002-0.013-0.004-0.025-0.006-0.038c-0.003-0.019-0.006-0.037-0.009-0.056c-0.001-0.01-0.003-0.019-0.005-0.029
			c-0.003-0.021-0.007-0.045-0.01-0.067c-0.001-0.005-0.002-0.011-0.003-0.017c-0.004-0.024-0.008-0.053-0.012-0.077
			c-0.001-0.006-0.002-0.012-0.003-0.018c-0.004-0.027-0.009-0.053-0.013-0.079c-0.001-0.003-0.001-0.007-0.002-0.01
			C977.706,813.718,977.674,813.523,977.641,813.329L977.641,813.329z M974.95,801.64l-2.878,0.847
			c0.542,1.845,1.039,3.74,1.474,5.632l2.923-0.673C976.007,805.435,975.497,803.496,974.95,801.64L974.95,801.64z M970.83,790.378
			l-2.738,1.227c0.76,1.694,1.489,3.499,2.168,5.363l2.819-1.026c-0.015-0.042-0.029-0.079-0.044-0.121c0-0.001,0-0.002-0.001-0.003
			c-0.004-0.012-0.008-0.022-0.012-0.034c-0.002-0.006-0.004-0.011-0.006-0.017c-0.003-0.008-0.004-0.011-0.007-0.019
			c-0.004-0.012-0.008-0.023-0.013-0.035c-0.001-0.002-0.002-0.006-0.003-0.008c-0.005-0.014-0.01-0.027-0.015-0.041
			c0-0.002,0-0.002-0.001-0.004c-0.512-1.393-1.04-2.719-1.573-3.971c0-0.002-0.001-0.004-0.002-0.006
			c-0.005-0.011-0.01-0.023-0.015-0.034c-0.002-0.006-0.003-0.008-0.005-0.013c-0.003-0.006-0.005-0.013-0.008-0.019
			c-0.004-0.009-0.009-0.02-0.013-0.03c-0.001-0.002-0.002-0.005-0.003-0.007C971.182,791.171,971.006,790.77,970.83,790.378
			L970.83,790.378z M964.834,780.021l-2.456,1.723c0.491,0.7,0.958,1.364,1.397,1.987c0.566,0.802,1.155,1.738,1.75,2.779
			l2.605-1.488c-0.204-0.358-0.407-0.702-0.608-1.033l0,0c-0.005-0.008-0.01-0.016-0.015-0.024l-0.001-0.002
			c-0.009-0.016-0.019-0.031-0.028-0.046c0-0.001-0.001-0.003-0.002-0.004c-0.004-0.007-0.009-0.014-0.013-0.022
			c0-0.001-0.001-0.002-0.002-0.003c-0.004-0.006-0.008-0.012-0.011-0.019c-0.001-0.001-0.002-0.004-0.003-0.005
			c-0.004-0.006-0.008-0.013-0.012-0.019c-0.002-0.004-0.003-0.005-0.005-0.009c-0.002-0.004-0.005-0.008-0.008-0.013
			c-0.003-0.005-0.005-0.009-0.009-0.014c-0.002-0.004-0.004-0.007-0.007-0.011c-0.002-0.004-0.004-0.006-0.006-0.01
			c-0.003-0.005-0.007-0.011-0.01-0.016c-0.002-0.004-0.004-0.007-0.007-0.011c-0.002-0.003-0.004-0.006-0.006-0.01
			c-0.004-0.006-0.006-0.011-0.01-0.017c-0.002-0.003-0.004-0.006-0.006-0.009c-0.003-0.005-0.004-0.007-0.007-0.012
			c-0.001-0.002-0.002-0.004-0.004-0.006c-0.004-0.007-0.009-0.015-0.014-0.022c-0.001-0.001-0.002-0.003-0.002-0.004
			c-0.34-0.55-0.674-1.057-1-1.527c0,0,0-0.001-0.001-0.001c-0.008-0.012-0.016-0.023-0.024-0.035
			c-0.001-0.002-0.003-0.005-0.004-0.007c-0.003-0.004-0.006-0.009-0.009-0.013c-0.002-0.003-0.004-0.006-0.007-0.01
			c-0.002-0.003-0.004-0.006-0.006-0.009c-0.003-0.004-0.005-0.007-0.008-0.011c-0.002-0.003-0.005-0.008-0.008-0.011
			c-0.002-0.003-0.005-0.007-0.007-0.01c-0.002-0.003-0.002-0.004-0.005-0.007c-0.003-0.005-0.007-0.01-0.011-0.016
			c-0.001-0.001-0.003-0.004-0.004-0.005c-0.004-0.006-0.008-0.012-0.012-0.017l0,0l0,0
			C965.788,781.379,965.323,780.719,964.834,780.021L964.834,780.021z M957.992,770.161l-2.472,1.7
			c1.196,1.738,2.34,3.394,3.417,4.947l2.465-1.71c-0.19-0.274-0.382-0.55-0.576-0.831
			C959.922,772.963,958.975,771.59,957.992,770.161L957.992,770.161z M951.224,760.25l-2.483,1.684
			c1.149,1.695,2.279,3.355,3.38,4.968l2.478-1.692c-0.293-0.428-0.585-0.857-0.881-1.292c-0.001-0.001-0.002-0.003-0.003-0.004
			c-0.008-0.012-0.016-0.024-0.024-0.036c-0.553-0.812-1.113-1.634-1.678-2.466C951.75,761.026,951.488,760.64,951.224,760.25
			L951.224,760.25z M944.522,750.295l-2.494,1.667c1.121,1.677,2.239,3.345,3.348,4.992l2.489-1.676
			c-0.206-0.305-0.409-0.608-0.616-0.915c-0.543-0.808-1.089-1.621-1.635-2.437C945.25,751.385,944.886,750.84,944.522,750.295
			L944.522,750.295z M937.895,740.29l-2.509,1.646c1.093,1.666,2.199,3.343,3.311,5.021l2.501-1.657
			c-0.657-0.992-1.31-1.981-1.962-2.97c-0.004-0.005-0.007-0.011-0.011-0.017c-0.028-0.043-0.058-0.088-0.086-0.132
			c-0.008-0.013-0.017-0.025-0.025-0.038c-0.025-0.038-0.049-0.074-0.074-0.112c-0.011-0.016-0.021-0.032-0.032-0.048
			c-0.023-0.036-0.045-0.069-0.068-0.104c-0.013-0.019-0.025-0.039-0.038-0.058c-0.023-0.036-0.045-0.069-0.069-0.105
			c-0.009-0.014-0.018-0.028-0.027-0.041c-0.026-0.039-0.052-0.078-0.078-0.118c-0.008-0.012-0.016-0.025-0.024-0.037
			c-0.028-0.043-0.057-0.085-0.085-0.129c-0.003-0.006-0.007-0.011-0.011-0.017C938.369,741.011,938.133,740.652,937.895,740.29
			L937.895,740.29z M931.368,730.22l-2.528,1.616c1.058,1.655,2.147,3.346,3.259,5.061l2.517-1.632
			C933.508,733.555,932.421,731.869,931.368,730.22L931.368,730.22z M925,720.05l-2.561,1.562c0.973,1.596,2.042,3.323,3.176,5.132
			l2.542-1.593C927.049,723.382,925.992,721.676,925,720.05L925,720.05z M918.995,709.66l-2.654,1.399
			c0.803,1.522,1.808,3.321,2.988,5.347l2.592-1.51c-0.265-0.455-0.523-0.902-0.772-1.336c0-0.001,0-0.001-0.001-0.002
			c-0.007-0.013-0.015-0.027-0.022-0.039c-0.001-0.002-0.002-0.003-0.003-0.005c-0.008-0.014-0.016-0.027-0.023-0.041
			c0-0.001-0.001-0.001-0.001-0.002c-0.007-0.013-0.014-0.024-0.021-0.037c-0.002-0.003-0.004-0.006-0.005-0.009
			c-0.016-0.027-0.029-0.051-0.045-0.078c-0.002-0.004-0.004-0.008-0.006-0.012c-0.005-0.009-0.011-0.02-0.017-0.029
			c-0.001-0.003-0.003-0.007-0.005-0.01c-0.006-0.011-0.014-0.025-0.021-0.036c-0.003-0.004-0.005-0.009-0.008-0.013
			c-0.005-0.009-0.011-0.019-0.016-0.028c-0.002-0.004-0.004-0.007-0.006-0.011c-0.015-0.027-0.03-0.053-0.045-0.079
			c0-0.001-0.001-0.003-0.002-0.004c-0.024-0.042-0.048-0.084-0.072-0.126c0,0,0,0,0-0.001
			C920.146,711.794,919.53,710.676,918.995,709.66L918.995,709.66z M915.433,698.291l-2.965,0.458
			c0.397,2.573,0.809,4.609,1.256,6.226l2.891-0.801c-0.346-1.248-0.67-2.798-0.976-4.603c0-0.003,0-0.004-0.001-0.007
			c-0.004-0.022-0.008-0.044-0.011-0.067c0-0.002-0.001-0.005-0.001-0.006c-0.001-0.008-0.003-0.016-0.004-0.024
			c0-0.005-0.001-0.009-0.002-0.013c-0.001-0.006-0.002-0.011-0.003-0.017c-0.002-0.013-0.004-0.025-0.006-0.039
			c0-0.001,0-0.001,0-0.002c-0.001-0.009-0.002-0.016-0.004-0.025c0-0.001,0-0.001,0-0.002
			C915.547,699.018,915.49,698.659,915.433,698.291L915.433,698.291z M914.056,686.375l-2.988,0.268
			c0.194,2.166,0.398,4.197,0.604,6.036l2.981-0.335c-0.195-1.736-0.381-3.584-0.558-5.524c0-0.003-0.001-0.006-0.001-0.009
			c-0.001-0.013-0.002-0.028-0.004-0.041C914.08,686.638,914.068,686.507,914.056,686.375L914.056,686.375z M913.151,674.408
			l-2.994,0.193c0.133,2.057,0.272,4.068,0.418,6.017l2.992-0.224C913.421,678.454,913.283,676.455,913.151,674.408L913.151,674.408z
			 M912.47,662.426l-2.996,0.151c0.102,2.023,0.209,4.03,0.321,6.009l2.995-0.169c-0.11-1.942-0.214-3.907-0.314-5.891
			c0-0.007-0.001-0.014-0.001-0.02c0-0.01-0.001-0.02-0.002-0.03C912.472,662.459,912.471,662.443,912.47,662.426L912.47,662.426z
			 M911.922,650.437l-2.998,0.125c0.083,1.998,0.17,4.004,0.262,6.007l2.997-0.137c0-0.004,0-0.008,0-0.011
			C912.091,654.424,912.005,652.43,911.922,650.437L911.922,650.437z M911.459,638.445l-2.998,0.108
			c0.071,1.973,0.146,3.979,0.224,6.005l2.998-0.115c-0.027-0.708-0.054-1.414-0.081-2.117c0-0.015-0.001-0.024-0.001-0.04
			c0-0.007,0-0.013,0-0.02C911.551,640.982,911.504,639.706,911.459,638.445L911.459,638.445z M911.045,626.452l-2.999,0.101
			c0.065,1.935,0.132,3.94,0.203,5.999l2.999-0.103C911.177,630.391,911.11,628.387,911.045,626.452L911.045,626.452z
			 M910.636,614.461l-2.998,0.108c0.067,1.85,0.136,3.854,0.208,5.986l2.999-0.101c-0.018-0.512-0.035-1.016-0.051-1.514
			C910.738,617.365,910.687,615.868,910.636,614.461L910.636,614.461z M909.925,602.492l-2.955,0.52
			c0.133,0.758,0.221,1.486,0.261,2.164c0.057,0.966,0.113,2.112,0.171,3.416l2.997-0.132c-0.017-0.377-0.033-0.742-0.05-1.093
			c0-0.004,0-0.008,0-0.011c0-0.001,0-0.001,0-0.002c-0.042-0.871-0.082-1.658-0.123-2.354l0,0l0,0c0-0.012-0.001-0.024-0.002-0.036
			c0-0.002,0-0.002,0-0.004C910.175,604.163,910.074,603.34,909.925,602.492L909.925,602.492z M906.098,591.149l-2.737,1.228
			c0.881,1.964,1.587,3.707,2.158,5.329l2.83-0.996c-0.61-1.732-1.348-3.541-2.189-5.425l0-0.001
			c-0.013-0.029-0.026-0.058-0.039-0.087c-0.001-0.003-0.002-0.005-0.003-0.007c-0.005-0.011-0.01-0.023-0.015-0.034
			C906.1,591.154,906.1,591.152,906.098,591.149L906.098,591.149z M900.753,580.405l-2.651,1.403
			c1.061,2.004,1.953,3.728,2.728,5.273l2.681-1.346c-0.744-1.481-1.53-3-2.348-4.553c-0.003-0.006-0.007-0.014-0.01-0.02
			c-0.007-0.014-0.015-0.028-0.022-0.042C901.006,580.883,900.879,580.643,900.753,580.405L900.753,580.405z M895.082,569.831
			l-2.646,1.414c0.808,1.512,1.61,3,2.398,4.463l0.447,0.829l2.641-1.423c-0.292-0.542-0.587-1.091-0.883-1.64
			c-0.302-0.561-0.606-1.126-0.912-1.694c-0.007-0.013-0.013-0.024-0.02-0.037c-0.004-0.008-0.009-0.017-0.013-0.025
			c-0.317-0.591-0.638-1.189-0.958-1.787C895.118,569.898,895.1,569.865,895.082,569.831L895.082,569.831z M889.559,559.177
			l-2.687,1.334c0.851,1.714,1.75,3.479,2.747,5.398l2.662-1.384C891.368,562.771,890.457,560.986,889.559,559.177L889.559,559.177z
			 M884.51,548.292l-2.759,1.178c0.767,1.797,1.603,3.669,2.486,5.566l2.72-1.265c-0.23-0.495-0.459-0.992-0.686-1.491
			c0-0.002-0.002-0.006-0.003-0.008c-0.011-0.023-0.021-0.046-0.031-0.069c-0.003-0.007-0.006-0.013-0.01-0.021
			c-0.006-0.013-0.012-0.026-0.018-0.04c-0.008-0.018-0.017-0.037-0.025-0.055c-0.004-0.009-0.008-0.018-0.012-0.027
			c-0.009-0.02-0.018-0.041-0.027-0.061c-0.003-0.008-0.007-0.015-0.011-0.023c-0.011-0.024-0.021-0.047-0.032-0.071
			c-0.001-0.002-0.002-0.005-0.003-0.008c-0.488-1.078-0.968-2.165-1.438-3.258c0-0.001-0.001-0.003-0.002-0.004
			c-0.011-0.026-0.022-0.053-0.034-0.079c-0.001-0.004-0.003-0.008-0.005-0.012c-0.01-0.022-0.02-0.046-0.029-0.068
			c-0.004-0.009-0.008-0.018-0.012-0.026c-0.008-0.019-0.016-0.038-0.024-0.057c-0.006-0.013-0.011-0.026-0.017-0.039
			c-0.003-0.008-0.005-0.011-0.008-0.019C884.522,548.322,884.516,548.307,884.51,548.292L884.51,548.292z M880.247,537.079
			l-2.849,0.939c0.621,1.884,1.314,3.829,2.059,5.782l2.803-1.069c-0.233-0.611-0.462-1.223-0.687-1.838
			c-0.001-0.003-0.002-0.006-0.003-0.009c-0.009-0.025-0.019-0.051-0.028-0.076c-0.002-0.005-0.004-0.011-0.006-0.016
			c-0.009-0.023-0.017-0.047-0.026-0.07c-0.002-0.008-0.005-0.016-0.008-0.023c-0.008-0.021-0.015-0.042-0.023-0.063
			c-0.004-0.011-0.008-0.022-0.012-0.032c-0.005-0.013-0.009-0.026-0.014-0.04c-0.007-0.019-0.014-0.037-0.021-0.056
			c-0.002-0.008-0.006-0.017-0.009-0.025c-0.009-0.024-0.018-0.049-0.026-0.073c-0.001-0.004-0.002-0.006-0.003-0.01
			C880.997,539.299,880.614,538.192,880.247,537.079L880.247,537.079z M877.12,525.496l-2.937,0.613l0.108,0.513
			c0.382,1.801,0.83,3.65,1.331,5.497l2.896-0.786c-0.03-0.109-0.06-0.219-0.089-0.328c0-0.001,0-0.003-0.001-0.004
			c-0.006-0.024-0.013-0.048-0.019-0.072c-0.002-0.006-0.003-0.012-0.005-0.018c-0.006-0.022-0.011-0.041-0.017-0.063
			c-0.002-0.01-0.005-0.019-0.007-0.029c-0.004-0.014-0.008-0.029-0.012-0.043c-0.004-0.016-0.008-0.032-0.013-0.047
			c-0.002-0.01-0.005-0.022-0.008-0.031c-0.006-0.023-0.012-0.046-0.018-0.07c-0.001-0.004-0.003-0.012-0.004-0.016
			c-0.209-0.794-0.409-1.591-0.599-2.39c-0.001-0.004-0.001-0.004-0.002-0.008c-0.007-0.028-0.013-0.056-0.02-0.084
			c0-0.002-0.002-0.008-0.002-0.01c-0.011-0.045-0.021-0.091-0.032-0.136c-0.001-0.006-0.003-0.014-0.005-0.02
			c-0.005-0.022-0.01-0.045-0.016-0.067c-0.002-0.008-0.004-0.018-0.006-0.026c-0.004-0.018-0.008-0.036-0.013-0.054
			c-0.003-0.012-0.006-0.026-0.009-0.038c-0.003-0.012-0.005-0.023-0.008-0.035c-0.004-0.018-0.009-0.038-0.013-0.056
			c-0.002-0.01-0.005-0.02-0.007-0.03c-0.004-0.016-0.008-0.033-0.011-0.049c-0.002-0.011-0.005-0.021-0.007-0.032
			c-0.004-0.018-0.008-0.035-0.012-0.053c-0.002-0.008-0.004-0.015-0.005-0.023c-0.005-0.022-0.01-0.042-0.015-0.064
			c-0.002-0.008-0.003-0.015-0.005-0.023c-0.005-0.022-0.01-0.043-0.015-0.065c-0.002-0.007-0.003-0.014-0.005-0.021
			c-0.004-0.02-0.009-0.041-0.014-0.061c-0.001-0.004-0.002-0.009-0.003-0.013c-0.005-0.024-0.01-0.045-0.015-0.069
			c-0.001-0.006-0.003-0.011-0.004-0.017c-0.005-0.022-0.011-0.048-0.016-0.07c-0.001-0.005-0.002-0.01-0.004-0.015
			c-0.005-0.024-0.011-0.048-0.016-0.072c0-0.004-0.001-0.007-0.002-0.011c-0.006-0.024-0.011-0.05-0.017-0.074
			c0-0.001,0-0.002-0.001-0.003c-0.006-0.026-0.011-0.05-0.017-0.077c0-0.003-0.001-0.007-0.002-0.011
			c-0.005-0.024-0.011-0.052-0.017-0.076c0-0.003-0.001-0.005-0.002-0.008c-0.005-0.026-0.011-0.052-0.017-0.078
			c0-0.001,0-0.001,0-0.002c-0.006-0.028-0.012-0.054-0.018-0.082c0,0,0-0.001,0-0.001c-0.006-0.026-0.012-0.053-0.017-0.079
			c-0.001-0.003-0.001-0.005-0.002-0.008c-0.006-0.026-0.011-0.051-0.017-0.077c0-0.003-0.001-0.005-0.002-0.008
			c-0.012-0.054-0.022-0.105-0.034-0.16c0-0.002-0.001-0.004-0.001-0.005c-0.005-0.024-0.011-0.052-0.016-0.077
			c-0.001-0.004-0.002-0.007-0.002-0.011c-0.006-0.026-0.011-0.049-0.016-0.075c0-0.003-0.001-0.007-0.002-0.01
			c-0.006-0.026-0.011-0.051-0.017-0.077C877.19,525.831,877.155,525.664,877.12,525.496L877.12,525.496z M875.195,513.659
			l-2.981,0.338c0.23,2.032,0.507,4.023,0.845,6.089l2.961-0.484c-0.014-0.086-0.028-0.172-0.042-0.258
			c-0.001-0.006-0.002-0.012-0.003-0.017c-0.001-0.008-0.002-0.015-0.004-0.023c-0.001-0.009-0.003-0.018-0.004-0.027
			c0-0.004-0.001-0.008-0.002-0.013c-0.002-0.013-0.004-0.029-0.006-0.042c0-0.001,0-0.002,0-0.003
			c-0.234-1.457-0.436-2.879-0.61-4.28c0-0.004-0.001-0.007-0.001-0.011c-0.001-0.009-0.002-0.018-0.003-0.027
			C875.291,514.485,875.242,514.071,875.195,513.659L875.195,513.659z M874.417,501.69l-2.999,0.066
			c0.047,2.148,0.131,4.15,0.256,6.119l2.994-0.191c-0.113-1.772-0.189-3.542-0.235-5.329c0-0.003,0-0.005,0-0.008
			c0-0.026-0.001-0.052-0.002-0.078c0-0.008,0-0.017,0-0.025c0-0.021-0.001-0.038-0.001-0.059c0-0.019-0.001-0.038-0.001-0.057
			c0-0.005,0-0.015,0-0.02C874.423,501.968,874.419,501.829,874.417,501.69L874.417,501.69z M871.475,489.624
			c-0.053,2.33-0.083,4.251-0.096,6.045l3,0.021c0.003-0.487,0.008-0.976,0.014-1.47c0.018-1.471,0.046-2.976,0.082-4.528
			L871.475,489.624L871.475,489.624z M871.795,477.619c-0.045,1.745-0.092,3.423-0.137,5.045l-0.026,0.945l2.999,0.083
			c0.003-0.11,0.006-0.221,0.009-0.332c0.005-0.175,0.01-0.351,0.015-0.528c0.006-0.197,0.011-0.397,0.017-0.596
			c0.041-1.465,0.083-2.976,0.124-4.54L871.795,477.619L871.795,477.619z M872.031,465.666c-0.025,2.075-0.058,4.062-0.096,5.97
			l2.999,0.06c0.021-1.067,0.042-2.16,0.059-3.277c0-0.009,0-0.018,0-0.028c0-0.005,0-0.004,0-0.009
			c0.012-0.752,0.023-1.516,0.033-2.293c0.001-0.131,0.003-0.256,0.005-0.388L872.031,465.666L872.031,465.666z M875.068,453.703
			l-3,0.018c0.012,2.066,0.014,4.055,0.008,5.972l3,0.009c0.005-1.511,0.005-3.068-0.001-4.67c0-0.008,0-0.017,0-0.025
			c0-0.003,0,0,0-0.003C875.073,454.574,875.071,454.14,875.068,453.703L875.068,453.703z M874.892,441.699l-2.999,0.07
			c0.048,2.059,0.085,4.051,0.114,5.98l3-0.044c-0.011-0.771-0.024-1.551-0.039-2.342c0-0.004,0-0.007,0-0.011
			c0-0.009,0-0.018,0-0.026c0-0.011,0-0.015,0-0.025c0-0.002,0-0.004,0-0.006C874.945,444.117,874.92,442.922,874.892,441.699
			L874.892,441.699z M874.518,429.711l-2.998,0.116c0.079,2.049,0.149,4.04,0.209,5.975l2.999-0.094
			c-0.044-1.415-0.093-2.86-0.147-4.335C874.561,430.821,874.54,430.272,874.518,429.711L874.518,429.711z M873.97,417.719
			l-2.996,0.157c0.107,2.042,0.205,4.033,0.293,5.974l2.997-0.137C874.175,421.769,874.077,419.764,873.97,417.719L873.97,417.719z
			 M873.27,405.745l-2.994,0.192c0.13,2.034,0.252,4.023,0.367,5.968l2.995-0.175c-0.048-0.82-0.098-1.649-0.148-2.485
			c0-0.005,0-0.007-0.001-0.012c-0.001-0.028-0.003-0.056-0.005-0.085c0-0.005,0-0.01-0.001-0.015
			c-0.001-0.016-0.002-0.032-0.003-0.049c-0.002-0.034-0.003-0.058-0.006-0.093c0-0.003,0-0.006,0-0.009
			c-0.003-0.054-0.007-0.112-0.01-0.166c0-0.005,0-0.009,0-0.014c-0.001-0.02-0.002-0.033-0.003-0.052c0-0.001,0-0.003,0-0.004
			C873.397,407.755,873.334,406.759,873.27,405.745L873.27,405.745z M872.437,393.774l-2.992,0.223
			c0.152,2.031,0.295,4.022,0.431,5.972l2.993-0.208C872.732,397.806,872.588,395.81,872.437,393.774L872.437,393.774z
			 M871.171,381.835l-2.964,0.461c0.293,1.885,0.544,3.834,0.746,5.794l2.984-0.307c-0.003-0.031-0.006-0.063-0.01-0.094v-0.002
			c-0.003-0.031-0.007-0.063-0.01-0.094v0c-0.003-0.031-0.006-0.062-0.01-0.093l0-0.004c-0.003-0.031-0.006-0.062-0.01-0.092v-0.002
			c-0.003-0.031-0.007-0.063-0.01-0.094l0-0.001c-0.006-0.061-0.013-0.123-0.02-0.184c0-0.004,0-0.004-0.001-0.008
			c-0.003-0.029-0.006-0.057-0.009-0.085c0-0.004-0.001-0.007-0.001-0.011c-0.003-0.027-0.006-0.054-0.009-0.082
			c0-0.004-0.001-0.013-0.002-0.017c-0.003-0.026-0.005-0.052-0.008-0.078c-0.001-0.008-0.001-0.01-0.002-0.019
			c-0.002-0.026-0.005-0.051-0.008-0.077c0-0.004-0.002-0.016-0.002-0.021c-0.003-0.026-0.005-0.051-0.008-0.076
			c-0.001-0.008-0.001-0.01-0.002-0.018c-0.002-0.023-0.005-0.047-0.008-0.07c-0.001-0.008-0.002-0.016-0.002-0.024
			c-0.002-0.022-0.005-0.043-0.008-0.065c-0.001-0.008-0.002-0.02-0.003-0.029c-0.002-0.021-0.005-0.043-0.007-0.064
			c-0.001-0.013-0.002-0.017-0.003-0.03c-0.003-0.022-0.005-0.044-0.008-0.067c-0.001-0.008-0.002-0.022-0.003-0.031
			c-0.002-0.021-0.005-0.042-0.007-0.063c-0.001-0.013-0.003-0.023-0.004-0.036c-0.002-0.02-0.005-0.04-0.007-0.06
			c-0.001-0.013-0.003-0.026-0.005-0.038c-0.001-0.014-0.003-0.027-0.005-0.041c-0.002-0.021-0.004-0.035-0.006-0.056
			c-0.001-0.012-0.003-0.024-0.004-0.037c-0.002-0.021-0.005-0.04-0.007-0.061c-0.001-0.011-0.002-0.021-0.004-0.032
			c-0.002-0.021-0.005-0.047-0.008-0.068c-0.001-0.009-0.002-0.018-0.003-0.027c-0.003-0.025-0.006-0.053-0.01-0.078
			c0-0.005-0.001-0.011-0.002-0.016c-0.01-0.084-0.02-0.164-0.03-0.248c0-0.002,0-0.003,0-0.005
			c-0.004-0.034-0.008-0.062-0.012-0.096c0-0.001,0-0.001,0-0.002C871.508,384.118,871.347,382.966,871.171,381.835L871.171,381.835z
			 M868.648,370.111l-2.889,0.809c0.512,1.831,0.98,3.73,1.391,5.645l2.933-0.628c-0.121-0.563-0.245-1.121-0.374-1.674
			c-0.001-0.004,0,0.002,0-0.002c-0.007-0.03-0.014-0.059-0.021-0.088c-0.001-0.004,0-0.003-0.001-0.006
			c-0.006-0.028-0.013-0.056-0.02-0.084c-0.001-0.004-0.001-0.007-0.002-0.011c-0.006-0.026-0.012-0.052-0.019-0.078
			c-0.001-0.008-0.002-0.009-0.003-0.016c-0.006-0.025-0.012-0.049-0.018-0.074c-0.002-0.008-0.003-0.012-0.005-0.02
			c-0.005-0.023-0.011-0.047-0.016-0.07c-0.002-0.007-0.004-0.015-0.006-0.023c-0.005-0.023-0.011-0.046-0.016-0.068
			c-0.001-0.007-0.003-0.016-0.005-0.023c-0.005-0.022-0.01-0.043-0.016-0.065c-0.002-0.011-0.004-0.018-0.007-0.029
			c-0.005-0.02-0.01-0.04-0.015-0.06c-0.002-0.011-0.005-0.022-0.008-0.034c-0.004-0.018-0.009-0.036-0.013-0.054
			c-0.004-0.019-0.007-0.032-0.012-0.05c-0.003-0.013-0.006-0.026-0.009-0.039c-0.004-0.019-0.01-0.04-0.014-0.059
			c-0.002-0.009-0.004-0.019-0.007-0.028c-0.005-0.022-0.011-0.046-0.017-0.069c-0.001-0.006-0.003-0.012-0.004-0.018
			c-0.006-0.026-0.013-0.052-0.02-0.078c0-0.003-0.001-0.005-0.002-0.008c-0.007-0.03-0.013-0.054-0.02-0.083
			c0-0.002-0.001-0.003-0.001-0.005c-0.172-0.698-0.349-1.385-0.533-2.067c0-0.001,0-0.001,0-0.002
			c-0.007-0.025-0.015-0.054-0.021-0.08c-0.001-0.005-0.003-0.01-0.004-0.015c-0.006-0.022-0.013-0.047-0.019-0.068
			c-0.002-0.008-0.004-0.016-0.007-0.024c-0.006-0.022-0.01-0.038-0.016-0.059c-0.003-0.012-0.007-0.025-0.01-0.037
			c-0.004-0.014-0.009-0.032-0.013-0.046c-0.005-0.018-0.01-0.037-0.015-0.055c-0.002-0.007-0.005-0.02-0.007-0.028
			c-0.006-0.022-0.013-0.045-0.019-0.067c-0.002-0.007-0.002-0.008-0.004-0.016c-0.007-0.025-0.014-0.05-0.021-0.075
			c-0.001-0.004-0.001-0.005-0.002-0.009C868.697,370.284,868.673,370.197,868.648,370.111L868.648,370.111z M864.635,358.811
			l-2.748,1.204c0.762,1.737,1.477,3.547,2.125,5.378l2.828-1.001c-0.272-0.769-0.553-1.526-0.842-2.273v0
			c-0.022-0.058-0.045-0.117-0.068-0.175c-0.001-0.003,0,0.001-0.001-0.002c-0.005-0.013-0.01-0.026-0.015-0.039
			c-0.001-0.003-0.003-0.008-0.005-0.011c-0.003-0.01-0.007-0.019-0.011-0.029c-0.002-0.007-0.005-0.013-0.007-0.019
			c-0.002-0.007-0.005-0.014-0.008-0.021c-0.002-0.007-0.006-0.016-0.009-0.023c-0.002-0.005-0.004-0.011-0.006-0.016
			c-0.004-0.01-0.007-0.017-0.011-0.027c-0.001-0.004-0.003-0.008-0.004-0.012c-0.004-0.01-0.007-0.019-0.011-0.028
			c-0.001-0.003-0.003-0.007-0.004-0.01C865.451,360.722,865.05,359.757,864.635,358.811L864.635,358.811z M858.982,348.241
			l-2.522,1.623c1.026,1.595,2.006,3.264,2.913,4.962l2.646-1.413C861.059,351.616,860.043,349.89,858.982,348.241L858.982,348.241z
			 M851.67,338.744l-2.213,2.026c1.287,1.406,2.529,2.885,3.693,4.398l2.377-1.83C854.29,341.728,853.001,340.198,851.67,338.744
			L851.67,338.744z M842.859,330.618l-1.843,2.367c1.515,1.18,2.991,2.43,4.387,3.717l2.033-2.206
			C845.947,333.124,844.421,331.834,842.859,330.618L842.859,330.618z M832.85,324.021l-1.455,2.623
			c1.693,0.939,3.354,1.944,4.939,2.987l1.649-2.506C836.297,326.016,834.583,324.983,832.85,324.021L832.85,324.021z
			 M821.983,318.952l-1.084,2.797c1.818,0.705,3.615,1.469,5.338,2.271l1.266-2.72C825.672,320.448,823.83,319.667,821.983,318.952
			L821.983,318.952z M810.559,315.303l-0.747,2.906c1.897,0.488,3.782,1.029,5.603,1.609l0.911-2.858
			c-0.287-0.091-0.573-0.181-0.859-0.269l-0.003-0.001c-0.014-0.004-0.028-0.009-0.042-0.013c-0.002-0.001,0,0-0.003-0.001
			c-0.013-0.004-0.026-0.008-0.039-0.012c-0.002-0.001-0.005-0.002-0.007-0.002c-0.013-0.004-0.026-0.008-0.039-0.012
			c-0.005-0.001-0.006-0.002-0.011-0.003c-0.01-0.003-0.02-0.006-0.03-0.009c-0.004-0.001-0.006-0.002-0.011-0.003
			c-0.011-0.003-0.022-0.007-0.033-0.01c-0.007-0.002-0.015-0.005-0.022-0.007c-0.004-0.001-0.009-0.003-0.014-0.004
			c-0.012-0.004-0.025-0.008-0.037-0.011c-0.005-0.002-0.01-0.003-0.015-0.005c-0.01-0.003-0.015-0.005-0.025-0.008
			c-0.002-0.001-0.005-0.002-0.007-0.002c-0.017-0.005-0.034-0.01-0.05-0.015c-0.001,0-0.002-0.001-0.003-0.001
			C813.56,316.108,812.056,315.688,810.559,315.303L810.559,315.303z M798.801,312.928l-0.446,2.967
			c1.94,0.292,3.879,0.632,5.763,1.011l0.592-2.941c-0.438-0.088-0.875-0.174-1.311-0.256l-0.004-0.001
			c-0.029-0.006-0.059-0.011-0.088-0.017c-0.002,0-0.007-0.001-0.009-0.002c-0.026-0.005-0.052-0.01-0.078-0.015
			c-0.007-0.001-0.013-0.002-0.02-0.004c-0.023-0.004-0.046-0.009-0.069-0.013c-0.009-0.002-0.021-0.004-0.03-0.006
			c-0.019-0.004-0.038-0.007-0.057-0.011c-0.014-0.003-0.026-0.005-0.04-0.007c-0.017-0.003-0.034-0.006-0.05-0.009
			c-0.018-0.003-0.034-0.006-0.052-0.01c-0.013-0.002-0.025-0.005-0.038-0.007c-0.028-0.005-0.054-0.01-0.082-0.015c0,0,0,0,0,0
			C801.437,313.345,800.108,313.124,798.801,312.928L798.801,312.928z M786.868,311.698l-0.172,2.995
			c1.952,0.112,3.919,0.269,5.847,0.467l0.307-2.984c-1.194-0.123-2.358-0.226-3.495-0.312c-0.004,0-0.008-0.001-0.012-0.001
			c-0.01-0.001-0.021-0.002-0.032-0.002c-0.009-0.001-0.017-0.001-0.026-0.002c-0.006,0-0.009-0.001-0.015-0.001
			c-0.013-0.001-0.026-0.002-0.04-0.003l-0.001,0C788.426,311.794,787.638,311.742,786.868,311.698L786.868,311.698z
			 M779.201,311.477v0.558c0.543,0.527,1.092,1.154,1.642,1.893v0c0.297,0.399,0.594,0.832,0.891,1.299l0,0
			c0.003,0.006,0.007,0.011,0.011,0.017c0.001,0.002,0.002,0.003,0.003,0.004c0.003,0.005,0.006,0.01,0.009,0.015
			c0.002,0.003,0.003,0.005,0.005,0.008c0.002,0.004,0.005,0.007,0.007,0.011c0.002,0.003,0.005,0.008,0.007,0.011
			c0.002,0.003,0.003,0.005,0.005,0.008c0.002,0.004,0.005,0.008,0.008,0.012c0.001,0.003,0.003,0.005,0.005,0.008
			c0.003,0.005,0.005,0.009,0.009,0.014c0.001,0.002,0.003,0.005,0.004,0.007c0.003,0.005,0.006,0.01,0.009,0.015
			c0.001,0.002,0.003,0.005,0.005,0.007c0.003,0.006,0.006,0.01,0.009,0.015c0.001,0.001,0.001,0.003,0.002,0.004
			c0.003,0.006,0.007,0.012,0.011,0.017c0,0.001,0.001,0.002,0.002,0.003c0.003,0.006,0.008,0.012,0.011,0.018
			c0,0.001,0.001,0.002,0.001,0.003c0.004,0.006,0.008,0.012,0.012,0.019c0,0.001,0.001,0.001,0.001,0.002
			c0.004,0.006,0.008,0.014,0.012,0.02c0,0,0,0.001,0,0.001c0.004,0.006,0.009,0.014,0.013,0.02c0,0,0,0,0,0
			c0.004,0.007,0.008,0.014,0.013,0.021v0c0.367,0.594,0.732,1.243,1.097,1.951l2.667-1.373c-0.905-1.757-1.876-3.285-2.898-4.561
			c-1.033-0.028-2.024-0.043-2.969-0.047c0,0,0,0-0.001,0c-0.096,0-0.189-0.001-0.284-0.001c-0.001,0-0.002,0-0.004,0
			c-0.005,0-0.01,0-0.015,0c-0.005,0-0.011,0-0.017,0s-0.009,0-0.015,0c-0.003,0-0.006,0-0.009,0c-0.006,0-0.009,0-0.016,0
			c-0.002,0-0.005,0-0.008,0c-0.004,0-0.005,0-0.01,0c-0.003,0-0.006,0-0.01,0c-0.008,0-0.017,0-0.025,0c-0.004,0-0.008,0-0.012,0
			c-0.003,0-0.003,0-0.007,0c-0.006,0-0.012,0-0.018,0c-0.002,0-0.003,0-0.005,0c-0.005,0-0.011,0-0.016,0c-0.006,0-0.014,0-0.02,0
			c-0.001,0-0.003,0-0.005,0c-0.005,0-0.007,0-0.012,0C779.267,311.477,779.233,311.477,779.201,311.477L779.201,311.477
			L779.201,311.477z"/>
		<path fill="#F15A24" d="M689.562,581.771l-0.049-3c1.97-0.032,3.961-0.113,5.918-0.243l0.197,2.993
			C693.622,581.654,691.581,581.738,689.562,581.771z M683.489,581.718c-2.009-0.066-4.048-0.184-6.062-0.349l0.244-2.99
			c1.967,0.161,3.958,0.275,5.918,0.341L683.489,581.718z M701.677,580.965l-0.352-2.979c1.963-0.231,3.933-0.517,5.855-0.848
			l0.508,2.957C705.715,580.435,703.692,580.728,701.677,580.965z M671.388,580.725c-1.994-0.262-4.013-0.577-6.002-0.937
			l0.535-2.952c1.94,0.352,3.911,0.659,5.857,0.915L671.388,580.725z M713.648,578.901l-0.67-2.924
			c1.924-0.441,3.849-0.94,5.719-1.484l0.838,2.881C717.609,577.933,715.629,578.447,713.648,578.901z M659.433,578.556
			c-1.975-0.46-3.955-0.974-5.885-1.528l0.828-2.884c1.881,0.54,3.812,1.041,5.738,1.49L659.433,578.556z M725.324,575.501
			l-1.01-2.825c1.859-0.664,3.706-1.391,5.49-2.159l1.186,2.756C729.149,574.066,727.242,574.816,725.324,575.501z M647.747,575.2
			c-1.922-0.661-3.84-1.378-5.701-2.132l1.127-2.78c1.812,0.733,3.679,1.432,5.549,2.075L647.747,575.2z M736.505,570.677
			l-1.369-2.669c1.757-0.901,3.486-1.866,5.142-2.869l1.555,2.566C740.116,568.745,738.324,569.745,736.505,570.677z
			 M636.468,570.625c-1.841-0.868-3.668-1.796-5.434-2.757l1.436-2.635c1.714,0.934,3.49,1.835,5.277,2.679L636.468,570.625z
			 M625.772,564.789c-1.735-1.088-3.438-2.234-5.062-3.407l1.756-2.433c1.571,1.135,3.22,2.244,4.899,3.298L625.772,564.789z
			 M746.935,564.351l-1.742-2.442c1.601-1.142,3.164-2.352,4.648-3.596l1.928,2.299C750.225,561.905,748.599,563.164,746.935,564.351
			z M615.884,557.645c-1.574-1.307-3.107-2.676-4.559-4.068l2.078-2.164c1.398,1.343,2.878,2.664,4.396,3.925L615.884,557.645z
			 M756.295,556.491l-2.109-2.133c1.392-1.377,2.736-2.824,3.995-4.3l2.283,1.947C759.149,553.546,757.747,555.055,756.295,556.491z
			 M607.078,549.181c-1.366-1.527-2.677-3.112-3.896-4.713l2.387-1.817c1.171,1.538,2.431,3.062,3.745,4.529L607.078,549.181z
			 M764.233,547.179l-2.441-1.742c1.133-1.589,2.208-3.243,3.195-4.916l2.584,1.524C766.54,543.792,765.418,545.52,764.233,547.179z
			 M599.678,539.457c-1.093-1.709-2.128-3.487-3.076-5.285l2.654-1.399c0.909,1.724,1.901,3.429,2.949,5.068L599.678,539.457z
			 M770.452,536.643l-2.705-1.296c0.838-1.751,1.615-3.566,2.31-5.394l2.805,1.065C772.138,532.924,771.327,534.816,770.452,536.643z
			 M593.985,528.637c-0.788-1.868-1.506-3.798-2.134-5.738l2.854-0.925c0.603,1.859,1.29,3.708,2.044,5.497L593.985,528.637z
			 M774.797,525.22l-2.881-0.836c0.538-1.855,1.015-3.774,1.416-5.705l2.938,0.611C775.852,521.296,775.356,523.291,774.797,525.22z
			 M590.209,517.019c-0.475-1.92-0.938-3.898-1.376-5.879l2.93-0.648c0.433,1.958,0.891,3.912,1.358,5.808L590.209,517.019z
			 M777.406,513.364l-2.951-0.543c0.366-1.988,0.714-3.95,1.044-5.888l2.957,0.504C778.124,509.387,777.774,511.363,777.406,513.364z
			 M587.601,505.23c-0.387-1.966-0.755-3.963-1.097-5.936l2.957-0.511c0.337,1.95,0.701,3.924,1.083,5.868L587.601,505.23z
			 M779.426,501.495l-2.965-0.462c0.312-1.998,0.604-3.969,0.877-5.913l2.971,0.418C780.033,497.497,779.739,499.482,779.426,501.495
			z M585.542,493.338c-0.3-1.995-0.578-3.987-0.835-5.976l2.975-0.385c0.255,1.969,0.529,3.94,0.827,5.914L585.542,493.338z
			 M781.102,489.568l-2.977-0.372c0.251-2.008,0.482-3.986,0.694-5.935l2.982,0.324C781.588,485.55,781.354,487.543,781.102,489.568z
			 M583.993,481.372c-0.219-2.006-0.417-4.007-0.595-6.003l2.988-0.266c0.176,1.975,0.372,3.957,0.589,5.943L583.993,481.372z
			 M782.404,477.591l-2.988-0.275c0.187-2.015,0.352-4,0.498-5.953l2.992,0.225C782.758,473.557,782.591,475.558,782.404,477.591z
			 M582.917,469.357c-0.143-2.014-0.266-4.02-0.372-6.018l2.996-0.159c0.104,1.98,0.228,3.97,0.368,5.966L582.917,469.357z
			 M783.302,465.574l-2.994-0.173c0.116-2.021,0.212-4.009,0.287-5.964l2.998,0.116C783.516,461.527,783.42,463.533,783.302,465.574z
			 M582.278,457.315c-0.072-2.02-0.128-4.029-0.166-6.027l3-0.058c0.038,1.981,0.092,3.975,0.164,5.978L582.278,457.315z
			 M783.772,453.528l-3-0.061c0.041-2.025,0.062-4.015,0.065-5.971l3,0.005C783.835,449.475,783.813,451.483,783.772,453.528z
			 M582.042,445.262l-0.003-1.5c0-1.516,0.009-3.024,0.025-4.524l3,0.033c-0.017,1.489-0.025,2.986-0.025,4.491l0.003,1.488
			L582.042,445.262z M780.789,441.526c-0.036-2.026-0.09-4.015-0.161-5.968l2.998-0.109c0.071,1.971,0.127,3.979,0.163,6.024
			L780.789,441.526z M585.176,433.293l-3-0.077c0.053-2.025,0.118-4.032,0.197-6.02l2.998,0.12
			C585.292,429.29,585.227,431.283,585.176,433.293z M780.354,429.595c-0.111-2.025-0.24-4.011-0.382-5.96l2.992-0.218
			c0.144,1.966,0.272,3.969,0.386,6.013L780.354,429.595z M585.644,421.34l-2.996-0.158c0.106-2.026,0.226-4.03,0.354-6.01
			l2.994,0.194C585.867,417.334,585.75,419.325,585.644,421.34z M779.486,417.681c-0.181-2.022-0.374-4.003-0.578-5.948l2.984-0.312
			c0.204,1.959,0.399,3.956,0.582,5.993L779.486,417.681z M586.419,409.397l-2.99-0.23c0.156-2.03,0.321-4.03,0.496-5.999
			l2.988,0.265C586.74,405.391,586.575,407.379,586.419,409.397z M778.248,405.79c-0.235-2.019-0.479-3.998-0.723-5.941l2.977-0.375
			c0.246,1.952,0.489,3.94,0.727,5.969L778.248,405.79z M587.471,397.472l-2.986-0.295c0.201-2.035,0.409-4.031,0.622-5.986
			l2.982,0.325C587.876,393.461,587.67,395.447,587.471,397.472z M776.759,393.905l-0.776-5.949l2.975-0.386l0.776,5.948
			L776.759,393.905z M588.76,385.565l-2.98-0.35c0.24-2.044,0.481-4.035,0.724-5.97l2.977,0.372
			C589.239,381.544,588.998,383.528,588.76,385.565z M775.228,381.993c-0.246-2.024-0.477-4.017-0.684-5.983l2.982-0.315
			c0.207,1.951,0.436,3.928,0.68,5.936L775.228,381.993z M590.239,373.671l-2.975-0.389c0.27-2.065,0.534-4.052,0.789-5.955
			l2.973,0.397C590.773,369.625,590.509,371.609,590.239,373.671z M773.98,370.001c-0.168-2.09-0.297-4.122-0.383-6.039l2.996-0.135
			c0.085,1.882,0.212,3.878,0.377,5.933L773.98,370.001z M591.827,361.774l-2.975-0.396c0.283-2.121,0.543-4.104,0.773-5.941
			l2.977,0.373C592.372,357.653,592.11,359.645,591.827,361.774z M776.46,357.901l-3-0.003c0.001-0.981,0.014-1.961,0.041-2.939
			c0.02-0.731,0.03-1.445,0.03-2.143c0-0.302-0.002-0.6-0.006-0.896l3-0.039c0.004,0.309,0.006,0.621,0.006,0.935
			c0,0.725-0.011,1.467-0.032,2.226C776.474,355.993,776.461,356.946,776.46,357.901z M593.287,349.804l-2.986-0.291
			c0.223-2.28,0.33-4.037,0.33-5.369l-0.005-0.448l3-0.057l0.005,0.505C593.631,345.574,593.519,347.426,593.287,349.804z
			 M681.047,348.511l-0.186-0.017c-1.128-0.105-2.248-0.22-3.361-0.344l0.332-2.981c1.034,0.115,2.073,0.222,3.119,0.32
			c0.606-0.097,2.686-0.435,5.736-0.991l0.537,2.951c-3.68,0.671-5.971,1.03-5.993,1.033L681.047,348.511z M671.498,347.384
			c-1.972-0.284-3.981-0.604-5.972-0.953l0.518-2.955c1.96,0.343,3.939,0.659,5.882,0.938L671.498,347.384z M693.16,346.318
			l-0.584-2.942c1.818-0.361,3.788-0.765,5.869-1.212l0.629,2.934C696.978,345.547,694.992,345.954,693.16,346.318z M773.194,346.075
			c-0.214-2.021-0.535-3.946-0.955-5.719l2.92-0.69c0.448,1.896,0.791,3.947,1.018,6.094L773.194,346.075z M659.586,345.3
			c-1.955-0.403-3.941-0.841-5.902-1.303l0.688-2.92c1.934,0.455,3.892,0.887,5.82,1.284L659.586,345.3z M704.968,343.783
			l-0.678-2.923c1.896-0.439,3.844-0.909,5.819-1.406l0.732,2.909C708.849,342.866,706.882,343.339,704.968,343.783z
			 M647.822,342.531c-1.928-0.511-3.886-1.058-5.821-1.627l0.846-2.878c1.91,0.561,3.842,1.101,5.743,1.604L647.822,342.531z
			 M716.688,340.824l-0.797-2.893c1.982-0.545,3.914-1.104,5.74-1.66l0.873,2.87C720.652,339.706,718.695,340.271,716.688,340.824z
			 M636.226,339.123c-1.915-0.619-3.842-1.27-5.726-1.934l0.998-2.829c1.858,0.655,3.76,1.297,5.649,1.908L636.226,339.123z
			 M593.429,337.898l-2.973-0.411c0.305-2.198,1.094-4.276,2.348-6.175l2.504,1.652C594.303,334.487,593.67,336.147,593.429,337.898z
			 M728.275,337.284l-0.971-2.839c1.967-0.672,3.845-1.359,5.582-2.042l1.098,2.792C732.205,335.895,730.284,336.597,728.275,337.284
			z M624.827,335.105c-1.894-0.724-3.784-1.476-5.616-2.234l1.146-2.772c1.808,0.748,3.672,1.49,5.542,2.205L624.827,335.105z
			 M770.466,334.918c-0.783-1.811-1.728-3.446-2.806-4.862l2.387-1.817c1.225,1.608,2.292,3.454,3.173,5.488L770.466,334.918z
			 M739.6,332.784l-1.279-2.713c1.924-0.908,3.66-1.833,5.158-2.75l1.564,2.56C743.453,330.854,741.622,331.83,739.6,332.784z
			 M613.655,330.48c-1.906-0.854-3.753-1.717-5.488-2.565l1.318-2.695c1.704,0.833,3.521,1.683,5.396,2.522L613.655,330.48z
			 M599.098,329.031l-1.803-2.397c2.082-1.566,4.16-2.578,5.537-3.151l1.152,2.77C602.768,326.759,600.931,327.653,599.098,329.031z
			 M763.707,326.209c-1.496-1.058-3.158-1.873-4.939-2.422l0.883-2.867c2.086,0.643,4.033,1.598,5.789,2.84L763.707,326.209z
			 M750.094,326.084l-2.086-2.157c1.05-1.014,1.818-2.028,2.283-3.012l0.345-0.727l0.796-0.116c0.073-0.011,0.75-0.105,1.854-0.108
			l0.008,3c-0.268,0.001-0.504,0.008-0.703,0.018C751.979,324.03,751.143,325.07,750.094,326.084z"/>
		<path fill="#FBB03B" d="M689.561,834.771l-0.047-3c1.963-0.03,3.954-0.112,5.917-0.242l0.197,2.994
			C693.615,834.655,691.574,834.739,689.561,834.771z M683.489,834.719c-2.016-0.066-4.056-0.185-6.063-0.35l0.246-2.99
			c1.96,0.161,3.95,0.276,5.917,0.342L683.489,834.719z M701.678,833.965l-0.352-2.979c1.96-0.231,3.93-0.517,5.855-0.848
			l0.508,2.957C705.713,833.436,703.689,833.729,701.678,833.965z M671.388,833.726c-2.004-0.264-4.023-0.578-6.002-0.938
			l0.535-2.951c1.931,0.35,3.902,0.657,5.857,0.914L671.388,833.726z M713.648,831.901l-0.67-2.924
			c1.924-0.441,3.849-0.94,5.719-1.484l0.838,2.881C717.609,830.934,715.629,831.447,713.648,831.901z M659.433,831.557
			c-1.981-0.462-3.961-0.977-5.885-1.529l0.828-2.883c1.875,0.539,3.806,1.04,5.738,1.49L659.433,831.557z M725.324,828.502
			l-1.01-2.826c1.853-0.661,3.699-1.388,5.49-2.158l1.186,2.756C729.143,827.069,727.235,827.818,725.324,828.502z M647.746,828.201
			c-1.927-0.662-3.844-1.38-5.7-2.132l1.127-2.781c1.807,0.732,3.674,1.431,5.55,2.077L647.746,828.201z M736.506,823.678
			l-1.367-2.67c1.749-0.896,3.479-1.861,5.141-2.868l1.555,2.566C740.111,821.749,738.318,822.749,736.506,823.678z M636.469,823.627
			c-1.838-0.867-3.666-1.795-5.434-2.757l1.436-2.635c1.716,0.935,3.492,1.836,5.277,2.679L636.469,823.627z M625.772,817.79
			c-1.743-1.095-3.446-2.241-5.062-3.408l1.758-2.432c1.562,1.129,3.211,2.239,4.899,3.299L625.772,817.79z M746.936,817.351
			l-1.742-2.443c1.598-1.139,3.162-2.349,4.648-3.595l1.928,2.299C750.223,814.908,748.597,816.166,746.936,817.351z
			 M615.885,810.646c-1.579-1.312-3.113-2.681-4.559-4.069l2.078-2.164c1.394,1.34,2.873,2.66,4.396,3.925L615.885,810.646z
			 M756.295,809.491l-2.109-2.133c1.394-1.379,2.738-2.826,3.996-4.3l2.281,1.947C759.151,806.544,757.749,808.053,756.295,809.491z
			 M607.08,802.182c-1.369-1.528-2.68-3.114-3.896-4.713l2.387-1.816c1.169,1.535,2.429,3.059,3.744,4.529L607.08,802.182z
			 M764.233,800.18l-2.441-1.742c1.133-1.589,2.208-3.242,3.195-4.916l2.584,1.525C766.54,796.794,765.417,798.521,764.233,800.18z
			 M599.679,792.458c-1.094-1.71-2.128-3.488-3.076-5.286l2.654-1.398c0.908,1.723,1.9,3.429,2.949,5.067L599.679,792.458z
			 M770.452,789.642l-2.705-1.295c0.839-1.752,1.616-3.567,2.31-5.394l2.805,1.064C772.138,785.923,771.327,787.814,770.452,789.642z
			 M593.985,781.639c-0.789-1.87-1.507-3.801-2.134-5.739l2.854-0.924c0.602,1.856,1.289,3.706,2.044,5.497L593.985,781.639z
			 M774.797,778.22l-2.881-0.836c0.538-1.855,1.015-3.774,1.416-5.704l2.938,0.611C775.852,774.296,775.356,776.29,774.797,778.22z
			 M590.209,770.021c-0.475-1.92-0.938-3.897-1.376-5.879l2.93-0.648c0.433,1.958,0.891,3.912,1.358,5.809L590.209,770.021z
			 M777.406,766.364l-2.951-0.543c0.366-1.989,0.714-3.952,1.044-5.889l2.957,0.504C778.124,762.386,777.774,764.361,777.406,766.364
			z M587.601,758.231c-0.387-1.966-0.756-3.964-1.097-5.937l2.957-0.51c0.336,1.949,0.701,3.924,1.083,5.868L587.601,758.231z
			 M779.426,754.495l-2.965-0.461c0.312-1.999,0.604-3.97,0.877-5.914l2.971,0.418C780.033,750.496,779.739,752.482,779.426,754.495z
			 M585.541,746.339c-0.3-1.994-0.578-3.985-0.835-5.976l2.975-0.385c0.255,1.969,0.529,3.94,0.827,5.913L585.541,746.339z
			 M781.102,742.568l-2.977-0.371c0.251-2.009,0.482-3.987,0.694-5.937l2.982,0.324C781.588,738.55,781.354,740.544,781.102,742.568z
			 M583.993,734.373c-0.219-2.007-0.417-4.008-0.595-6.005l2.988-0.266c0.176,1.978,0.372,3.959,0.589,5.944L583.993,734.373z
			 M782.404,730.59l-2.988-0.275c0.187-2.016,0.352-4,0.498-5.953l2.992,0.225C782.758,726.557,782.591,728.557,782.404,730.59z
			 M582.917,722.357c-0.143-2.014-0.266-4.021-0.372-6.018l2.996-0.159c0.104,1.981,0.228,3.97,0.368,5.966L582.917,722.357z
			 M783.302,718.573l-2.994-0.172c0.116-2.021,0.212-4.009,0.287-5.964l2.998,0.116C783.516,714.527,783.42,716.533,783.302,718.573z
			 M582.278,710.316c-0.072-2.02-0.128-4.029-0.166-6.027l3-0.058c0.038,1.981,0.092,3.975,0.164,5.978L582.278,710.316z
			 M783.772,706.528l-3-0.061c0.041-2.025,0.062-4.015,0.065-5.971l3,0.005C783.835,702.475,783.813,704.483,783.772,706.528z
			 M582.042,698.264l-0.003-1.501c0-1.516,0.009-3.024,0.025-4.523l3,0.033c-0.017,1.488-0.025,2.985-0.025,4.49l0.003,1.49
			L582.042,698.264z M780.789,694.527c-0.036-2.027-0.09-4.016-0.161-5.968l2.998-0.109c0.071,1.971,0.127,3.979,0.163,6.024
			L780.789,694.527z M585.176,686.294l-3-0.077c0.053-2.024,0.118-4.032,0.197-6.02l2.998,0.12
			C585.292,682.291,585.227,684.283,585.176,686.294z M780.354,682.595c-0.111-2.025-0.24-4.011-0.382-5.96l2.992-0.218
			c0.144,1.966,0.272,3.969,0.386,6.013L780.354,682.595z M585.644,674.341l-2.996-0.158c0.106-2.026,0.226-4.03,0.354-6.01
			l2.994,0.194C585.867,670.335,585.75,672.326,585.644,674.341z M779.486,670.681c-0.181-2.022-0.374-4.003-0.578-5.948l2.984-0.312
			c0.204,1.959,0.399,3.956,0.582,5.993L779.486,670.681z M586.419,662.398l-2.99-0.23c0.156-2.03,0.321-4.03,0.496-5.999
			l2.988,0.265C586.74,658.392,586.575,660.38,586.419,662.398z M778.248,658.79c-0.235-2.019-0.479-3.998-0.723-5.941l2.977-0.375
			c0.246,1.952,0.489,3.94,0.727,5.969L778.248,658.79z M587.471,650.473l-2.986-0.295c0.201-2.035,0.409-4.031,0.622-5.986
			l2.982,0.325C587.876,646.462,587.67,648.448,587.471,650.473z M776.759,646.905l-0.776-5.949l2.975-0.386l0.776,5.948
			L776.759,646.905z M588.76,638.565l-2.98-0.35c0.24-2.044,0.481-4.035,0.724-5.97l2.977,0.372
			C589.239,634.545,588.998,636.529,588.76,638.565z M775.228,634.993c-0.246-2.024-0.477-4.017-0.684-5.983l2.982-0.315
			c0.207,1.951,0.436,3.927,0.68,5.936L775.228,634.993z M590.239,626.671l-2.975-0.389c0.27-2.065,0.534-4.052,0.789-5.955
			l2.973,0.397C590.773,622.625,590.509,624.609,590.239,626.671z M773.98,623.001c-0.168-2.09-0.297-4.122-0.383-6.039l2.996-0.135
			c0.085,1.882,0.212,3.878,0.377,5.933L773.98,623.001z M591.826,614.776l-2.975-0.396c0.283-2.121,0.543-4.104,0.773-5.941
			l2.977,0.373C592.371,610.655,592.109,612.646,591.826,614.776z M776.46,610.901l-3-0.003c0.001-0.981,0.014-1.961,0.041-2.939
			c0.02-0.731,0.03-1.445,0.03-2.143c0-0.302-0.002-0.6-0.006-0.896l3-0.039c0.004,0.309,0.006,0.621,0.006,0.935
			c0,0.725-0.011,1.467-0.032,2.226C776.474,608.993,776.461,609.946,776.46,610.901z M593.286,602.804l-2.986-0.291
			c0.222-2.278,0.33-4.035,0.331-5.371l-0.005-0.446l3-0.058l0.005,0.505C593.63,598.578,593.517,600.429,593.286,602.804z
			 M681.047,601.511l-0.186-0.017c-1.129-0.105-2.249-0.22-3.362-0.344l0.332-2.981c1.034,0.115,2.073,0.222,3.12,0.32
			c0.606-0.097,2.686-0.435,5.736-0.991l0.537,2.951c-3.68,0.671-5.971,1.03-5.993,1.033L681.047,601.511z M671.498,600.384
			c-1.972-0.284-3.981-0.604-5.972-0.953l0.518-2.955c1.96,0.343,3.939,0.659,5.882,0.938L671.498,600.384z M693.16,599.318
			l-0.584-2.942c1.818-0.361,3.788-0.765,5.869-1.212l0.629,2.934C696.978,598.547,694.992,598.954,693.16,599.318z M773.194,599.075
			c-0.214-2.021-0.535-3.946-0.955-5.719l2.92-0.69c0.448,1.896,0.791,3.947,1.018,6.094L773.194,599.075z M659.585,598.3
			c-1.955-0.403-3.941-0.841-5.902-1.303l0.688-2.92c1.934,0.455,3.892,0.887,5.82,1.284L659.585,598.3z M704.968,596.783
			l-0.678-2.923c1.896-0.439,3.844-0.909,5.819-1.406l0.732,2.909C708.849,595.866,706.882,596.339,704.968,596.783z
			 M647.821,595.531c-1.934-0.512-3.893-1.06-5.821-1.627l0.846-2.878c1.904,0.56,3.836,1.1,5.743,1.605L647.821,595.531z
			 M716.688,593.824l-0.797-2.893c1.982-0.546,3.914-1.104,5.74-1.66l0.873,2.87C720.652,592.706,718.695,593.271,716.688,593.824z
			 M636.225,592.122c-1.899-0.613-3.825-1.264-5.726-1.934l0.998-2.829c1.875,0.661,3.775,1.303,5.649,1.908L636.225,592.122z
			 M593.429,590.899l-2.973-0.411c0.305-2.198,1.094-4.276,2.348-6.175l2.504,1.652C594.303,587.488,593.67,589.147,593.429,590.899z
			 M728.275,590.284l-0.971-2.839c1.967-0.672,3.845-1.359,5.582-2.042l1.098,2.792C732.205,588.895,730.284,589.597,728.275,590.284
			z M624.827,588.105c-1.894-0.724-3.784-1.476-5.616-2.234l1.146-2.772c1.808,0.748,3.672,1.49,5.542,2.205L624.827,588.105z
			 M770.466,587.918c-0.783-1.811-1.728-3.446-2.806-4.862l2.387-1.817c1.225,1.608,2.292,3.454,3.173,5.488L770.466,587.918z
			 M739.6,585.784l-1.279-2.713c1.924-0.908,3.66-1.833,5.158-2.75l1.564,2.56C743.453,583.854,741.622,584.83,739.6,585.784z
			 M613.654,583.48c-1.909-0.855-3.756-1.719-5.488-2.565l1.318-2.695c1.702,0.833,3.518,1.682,5.396,2.523L613.654,583.48z
			 M599.097,582.032l-1.803-2.397c2.081-1.565,4.158-2.578,5.535-3.151l1.154,2.77C602.766,579.76,600.929,580.654,599.097,582.032z
			 M763.707,579.209c-1.496-1.058-3.158-1.873-4.939-2.422l0.883-2.867c2.086,0.643,4.033,1.598,5.789,2.84L763.707,579.209z
			 M750.094,579.084l-2.086-2.157c1.05-1.014,1.818-2.028,2.283-3.012l0.345-0.727l0.796-0.116c0.073-0.011,0.75-0.105,1.854-0.108
			l0.008,3c-0.268,0.001-0.504,0.008-0.703,0.018C751.979,577.03,751.143,578.07,750.094,579.084z"/>
		<path fill="#006837" d="M640.47,1448.656c-1.979-0.026-4.015-0.085-6.049-0.175l0.131-2.997c2.005,0.088,4.01,0.146,5.959,0.172
			L640.47,1448.656z M646.523,1448.638l-0.061-3c2.039-0.041,4.037-0.12,5.938-0.232l0.178,2.994
			C650.639,1448.515,648.603,1448.595,646.523,1448.638z M628.382,1448.134c-1.994-0.144-4.021-0.317-6.025-0.517l0.297-2.984
			c1.977,0.196,3.977,0.367,5.943,0.509L628.382,1448.134z M658.633,1447.897l-0.324-2.982c2.061-0.224,4.026-0.498,5.846-0.817
			l0.518,2.955C662.789,1447.383,660.757,1447.667,658.633,1447.897z M616.354,1446.938c-2.005-0.255-4.02-0.539-5.988-0.844
			l0.459-2.965c1.942,0.301,3.929,0.581,5.906,0.832L616.354,1446.938z M670.675,1445.711l-0.812-2.888
			c2.086-0.587,3.882-1.26,5.337-1.998l1.357,2.676C674.928,1444.327,672.949,1445.071,670.675,1445.711z M604.395,1445.08
			c-2.011-0.371-4.008-0.773-5.935-1.193l0.639-2.932c1.896,0.414,3.862,0.81,5.841,1.176L604.395,1445.08z M592.565,1442.499
			c-1.99-0.506-3.958-1.046-5.848-1.605l0.852-2.877c1.853,0.549,3.781,1.079,5.734,1.574L592.565,1442.499z M681.714,1439.139
			l-2.586-1.521c0.191-0.326,0.333-0.661,0.421-0.996c0.285-1.092,0.518-2.505,0.688-4.201l2.984,0.301
			c-0.187,1.851-0.446,3.418-0.771,4.659C682.294,1437.981,682.046,1438.573,681.714,1439.139z M580.934,1439.039
			c-1.968-0.682-3.884-1.403-5.695-2.146l1.137-2.775c1.761,0.721,3.625,1.423,5.541,2.087L580.934,1439.039z M569.666,1434.392
			c-1.919-0.946-3.729-1.939-5.382-2.952l1.568-2.559c1.574,0.966,3.304,1.914,5.14,2.819L569.666,1434.392z M559.212,1427.898
			c-1.729-1.388-3.252-2.845-4.526-4.331l2.277-1.953c1.154,1.345,2.543,2.673,4.128,3.944L559.212,1427.898z M683.571,1426.619
			l-2.998-0.076c0.026-1.071,0.039-2.196,0.039-3.372c0-0.831-0.007-1.688-0.02-2.568l3-0.043c0.013,0.896,0.02,1.767,0.02,2.611
			C683.612,1424.372,683.6,1425.522,683.571,1426.619z M551.194,1418.236c-0.938-2.016-1.502-4.117-1.677-6.248l2.99-0.246
			c0.146,1.776,0.619,3.535,1.407,5.229L551.194,1418.236z M680.43,1414.628c-0.074-1.914-0.17-3.91-0.286-5.979l2.996-0.168
			c0.116,2.087,0.214,4.101,0.288,6.032L680.43,1414.628z M553.017,1406.301l-2.902-0.758c0.502-1.927,1.304-3.906,2.384-5.885
			l2.633,1.438C554.169,1402.859,553.458,1404.61,553.017,1406.301z M679.769,1402.669c-0.135-1.945-0.284-3.939-0.446-5.979
			l2.99-0.238c0.164,2.049,0.313,4.055,0.448,6.01L679.769,1402.669z M558.312,1396.308l-2.365-1.846
			c1.187-1.521,2.556-3.078,4.068-4.629l2.146,2.096C560.727,1393.399,559.432,1394.873,558.312,1396.308z M678.82,1390.715
			c-0.174-1.959-0.356-3.952-0.55-5.973l2.986-0.285c0.193,2.028,0.378,4.027,0.552,5.994L678.82,1390.715z M563.883,1390.213
			l-1.766-2.426L563,1389l-0.886-1.21c0.016-0.013,1.641-1.223,3.935-4.015l2.318,1.904
			C565.801,1388.803,563.96,1390.157,563.883,1390.213z M571.978,1380.667l-2.531-1.609c1.006-1.582,1.979-3.294,2.892-5.089
			l2.674,1.359C574.056,1377.208,573.035,1379.005,571.978,1380.667z M677.682,1378.771c-0.2-1.971-0.409-3.961-0.624-5.968
			l2.982-0.32c0.217,2.013,0.426,4.009,0.626,5.985L677.682,1378.771z M577.554,1369.763l-2.775-1.137
			c0.722-1.763,1.407-3.623,2.037-5.528l2.848,0.941C579.012,1366.011,578.303,1367.937,577.554,1369.763z M676.403,1366.837
			c-0.222-1.977-0.45-3.965-0.684-5.962l2.98-0.348c0.233,2.001,0.462,3.995,0.684,5.976L676.403,1366.837z M581.388,1358.197
			l-2.902-0.762c0.492-1.873,0.942-3.811,1.34-5.759l2.939,0.6C582.356,1354.278,581.894,1356.271,581.388,1358.197z
			 M675.012,1354.915c-0.24-1.983-0.483-3.971-0.731-5.958l2.977-0.371c0.248,1.991,0.493,3.982,0.733,5.968L675.012,1354.915z
			 M583.831,1346.302l-2.965-0.455c0.295-1.918,0.553-3.896,0.768-5.879l2.982,0.322
			C584.397,1342.317,584.133,1344.34,583.831,1346.302z M673.525,1342.998c-0.254-1.985-0.512-3.968-0.772-5.943l2.975-0.393
			c0.261,1.979,0.519,3.966,0.773,5.955L673.525,1342.998z M585.146,1334.257l-2.992-0.203c0.131-1.935,0.23-3.932,0.293-5.936
			l2.998,0.094C585.381,1330.253,585.28,1332.286,585.146,1334.257z M671.959,1331.102c-0.269-1.995-0.54-3.979-0.812-5.948
			l2.973-0.41c0.272,1.972,0.544,3.959,0.812,5.958L671.959,1331.102z M582.533,1322.171c-0.002-1.033-0.013-2.082-0.033-3.143
			c-0.017-0.933-0.046-1.863-0.087-2.794l2.998-0.131c0.041,0.956,0.071,1.912,0.089,2.868c0.021,1.078,0.031,2.143,0.033,3.193
			L582.533,1322.171z M670.316,1319.21c-0.284-2.004-0.566-3.987-0.85-5.944l2.969-0.43c0.283,1.961,0.567,3.946,0.852,5.952
			L670.316,1319.21z M582.015,1310.321c-0.177-1.929-0.403-3.911-0.674-5.891l2.973-0.406c0.276,2.023,0.508,4.05,0.689,6.023
			L582.015,1310.321z M668.6,1307.323c-0.298-2.014-0.594-3.995-0.888-5.939l2.967-0.448c0.294,1.947,0.59,3.933,0.888,5.949
			L668.6,1307.323z M580.416,1298.571c-0.338-1.895-0.726-3.855-1.15-5.827l2.932-0.633c0.434,2.007,0.827,4.003,1.172,5.933
			L580.416,1298.571z M666.806,1295.448c-0.314-2.032-0.624-4.013-0.929-5.934l2.963-0.469c0.305,1.925,0.616,3.907,0.931,5.943
			L666.806,1295.448z M577.913,1286.955c-0.466-1.862-0.98-3.798-1.528-5.753l2.889-0.811c0.556,1.983,1.077,3.946,1.55,5.835
			L577.913,1286.955z M664.926,1283.584c-0.336-2.064-0.664-4.043-0.981-5.924l2.959-0.498c0.317,1.885,0.646,3.869,0.983,5.939
			L664.926,1283.584z M574.703,1275.484c-0.584-1.9-1.189-3.796-1.813-5.686l2.85-0.939c0.63,1.909,1.241,3.824,1.831,5.744
			L574.703,1275.484z M662.925,1271.743c-0.381-2.167-0.739-4.143-1.07-5.903l2.949-0.555c0.333,1.772,0.693,3.76,1.076,5.938
			L662.925,1271.743z M570.969,1264.141c-0.657-1.884-1.329-3.763-2.011-5.637l2.82-1.025c0.686,1.888,1.361,3.778,2.022,5.674
			L570.969,1264.141z M660.683,1259.984l-0.146-0.651c-0.354-1.561-0.609-3.408-0.762-5.493l2.992-0.219
			c0.141,1.937,0.375,3.635,0.695,5.047l0.148,0.664L660.683,1259.984z M566.878,1252.886c-0.704-1.875-1.415-3.745-2.129-5.61
			l2.803-1.072c0.716,1.871,1.429,3.747,2.135,5.628L566.878,1252.886z M662.599,1247.744l-3-0.02
			c0.012-1.836,0.071-3.877,0.178-6.063l2.996,0.146C662.668,1243.952,662.609,1245.949,662.599,1247.744z M562.593,1241.668
			l-2.161-5.601l2.799-1.078l2.161,5.599L562.593,1241.668z M663.144,1235.854l-2.992-0.221c0.144-1.941,0.309-3.946,0.491-6.005
			l2.988,0.264C663.45,1231.938,663.285,1233.927,663.144,1235.854z M558.281,1230.455c-0.715-1.883-1.422-3.76-2.115-5.631
			l2.812-1.043c0.691,1.863,1.395,3.732,2.107,5.607L558.281,1230.455z M664.188,1223.928l-2.986-0.287
			c0.188-1.959,0.389-3.954,0.593-5.98l2.984,0.301C664.575,1219.983,664.377,1221.975,664.188,1223.928z M554.11,1219.166
			c-0.678-1.904-1.338-3.804-1.975-5.694l2.844-0.957c0.631,1.875,1.285,3.757,1.957,5.646L554.11,1219.166z M665.382,1211.989
			l-2.984-0.299c0.197-1.97,0.395-3.961,0.59-5.97l2.986,0.291C665.778,1208.022,665.579,1210.018,665.382,1211.989z
			 M550.271,1207.731c-0.62-1.987-1.199-3.937-1.722-5.793l2.889-0.812c0.514,1.83,1.085,3.752,1.696,5.712L550.271,1207.731z
			 M666.538,1200.026l-2.988-0.271c0.18-1.979,0.353-3.97,0.516-5.966l2.99,0.244
			C666.893,1196.037,666.718,1198.037,666.538,1200.026z M547.008,1196.087c-0.491-2.007-0.937-3.997-1.324-5.913l2.941-0.594
			c0.379,1.876,0.815,3.826,1.297,5.794L547.008,1196.087z M667.51,1188.027l-2.992-0.207c0.138-1.991,0.263-3.983,0.369-5.968
			l2.996,0.162C667.774,1184.015,667.649,1186.021,667.51,1188.027z M544.626,1184.196c-0.311-2.051-0.56-4.082-0.741-6.037
			l2.986-0.277c0.177,1.898,0.419,3.871,0.722,5.865L544.626,1184.196z M668.153,1175.99l-2.998-0.105
			c0.072-2.032,0.12-4.038,0.143-5.962l3,0.035C668.274,1171.905,668.227,1173.936,668.153,1175.99z M543.515,1172.076
			c-0.021-0.776-0.032-1.552-0.033-2.325c0-0.597,0.006-1.19,0.019-1.783c0.016-0.665,0.033-1.323,0.055-1.979l2.998,0.1
			c-0.021,0.644-0.039,1.292-0.053,1.944c-0.013,0.57-0.019,1.143-0.019,1.716c0.001,0.746,0.012,1.496,0.031,2.247L543.515,1172.076
			z M665.289,1163.965c-0.033-2.05-0.1-4.05-0.196-5.945l2.996-0.152c0.099,1.929,0.166,3.964,0.2,6.049L665.289,1163.965z
			 M546.853,1160.153l-2.993-0.203c0.134-1.966,0.306-3.992,0.512-6.022l2.984,0.303
			C547.153,1156.228,546.984,1158.221,546.853,1160.153z M664.666,1152.1c-0.193-2.039-0.433-4.016-0.711-5.875l2.967-0.445
			c0.287,1.913,0.532,3.944,0.73,6.037L664.666,1152.1z M548.05,1148.322l-2.975-0.393c0.256-1.939,0.541-3.893,0.87-5.97l2.963,0.47
			C548.583,1144.48,548.302,1146.408,548.05,1148.322z M662.889,1140.428c-0.443-1.993-0.954-3.897-1.52-5.66l2.857-0.916
			c0.593,1.851,1.128,3.844,1.59,5.926L662.889,1140.428z M549.902,1136.546l-2.953-0.527c0.353-1.974,0.721-3.941,1.1-5.913
			l2.946,0.564C550.619,1132.63,550.253,1134.586,549.902,1136.546z M659.396,1129.118c-0.649-1.958-1.233-3.925-1.733-5.845
			l2.902-0.756c0.484,1.856,1.049,3.759,1.679,5.655L659.396,1129.118z M552.144,1124.795l-2.943-0.582l1.162-5.88l2.943,0.578
			L552.144,1124.795z M656.335,1117.315c-0.369-1.984-0.668-4.018-0.89-6.043l2.982-0.326c0.214,1.951,0.502,3.91,0.856,5.822
			L656.335,1117.315z M554.442,1113.012l-2.947-0.555c0.362-1.93,0.721-3.887,1.068-5.879l2.955,0.516
			C555.169,1109.1,554.809,1111.07,554.442,1113.012z M654.998,1105.185c-0.046-1.19-0.069-2.415-0.069-3.641
			c0.001-0.813,0.011-1.629,0.03-2.451l3,0.072c-0.02,0.799-0.029,1.593-0.03,2.381c0,1.185,0.022,2.371,0.067,3.523
			L654.998,1105.185z M556.507,1101.155l-2.963-0.467c0.303-1.928,0.594-3.893,0.868-5.9l2.973,0.406
			C557.106,1097.224,556.813,1099.208,556.507,1101.155z M658.262,1093.254l-2.992-0.227c0.146-1.91,0.344-3.938,0.591-6.03
			l2.979,0.352C658.598,1089.398,658.403,1091.386,658.262,1093.254z M558.139,1089.214l-2.98-0.344
			c0.227-1.971,0.434-3.965,0.615-5.929l2.987,0.275C558.577,1085.203,558.368,1087.221,558.139,1089.214z M659.632,1081.453
			l-2.967-0.445c0.281-1.877,0.605-3.88,0.964-5.953l2.957,0.512C660.23,1077.617,659.91,1079.598,659.632,1081.453z
			 M559.247,1077.207l-2.992-0.209c0.135-1.939,0.251-3.922,0.348-5.952l2.996,0.143
			C559.504,1073.198,559.386,1075.224,559.247,1077.207z M661.654,1069.688l-2.947-0.558c0.362-1.916,0.747-3.881,1.152-5.902
			l2.941,0.59C662.397,1065.828,662.015,1067.783,661.654,1069.688z M559.817,1065.165l-2.998-0.076
			c0.049-1.945,0.08-3.933,0.09-5.965l3,0.014C559.899,1061.191,559.868,1063.2,559.817,1065.165z M663.999,1057.953l-2.938-0.609
			c0.396-1.91,0.807-3.866,1.23-5.876l2.936,0.619C664.804,1054.093,664.395,1056.047,663.999,1057.953z M556.881,1053.152
			c-0.028-1.948-0.076-3.938-0.143-5.969l2.998-0.1c0.067,2.05,0.116,4.058,0.145,6.025L556.881,1053.152z M666.489,1046.127
			l-2.955-0.53l1.244-5.866l2.936,0.621L666.489,1046.127z M556.486,1041.214c-0.1-1.95-0.216-3.938-0.353-5.966l2.994-0.201
			c0.137,2.045,0.255,4.049,0.354,6.015L556.486,1041.214z M668.949,1034.479l-2.936-0.615c0.4-1.912,0.808-3.867,1.221-5.867
			l2.938,0.607C669.759,1030.606,669.352,1032.565,668.949,1034.479z M555.688,1029.289c-0.16-1.949-0.339-3.933-0.535-5.953
			l2.986-0.291c0.198,2.036,0.377,4.035,0.539,5.998L555.688,1029.289z M671.377,1022.723l-2.939-0.597
			c0.389-1.917,0.782-3.875,1.179-5.874l2.943,0.584C672.161,1018.839,671.768,1020.801,671.377,1022.723z M554.533,1017.38
			l-0.663-5.95l2.982-0.332l0.665,5.968L554.533,1017.38z M673.717,1010.943l-2.945-0.57c0.373-1.925,0.749-3.886,1.128-5.885
			l2.947,0.559C674.468,1007.05,674.09,1009.015,673.717,1010.943z M553.204,1005.472l-0.671-5.957l2.98-0.336l0.671,5.959
			L553.204,1005.472z M675.95,999.142l-2.951-0.543l0.525-2.867c0.183-1.009,0.361-2.017,0.536-3.021l2.955,0.512
			c-0.175,1.013-0.355,2.028-0.54,3.046L675.95,999.142z M551.857,993.561l-0.682-5.955l2.98-0.342l0.682,5.957L551.857,993.561z
			 M677.996,987.281l-2.963-0.465c0.311-1.982,0.604-3.953,0.879-5.911l2.971,0.418C678.605,983.297,678.311,985.283,677.996,987.281
			z M550.487,981.653l-0.696-5.952l2.98-0.35l0.696,5.954L550.487,981.653z M679.674,975.353l-2.977-0.372
			c0.249-1.991,0.479-3.971,0.693-5.935l2.982,0.324C680.157,971.35,679.925,973.345,679.674,975.353z M549.084,969.753
			c-0.243-2.03-0.482-4.012-0.72-5.946l2.979-0.365c0.237,1.938,0.477,3.922,0.72,5.954L549.084,969.753z M680.979,963.377
			l-2.986-0.279c0.187-2.002,0.356-3.987,0.511-5.955l2.99,0.234C681.339,959.358,681.167,961.359,680.979,963.377z M547.634,957.861
			c-0.253-2.027-0.503-4.006-0.751-5.94l2.977-0.381c0.248,1.938,0.498,3.919,0.751,5.95L547.634,957.861z M681.918,951.37
			l-2.994-0.189c0.128-2.011,0.237-4,0.332-5.969l2.996,0.145C682.157,947.339,682.046,949.344,681.918,951.37z M546.113,945.984
			c-0.266-2.021-0.529-3.996-0.792-5.933l2.973-0.402c0.263,1.94,0.528,3.92,0.794,5.944L546.113,945.984z M682.496,939.339
			l-2.998-0.1c0.067-2.019,0.118-4.012,0.154-5.977l3,0.055C682.616,935.298,682.563,937.305,682.496,939.339z M544.502,934.125
			c-0.283-2.007-0.565-3.979-0.849-5.923l2.969-0.432c0.283,1.948,0.567,3.924,0.851,5.937L544.502,934.125z M682.72,927.297
			l-3-0.012l0.003-1.544c-0.001-1.496-0.009-2.976-0.024-4.436l3-0.031c0.016,1.47,0.023,2.959,0.024,4.466L682.72,927.297z
			 M542.771,922.285c-0.301-1.987-0.605-3.954-0.914-5.91l2.963-0.469c0.311,1.963,0.615,3.937,0.918,5.93L542.771,922.285z
			 M679.591,915.328c-0.053-2.034-0.119-4.026-0.198-5.977l2.998-0.121c0.079,1.964,0.146,3.972,0.199,6.02L679.591,915.328z
			 M540.905,910.468c-0.321-1.962-0.648-3.923-0.982-5.894l2.957-0.502c0.336,1.977,0.663,3.942,0.986,5.909L540.905,910.468z
			 M679.107,903.379c-0.113-2.045-0.239-4.034-0.375-5.967l2.992-0.211c0.138,1.946,0.264,3.951,0.379,6.012L679.107,903.379z
			 M538.907,898.679c-0.34-1.942-0.688-3.903-1.045-5.893l2.953-0.529c0.357,1.993,0.707,3.958,1.047,5.905L538.907,898.679z
			 M678.266,891.451c-0.178-2.063-0.365-4.049-0.56-5.951l2.984-0.305c0.196,1.918,0.386,3.919,0.563,6L678.266,891.451z
			 M536.792,886.897c-0.354-1.926-0.716-3.886-1.09-5.886l2.949-0.551c0.374,2.004,0.737,3.966,1.092,5.896L536.792,886.897z
			 M677.05,879.56c-0.251-2.11-0.506-4.089-0.758-5.928l2.973-0.406c0.254,1.854,0.511,3.851,0.764,5.98L677.05,879.56z
			 M534.597,875.127c-0.363-1.919-0.736-3.879-1.12-5.884l2.947-0.563c0.384,2.008,0.757,3.968,1.12,5.889L534.597,875.127z
			 M675.424,867.723c-0.322-2.049-0.657-4.028-0.996-5.882l2.951-0.539c0.343,1.878,0.683,3.882,1.008,5.956L675.424,867.723z
			 M532.351,863.345c-0.358-1.932-0.692-3.941-0.991-5.972l2.969-0.438c0.293,1.994,0.62,3.967,0.972,5.862L532.351,863.345z
			 M673.527,858.386c-2.021-0.174-4.048-0.388-6.022-0.635l0.373-2.977c1.936,0.242,3.923,0.452,5.907,0.623L673.527,858.386z
			 M661.512,856.872c-2.002-0.336-4.004-0.716-5.95-1.131l0.625-2.934c1.903,0.405,3.862,0.777,5.821,1.105L661.512,856.872z
			 M649.666,854.347c-1.965-0.513-3.925-1.072-5.827-1.664l0.893-2.865c1.856,0.579,3.772,1.126,5.692,1.627L649.666,854.347z
			 M530.586,851.37c-0.221-1.972-0.414-3.997-0.574-6.019l2.99-0.238c0.158,1.99,0.349,3.983,0.565,5.925L530.586,851.37z
			 M638.097,850.74c-1.899-0.694-3.798-1.442-5.644-2.224l1.17-2.762c1.799,0.762,3.65,1.49,5.503,2.167L638.097,850.74z
			 M626.93,846.012c-1.807-0.874-3.62-1.812-5.392-2.786l1.445-2.629c1.727,0.95,3.493,1.863,5.253,2.714L626.93,846.012z
			 M616.297,840.165c-1.714-1.062-3.421-2.183-5.075-3.33l1.711-2.465c1.611,1.118,3.274,2.21,4.944,3.244L616.297,840.165z
			 M529.622,839.321c-0.101-1.982-0.175-4.013-0.223-6.035l3-0.07c0.046,1.996,0.12,3.999,0.219,5.953L529.622,839.321z
			 M606.325,833.246c-1.593-1.232-3.176-2.521-4.705-3.833l1.953-2.277c1.491,1.278,3.035,2.536,4.588,3.737L606.325,833.246z
			 M532.334,827.254l-3-0.004c0.003-1.984,0.029-4.015,0.078-6.033l3,0.072C532.363,823.286,532.337,825.293,532.334,827.254z
			 M597.112,825.349c-1.45-1.375-2.896-2.814-4.301-4.281l2.166-2.074c1.371,1.431,2.784,2.837,4.199,4.178L597.112,825.349z
			 M588.72,816.586c-1.32-1.517-2.625-3.086-3.879-4.664l2.35-1.865c1.226,1.543,2.501,3.076,3.792,4.559L588.72,816.586z
			 M532.622,815.324l-2.996-0.137c0.092-2.011,0.206-4.021,0.339-6.025l2.994,0.199C532.826,811.347,532.714,813.335,532.622,815.324
			z M581.176,807.092c-1.175-1.618-2.338-3.295-3.457-4.982l2.5-1.658c1.097,1.652,2.235,3.294,3.385,4.879L581.176,807.092z
			 M533.41,803.401l-2.988-0.256c0.171-2.009,0.361-4.013,0.568-6.008l2.984,0.309C533.77,799.424,533.581,801.41,533.41,803.401z
			 M574.471,796.992c-1.04-1.712-2.064-3.475-3.045-5.24l2.623-1.457c0.962,1.731,1.967,3.461,2.986,5.141L574.471,796.992z
			 M534.644,791.508l-2.979-0.361c0.243-2.004,0.502-3.997,0.773-5.976l2.973,0.408C535.142,787.542,534.885,789.52,534.644,791.508z
			 M568.581,786.402c-0.902-1.774-1.794-3.606-2.65-5.447l2.721-1.266c0.841,1.809,1.717,3.609,2.604,5.352L568.581,786.402z
			 M536.276,779.664l-2.965-0.457c0.308-2,0.629-3.983,0.963-5.947l2.957,0.502C536.901,775.71,536.582,777.679,536.276,779.664z
			 M563.47,775.423c-0.776-1.829-1.543-3.717-2.276-5.611l2.797-1.084c0.723,1.865,1.477,3.723,2.241,5.521L563.47,775.423z
			 M538.276,767.872l-2.949-0.545c0.369-1.998,0.749-3.971,1.139-5.916l2.941,0.588C539.021,763.931,538.644,765.89,538.276,767.872z
			 M559.097,764.13c-0.66-1.876-1.308-3.809-1.923-5.741l2.859-0.91c0.605,1.904,1.243,3.808,1.894,5.655L559.097,764.13z
			 M540.625,756.143l-2.934-0.631c0.43-1.996,0.867-3.957,1.311-5.881l2.924,0.676C541.484,752.215,541.051,754.162,540.625,756.143z
			 M555.423,752.594c-0.55-1.925-1.083-3.891-1.582-5.846l2.906-0.742c0.493,1.927,1.018,3.865,1.561,5.762L555.423,752.594z
			 M543.312,744.487l-2.914-0.717c0.491-1.997,0.986-3.946,1.482-5.84l2.902,0.76C544.29,740.57,543.799,742.504,543.312,744.487z
			 M552.426,740.857c-0.44-1.957-0.859-3.952-1.246-5.931l2.945-0.574c0.38,1.95,0.794,3.917,1.229,5.847L552.426,740.857z
			 M546.345,732.918l-2.889-0.807c0.561-2.009,1.119-3.942,1.671-5.793l2.875,0.857C547.454,729.011,546.901,730.927,546.345,732.918
			z M550.105,728.955c-0.336-2.049-0.635-4.071-0.887-6.011l2.975-0.387c0.248,1.906,0.542,3.895,0.873,5.911L550.105,728.955z
			 M549.765,721.469l-2.857-0.914c0.572-1.787,1.132-3.471,1.675-5.043l2.453-7.114l0.462,7.511c0.001,0.009,0.016,0.254,0.054,0.714
			l-0.184,0.015C550.848,718.148,550.312,719.761,549.765,721.469z"/>
		<path fill="#006837" d="M722.477,1448.656l-0.041-3c1.949-0.026,3.954-0.084,5.959-0.172l0.131,2.997
			C726.491,1448.571,724.455,1448.63,722.477,1448.656z M716.423,1448.638c-2.078-0.043-4.115-0.123-6.055-0.238l0.178-2.994
			c1.9,0.112,3.898,0.191,5.938,0.232L716.423,1448.638z M734.564,1448.134l-0.215-2.992c1.967-0.142,3.967-0.312,5.943-0.509
			l0.297,2.984C738.586,1447.816,736.559,1447.99,734.564,1448.134z M704.313,1447.897c-2.124-0.23-4.156-0.515-6.039-0.845
			l0.518-2.955c1.819,0.319,3.785,0.594,5.846,0.817L704.313,1447.897z M746.593,1446.938l-0.377-2.977
			c1.978-0.251,3.964-0.531,5.906-0.832l0.459,2.965C750.612,1446.399,748.598,1446.684,746.593,1446.938z M692.271,1445.711
			c-2.274-0.64-4.253-1.384-5.882-2.21l1.357-2.676c1.455,0.738,3.251,1.411,5.337,1.998L692.271,1445.711z M758.552,1445.08
			l-0.545-2.949c1.979-0.366,3.944-0.762,5.841-1.176l0.639,2.932C762.56,1444.307,760.562,1444.709,758.552,1445.08z
			 M770.382,1442.499l-0.738-2.908c1.952-0.495,3.882-1.025,5.734-1.574l0.852,2.877
			C774.34,1441.453,772.372,1441.993,770.382,1442.499z M681.232,1439.139c-0.332-0.565-0.58-1.157-0.737-1.76
			c-0.325-1.24-0.584-2.808-0.771-4.658l2.984-0.301c0.171,1.696,0.403,3.109,0.688,4.2c0.088,0.336,0.229,0.671,0.421,0.997
			L681.232,1439.139z M782.013,1439.039l-0.982-2.834c1.916-0.664,3.78-1.366,5.541-2.087l1.137,2.775
			C785.896,1437.636,783.98,1438.357,782.013,1439.039z M793.28,1434.392l-1.326-2.691c1.833-0.904,3.563-1.853,5.141-2.818
			l1.566,2.559C797.007,1432.454,795.196,1433.446,793.28,1434.392z M803.734,1427.899l-1.878-2.34
			c1.588-1.273,2.977-2.601,4.128-3.943l2.277,1.953C806.989,1425.053,805.466,1426.51,803.734,1427.899z M679.374,1426.619
			c-0.027-1.097-0.04-2.247-0.04-3.448c0-0.845,0.007-1.716,0.02-2.611l3,0.043c-0.013,0.88-0.02,1.737-0.02,2.568
			c0,1.176,0.013,2.301,0.04,3.372L679.374,1426.619z M811.754,1418.237l-2.721-1.266c0.788-1.693,1.262-3.452,1.407-5.229
			l2.99,0.246C813.256,1414.12,812.691,1416.222,811.754,1418.237z M682.517,1414.628l-2.998-0.115
			c0.074-1.932,0.172-3.945,0.288-6.032l2.996,0.168C682.687,1410.718,682.591,1412.714,682.517,1414.628z M809.931,1406.301
			c-0.441-1.69-1.152-3.441-2.114-5.205l2.633-1.438c1.08,1.979,1.882,3.958,2.384,5.885L809.931,1406.301z M683.178,1402.669
			l-2.992-0.207c0.135-1.955,0.284-3.961,0.448-6.01l2.99,0.238C683.462,1398.729,683.312,1400.724,683.178,1402.669z
			 M804.635,1396.31c-1.119-1.435-2.414-2.908-3.85-4.379l2.146-2.096c1.513,1.551,2.882,3.107,4.068,4.629L804.635,1396.31z
			 M684.126,1390.715l-2.988-0.264c0.174-1.967,0.358-3.966,0.552-5.994l2.986,0.285
			C684.482,1386.763,684.3,1388.756,684.126,1390.715z M799.063,1390.213c-0.077-0.056-1.918-1.41-4.484-4.533l2.318-1.904
			c2.294,2.792,3.919,4.002,3.935,4.015L799.063,1390.213z M790.969,1380.667c-1.059-1.665-2.08-3.461-3.034-5.339l2.675-1.359
			c0.911,1.793,1.884,3.505,2.891,5.089L790.969,1380.667z M685.265,1378.771l-2.984-0.303c0.2-1.977,0.409-3.973,0.626-5.985
			l2.982,0.32C685.674,1374.811,685.465,1376.801,685.265,1378.771z M785.393,1369.763c-0.749-1.826-1.458-3.752-2.109-5.724
			l2.848-0.941c0.63,1.905,1.315,3.766,2.037,5.528L785.393,1369.763z M686.543,1366.837l-2.98-0.334
			c0.222-1.98,0.45-3.975,0.684-5.976l2.98,0.348C686.993,1362.872,686.765,1364.86,686.543,1366.837z M781.559,1358.197
			c-0.506-1.927-0.969-3.919-1.377-5.921l2.939-0.6c0.397,1.948,0.848,3.886,1.34,5.759L781.559,1358.197z M687.935,1354.915
			l-2.979-0.361c0.24-1.985,0.485-3.977,0.733-5.968l2.977,0.371C688.418,1350.944,688.175,1352.932,687.935,1354.915z
			 M779.115,1346.302c-0.302-1.962-0.566-3.984-0.785-6.012l2.982-0.322c0.215,1.983,0.473,3.961,0.768,5.879L779.115,1346.302z
			 M689.421,1342.998l-2.977-0.381c0.256-1.989,0.514-3.976,0.774-5.955l2.975,0.393
			C689.933,1339.03,689.675,1341.013,689.421,1342.998z M777.801,1334.257c-0.135-1.971-0.235-4.004-0.299-6.045l2.998-0.094
			c0.062,2.004,0.161,4.001,0.293,5.936L777.801,1334.257z M690.987,1331.102l-2.973-0.4c0.269-1.999,0.54-3.986,0.813-5.958
			l2.972,0.41C691.527,1327.122,691.256,1329.106,690.987,1331.102z M780.413,1322.171l-3-0.006c0.002-1.051,0.013-2.115,0.033-3.193
			c0.019-0.956,0.048-1.912,0.089-2.868l2.998,0.131c-0.041,0.931-0.07,1.861-0.087,2.794
			C780.426,1320.089,780.415,1321.138,780.413,1322.171z M692.63,1319.21l-2.971-0.422c0.284-2.006,0.568-3.991,0.852-5.952
			l2.969,0.43C693.196,1315.223,692.914,1317.206,692.63,1319.21z M780.932,1310.321l-2.988-0.273c0.182-1.974,0.413-4,0.689-6.023
			l2.973,0.406C781.335,1306.41,781.108,1308.393,780.932,1310.321z M694.347,1307.323l-2.968-0.438
			c0.299-2.017,0.595-4.002,0.889-5.949l2.967,0.448C694.94,1303.328,694.645,1305.31,694.347,1307.323z M782.53,1298.571
			l-2.953-0.527c0.344-1.93,0.738-3.926,1.172-5.933l2.932,0.633C783.256,1294.716,782.868,1296.677,782.53,1298.571z
			 M696.141,1295.448l-2.965-0.459c0.314-2.036,0.626-4.019,0.931-5.943l2.963,0.469
			C696.765,1291.436,696.455,1293.416,696.141,1295.448z M785.033,1286.955l-2.91-0.729c0.473-1.889,0.994-3.852,1.55-5.835
			l2.889,0.811C786.014,1283.157,785.499,1285.093,785.033,1286.955z M698.021,1283.584l-2.961-0.482
			c0.337-2.07,0.666-4.055,0.983-5.939l2.959,0.498C698.685,1279.541,698.357,1281.52,698.021,1283.584z M788.243,1275.484
			l-2.867-0.881c0.59-1.92,1.201-3.835,1.831-5.744l2.85,0.939C789.433,1271.688,788.827,1273.584,788.243,1275.484z
			 M700.021,1271.743l-2.955-0.52c0.383-2.179,0.743-4.166,1.076-5.938l2.949,0.555
			C700.761,1267.601,700.402,1269.576,700.021,1271.743z M791.978,1264.141l-2.832-0.988c0.661-1.896,1.337-3.786,2.022-5.674
			l2.82,1.025C793.307,1260.378,792.635,1262.257,791.978,1264.141z M702.265,1259.98l-2.931-0.645l0.149-0.669
			c0.32-1.411,0.555-3.109,0.695-5.046l2.992,0.219c-0.152,2.085-0.408,3.933-0.762,5.492L702.265,1259.98z M796.068,1252.886
			l-2.809-1.055c0.706-1.881,1.419-3.757,2.135-5.628l2.803,1.072C797.483,1249.141,796.772,1251.011,796.068,1252.886z
			 M700.348,1247.744c-0.011-1.795-0.069-3.792-0.174-5.937l2.996-0.146c0.106,2.187,0.166,4.228,0.178,6.063L700.348,1247.744z
			 M800.354,1241.668l-2.799-1.08l2.161-5.599l2.799,1.078L800.354,1241.668z M699.803,1235.854
			c-0.142-1.928-0.307-3.917-0.487-5.962l2.988-0.264c0.183,2.059,0.348,4.063,0.491,6.005L699.803,1235.854z M804.665,1230.455
			l-2.805-1.066c0.713-1.875,1.416-3.744,2.107-5.607l2.812,1.043C806.087,1226.695,805.38,1228.572,804.665,1230.455z
			 M698.758,1223.928c-0.188-1.953-0.387-3.944-0.591-5.967l2.984-0.301c0.204,2.026,0.404,4.021,0.593,5.98L698.758,1223.928z
			 M808.836,1219.166l-2.826-1.006c0.672-1.889,1.326-3.771,1.957-5.646l2.844,0.957
			C810.174,1215.362,809.514,1217.262,808.836,1219.166z M697.564,1211.989c-0.198-1.972-0.396-3.967-0.592-5.978l2.986-0.291
			c0.195,2.009,0.394,4,0.59,5.97L697.564,1211.989z M812.675,1207.731l-2.863-0.894c0.611-1.96,1.183-3.882,1.696-5.712l2.889,0.812
			C813.874,1203.795,813.295,1205.744,812.675,1207.731z M696.408,1200.026c-0.18-1.989-0.354-3.989-0.518-5.993l2.99-0.244
			c0.162,1.996,0.336,3.986,0.516,5.966L696.408,1200.026z M815.938,1196.087l-2.914-0.713c0.481-1.968,0.918-3.918,1.297-5.794
			l2.941,0.594C816.875,1192.09,816.43,1194.08,815.938,1196.087z M695.437,1188.027c-0.14-2.007-0.265-4.013-0.373-6.013
			l2.996-0.162c0.106,1.984,0.231,3.977,0.369,5.968L695.437,1188.027z M818.32,1184.196l-2.967-0.449
			c0.303-1.994,0.545-3.967,0.722-5.865l2.986,0.277C818.88,1180.114,818.631,1182.146,818.32,1184.196z M694.793,1175.99
			c-0.073-2.055-0.121-4.085-0.145-6.032l3-0.035c0.022,1.924,0.07,3.93,0.143,5.962L694.793,1175.99z M819.432,1172.076l-2.998-0.08
			c0.02-0.751,0.03-1.501,0.031-2.249c0-0.571-0.006-1.144-0.019-1.714c-0.014-0.652-0.031-1.301-0.053-1.944l2.998-0.1
			c0.021,0.655,0.04,1.313,0.055,1.978c0.013,0.594,0.019,1.188,0.019,1.782C819.464,1170.524,819.453,1171.3,819.432,1172.076z
			 M697.657,1163.965l-3-0.049c0.034-2.085,0.102-4.12,0.2-6.049l2.996,0.152C697.757,1159.915,697.69,1161.915,697.657,1163.965z
			 M816.093,1160.153c-0.131-1.933-0.3-3.926-0.502-5.923l2.984-0.303c0.206,2.03,0.378,4.057,0.511,6.022L816.093,1160.153z
			 M698.28,1152.1l-2.986-0.283c0.198-2.093,0.443-4.124,0.73-6.037l2.967,0.445C698.713,1148.084,698.474,1150.061,698.28,1152.1z
			 M814.896,1148.322c-0.252-1.914-0.533-3.842-0.858-5.893l2.963-0.47c0.329,2.077,0.614,4.03,0.87,5.97L814.896,1148.322z
			 M700.058,1140.428l-2.928-0.65c0.462-2.082,0.997-4.075,1.59-5.926l2.857,0.916
			C701.012,1136.53,700.501,1138.435,700.058,1140.428z M813.044,1136.546c-0.351-1.96-0.717-3.916-1.093-5.876l2.947-0.564
			c0.378,1.972,0.746,3.939,1.099,5.913L813.044,1136.546z M703.551,1129.118l-2.848-0.945c0.63-1.896,1.194-3.799,1.679-5.655
			l2.902,0.756C704.784,1125.193,704.2,1127.16,703.551,1129.118z M810.803,1124.795l-1.162-5.884l2.943-0.578l1.162,5.88
			L810.803,1124.795z M706.611,1117.315l-2.949-0.547c0.354-1.912,0.643-3.871,0.856-5.822l2.982,0.326
			C707.279,1113.298,706.98,1115.331,706.611,1117.315z M808.504,1113.012c-0.366-1.941-0.727-3.912-1.076-5.918l2.955-0.516
			c0.348,1.992,0.706,3.949,1.068,5.879L808.504,1113.012z M707.948,1105.185l-2.998-0.115c0.045-1.152,0.067-2.339,0.067-3.525
			c-0.001-0.786-0.011-1.58-0.03-2.379l3-0.072c0.02,0.822,0.029,1.638,0.03,2.449
			C708.018,1102.77,707.994,1103.994,707.948,1105.185z M806.439,1101.155c-0.307-1.947-0.6-3.932-0.878-5.961l2.973-0.406
			c0.274,2.008,0.565,3.973,0.868,5.9L806.439,1101.155z M704.685,1093.254c-0.142-1.868-0.336-3.855-0.577-5.905l2.979-0.352
			c0.247,2.092,0.446,4.12,0.591,6.03L704.685,1093.254z M804.808,1089.214c-0.229-1.993-0.438-4.011-0.621-5.997l2.987-0.275
			c0.181,1.964,0.388,3.958,0.614,5.929L804.808,1089.214z M703.314,1081.453c-0.278-1.855-0.599-3.836-0.953-5.887l2.956-0.512
			c0.358,2.073,0.683,4.076,0.964,5.953L703.314,1081.453z M803.699,1077.207c-0.139-1.983-0.257-4.009-0.352-6.019l2.996-0.143
			c0.097,2.03,0.213,4.013,0.348,5.952L803.699,1077.207z M701.292,1069.688c-0.36-1.905-0.743-3.86-1.146-5.87l2.941-0.59
			c0.405,2.021,0.79,3.986,1.152,5.902L701.292,1069.688z M803.129,1065.165c-0.051-1.965-0.082-3.974-0.092-6.027l3-0.014
			c0.01,2.032,0.041,4.02,0.091,5.965L803.129,1065.165z M698.947,1057.953c-0.396-1.906-0.805-3.86-1.229-5.866l2.936-0.619
			c0.424,2.01,0.835,3.966,1.23,5.876L698.947,1057.953z M806.065,1053.152l-3-0.043c0.028-1.968,0.077-3.976,0.145-6.025l2.998,0.1
			C806.141,1049.214,806.094,1051.204,806.065,1053.152z M696.478,1046.22l-1.245-5.868l2.936-0.621l1.224,5.773l-1.447,0.404
			L696.478,1046.22z M806.46,1041.214l-2.996-0.152c0.1-1.966,0.218-3.97,0.354-6.015l2.994,0.201
			C806.676,1037.275,806.56,1039.264,806.46,1041.214z M693.997,1034.479c-0.402-1.914-0.81-3.873-1.223-5.875l2.938-0.607
			c0.413,2,0.82,3.955,1.221,5.867L693.997,1034.479z M807.259,1029.289l-2.99-0.246c0.162-1.963,0.341-3.962,0.539-5.998
			l2.986,0.291C807.597,1025.356,807.419,1027.34,807.259,1029.289z M691.569,1022.723c-0.391-1.922-0.784-3.884-1.183-5.887
			l2.943-0.584c0.396,1.999,0.79,3.957,1.179,5.874L691.569,1022.723z M808.413,1017.38l-2.984-0.314l0.665-5.968l2.982,0.332
			L808.413,1017.38z M689.229,1010.943c-0.373-1.929-0.751-3.894-1.13-5.896l2.947-0.559c0.379,1.999,0.755,3.96,1.128,5.885
			L689.229,1010.943z M809.742,1005.472l-2.98-0.334l0.671-5.959l2.98,0.336L809.742,1005.472z M686.996,999.142l-0.525-2.873
			c-0.185-1.019-0.365-2.034-0.54-3.047l2.955-0.512c0.175,1.005,0.354,2.013,0.536,3.022l0.525,2.866L686.996,999.142z
			 M811.089,993.561l-2.98-0.34l0.682-5.957l2.98,0.342L811.089,993.561z M684.95,987.281c-0.314-1.998-0.609-3.984-0.887-5.958
			l2.971-0.418c0.275,1.958,0.568,3.929,0.879,5.911L684.95,987.281z M812.459,981.653l-2.98-0.348l0.696-5.954l2.98,0.35
			L812.459,981.653z M683.272,975.353c-0.251-2.008-0.483-4.003-0.699-5.982l2.982-0.324c0.214,1.964,0.444,3.943,0.693,5.935
			L683.272,975.353z M813.862,969.753l-2.979-0.357c0.243-2.032,0.482-4.016,0.72-5.954l2.979,0.365
			C814.345,965.741,814.105,967.723,813.862,969.753z M681.968,963.377c-0.188-2.018-0.36-4.019-0.515-6l2.99-0.234
			c0.154,1.968,0.324,3.953,0.511,5.955L681.968,963.377z M815.312,957.861l-2.977-0.371c0.253-2.031,0.503-4.013,0.751-5.95
			l2.977,0.381C815.815,953.855,815.565,955.834,815.312,957.861z M681.028,951.37c-0.128-2.026-0.239-4.031-0.334-6.014l2.996-0.145
			c0.095,1.969,0.204,3.958,0.332,5.969L681.028,951.37z M816.833,945.984l-2.975-0.391c0.266-2.024,0.531-4.004,0.794-5.944
			l2.973,0.402C817.362,941.988,817.099,943.964,816.833,945.984z M680.45,939.339c-0.067-2.034-0.12-4.041-0.156-6.021l3-0.055
			c0.036,1.965,0.087,3.958,0.154,5.977L680.45,939.339z M818.444,934.125l-2.971-0.418c0.283-2.013,0.567-3.988,0.851-5.937
			l2.969,0.432C819.01,930.146,818.728,932.118,818.444,934.125z M680.227,927.297l-0.003-1.556c0.001-1.508,0.009-2.997,0.024-4.467
			l3,0.031c-0.016,1.46-0.023,2.939-0.024,4.437l0.003,1.543L680.227,927.297z M820.175,922.285l-2.967-0.449
			c0.303-1.993,0.607-3.967,0.918-5.93l2.963,0.469C820.78,918.331,820.476,920.298,820.175,922.285z M683.355,915.328l-2.998-0.078
			c0.053-2.048,0.119-4.056,0.198-6.02l2.998,0.121C683.475,911.302,683.408,913.294,683.355,915.328z M822.041,910.468l-2.961-0.486
			c0.323-1.967,0.65-3.933,0.986-5.909l2.957,0.502C822.689,906.545,822.362,908.506,822.041,910.468z M683.839,903.379l-2.996-0.166
			c0.115-2.061,0.241-4.065,0.379-6.012l2.992,0.211C684.078,899.345,683.952,901.334,683.839,903.379z M824.039,898.679
			l-2.955-0.517c0.34-1.947,0.689-3.912,1.047-5.905l2.953,0.529C824.727,894.775,824.379,896.736,824.039,898.679z M684.681,891.451
			l-2.988-0.256c0.178-2.081,0.367-4.082,0.563-6l2.984,0.305C685.046,887.402,684.858,889.388,684.681,891.451z M826.154,886.897
			l-2.951-0.541c0.354-1.93,0.718-3.892,1.092-5.896l2.949,0.551C826.87,883.012,826.508,884.972,826.154,886.897z M685.896,879.56
			l-2.979-0.354c0.253-2.13,0.51-4.126,0.764-5.98l2.973,0.406C686.402,875.471,686.147,877.449,685.896,879.56z M828.35,875.127
			l-2.947-0.559c0.363-1.921,0.736-3.881,1.12-5.889l2.947,0.563C829.086,871.248,828.713,873.208,828.35,875.127z M687.522,867.723
			l-2.963-0.465c0.325-2.074,0.665-4.078,1.008-5.956l2.951,0.539C688.18,863.694,687.845,865.674,687.522,867.723z M830.596,863.345
			l-2.949-0.547c0.352-1.896,0.679-3.868,0.972-5.862l2.969,0.438C831.287,859.403,830.954,861.413,830.596,863.345z
			 M689.419,858.386l-0.258-2.988c1.984-0.171,3.972-0.381,5.907-0.623l0.373,2.977C693.467,857.998,691.44,858.212,689.419,858.386z
			 M701.435,856.872l-0.496-2.959c1.959-0.328,3.918-0.7,5.821-1.105l0.625,2.934C705.438,856.156,703.437,856.536,701.435,856.872z
			 M713.28,854.347l-0.758-2.902c1.92-0.501,3.836-1.048,5.692-1.627l0.893,2.865C717.205,853.274,715.245,853.834,713.28,854.347z
			 M832.361,851.37l-2.982-0.332c0.217-1.941,0.407-3.935,0.565-5.925l2.99,0.238C832.774,847.373,832.581,849.398,832.361,851.37z
			 M724.85,850.74l-1.029-2.818c1.853-0.677,3.704-1.405,5.503-2.167l1.17,2.762C728.648,849.298,726.749,850.046,724.85,850.74z
			 M736.017,846.012l-1.307-2.701c1.76-0.851,3.526-1.764,5.253-2.714l1.445,2.629C739.637,844.2,737.823,845.138,736.017,846.012z
			 M746.649,840.165l-1.58-2.551c1.67-1.034,3.333-2.126,4.944-3.244l1.711,2.465C750.07,837.982,748.363,839.103,746.649,840.165z
			 M833.324,839.321l-2.996-0.152c0.099-1.954,0.173-3.957,0.219-5.953l3,0.07C833.499,835.309,833.425,837.339,833.324,839.321z
			 M756.621,833.246l-1.836-2.373c1.553-1.201,3.097-2.459,4.588-3.737l1.953,2.277C759.797,830.725,758.214,832.014,756.621,833.246
			z M830.612,827.254c-0.003-1.961-0.029-3.968-0.078-5.965l3-0.072c0.049,2.019,0.075,4.049,0.078,6.033L830.612,827.254z
			 M765.834,825.349l-2.064-2.178c1.415-1.341,2.828-2.747,4.199-4.178l2.166,2.074C768.73,822.534,767.284,823.974,765.834,825.349z
			 M774.228,816.586l-2.263-1.971c1.29-1.482,2.565-3.016,3.791-4.559l2.35,1.865C776.852,813.5,775.547,815.069,774.228,816.586z
			 M830.324,815.324c-0.092-1.989-0.204-3.978-0.337-5.963l2.994-0.199c0.133,2.005,0.247,4.015,0.339,6.025L830.324,815.324z
			 M781.771,807.092l-2.428-1.762c1.149-1.585,2.288-3.227,3.385-4.879l2.5,1.658C784.108,803.797,782.945,805.474,781.771,807.092z
			 M829.536,803.401c-0.171-1.991-0.359-3.978-0.564-5.955l2.984-0.309c0.207,1.995,0.397,3.999,0.568,6.008L829.536,803.401z
			 M788.476,796.992l-2.564-1.557c1.02-1.68,2.024-3.409,2.986-5.141l2.623,1.457C790.54,793.518,789.516,795.28,788.476,796.992z
			 M828.303,791.508c-0.241-1.988-0.498-3.966-0.768-5.929l2.973-0.408c0.271,1.979,0.53,3.972,0.773,5.976L828.303,791.508z
			 M794.365,786.402l-2.674-1.361c0.887-1.742,1.763-3.543,2.604-5.352l2.721,1.266C796.159,782.796,795.268,784.628,794.365,786.402
			z M826.67,779.664c-0.306-1.985-0.625-3.954-0.955-5.902l2.957-0.502c0.334,1.964,0.655,3.947,0.963,5.947L826.67,779.664z
			 M799.477,775.423l-2.762-1.174c0.765-1.799,1.519-3.656,2.241-5.521l2.797,1.084C801.02,771.706,800.253,773.594,799.477,775.423z
			 M824.67,767.872c-0.367-1.982-0.745-3.941-1.131-5.873l2.941-0.588c0.39,1.945,0.77,3.918,1.139,5.916L824.67,767.872z
			 M803.85,764.13l-2.83-0.996c0.65-1.848,1.288-3.751,1.894-5.655l2.859,0.91C805.157,760.321,804.51,762.254,803.85,764.13z
			 M822.321,756.143c-0.426-1.98-0.859-3.928-1.301-5.836l2.924-0.676c0.443,1.924,0.881,3.885,1.311,5.881L822.321,756.143z
			 M807.523,752.594l-2.885-0.826c0.543-1.896,1.068-3.835,1.561-5.762l2.906,0.742C808.606,748.703,808.073,750.669,807.523,752.594
			z M819.635,744.487c-0.487-1.983-0.979-3.917-1.471-5.797l2.902-0.76c0.496,1.894,0.991,3.843,1.482,5.84L819.635,744.487z
			 M810.521,740.857l-2.928-0.658c0.435-1.93,0.848-3.896,1.229-5.847l2.945,0.574C811.38,736.905,810.961,738.9,810.521,740.857z
			 M816.602,732.918c-0.557-1.991-1.109-3.907-1.657-5.742l2.875-0.857c0.552,1.851,1.11,3.784,1.671,5.793L816.602,732.918z
			 M812.841,728.955l-2.961-0.486c0.331-2.017,0.625-4.005,0.873-5.911l2.975,0.387C813.476,724.884,813.177,726.906,812.841,728.955
			z M813.182,721.469c-0.547-1.708-1.083-3.32-1.603-4.832l-0.184-0.015c0.038-0.46,0.053-0.705,0.054-0.714l0.462-7.511l2.453,7.114
			c0.543,1.573,1.103,3.256,1.675,5.043L813.182,721.469z"/>
		<path fill="#0071BC" d="M351.104,1162h-6v-4h6V1162z M339.104,1162h-6v-4h6V1162z M327.104,1162h-6v-4h6V1162z M315.104,1162h-6v-4
			h6V1162z M303.104,1162h-6v-4h6V1162z M291.104,1162h-6v-4h6V1162z M279.104,1162h-1.32c-1.661,0-3.332-0.129-4.966-0.383
			l0.615-3.952c1.432,0.223,2.896,0.335,4.352,0.335h1.32V1162z M355.214,1161.738l-0.51-3.968c1.849-0.237,3.681-0.662,5.446-1.261
			l1.286,3.788C359.42,1160.982,357.327,1161.467,355.214,1161.738z M266.636,1160.008c-1.992-0.737-3.923-1.679-5.739-2.799
			l2.1-3.404c1.591,0.981,3.283,1.806,5.027,2.451L266.636,1160.008z M367.249,1157.655l-2.008-3.46
			c1.612-0.935,3.14-2.036,4.542-3.27l2.644,3.002C370.829,1155.334,369.086,1156.588,367.249,1157.655z M255.819,1153.347
			c-1.56-1.448-2.983-3.055-4.23-4.775l3.239-2.348c1.094,1.51,2.343,2.92,3.713,4.191L255.819,1153.347z M376.783,1149.266
			l-3.176-2.433c1.138-1.484,2.131-3.084,2.953-4.755l3.589,1.766C379.212,1145.749,378.08,1147.573,376.783,1149.266z
			 M248.368,1143.061c-0.89-1.939-1.587-3.972-2.073-6.041l3.895-0.914c0.425,1.811,1.036,3.589,1.814,5.287L248.368,1143.061z
			 M382.385,1137.86l-3.868-1.02c0.475-1.8,0.771-3.657,0.879-5.52l3.993,0.233C383.265,1133.682,382.927,1135.804,382.385,1137.86z
			 M245.461,1130.69c-0.011-0.341-0.016-0.684-0.017-1.027v-5.036h4v5.033c0,0.3,0.005,0.602,0.015,0.902L245.461,1130.69z
			 M383.444,1125.439h-4v-6h4V1125.439z M249.444,1118.627h-4v-6h4V1118.627z M383.444,1113.439h-4v-6h4V1113.439z M249.444,1106.627
			h-4v-6h4V1106.627z M383.444,1101.439h-4v-6h4V1101.439z M249.444,1094.627h-4v-6h4V1094.627z M383.444,1089.439h-4v-6h4V1089.439z
			 M249.444,1082.627h-4v-6h4V1082.627z M383.444,1077.439h-4v-6h4V1077.439z M249.444,1070.627h-4v-6h4V1070.627z M383.444,1065.439
			h-4v-6h4V1065.439z M249.444,1058.627h-4v-6h4V1058.627z M383.444,1053.439h-4v-6h4V1053.439z M249.444,1046.627h-4v-6h4V1046.627z
			 M383.444,1041.439h-4v-6h4V1041.439z M249.444,1034.627h-4v-6h4V1034.627z M383.444,1029.439h-4v-6h4V1029.439z M249.444,1022.627
			h-4v-6h4V1022.627z M383.444,1017.439h-4v-6h4V1017.439z M249.444,1010.627h-4v-6h4V1010.627z M383.444,1005.439h-4v-6h4V1005.439z
			 M249.444,998.627h-4v-6h4V998.627z M383.444,993.439h-4v-6h4V993.439z M249.444,986.627h-4v-6h4V986.627z M383.444,981.439h-4v-6
			h4V981.439z M249.444,974.627h-4v-6h4V974.627z M383.444,969.439h-4v-6h4V969.439z M249.444,962.627h-4v-6h4V962.627z
			 M383.444,957.439h-4v-6h4V957.439z M249.444,950.627h-4v-6h4V950.627z M383.444,945.439h-4v-6h4V945.439z M249.444,938.627h-4v-6
			h4V938.627z M383.444,933.439h-4v-6h4V933.439z M249.444,926.627h-4v-5.287c0-0.253,0.003-0.506,0.009-0.758l3.999,0.091
			c-0.005,0.222-0.008,0.444-0.008,0.667V926.627z M379.444,921.439c0-1.939-0.18-3.78-0.534-5.573l3.924-0.775
			c0.405,2.047,0.61,4.149,0.61,6.249L379.444,921.439z M250.135,915.125l-3.901-0.882c0.469-2.075,1.149-4.113,2.022-6.059
			l3.649,1.638C251.142,911.525,250.546,913.31,250.135,915.125z M377.284,910.52c-0.716-1.718-1.608-3.375-2.652-4.926l3.318-2.233
			c1.191,1.77,2.209,3.661,3.026,5.621L377.284,910.52z M254.689,904.968l-3.258-2.321c1.232-1.729,2.643-3.348,4.191-4.81
			l2.746,2.909C257.008,902.03,255.77,903.45,254.689,904.968z M371.067,901.276c-1.325-1.318-2.783-2.51-4.335-3.544l2.217-3.329
			c1.769,1.178,3.43,2.536,4.939,4.037L371.067,901.276z M262.794,897.32l-2.127-3.388c1.805-1.133,3.727-2.091,5.714-2.845
			l1.42,3.739C266.061,895.487,264.376,896.326,262.794,897.32z M361.792,895.105c-1.724-0.709-3.526-1.247-5.356-1.599l0.756-3.928
			c2.091,0.402,4.15,1.017,6.121,1.826L361.792,895.105z M273.199,893.373l-0.649-3.947c1.717-0.283,3.476-0.426,5.226-0.426
			c0.003,0,0.006,0,0.009,0h1.066v4h-1.067c-0.003,0-0.006,0-0.008,0C276.242,893,274.703,893.125,273.199,893.373z M350.851,893h-6
			v-4h6V893z M338.851,893h-6v-4h6V893z M326.851,893h-6v-4h6V893z M314.851,893h-6v-4h6V893z M302.851,893h-6v-4h6V893z
			 M290.851,893h-6v-4h6V893z"/>
	</g>
	<path id="X" d="M230.6,186.6h-0.2c-2.6,0-4.9,1-6.7,2.6c-0.8-4.7-4.9-8.3-9.8-8.3h-0.2c-2.7,0-5.1,1.1-6.9,2.8
	c-1.2-4.8-5.5-8.4-10.7-8.4h-0.1c-2.5,0-4.8,0.9-6.6,2.5v-23.3c0-5.7-4.7-10.4-10.4-10.4h-0.2c-5.8,0-10.4,4.7-10.4,10.4V205
	c0,0.2-0.3,0.3-0.4,0.2l-4-4.7c-2.4-2.7-5.8-4.3-9.4-4.3c-3,0-5.8,1.1-8.1,3l-0.2,0.1c-5.2,4.5-5.7,12.3-1.3,17.5l9.6,11.1
	c0.4,0.5,10.9,13.2,18.6,24.9c0.1,0.1,0.3,0.6,0.8,1.2l0,0c0.9,1.3,1.4,2.8,1.4,4.4v2.3c0,4.2,3.4,7.6,7.6,7.6h44.7
	c4.2,0,7.6-3.4,7.6-7.6v-0.6c0-1.7,0.5-3.3,1.5-4.7c2.3-3.4,3.6-7.3,3.6-11.4v-47.5C240.5,191.1,236.1,186.6,230.6,186.6z
	 M237.2,244.1c0,3.4-1,6.7-3,9.5c-1.4,2-2.1,4.2-2.1,6.6v0.6c0,2.4-1.9,4.3-4.3,4.3h-44.7c-2.4,0-4.3-1.9-4.3-4.3v-2.3
	c0-2.3-0.7-4.5-2-6.4l0,0c-0.4-0.6-0.7-1-0.7-1l-0.1-0.1c-7.9-12-18.8-25.1-18.9-25.3l-9.6-11.2c-3.3-3.8-2.8-9.5,0.9-12.7l0.2-0.1
	c1.6-1.4,3.7-2.2,5.9-2.2c2.6,0,5.1,1.1,6.8,3.1l7.3,8.4c1.1,1.2,3.1,0.5,3.1-1.1v-55.3c0-3.9,3.2-7.1,7.1-7.1h0.2
	c3.9,0,7.1,3.2,7.1,7.1l0,46.2c0,0.9,0.7,1.6,1.6,1.6h0c0.9,0,1.6-0.7,1.6-1.6l0-15.6h0c0-3.6,2.9-6.6,6.6-6.6h0.1
	c4.2,0,7.7,3.4,7.7,7.7l0,20.5c0,0.9,0.7,1.6,1.6,1.6h0c0.9,0,1.6-0.7,1.6-1.6l0-16h0c0-3.6,2.9-6.6,6.6-6.6h0.2
	c3.6,0,6.6,2.9,6.6,6.6l0,21.3c0,0.9,0.7,1.6,1.6,1.6h0c0.9,0,1.6-0.7,1.6-1.6l0-16c0.2-3.5,3.1-6.2,6.6-6.2h0.2
	c3.6,0,6.6,2.9,6.6,6.6V244.1z M178.7,136.8c-1,0-1.9-0.8-1.9-1.9v-16.4c0-1,0.8-1.9,1.9-1.9h0.1c1,0,1.9,0.8,1.9,1.9v16.4
	C180.7,135.9,179.8,136.8,178.7,136.8L178.7,136.8z M191.6,142.1c-0.7-0.7-0.7-1.9,0-2.6l11.6-11.6c0.7-0.7,1.9-0.7,2.6,0l0,0
	c0.7,0.7,0.7,1.9,0,2.6l-11.6,11.6C193.5,142.8,192.3,142.8,191.6,142.1L191.6,142.1z M196.9,154.9c0-1,0.8-1.9,1.9-1.9h16.4
	c1,0,1.9,0.8,1.9,1.9v0.1c0,1-0.8,1.9-1.9,1.9h-16.4C197.7,156.8,196.9,155.9,196.9,154.9L196.9,154.9z M165.9,142.1
	c-0.7,0.7-1.9,0.7-2.6,0l-11.6-11.6c-0.7-0.7-0.7-1.9,0-2.6l0,0c0.7-0.7,1.9-0.7,2.6,0l11.6,11.6
	C166.7,140.2,166.7,141.3,165.9,142.1L165.9,142.1z M160.7,154.9c0,1-0.8,1.9-1.9,1.9h-16.4c-1,0-1.9-0.8-1.9-1.9v-0.1
	c0-1,0.8-1.9,1.9-1.9h16.4C159.8,153,160.7,153.8,160.7,154.9L160.7,154.9z M155.5,304.4h-12.8v-6.5h32.8v6.5h-12.8v41.8h-7.1V304.4
	z M203.2,335.5h-17.8l-3.6,10.8h-7.5l17-48.3h5.9l17,48.3h-7.5L203.2,335.5z M201.2,329l-6.8-21.1h-0.1l-6.8,21.1H201.2z
	 M220.6,297.9h17c1.8,0,3.6,0.2,5.3,0.5c1.7,0.5,3.5,1.4,5.2,2.8c1.5,1.1,2.7,2.6,3.6,4.6c1,1.9,1.4,4.2,1.4,6.9
	c0,3.6-1.2,6.8-3.7,9.8c-2.5,3.1-6.2,4.7-11,4.8h-10.8v18.9h-7.1V297.9z M227.7,320.5h10.5c2.6,0,4.6-0.9,6-2.5
	c1.3-1.6,1.9-3.4,1.9-5.3c0-1.6-0.3-3-0.8-4.1c-0.5-1-1.3-1.8-2.3-2.4c-1.5-1-3.2-1.4-5.1-1.4h-10.3V320.5z"/>
</svg>
</template>

<style>

#parts_arr0 {
	fill: #C1272D;
}
#parts_arr1 {
	fill: #8CC63F;
}
#parts_arr2 {
	fill: #8CC63F;
}
#parts_arr3 {
	fill: #F7931E;
}
#parts_arr4 {
	fill: #FFFF00;
}
#parts_arr5 {
	fill: #39B54A;
}
#parts_arr6 {
	fill: #39B54A;
}
#parts_arr7 {
	fill: #29ABE2;
}

#parts_arr0,
#parts_arr1,
#parts_arr2,
#parts_arr3,
#parts_arr4,
#parts_arr5,
#parts_arr6,
#parts_arr7 {
	opacity: 0;
  transition-duration: 200ms;
  cursor: pointer;
}
#parts_arr0:hover,
#parts_arr1:hover,
#parts_arr2:hover,
#parts_arr3:hover,
#parts_arr4:hover,
#parts_arr5:hover,
#parts_arr6:hover,
#parts_arr7:hover {
	opacity: 0.5;
}
#Wire,#X{
	animation-name: flash;
	animation-duration: 1.0s;
	animation-iteration-count: 1;
	animation-direction: alternate;
	animation-play-state: running;
}
@keyframes flash {
	50%{
		opacity: 0.05;
	}
}
</style>

<style scoped>
svg{
	position: relative;
}
</style>

<script>
export default {
	props: {
		parts_list : Array,
		parts: Array,
  },
	methods: {
		parts0 () {
			this.$emit('parts0')
		},
		parts1 () {
			this.$emit('parts1')
		},
		parts2 () {
			this.$emit('parts2')
		},
		parts3 () {
			this.$emit('parts3')
		},
		parts4 () {
			this.$emit('parts4')
		},
		parts5 () {
			this.$emit('parts5')
		},
		parts6 () {
			this.$emit('parts6')
		},
		parts7 () {
			this.$emit('parts7')
		},

		//エリア選択設定
		setFigureSelectedFigure () {
			var figureSelected_arr = {};
			var parts = this.parts;

			for(var l in this.parts_list) {
				var parts_list = this.$i18n.t('data.parts_list.'+this.parts_list[l]);
				for(var i in parts) {
					for(var ii in parts_list) {
						if(parts_list[ii]['code'] == parts[i]){
							figureSelected_arr[this.parts_list[l]] = true;
							if(figureSelected_arr[this.parts_list[l]] == true){
								document.getElementById([this.parts_list[l]]).style.opacity="0.5";
							}
						}
					}
				}if(figureSelected_arr[this.parts_list[l]] === undefined || figureSelected_arr[this.parts_list[l]].length == 0){
					document.getElementById([this.parts_list[l]]).style.opacity="0";
				}
			}
		}
	},

	watch: {
		parts: function(){
			this.setFigureSelectedFigure();
		},
	},

	mounted: function(){
		this.setFigureSelectedFigure();
	},

}
</script>
