<template>
<div class="main_div">
	<h2 class="text-xs-center headline mt-5">
		{{ $t('pages.MainComfirm.text001') }}
		<p class="caption_ja">{{ $t('pages.MainComfirm.text001', 'ja') }}</p>
	</h2>

	<!--基礎情報、主訴-->
	<div class="interview_wrapper pa-3">

		<v-layout row wrap>
			<v-flex xs12>
				<h2 class="comfirm_tittle mt-4 mb-2">{{ $t('pages.MainComfirm.text002') }}<p class="caption_ja">{{ $t('pages.MainComfirm.text002', 'ja') }}</p></h2>
			</v-flex>

			<v-flex xs8 sm6>
				<p class="subheading mb-0">{{ $t('pages.Interview001.gender') }}：{{ searchTranslation('gender', $store.state.mmq.hospital.Interview001.sex, 'foreign') }}</p>
				<p class="subheading mb-0">{{ $t('pages.Interview001.age') }}：{{ searchTranslation('age', $store.state.mmq.hospital.Interview001.age, 'foreign') }}</p>
				<p class="subheading mb-0" v-if="$store.state.mmq.hospital.Interview001.sex == '女性'">{{ $t('pages.MainComfirm.text003') }}：
					<span class="subheading" v-if="$store.state.mmq.hospital.Interview001.pregnant_flag">{{ $t('common.yes') }}</span>
					<span class="subheading" v-else="$store.state.mmq.hospital.Interview001.pregnant_flag">{{ $t('common.no') }}</span>
				</p>
				<p class="subheading mb-0" v-if="$store.state.mmq.hospital.Interview001.sex == '女性'">{{ $t('pages.MainComfirm.text004') }}：
					<span class="subheading" v-if="$store.state.mmq.hospital.Interview001.breast_feeding_flag">{{ $t('common.yes') }}</span>
					<span class="subheading" v-else="$store.state.mmq.hospital.Interview001.breast_feeding_flag">{{ $t('common.no') }}</span>
				</p>
				<p class="caption_ja mt-2">{{ $t('pages.Interview001.gender', 'ja') }}：{{ searchTranslation('gender', $store.state.mmq.hospital.Interview001.sex, 'ja') }}</p>
				<p class="caption_ja mb-0">{{ $t('pages.Interview001.age', 'ja') }}：{{ searchTranslation('age', $store.state.mmq.hospital.Interview001.age, 'ja') }}</p>
				<p class="caption_ja mb-0" v-if="$store.state.mmq.hospital.Interview001.sex == '女性'">{{ $t('pages.MainComfirm.text003', 'ja') }}：
					<span v-if="$store.state.mmq.hospital.Interview001.pregnant_flag">{{ $t('common.yes', 'ja') }}</span>
					<span v-else="$store.state.mmq.hospital.Interview001.pregnant_flag">{{ $t('common.no', 'ja') }}</span>
				</p>
				<p class="caption_ja mb-0" v-if="$store.state.mmq.hospital.Interview001.sex == '女性'">{{ $t('pages.MainComfirm.text004', 'ja') }}：
					<span v-if="$store.state.mmq.hospital.Interview001.breast_feeding_flag">{{ $t('common.yes', 'ja') }}</span>
					<span v-else="$store.state.mmq.hospital.Interview001.breast_feeding_flag">{{ $t('common.no', 'ja') }}</span>
				</p>
			</v-flex>
			<v-flex xs4 sm4>
				<div class="genderbox" v-if="$store.state.mmq.hospital.Interview001.sex == '男性'"><img src="/img/male.svg"></div>
				<div class="genderbox" v-if="$store.state.mmq.hospital.Interview001.sex == '女性'"><img src="/img/female.svg"></div>
			</v-flex>

			<v-flex xs12>
				<h2 class="comfirm_tittle mt-4 mb-2">{{ $t('pages.MainComfirm.text005') }}<p class="caption_ja">{{ $t('pages.MainComfirm.text005', 'ja') }}</p></h2>
			</v-flex>

			<v-flex xs8 sm6>
				<p class="subheading mb-0" v-for="(ind, key) in $store.state.mmq.hospital.Interview002.injury" :key="'injury'+key">{{ searchTranslation('injury', ind, 'foreign') }}</p>
				<p class="subheading mb-0" v-for="(ind, key) in $store.state.mmq.hospital.Interview002.disease" :key="'disease'+key">{{ searchTranslation('symptoms', ind, 'foreign') }}</p>
			</v-flex>
			<v-flex xs8 sm6>
				<p class="caption_ja mb-0" v-for="(ind, key) in $store.state.mmq.hospital.Interview002.injury" :key="'injury'+key">{{ searchTranslation('injury', ind, 'ja') }}</p>
				<p class="caption_ja mb-0" v-for="(ind, key) in $store.state.mmq.hospital.Interview002.disease" :key="'disease'+key">{{ searchTranslation('symptoms', ind, 'ja') }}</p>
			</v-flex>

		</v-layout>

		<!--痛みの場所-->
		<v-flex xs12>
			<h2 class="comfirm_tittle mt-4 mb-2">{{ $t('pages.Interview003_1.point') }}<p class="caption_ja">{{ $t('pages.Interview003_1.point', 'ja') }}</p></h2>
		</v-flex>

		<v-flex xs12 sm6 order-xs2 order-sm1>
			<div class="partsbox mb-3" v-for="(ind, key) in $store.state.mmq.hospital.Interview003.pain" :key="'pain'+key">
				<p class="title pain_point">{{ searchTranslationPartsList(ind.point, 'foreign') }}
					<span class="caption_ja">{{ searchTranslationPartsList(ind.point, 'ja') }}</span>
				</p>
				<v-layout row wrap align-center mb-2>
					<v-flex xs2 offset-xs1>
						<img :src="'/img/face_scale/face0'+ind.verbal_rating_scale+'_selected.png'" class="face_scale">
					</v-flex>
					<v-flex xs8 offset-xs1 class="verbal_rating_scale display-1" :class="'verbal_rating_scale'+ind.verbal_rating_scale">{{ ind.verbal_rating_scale }} / 5</v-flex>
				</v-layout>
				<p class="subheading">{{ searchTranslation('kind_of_pain_arr', ind.kind_of_pain, 'foreign') }}
					<span class="caption_ja">{{ searchTranslation('kind_of_pain_arr', ind.kind_of_pain, 'ja') }}</span>
				</p>
			</div>
		</v-flex>

		<v-flex xs12 sm6 order-xs1 order-sm2 id="figurebox">
			<img src="/img/figure.png" class="base" />
			<img src="/img/figure_selected/back.png" />
			<img v-for="(ind, key) in parts_img" :key="key" :src="'/img/figure_selected/'+ind+'.png'">
		</v-flex>

		<!--バイタル-->
		<v-flex xs12>
			<h2 class="comfirm_tittle mt-4 mb-2">{{ $t('pages.MainComfirm.text006') }}<p class="caption_ja">{{ $t('pages.MainComfirm.text006', 'ja') }}</p></h2>
		</v-flex>
		<v-layout row wrap align-center v-if="$store.state.mmq.hospital.Interview005.temperature != null">
			<v-flex xs2 sm1 pa-1>
				<img src="/img/vital_icon/1.png">
			</v-flex>
			<v-flex xs10 class="pa-1 mb-2">
				<h4 class="title mb-1">{{ $t('pages.MainComfirm.text008') }}<p class="caption_ja">{{ $t('pages.MainComfirm.text008', 'ja') }}</p></h4>
				<p class="subheading">{{ $t('data.choices.1.name') }}</p>
				<p class="subheading">{{ $t('pages.Interview005.temperature') }}：{{ $store.state.mmq.hospital.Interview005.temperature }} {{ $store.state.mmq.hospital.Interview005.temperature_unit }}</p>
				<p class="subheading">{{ $t('pages.Interview005.fever_date') }}：{{ searchTranslation('fever_date_arr', $store.state.mmq.hospital.Interview005.fever_date, 'foreign') }}</p>
				<p class="caption_ja mt-1">{{ $t('data.choices.1.name', 'ja') }}</p>
				<p class="caption_ja">{{ $t('pages.Interview005.temperature', 'ja') }}：{{ $store.state.mmq.hospital.Interview005.temperature }} {{ $store.state.mmq.hospital.Interview005.temperature_unit }}</p>
				<p class="caption_ja">{{ $t('pages.Interview005.fever_date', 'ja') }}：{{ searchTranslation('fever_date_arr', $store.state.mmq.hospital.Interview005.fever_date, 'ja') }}</p>
			</v-flex>
		</v-layout>
		<v-layout row wrap align-center v-if="$store.state.mmq.hospital.Interview005.blood_pressure_low != null">
			<v-flex xs2 sm1 pa-1>
				<img src="/img/vital_icon/2.png">
			</v-flex>
			<v-flex xs10 class="pa-1 mb-2">
				<h4 class="title mb-1">{{ $t('pages.Interview005.blood_pressure') }}<p class="caption_ja">{{ $t('pages.Interview005.blood_pressure', 'ja') }}</p></h4>
				<p class="subheading">{{ $t('pages.MainComfirm.text009_1') }}：{{ $store.state.mmq.hospital.Interview005.blood_pressure_low }}</p>
				<p class="subheading">{{ $t('pages.MainComfirm.text009_2') }}：{{ $store.state.mmq.hospital.Interview005.blood_pressure_high }}</p>
				<p class="caption_ja mt-1">{{ $t('pages.MainComfirm.text009_1', 'ja') }}：{{ $store.state.mmq.hospital.Interview005.blood_pressure_low }}</p>
				<p class="caption_ja">{{ $t('pages.MainComfirm.text009_2', 'ja') }}：{{ $store.state.mmq.hospital.Interview005.blood_pressure_high }}</p>
			</v-flex>
		</v-layout>
		<v-layout row wrap align-center v-if="$store.state.mmq.hospital.Interview005.pulse != null">
			<v-flex xs2 sm1 pa-1>
				<img src="/img/vital_icon/3.png">
			</v-flex>
			<v-flex xs10 class="pa-1 mb-2">
				<h4 class="title mb-1">{{ $t('pages.Interview005.pulse') }}<p class="caption_ja">{{ $t('pages.Interview005.pulse', 'ja') }}</p></h4>
				<p class="subheading mb-1">{{ $store.state.mmq.hospital.Interview005.pulse }} / {{ $t('pages.Interview005.minute') }}</p>
				<p class="caption_ja">{{ $store.state.mmq.hospital.Interview005.pulse }} / {{ $t('pages.Interview005.minute', 'ja') }}</p>
			</v-flex>
		</v-layout>
		<v-layout row wrap align-center v-if="$store.state.mmq.hospital.Interview005.respiratory_rate != null">
			<v-flex xs2 sm1 pa-1>
				<img src="/img/vital_icon/4.png">
			</v-flex>
			<v-flex xs10 class="pa-1 mb-2">
				<h4 class="title mb-1">{{ $t('pages.Interview005.respiratory_rate') }}<p class="caption_ja">{{ $t('pages.Interview005.respiratory_rate', 'ja') }}</p></h4>
				<p class="subheading mb-1">{{ $store.state.mmq.hospital.Interview005.respiratory_rate }} / {{ $t('pages.Interview005.minute') }}</p>
				<p class="caption_ja">{{ $store.state.mmq.hospital.Interview005.respiratory_rate }} / {{ $t('pages.Interview005.minute', 'ja') }}</p>
			</v-flex>
		</v-layout>

		<!--基礎病歴-->

		<v-flex xs12>
			<h2 class="comfirm_tittle mt-4 mb-2">{{ $t('pages.MainComfirm.text010') }}<p class="caption_ja">{{ $t('pages.MainComfirm.text010', 'ja') }}</p></h2>
		</v-flex>
		<v-flex xs12>
			<p class="title mb-0">
				{{ $t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_cerebral_vascular_disease_flg">{{ $t('data.choices.1.name') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_cerebral_vascular_disease_flg">{{ $t('data.choices.0.name') }}</span>
			</p>
			<p class="body-2">（{{ $t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg_etc') }}）</p>
			<p class="title mb-0 mb-0">
				{{ $t('pages.Interview006.basic_medical_heart_disease_flg') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_heart_disease_flg">{{ $t('data.choices.1.name') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_heart_disease_flg">{{ $t('data.choices.0.name') }}</span>
			</p>
			<p class="body-2">（{{ $t('pages.Interview006.basic_medical_heart_disease_flg_etc') }}）</p>
			<p class="title mb-0">
				{{ $t('pages.MainComfirm.text011') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_pacemaker">{{ $t('data.choices.1.name') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_pacemaker">{{ $t('data.choices.0.name') }}</span>
			</p>
			<p class="title mb-0">
				{{ $t('pages.MainComfirm.text012') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ $t('data.choices.1.name') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ $t('data.choices.0.name') }}</span>
				<p class="title mb-0" v-if="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">
					{{ $t('pages.MainComfirm.text013') }}：
					<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ searchTranslation('basic_medical_shunt_position_arr', $store.state.mmq.hospital.Interview006.basic_medical_shunt_position, 'forign') }}</span>
					<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ $t('data.choices.0.name') }}</span>
				</p>
			</p>
			<p class="title mb-0">
				{{ $t('pages.MainComfirm.text014') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_food_allergy">{{ $t('data.choices.1.name') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_food_allergy">{{ $t('data.choices.0.name') }}</span>
			</p>
			<p class="title mb-0">
				{{ $t('pages.MainComfirm.text028') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_drug_allergy">{{ $t('data.choices.1.name') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_drug_allergy">{{ $t('data.choices.0.name') }}</span>
				<p class="subheading" v-if="$store.state.mmq.hospital.Interview006.basic_medical_drug_allergy">
					<span v-for="(ind, key) in $store.state.mmq.hospital.Interview006.basic_medical_drug_name">
						{{ searchTranslation('basic_medical_drug_name_arr', ind, 'foreign') }}&emsp;
					</span>
				</p>
			</p>
		</v-flex>
		<v-flex xs12 mt-4>
			<p class="caption_ja">{{ $t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg', 'ja') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_cerebral_vascular_disease_flg">{{ $t('data.choices.1.name', 'ja') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_cerebral_vascular_disease_flg">{{ $t('data.choices.0.name', 'ja') }}</span>
			</p>
			<p class="caption_ja">（{{ $t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg_etc', 'ja') }}）</p>
			<p class="caption_ja">{{ $t('pages.Interview006.basic_medical_heart_disease_flg', 'ja') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_heart_disease_flg">{{ $t('data.choices.1.name', 'ja') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_heart_disease_flg">{{ $t('data.choices.0.name', 'ja') }}</span>
			</p>
			<p class="caption_ja">（{{ $t('pages.Interview006.basic_medical_heart_disease_flg_etc', 'ja') }}）</p>
			<p class="caption_ja">{{ $t('pages.MainComfirm.text011', 'ja') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_pacemaker">{{ $t('data.choices.1.name', 'ja') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_pacemaker">{{ $t('data.choices.0.name', 'ja') }}</span>
			</p>
			<p class="caption_ja">{{ $t('pages.MainComfirm.text012', 'ja') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ $t('data.choices.1.name', 'ja') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ $t('data.choices.0.name', 'ja') }}</span>
				<p class="caption_ja mb-0" v-if="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">
					{{ $t('pages.MainComfirm.text013', 'ja') }}：
					<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ searchTranslation('basic_medical_shunt_position_arr', $store.state.mmq.hospital.Interview006.basic_medical_shunt_position, 'ja') }}</span>
					<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_dialysis">{{ $t('data.choices.0.name', 'ja') }}</span>
				</p>
			</p>
			<p class="caption_ja">{{ $t('pages.MainComfirm.text014', 'ja') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_food_allergy">{{ $t('data.choices.1.name', 'ja') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_food_allergy">{{ $t('data.choices.0.name', 'ja') }}</span>
			</p>
			<p class="caption_ja">{{ $t('pages.MainComfirm.text028', 'ja') }}：
				<span v-if="$store.state.mmq.hospital.Interview006.basic_medical_drug_allergy">{{ $t('data.choices.1.name', 'ja') }}</span>
				<span v-else="$store.state.mmq.hospital.Interview006.basic_medical_drug_allergy">{{ $t('data.choices.0.name', 'ja') }}</span>
				<p class="caption_ja" v-if="$store.state.mmq.hospital.Interview006.basic_medical_drug_allergy">
					<span v-for="(ind, key) in $store.state.mmq.hospital.Interview006.basic_medical_drug_name">
						{{ searchTranslation('basic_medical_drug_name_arr', ind, 'ja') }}&emsp;
					</span>
				</p>
			</p>
		</v-flex>

	<!--付添い人-->
		<v-flex xs12>
			<h2 class="comfirm_tittle mt-4 mb-2">{{ $t('pages.MainComfirm.text016') }}<p class="caption_ja">{{ $t('pages.MainComfirm.text016', 'ja') }}</p></h2>
		</v-flex>
		<div v-if="$store.state.mmq.hospital.Interview007.attendant">
			<p class="subheading mb-0">{{ searchTranslation('connection_arr', $store.state.mmq.hospital.Interview007.connection, 'foreign') }}</p>
			<p class="subheading">{{ $t('pages.Interview007.telephone_number') }}：{{ $store.state.mmq.hospital.Interview007.telephone_number }}</p>
		</div>
		<div v-if="$store.state.mmq.hospital.Interview007.attendant" class="mb-2">
			<p class="caption_ja mb-0">{{ searchTranslation('connection_arr', $store.state.mmq.hospital.Interview007.connection, 'ja') }}</p>
			<p class="caption_ja">{{ $t('pages.Interview007.telephone_number', 'ja') }}：{{ $store.state.mmq.hospital.Interview007.telephone_number }}</p>
		</div>
		<div v-if="$store.state.mmq.hospital.Interview007.attendant_jp" class="mb-2">
			<h3 class="title mb-1">{{ $t('pages.MainComfirm.text017') }}</h3>
			<p class="subheading mb-0">{{ searchTranslation('connection_arr', $store.state.mmq.hospital.Interview007.connection_jp, 'foreign') }}</p>
			<p class="subheading">{{ $t('pages.Interview007.telephone_number') }}：{{ $store.state.mmq.hospital.Interview007.telephone_number_jp }}</p>
		</div>
		<div v-if="$store.state.mmq.hospital.Interview007.attendant_jp" class="mb-2">
			<h3 class="caption_ja mb-0">{{ $t('pages.MainComfirm.text017', 'ja') }}</h3>
			<p class="caption_ja mb-0">{{ searchTranslation('connection_arr', $store.state.mmq.hospital.Interview007.connection_jp, 'ja') }}</p>
			<p class="caption_ja">{{ $t('pages.Interview007.telephone_number', 'ja') }}：{{ $store.state.mmq.hospital.Interview007.telephone_number_jp }}</p>
		</div>

	<!--支払情報-->
		<v-flex xs12>
			<h2 class="comfirm_tittle mt-4 mb-2">{{ $t('pages.MainComfirm.text018') }}<p class="caption_ja">{{ $t('pages.MainComfirm.text018', 'ja') }}</p></h2>
		</v-flex>

		<h3 class="title mb-2">{{ $t('pages.MainComfirm.text019') }}</h3>
		<p class="subheading" v-if="$store.state.mmq.hospital.Interview008.japanese_insurance">{{ $t('common.yes') }}</p>
		<p class="subheading" v-else="$store.state.mmq.hospital.Interview008.japanese_insurance">{{ $t('common.no') }}</p>

		<h3 class="title mb-2 mt-4">{{ $t('pages.MainComfirm.text020') }}</h3>
		<p class="subheading mb-0" v-if="$store.state.mmq.hospital.Interview008.travel_insurance">{{ $t('common.yes') }}</p>
		<p class="subheading mb-0" v-else="$store.state.mmq.hospital.Interview008.travel_insurance">{{ $t('common.no') }}</p>
		<div v-if="off">
		<!--<div v-if="$store.state.mmq.hospital.Interview008.travel_insurance">-->
			<span class="subheading mb-0">{{ $t('pages.MainComfirm.text021') }}：{{ returnNationName($store.state.mmq.hospital.Interview008.travel_insurance_country) }}</span>
			<span class="subheading mb-0">{{ $t('pages.MainComfirm.text022') }}：{{ $store.state.mmq.hospital.Interview008.travel_insurance_company }}</span>
			<span class="subheading mb-0">{{ $t('pages.Interview007.telephone_number') }}：{{ $store.state.mmq.hospital.Interview008.travel_insurance_contact_info }}</span>
			<span class="subheading mb-0" v-if="$store.state.mmq.hospital.Interview008.medical_certificate">{{ $t('pages.MainComfirm.text023') }}：{{ $t('pages.MainComfirm.text024_1') }}</span>
		</div>

		<h3 class="title mb-2 mt-4">{{ $t('pages.MainComfirm.text025') }}</h3>
		<v-layout row wrap v-if="$store.state.mmq.hospital.Interview008.credit_card">
			<v-flex v-for="(ind, key) in $store.state.mmq.hospital.Interview008.credit_card_brand" :key="'credit_card_brand'+key" xs4  sm3 pa-1>
				<div :id="'credit_card_brand'+key" class="credit_card">
					<img :src="'/img/credit_card/'+credit_card_brand_img[ind]" class="card_img">
				</div>
			</v-flex>
		</v-layout>

		<h3 class="title mb-2 mt-4">{{ $t('pages.MainComfirm.text026') }}</h3>
		<p class="subheading" v-if="!$store.state.mmq.hospital.Interview008.japanese_yen">{{ $t('common.no') }}</p>
		<div v-if="$store.state.mmq.hospital.Interview008.japanese_yen">
			<p class="subheading">{{ searchTranslation('possession_amount_arr', $store.state.mmq.hospital.Interview008.possession_amount, 'foreign') }}</p>
		</div>

		<h3 class="title mb-2">{{ $t('pages.Interview008.medical_certificate') }}</h3>
		<p class="subheading" v-if="$store.state.mmq.hospital.Interview008.medical_certificate">{{ $t('common.yes') }}</p>
		<p class="subheading" v-else="$store.state.mmq.hospital.Interview008.medical_certificate">{{ $t('common.no') }}</p>

		<div class="mt-4">
			<h3 class="caption_ja">{{ $t('pages.MainComfirm.text019', 'ja') }}</h3>
			<p class="caption_ja mb-1" v-if="$store.state.mmq.hospital.Interview008.japanese_insurance">{{ $t('common.yes', 'ja') }}</p>
			<p class="caption_ja mb-1" v-else="$store.state.mmq.hospital.Interview008.japanese_insurance">{{ $t('common.no', 'ja') }}</p>
			<h3 class="caption_ja">{{ $t('pages.MainComfirm.text020', 'ja') }}</h3>
			<p class="caption_ja mb-1" v-if="$store.state.mmq.hospital.Interview008.travel_insurance">{{ $t('common.yes', 'ja') }}</p>
			<p class="caption_ja mb-1" v-else="$store.state.mmq.hospital.Interview008.travel_insurance">{{ $t('common.no', 'ja') }}</p>
			<div v-if="off">
				<span class="subheading mb-0">{{ $t('pages.MainComfirm.text021', 'ja') }}：{{ returnNationName($store.state.mmq.hospital.Interview008.travel_insurance_country) }}</span>
				<span class="caption_ja mb-1">{{ $t('pages.MainComfirm.text022', 'ja') }}：{{ $store.state.mmq.hospital.Interview008.travel_insurance_company }}</span>
				<span class="caption_ja mb-1">{{ $t('pages.Interview007.telephone_number', 'ja') }}：{{ $store.state.mmq.hospital.Interview008.travel_insurance_contact_info }}</span>
				<span class="caption_ja mb-1" v-if="$store.state.mmq.hospital.Interview008.medical_certificate">{{ $t('pages.MainComfirm.text023', 'ja') }}：{{ $t('pages.MainComfirm.text024_1', 'ja') }}</span>
			</div>
			<h3 class="caption_ja">{{ $t('pages.MainComfirm.text026', 'ja') }}</h3>
			<p class="caption_ja mb-1" v-if="!$store.state.mmq.hospital.Interview008.japanese_yen">{{ $t('common.no', 'ja') }}</p>
			<div v-if="$store.state.mmq.hospital.Interview008.japanese_yen">
				<p class="caption_ja mb-1">{{ searchTranslation('possession_amount_arr', $store.state.mmq.hospital.Interview008.possession_amount, 'ja') }}</p>
			</div>
			<h3 class="caption_ja">{{ $t('pages.Interview008.medical_certificate', 'ja') }}</h3>
			<p class="caption_ja mb-1" v-if="$store.state.mmq.hospital.Interview008.medical_certificate">{{ $t('common.yes', 'ja') }}</p>
			<p class="caption_ja mb-1" v-else="$store.state.mmq.hospital.Interview008.japanese_insurance">{{ $t('common.no', 'ja') }}</p>
		</div>

	</div>

	<!--免責-->
	<div class="agree_box">
		<v-form v-model="valid" ref="form" lazy-validation>
			<v-container fluid class="pa-3">
					<h2 class="text-xs-center headline mt-2">
						{{ $t('pages.MainComfirm.text027') }}
						<p class="text-xs-center caption_ja mb-3">{{ $t('pages.MainComfirm.text027', 'ja') }}</p>
					</h2>
				<v-layout row wrap justify-center>
					<v-flex xs10>
						<v-checkbox v-model="agree" :label="$t('common.agree')" class="agree_area" :rules="[rules.must]"></v-checkbox>
						<span class="caption_radio">{{ $t('common.agree', 'ja') }}</span>
					</v-flex>
				</v-layout>
			</v-container fluid>
		</v-form>

		<div class="text-xs-center ma-3">
			<v-btn to="/mmq/Interview008" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="body-1">({{ $t('common.back', 'ja') }})</span></v-btn>
			<v-btn @click="store_main" round color="primary" large>{{ $t('common.finish') }}&nbsp;<span class="body-1">({{ $t('common.finish', 'ja') }})</span></v-btn>
		</div>
	</div>



</div>
</template>



<style scoped>
h3{font-weight:bold;}
h4{font-weight:bold;}
.genderbox{
	text-align:center;
	margin-top:20px; padding:10px; background-color:#eaeaea; border-radius:12px;}
.maxbox{border:2px solid #AAA;border-radius:12px;padding:20px 28px;}
img{max-width:100%;height:auto;}

.partsbox{border:2px solid #AAA;border-radius:10px;background:#FFF;padding:20px 20px 5px 20px;}
.pain_point{font-weight: bold;}
.face_scale{display:inline-block;}
.verbal_rating_scale{font-weight: bold;}
.verbal_rating_scale1{color:#008000;}
.verbal_rating_scale2{color:#00ff00;}
.verbal_rating_scale3{color:#ffd700;}
.verbal_rating_scale4{color:#ff8c00;}
.verbal_rating_scale5{color:#ff0000;}
#figurebox{position:relative;min-height:500px;}
#figurebox img{position:absolute;display:block;top:0;}
#figurebox img.base{position:relative;display:block;}

.credit_card img{width:100%;height:auto;}
.credit_card{border: 2px solid #BBB;border-radius: 8px;padding:2px;}

.comfirm_tittle{
	background: #eee;
	padding: 15px;
}
</style>

<style>
.agree_area label{
  font-size: 28px !important;
	position: initial!important;
}
.v-messages__wrapper{
	margin-bottom: 0;
}
.agree_area .input-group__input .icon{
  height: 30px !important;
  font-size: 30px !important;
}
.agree_box{
	background: #FFF;
	overflow: hidden;
	padding-bottom: 20px;
	margin: 20px auto;
	width: 95%;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
</style>


<script>
import http from '../services/http'
import userStore from '../stores/userStore'

export default {
	data: function () {
		return {
			valid: true,
			agree: false,
			rules: {
				must: (value) => !!value || 'You must agree to continue!',
			},
			off: false,//渡航保険非表示2018/12/27 NIC

			parts_img: {},
			parts_list: [
				'parts_arr0',
				'parts_arr1',
				'parts_arr2',
				'parts_arr3',
				'parts_arr4',
				'parts_arr5',
				'parts_arr6',
				'parts_arr7'
			],
			credit_card_brand_img: {
				'ビザ': 'visa.png',
				'マスター': 'mastercard.png',
				'アメリカンエクスプレス': 'amex.png',
				'JCB': 'jcb.png',
				'銀嶺': 'unionpay.png',
			},
		}
	},

	methods: {
		store_main () {
			if (this.$refs.form.validate()) {
				var post_param = {state: this.$store.state, customer: userStore.state.customer}
				http.post('store_main', post_param, res => {
					console.log(res)
					if(res.data.sheet_code){
						this.$store.commit('setSheetCode', res.data.sheet_code);
						this.$router.push('/mmq/ResultHome')
					}else{
						alert(this.$i18n.t('CK.unkwnownerror'))
					}
				}, error => {
					console.log(error);
				})
			}
		},

		//コードから国名を返す
		returnNationName(code){
			var nation = this.$t('data.nation');
			var keyset = Object.keys(nation);
			for(let j in keyset) {
				if(nation[keyset[j]].code == code){
					return nation[keyset[j]].name
				}
			}
			return code
		},

		//翻訳を検索して返す（キーを指定していないもの用）
		searchTranslation(arr, code, lang){
			var line = this.$i18n.t('data.'+arr).filter(function(item, index){
				if (item.code == code) return true;
			});
			if(line.length){
				if(lang == 'ja'){
					return line[0].code;
				}else{
					return line[0].name;
				}
			}
		},

		//痛みの箇所翻訳を検索して返す（キーを指定していないもの用）
		searchTranslationPartsList(code, lang){
			for(var i in this.parts_list) {
				var line = this.$i18n.t('data.parts_list.'+this.parts_list[i]).filter(function(item, index){
					if (item.code == code) return true;
				});
				if(line.length){
					if(lang == 'ja'){
						return line[0].code;
					}else{
						return line[0].name;
					}
				}
			}
		},

		init(){
			var parts = this.$store.state.mmq.hospital.Interview003.parts;
			var parts_list = this.parts_list;
			for(let i in parts) {
				for(let j in parts_list) {
					var arr = this.$i18n.t('data.parts_list.'+parts_list[j])
					for(let k in arr) {
						if(parts[i] == arr[k].code){
							this.parts_img[parts_list[j]] = parts_list[j];
						}
					}
				}
			}
		},
	},

	watch: {

	},

	beforeMount () {
		this.init()
	},
}
</script>
