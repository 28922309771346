<template>
<div class="main_div">
	<interview_step :step="5"></interview_step>

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn :to="'/mmq/'+backto" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn @click="to_next()" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

	<v-form v-model="valid" ref="form" lazy-validation>

			<div class="interview_wrapper">
					<h2 class="text-xs-center headline mt-3">
						{{ $t('pages.Interview005.temperature') }}
						<p class="text-xs-center caption_ja mb-0">{{ $t('pages.Interview005.temperature', 'ja') }}</p>
					</h2>
					<v-layout row wrap mt-4>
						<v-flex xs2 sm2 md3 offset-xs4 offset-sm4 offset-md4>
							<v-text-field :label="$t('pages.Interview005.temperature')" v-model="temperature" :rules="[rules.temperature, rules.digits]"></v-text-field>
						</v-flex>
						<v-flex xs2 sm2 md2>
							<v-select solo :items="$t('data.temperature_unit')" v-model="temperature_unit" item-text="name" item-value="code" class="title font-weight-regular" single-line></v-select>
						</v-flex>
					</v-layout>
			</div>


			<div class="interview_wrapper">
				<h2 class="text-xs-center headline mt-3">
					{{ $t('pages.Interview005.fever_date') }}
					<p class="text-xs-center caption_ja mb-0">{{ $t('pages.Interview005.fever_date', 'ja') }}</p>
				</h2>
				<v-layout row wrap mt-4>
					<v-flex class="mx-auto" xs8 sm5>
						<v-select solo :label="$t('pages.Interview005.fever_date')" :items="$t('data.fever_date_arr')" v-model="fever_date" item-text="name" item-value="code" max-height="auto" clearable>
							<template slot="selection" slot-scope="data">
								<div class="select_div" :selected="data.selected" :key="JSON.stringify(data.item)">
									<span class="subheading">{{data.item.name}}</span><br><span class="caption">{{data.item.code}}</span>
								</div>
							</template>
							<template slot="item" slot-scope="data">
								<template v-if="typeof data.item !== 'object'">
									<v-list-tile-content v-text="data.item"></v-list-tile-content>
								</template>
								<template v-else>
									<v-list-tile-content>
										<v-list-tile-title v-html="data.item.name" class="mt-2 title font-weight-regular"></v-list-tile-title>
										<v-list-tile-sub-title v-html="data.item.code" class="mb-2 caption"></v-list-tile-sub-title>
										<v-list-tile-sub-title><v-divider></v-divider></v-list-tile-sub-title>
									</v-list-tile-content>
								</template>
							</template>
						</v-select>
					</v-flex>
				</v-layout>
			</div>

	</v-form>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn :to="'/mmq/'+backto" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn @click="to_next()" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<script>
export default {
	data: function () {
		return {
			valid: true,
			scrollTop: 0,
			buttonTop: 0,
			temperature: this.$store.state.mmq.medicine.Interview005.temperature,
			temperature_unit: this.$store.state.mmq.medicine.Interview005.temperature_unit,
			fever_date: this.$store.state.mmq.medicine.Interview005.fever_date,

			backto: 'MedicineInterview003_2',

			rules: {
				required: (value) => !!value || this.$i18n.t('CK.input'),
				temperature: (value) => {
					const pattern = /^([1-9]\d*|0)(\.\d+)?$/
					return (pattern.test(value) || !value) || this.$i18n.t('CK.incorrect')
				},
				digits: (value) => {
					var digits_check = true
					var digits = (value || '').split(".")
					if(!!digits){
						if(!!digits[0]){
							if(digits[0].length > 3){
								digits_check = false
							}
						}
						if(!!digits[1]){
							if(digits[1].length > 1){
								digits_check = false
							}
						}
						if(!!digits[2]){
							digits_check = false
						}
					}

					return digits_check || this.$i18n.t('CK.incorrect')
				}
			},

			menu: false,
			modal: false,
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},

		to_next () {
			if (this.$refs.form.validate()) {
				this.$router.push('/mmq/MedicineInterview006')
			}
		},
	},

	watch: {
		temperature: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview005', 'temperature', this.temperature]);
		},
		temperature_unit: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview005', 'temperature_unit', this.temperature_unit]);
		},
		fever_date: function(){
			if(this.fever_date == undefined){
				this.fever_date = null
			}
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview005', 'fever_date', this.fever_date]);
		},
	},

	mounted() {
		//痛み箇所がなければ戻るを痛み箇所選択に変更
		if(this.$store.state.mmq.medicine.Interview003.pain.length == 0){
			this.backto = 'MedicineInterview003_1';
		}
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
        interview_step: require('./MedicineInterviewStep.vue').default,
    }
}
</script>
