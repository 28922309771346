import http from '../services/http'
import router from '../router.js';

export default {
  debug: true,
  state: {
    customer: {},
    authenticated: false,
  },
  coords: {
    latitude: null,
    longitude: null
  },

  login() {
    if(window.Laravel.error) {
      router.replace('/mmq/error')
    }
    if(!window.Laravel.router)
    {
      router.replace('/mmq/error')
    }
    if(Object.keys(window.Laravel.customer).length == 0)
    {
      router.replace('/mmq/error')
    }

    this.authenticated = true
    this.state.customer = window.Laravel.customer
    localStorage.setItem('customer', JSON.stringify(this.state.customer))
  },
  
  setCurrentUser() {
    if(router.history.current.path == '/mmq/auth/new') router.replace('/mmq/login')
    if(router.history.current.path == '/mmq/auth/history') router.replace('/mmq/login')

    if(router.history.current.path != '/mmq/login' && router.history.current.path != '/mmq/error') {

      // 履歴閲覧時のローカルストレージが履歴の場合
      if(router.history.current.path.match('History') == null && localStorage.getItem('type') == 'history') {
        localStorage.setItem('type', 'history')
      }
      // 問診作成時のローカルストレージが問診の場合
      else if(localStorage.getItem('type') == 'new') {
        localStorage.setItem('type', 'new')
      }

      // localStorage
      if(router.history.current.path != '/mmq/error' && !localStorage.getItem('customer')){
        localStorage.clear()
        router.replace('/mmq/error');
      }
      else{
        this.state.authenticated = true
        this.state.customer = JSON.parse(localStorage.getItem('customer'))
      }
    }
  },
  /**
   * Init the store.
   */
  init () {
    this.setCurrentUser();
  }
}