<template>
	<div>

		<v-flex xs10 text-xs-center class="login_logo">
			<logo></logo>
		</v-flex>

		<hart_logo_top></hart_logo_top>

		<v-flex xs10 text-xs-center class="login_box">

			<v-layout row>
				<div class="login_inner">
					<v-alert type="warning" :value="true">{{ $t('CK.verify', 'en') }}</v-alert>
				</div>
			</v-layout>

		</v-flex>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				alertMessage: '',
			}
		},

		components: {
			logo: require('./logo.vue').default,
			hart_logo_top: require('./hartLogo_top.vue').default
		},

		created: function() {
			this.alertMessage = 'Wrong access.'
		}
	}
</script>

<style>
#particles-js {
	width: 100%;
	height: 100%;
	position: fixed;
}
.login_logo{
	width: 400px;
	margin: 30px auto;
	overflow: hidden;
}
.v-footer{
	background: none!important;
}
.login_box{
	background: #FFF;
	width: 500px;
	margin: -60px auto;
	padding-top: 60px;
	padding-bottom: 20px;
	border-radius: 10px;
}
.login_inner{
	overflow: hidden;
	width: 90%;
	height: auto;
	margin: 5px auto;
}
</style>