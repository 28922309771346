
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';
import userStore from './stores/userStore';
import router from './router.js';
import http from './services/http.js';
import store from './vuex.js';
//vuexのstoreを変更があるたびlacalstrageに保存
store.subscribe((mutation, state) => {
    localStorage.setItem('state', JSON.stringify(state))
})
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'babel-polyfill';
import moment from 'vue-moment';
Vue.use(moment);

Vue.use(VueI18n);
Vue.use(Vuetify, {
  theme: {
    primary: '#E8546B',
    secondary: '#b0bec5',
    accent: '#ff6868',
    error: '#b71c1c',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
})

const TranslationData = {
    'en': require('./translation/en_v2.0.1.json'),
    'zh-cn': require('./translation/zh-cn_v2.0.1.json'),
    'zh-tw': require('./translation/zh-tw_v2.0.1.json'),
    'ko': require('./translation/ko_v2.0.1.json'),
    'th': require('./translation/th_v2.0.1.json'),
    'es': require('./translation/es_v2.0.1.json'),
    'de': require('./translation/de_v2.0.1.json'),
    'ms': require('./translation/ms_v2.0.1.json'),
    'pt': require('./translation/pt_v2.0.1.json'),
    'tl': require('./translation/tl_v2.0.1.json'),
    'vi': require('./translation/vi_v2.0.1.json'),
    'fr': require('./translation/fr_v2.0.1.json'),
    'id': require('./translation/id_v2.0.1.json'),
    'it': require('./translation/it_v2.0.1.json'),
    'ru': require('./translation/ru_v2.0.1.json'),
    'tr': require('./translation/tr_v2.0.1.json'),
    'ja': require('./translation/ja_v2.0.1.json'),
}
const i18n = new VueI18n({
    locale: 'en', // 言語変更されたら書き換える
    fallbackLocale: 'en',
    messages: TranslationData,
    silentTranslationWarn: true
});


const app = new Vue({
    router,
    store,
    moment,
    el: '#app',
    i18n: i18n,
    created () {
        http.init(),
        userStore.init()
    },
    watch:{
        $route (to, from){
            if(!userStore.state.authenticated){
                userStore.init()
            }
        }
    },
    mounted: function() {
        //緯度経度を追加
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(position=>{
                userStore.coords.latitude = position.coords.latitude;
                userStore.coords.longitude = position.coords.longitude;
            })
        }
    },
    beforeCreate() {
        this.$store.commit('initialiseStore')
        this.$i18n.locale = this.$store.getters['getLang']().sheet_lang
    },
    render: h => h(require('./components/app.vue').default),
});
