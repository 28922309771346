<template>
<div class="main_div">

	<v-dialog v-model="disclaimer" persistent max-width="90%">
		<v-card class="text-xs-center pt-5 pb-3" justify-center>

			<h3 class="headline text-xs-center mt-2 mb-2">免責</h3>

			<v-divider style="width: 90%;margin:20px auto;"></v-divider>

			<v-card-text class="subheading">
				この問診票を利用した一切の事項に責任を負いません。
			</v-card-text>
			<v-card-text class="subheading" mt-3>
				Legal Disclaimer<br>
				We are not responsible for the use of this<br>
				medical questionnaire.
			</v-card-text>
			<v-card-text class="subheading" mt-3>
				{{ facility_name }}
			</v-card-text>

			<v-container fluid mt-2>
				<v-layout row wrap justify-center>
					<v-flex xs12>
						<v-btn round color="primary" class="headline" large justify-center @click.native="disclaimer = false">OK</v-btn>
					</v-flex>
				</v-layout>
			</v-container>

			<v-divider style="width: 90%;margin:20px auto;"></v-divider>

		</v-card>
	</v-dialog>

	<v-layout row wrap align-center justify-center>

		<v-flex xs10 sm6 py-2 pl-3 hidden-xs-only>
			<div class="lang_leftbox">
				<lang></lang>
			</div>
			<div class="lang_rightbox">
				<p class="font-please">Please select your langage</p>
				<span class="body-1">言語を選択してください</span>
			</div>
		</v-flex>

		<v-flex xs10 sm6 py-2 hidden-sm-and-up>
			<div class="lang_leftbox">
				<lang2></lang2>
			</div>
			<div class="lang_rightbox">
				<p class="font-please">Please select your langage</p>
				<span class="body-1">言語を選択してください</span>
			</div>
		</v-flex>

		<v-flex v-for="(ind, key) in language_arr" :key="ind.value" @click="changeLang(ind.value)" class="lang" xs12 sm6 px-3 py-1>
			<img :src="'/img/lang_icon/' + ind.value + '.png'">
		</v-flex>

	</v-layout>
</div>
</template>

<style scoped>
	.v-card{border-radius: 20px;}
	.lang_leftbox{
		float: left;
		width: 15%;
		text-align: right;
		padding-right: 2%;
	}
	.lang_rightbox{
		color: #E8546B;
		float: right;
		width: 85%;
		padding-left: 2%;
	}
	p.font-please{
	 margin: 0;
	 font-size: 1.45em;
	}
	.lang img{
    width: 100%;
		vertical-align: bottom;
	}
</style>

<script>
export default {
	props: ['i18n'],
	methods: {
		changeLang (lang) {
			this.$store.commit('changeLang', lang)
			this.$i18n.locale = lang
			this.$router.push('/mmq/Treatment')
		}
	},

	components: {
		lang: require('./lang.vue').default,
		lang2: require('./lang2.vue').default
	},

	data: function () {
		return {
			disclaimer: true,
			facility_name: localStorage.getItem('facility_name'),
			language_arr: [
				{value: 'id', text: 'Bahasa indonesia', ja: 'インドネシア語'},
				{value: 'th', text: 'ภาษาไทย', ja: 'タイ語'},
				{value: 'ko', text: '한국어', ja: '韓国語'},
				{value: 'zh-cn', text: '中国', ja: '中国語（簡体字）'},
				{value: 'zh-tw', text: '中國語', ja: '中国語（繁体字）'},
				{value: 'tl', text: 'Tagalog', ja: 'タガログ語'},
				{value: 'vi', text: 'Tiếng Việt', ja: 'ベトナム語'},
				{value: 'ms', text: 'Bahasa Melayu', ja: 'マレー語'},
				{value: 'en', text: 'English', ja: '英語'},
				{value: 'it', text: 'italiano', ja: 'イタリア語'},
				{value: 'es', text: 'Español', ja: 'スペイン語'},
				{value: 'de', text: 'Deutsch', ja: 'ドイツ語'},
				{value: 'fr', text: 'Français', ja: 'フランス語'},
				{value: 'pt', text: 'Português', ja: 'ポルトガル語'},
				{value: 'ru', text: 'Русский язык', ja: 'ロシア語'},
				{value: 'tr', text: 'Türk', tr: 'トルコ語'},
				{value: 'ja', text: '日本語', ja: '日本語'},
			]
		}
	},
}
</script>
