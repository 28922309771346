<template>
<div class="main_div">
	<interview_step :step="9"></interview_step>

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/Interview007" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/MainComfirm" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

	<div>

		<h2 class="text-xs-center headline_mmq mt-3">
			{{ $t('pages.Interview008.text001') }}
			<p class="text-xs-center caption">{{ $t('pages.Interview008.text001', 'ja') }}</p>
		</h2>

		<div class="interview_wrapper">
			<h2 class="text-xs-center headline mt-3">
				{{ $t('pages.Interview008.credit_card') }}
				<p class="text-xs-center caption">{{ $t('pages.Interview008.credit_card', 'ja') }}</p>
			</h2>

			<div class="switch">
				<v-layout row wrap>
					<v-flex xs6>
						<input type="radio" id="credit_card_off" value="" v-model="credit_card">
						<label for="credit_card_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
					</v-flex>
					<v-flex xs6>
						<input type="radio" id="credit_card_on" value="true" v-model="credit_card">
						<label for="credit_card_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
					</v-flex>
				</v-layout>
			</div>
			<transition name="slide-fade">
				<div v-show="credit_card">
					<v-layout row wrap v-show="credit_card" class="mt-3">
						<v-flex class="text-xs-center subheading mb-2" wrap xs12>
							{{ $t('pages.Interview008.credit_card_brand') }}
							<p class="text-xs-center caption">{{ $t('pages.Interview008.credit_card_brand', 'ja') }}</p>
						</v-flex>
						<v-flex v-for="(ind, key) in $t('data.credit_card_type')" :key="key" xs4 sm3 pa-1>
							<div :id="'credit_card_brand'+key" class="credit_card">
								<img :src="'/img/credit_card/'+ind.src" @click="changeCreditCardBrand(ind.code, 'credit_card_brand'+key)">
							</div>
						</v-flex>
					</v-layout>
				</div>
			</transition>
			<p class="text-xs-center subheading mt-2 mb-1">{{ $t('pages.Interview008.text002') }}</p>
			<p class="text-xs-center caption line100">{{ $t('pages.Interview008.text002', 'ja') }}</p>
		</div>

		<div class="interview_wrapper">
			<h2 class="text-xs-center headline mt-3">
				{{ $t('pages.Interview008.text003') }}
				<p class="text-xs-center caption_ja">{{ $t('pages.Interview008.text003', 'ja') }}</p>
			</h2>
			<div class="switch">
				<v-layout row wrap>
					<v-flex xs6>
						<input type="radio" id="japanese_yen_off" value="" v-model="japanese_yen">
						<label for="japanese_yen_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
					</v-flex>
					<v-flex xs6>
						<input type="radio" id="japanese_yen_on" value="true" v-model="japanese_yen">
						<label for="japanese_yen_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
					</v-flex>
				</v-layout>
			</div>
			<transition name="slide-fade">
				<div v-show="japanese_yen">
					<v-layout row wrap class="mt-3">
						<v-flex class="text-xs-center subheading" wrap xs12 mb-1>
							{{ $t('pages.Interview008.text004') }}
							<p class="text-xs-center caption  mb-3">{{ $t('pages.Interview008.text004', 'ja') }}</p>
						</v-flex>
						<v-flex xs12>
							<div class="pa-2">
								<p>{{ $t('pages.Interview008.japanese_yen') }}</p>
								<span class="caption_ja">{{ $t('pages.Interview008.japanese_yen', 'ja') }}</span>
							</div>
							<v-flex xs10 pa-2 offset-xs1>
							<v-select solo :label="$t('pages.Interview008.japanese_yen')" :items="$t('data.possession_amount_arr')" v-model="possession_amount" item-text="name" item-value="code" max-height="auto" clearable>
								<template slot="selection" slot-scope="data">
									<div class="select_div" :selected="data.selected" :key="JSON.stringify(data.item)">
										<span class="subheading">{{data.item.name}}</span>
									</div>
								</template>
								<template slot="item" slot-scope="data">
									<template v-if="typeof data.item !== 'object'">
										<v-list-tile-content v-text="data.item"></v-list-tile-content>
									</template>
									<template v-else>
										<v-list-tile-content>
											<v-list-tile-title v-html="data.item.name" class="my-2"></v-list-tile-title>
											<v-list-tile-sub-title><v-divider></v-divider></v-list-tile-sub-title>
											<v-divider></v-divider>
										</v-list-tile-content>
									</template>
								</template>
							</v-select>
							</v-flex>

						</v-flex>
					</v-layout>
				</div>
			</transition>

		</div>

		<div class="interview_wrapper">
			<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview008.japanese_insurance') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview008.japanese_insurance', 'ja') }}</p>
			</h2>
			<div class="switch">
				<v-layout row wrap>
					<v-flex xs6>
						<input type="radio" id="japanese_insurance_off" value="" v-model="japanese_insurance">
						<label for="japanese_insurance_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
					</v-flex>
					<v-flex xs6>
						<input type="radio" id="japanese_insurance_on" value="true" v-model="japanese_insurance">
						<label for="japanese_insurance_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
					</v-flex>
				</v-layout>
			</div>
		</div>

		<div class="interview_wrapper">
			<h2 class="text-xs-center headline mt-3">
				{{ $t('pages.Interview008.travel_insurance') }}
				<p class="text-xs-center caption_ja">{{ $t('pages.Interview008.travel_insurance', 'ja') }}</p>
			</h2>
			<div class="switch">
				<v-layout row wrap>
					<v-flex xs6>
						<input type="radio" id="travel_insurance_off" value="" v-model="travel_insurance">
						<label for="travel_insurance_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
					</v-flex>
					<v-flex xs6>
						<input type="radio" id="travel_insurance_on" value="true" v-model="travel_insurance">
						<label for="travel_insurance_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
					</v-flex>
				</v-layout>
			</div>
			<transition name="slide-fade">
				<!--<div v-show="travel_insurance">-->
				<div v-show="off">
					<v-layout row wrap class="mt-3">
						<v-flex xs12>
							<p class="line100 subheading">{{ $t('pages.Interview008.travel_insurance_country') }}</p>
							<v-autocomplete
								:items="$t('data.nation')"
								:filter="customFilter"
								v-model="travel_insurance_country"
								item-text="name"
								item-value="code"
								clearable
								class="mx-3"
							></v-autocomplete>
						</v-flex>
						<v-flex xs12 class="mb-4"><span class="caption">{{ $t('pages.Interview008.travel_insurance_country', 'ja') }}</span></v-flex></v-flex>
						<v-flex xs12><v-text-field :label="$t('pages.Interview008.travel_insurance_company')" v-model="travel_insurance_company"></v-text-field></v-flex>
						<v-flex xs12 class="mb-4"><span class="caption">{{ $t('pages.Interview008.travel_insurance_company', 'ja') }}</span></v-flex></v-flex>
						<v-flex xs12><v-text-field :label="$t('pages.Interview008.travel_insurance_contact_info')" v-model="travel_insurance_contact_info"></v-text-field></v-flex>
						<v-flex xs12><span class="caption">{{ $t('pages.Interview008.travel_insurance_contact_info', 'ja') }}</span></v-flex></v-flex>
					</v-layout>
				</div>
			</transition>
		</div>

		<div class="interview_wrapper">
			<h2 class="text-xs-center headline mt-3">
				{{ $t('pages.Interview008.medical_certificate') }}
				<p class="text-xs-center caption_ja">{{ $t('pages.Interview008.medical_certificate', 'ja') }}</p>
			</h2>
			<div style="display:block;" class="pa-2">
				<p class="body-1 mt-3 mb-2">{{ $t('pages.Interview008.text005') }}</p>
				<p class="caption_ja line100 mb-3">{{ $t('pages.Interview008.text005', 'ja') }}</p>
			</div>
			<div class="switch">
				<v-layout row wrap>
					<v-flex xs6>
						<input type="radio" id="medical_certificate_off" value="" v-model="medical_certificate">
						<label for="medical_certificate_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
					</v-flex>
					<v-flex xs6>
						<input type="radio" id="medical_certificate_on" value="true" v-model="medical_certificate">
						<label for="medical_certificate_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
					</v-flex>
				</v-layout>
			</div>
		</div>

	</div>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn to="/mmq/Interview007" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/MainComfirm" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<style scoped>
.credit_card img{width:90%;height:auto;}
.credit_card{
	border: 2px solid #BBB;
	border-radius: 10px;
	padding:10px;
	text-align: center;
}
.val-selected > object {
    filter: invert(0);
}
.val-selected > img {
    filter: invert(0);
}
</style>

<script>
export default {
	data: function () {
		return {
			scrollTop: 0,
			buttonTop: 0,
			credit_card: this.$store.state.mmq.hospital.Interview008.credit_card,
			credit_card_brand: this.$store.state.mmq.hospital.Interview008.credit_card_brand,
			japanese_yen: this.$store.state.mmq.hospital.Interview008.japanese_yen,
			possession_amount: this.$store.state.mmq.hospital.Interview008.possession_amount,
			japanese_insurance: this.$store.state.mmq.hospital.Interview008.japanese_insurance,
			travel_insurance: this.$store.state.mmq.hospital.Interview008.travel_insurance,
			off: false,//渡航保険非表示2018/12/27 NIC
			travel_insurance_country: this.$store.state.mmq.hospital.Interview008.travel_insurance_country,
			travel_insurance_company: this.$store.state.mmq.hospital.Interview008.travel_insurance_company,
			travel_insurance_contact_info: this.$store.state.mmq.hospital.Interview008.travel_insurance_contact_info,
			medical_certificate: this.$store.state.mmq.hospital.Interview008.medical_certificate,

			rules: {
				required: (value) => !!value || this.$i18n.t('CK.input'),
				number: (value) => {
				const pattern = /^([1-9]\d*|0)$/
					return pattern.test(value) || this.$i18n.t('CK.incorrect')
				}
			},

			customFilter (item, queryText, itemText) {
				const hasValue = val => val != null ? val : ''
				const text = hasValue(item.name)
				const query = hasValue(queryText)
				return text.toString()
				.toLowerCase()
				.indexOf(query.toString().toLowerCase()) > -1
			}
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},

		//クレジットカード選択状態のクラスを設定
		changeCreditCardBrand (val, id) {
			var index = this.credit_card_brand.indexOf(val);
			if(index == -1){
				this.credit_card_brand.push(val);
				document.getElementById(id).classList.add("val-selected");
			}else{
				this.credit_card_brand.splice(index, 1);
				document.getElementById(id).classList.remove("val-selected");
			}
		},

		//読込時にクレジットカード選択状態のクラスを設定
		init(){
			this.$t('data.credit_card_type').forEach(function(val, i) {
					var id = 'credit_card_brand'+i;
					if(this.credit_card_brand.indexOf(val.code) != -1){
					document.getElementById(id).classList.add("val-selected");
				}
			}, this);
		}
	},

	watch: {
		credit_card: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'credit_card', this.credit_card]);
		},
		credit_card_brand: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'credit_card_brand', this.credit_card_brand]);
		},
		japanese_yen: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'japanese_yen', this.japanese_yen]);
		},
		possession_amount: function(){
			if(this.possession_amount == undefined){
				this.possession_amount = null
			}
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'possession_amount', this.possession_amount]);
		},
		japanese_insurance: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'japanese_insurance', this.japanese_insurance]);
		},
		travel_insurance: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'travel_insurance', this.travel_insurance]);
		},
		travel_insurance_country: function(){
			if(this.travel_insurance_country == undefined){
				this.travel_insurance_country = null
			}
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'travel_insurance_country', this.travel_insurance_country]);
		},
		travel_insurance_company: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'travel_insurance_company', this.travel_insurance_company]);
		},
		travel_insurance_contact_info: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'travel_insurance_contact_info', this.travel_insurance_contact_info]);
		},
		medical_certificate: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview008', 'medical_certificate', this.medical_certificate]);
		},
	},

	mounted () {
		this.init()
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
    interview_step: require('./InterviewStep.vue').default,
  }
}
</script>
