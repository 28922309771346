<template>
<div class="main_div">

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/Language" round color="secondary" depressed large>
			<v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span>
		</v-btn>
	</div>

	<h2 class="text-xs-center headline_mmq">
		{{ $t('pages.Treatment.title') }}
		<p class="text-xs-center caption_ja">{{ $t('pages.Treatment.title', 'ja') }}</p>
	</h2>

	<div class="interview_wrapper">

			<p class="text-xs-center title mt-5 mb-1">
				{{ $t('pages.Treatment.text001') }}
			</p>
			<p class="text-xs-center caption_ja mb-5">
				{{ $t('pages.Treatment.text001', 'ja') }}
			</p>

			<v-layout row wrap class="mt-3">

				<v-flex xs6 class="pa-1 xs12 sm6 mb-5" v-for="(ind, key) in $t('data.treatment')" :key="key">
					<div :class="{'val-selected': ind.code == treatment}" class="imgbox pt-2 pb-2 px-2 title line100" @click="changeTreatment(ind.code)">
						<img :src="'/img/treatment_' + key + '.svg'"><br>
						{{ $t('data.treatment['+key+'].name') }}<br>
						<span class="caption_ja">{{ $t('data.treatment['+key+'].name', 'ja') }}</span>
					</div>
				</v-flex>

			</v-layout>
	</div>

	<div class="text-xs-center mt-3" id="next_buttons">
		<v-btn to="/mmq/Language" round color="secondary" depressed large>
			<v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span>
		</v-btn>
	</div>

</div>
</template>

<style>
.imgbox img{
	width: 100%;
	height: auto;
}
.imgbox{
	text-align: center;
	background-color: #eaeaea;
	border-radius: 10px;
	max-width: 85%;
	margin: 0 auto;
}
</style>

<script>
export default {

	data: function () {
		return {
			scrollTop: 0,
			buttonTop: 0,
			treatment: this.$store.state.mmq.treatment
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},

		//入力者設定
		changeTreatment(treatment){
			this.$store.commit('changeTreatment', treatment)
			if(treatment == "私は病院に行きたい"){
				this.$router.push('/mmq/Interview001')
			}else if(treatment == "私は薬がほしい"){
				this.$router.push('/mmq/MedicineInterview001')
			}
		}
	},

	mounted(){
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},
}
</script>
