
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        "Lang": {
            "sheet_lang": "en"
        },
        "mmq": {
            "treatment": null,
            "sheet_code": null,
            "hospital": {
                "Interview001": {
                    "sex": null,
                    "age": null,
                    "pregnant_flag": null,
                    "breast_feeding_flag": null
                },
                "Interview002": {
                    "injury": [],
                    "disease": []
                },
                "Interview003": {
                    "parts": [],
                    "pain": []
                },
                "Interview004": {
                    "jcs": null
                },
                "Interview005": {
                    "temperature": null,
                    "temperature_unit": "°C",
                    "fever_date": null,
                    "blood_pressure_high": null,
                    "blood_pressure_low": null,
                    "pulse": null,
                    "respiratory_rate": null
                },
                "Interview006": {
                    "basic_medical_cerebral_vascular_disease_flg": null,
                    "basic_medical_heart_disease_flg": null,
                    "basic_medical_cerebral_vascular_disease": [],
                    "basic_medical_heart_disease": [],
                    "basic_medical_pacemaker": "",
                    "basic_medical_dialysis": "",
                    "basic_medical_shunt_flag": "",
                    "basic_medical_shunt_position": null,
                    "basic_medical_food_allergy": "",
                    "basic_medical_drug_allergy": "",
                    "basic_medical_drug_name": []
                },
                "Interview007": {
                    "attendant": "",
                    "connection": null,
                    "telephone_number": null,
                    "attendant_jp": "",
                    "connection_jp": null,
                    "telephone_number_jp": null
                },
                "Interview008": {
                    "credit_card": "",
                    "credit_card_brand": [],
                    "japanese_yen": "",
                    "possession_amount": null,
                    "japanese_insurance": "",
                    "travel_insurance": "",
                    "travel_insurance_country": null,
                    "travel_insurance_company": null,
                    "travel_insurance_contact_info": null,
                    "medical_certificate": ""
                },
                "Anamnesis": {
                    "done": false,
                    "disease": [],
                    "dosage": [],
                    "surgical": [],
                },
                "Personal001": {
                    "done": false,
                    "name1": null,
                    "name2": null,
                    "middlename": null,
                    "nationality": null,
                    "home_country": null,
                    "birthday": null,
                    "tel": null,
                    "zip_code": null,
                    "address1": null,
                    "address2": null,
                    "email": null
                },
                "Traveling001": {
                    "accommodation": null,
                    "accommodation_tel": null,
                    "immigration_from": null,
                    "immigration_port": "空港",
                    "immigration_airport": null,
                    "immigration_seaport_pref": null,
                    "immigration_date": null
                },
            },
            "medicine": {
                "Interview001": {
                    "sex": null,
                    "age": null,
                    "pregnant_flag": null,
                    "breast_feeding_flag": null
                },
                "Interview002": {
                    "injury": [],
                    "disease": []
                },
                "Interview003": {
                    "parts": [],
                    "pain": []
                },
                "Interview005": {
                    "temperature": null,
                    "temperature_unit": "°C",
                    "fever_date": null
                },
                "Interview006": {
                    "basic_medical_cerebral_vascular_disease_flg": null,
                    "basic_medical_heart_disease_flg": null,
                    "basic_medical_food_allergy": "",
                    "basic_medical_drug_allergy": "",
                    "basic_medical_drug_name": []
                },
                "Interview008": {
                    "credit_card": "",
                    "credit_card_brand": [],
                    "japanese_yen": "",
                    "possession_amount": null
                }
            }
        },
    },


    mutations: {
        changeLang (state, lang) {
            state.Lang.sheet_lang = lang;
        },

        //localStorageに保存されている情報があれば取得
        initialiseStore(state) {
			if(localStorage.getItem('state')) {
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('state')))
                )
            }
        },

        /*-
         * vuexの格納値を変更する
         *   obj: 変更値指定配列
         *     obj[0]: type(mmqのみ)
         *     obj[1]: treatment(hospitalかmedicine)
         *     obj[2]: page(ページ名)
         *     obj[3]: column(項目名)
         *     obj[4]: after(設定値)
        -*/
        changeVal (state, obj) {
            state[obj[0]][obj[1]][obj[2]][obj[3]] = obj[4]
        },

        setSheetCode(state, obj){
            state.mmq.sheet_code = obj
        },

        changeMmq (state, obj) {
            state.mmq = obj
        },

        changeHospital (state, obj) {
            state.mmq.hospital = obj
        },

        changeMedicine (state, obj) {
            state.mmq.medicine = obj
        },

        changeTreatment(state, obj){
            state.mmq.treatment = obj
        },

        resetMmq (state){
            state.mmq = {
                "treatment": null,
                "sheet_code": null,
                "hospital": {
                    "Interview001": {
                        "sex": null,
                        "age": null,
                        "pregnant_flag": null,
                        "breast_feeding_flag": null
                    },
                    "Interview002": {
                        "injury": [],
                        "disease": []
                    },
                    "Interview003": {
                        "parts": [],
                        "pain": []
                    },
                    "Interview004": {
                        "jcs": null
                    },
                    "Interview005": {
                        "temperature": null,
                        "temperature_unit": "°C",
                        "fever_date": null,
                        "blood_pressure_high": null,
                        "blood_pressure_low": null,
                        "pulse": null,
                        "respiratory_rate": null
                    },
                    "Interview006": {
                        "basic_medical_cerebral_vascular_disease_flg": null,
                        "basic_medical_heart_disease_flg": null,
                        "basic_medical_cerebral_vascular_disease": [],
                        "basic_medical_heart_disease": [],
                        "basic_medical_pacemaker": "",
                        "basic_medical_dialysis": "",
                        "basic_medical_shunt_flag": "",
                        "basic_medical_shunt_position": null,
                        "basic_medical_food_allergy": "",
                        "basic_medical_drug_allergy": "",
                        "basic_medical_drug_name": []
                    },
                    "Interview007": {
                        "attendant": "",
                        "connection": null,
                        "telephone_number": null,
                        "attendant_jp": "",
                        "connection_jp": null,
                        "telephone_number_jp": null
                    },
                    "Interview008": {
                        "credit_card": "",
                        "credit_card_brand": [],
                        "japanese_yen": "",
                        "possession_amount": null,
                        "japanese_insurance": "",
                        "travel_insurance": "",
                        "travel_insurance_country": null,
                        "travel_insurance_company": null,
                        "travel_insurance_contact_info": null,
                        "medical_certificate": ""
                    },
                    "Anamnesis": {
                        "done": false,
                        "disease": [],
                        "dosage": [],
                        "surgical": [],
                    },
                    "Personal001": {
                        "done": false,
                        "name1": null,
                        "name2": null,
                        "middlename": null,
                        "nationality": null,
                        "home_country": null,
                        "birthday": null,
                        "tel": null,
                        "zip_code": null,
                        "address1": null,
                        "address2": null,
                        "email": null
                    },
                    "Traveling001": {
                        "accommodation": null,
                        "accommodation_tel": null,
                        "immigration_from": null,
                        "immigration_port": "空港",
                        "immigration_airport": null,
                        "immigration_seaport_pref": null,
                        "immigration_date": null
                    },
                },
                "medicine": {
                    "Interview001": {
                        "sex": null,
                        "age": null,
                        "pregnant_flag": null,
                        "breast_feeding_flag": null
                    },
                    "Interview002": {
                        "injury": [],
                        "disease": []
                    },
                    "Interview003": {
                        "parts": [],
                        "pain": []
                    },
                    "Interview005": {
                        "temperature": null,
                        "temperature_unit": "°C",
                        "fever_date": null
                    },
                    "Interview006": {
                        "basic_medical_cerebral_vascular_disease_flg": null,
                        "basic_medical_heart_disease_flg": null,
                        "basic_medical_food_allergy": "",
                        "basic_medical_drug_allergy": "",
                        "basic_medical_drug_name": []
                    },
                    "Interview008": {
                        "credit_card": "",
                        "credit_card_brand": [],
                        "japanese_yen": "",
                        "possession_amount": null
                    }
                }
            }
        },
    },
    getters: {
        getLang: state => () => state.Lang
    }
})
