<template>

	<div class="main_div">
		<interview_step :step="1"></interview_step>

		<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
			<v-btn to="/mmq/Treatment" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
			<v-btn @click="validateForm(true)" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
		</div>

		<div class="interview_wrapper">

			<h2 class="text-xs-center headline_mmq">
				{{ $t('pages.Interview001.gender') }}
				<p class="text-xs-center caption_ja">{{ $t('pages.Interview001.gender', 'ja') }}</p>
			</h2>

			<v-layout row wrap>
				<v-flex xs6 sm3 offset-sm2 class="pa-1">
					<div class="genderbox pa-1 mb-2" :class="{'val-selected': male_sel}" @click="changeSex($t('data.gender.0.code'))">
						<img src="/img/male.svg">
					</div>
				</v-flex>
				<v-flex xs6 sm3 offset-sm2 class="pa-1">
					<div class="genderbox pa-1 mb-2" :class="{'val-selected': female_sel}" @click="changeSex($t('data.gender.1.code'))">
						<img src="/img/female.svg">
					</div>
				</v-flex>
			</v-layout>

			<v-layout row wrap :class="{'error--text': errors['sex']}">
				<v-flex xs6 sm3 offset-sm2 class="text-xs-center title line100">
					{{ $t('data.gender.0.name') }}<br>
					<span class="caption">{{ $t('data.gender.0.name', 'ja') }}</span>
				</v-flex>
				<v-flex xs6 sm3 offset-sm2 class="text-xs-center title line100">
					{{ $t('data.gender.1.name') }}<br>
					<span class="caption">{{ $t('data.gender.1.name', 'ja') }}</span>
				</v-flex>
			</v-layout>
			<p v-if="errors['sex']" class="error--text text-xs-center caption">{{ $i18n.t('CK.select') }}</p>


			<transition name="slide-fade">
				<div v-show="female_sel">

					<div class="interview_subwrapper">

						<h2 class="text-xs-center headline_mmq">
						{{ $t('pages.Interview001.pregnant_flag') }}
						<p class="text-xs-center caption_ja">{{ $t('pages.Interview001.pregnant_flag', 'ja') }}</p>
						</h2>

						<div class="switch">
							<v-layout row wrap>
								<v-flex xs6>
									<input type="radio" id="pregnant_flag_off" value="" v-model="pregnant_flag">
									<label for="pregnant_flag_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
								</v-flex>
								<v-flex xs6>
									<input type="radio" id="pregnant_flag_on" value="true" v-model="pregnant_flag">
									<label for="pregnant_flag_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
								</v-flex>
							</v-layout>
						</div>

						<p v-if="errors['pregnant_flag']" class="error--text text-xs-center caption">{{ $i18n.t('CK.select') }}</p>

					</div>

					<div class="interview_subwrapper">

						<h2 class="text-xs-center headline_mmq">
						{{ $t('pages.Interview001.breast_feeding_flag') }}
						<p class="text-xs-center caption_ja">{{ $t('pages.Interview001.breast_feeding_flag', 'ja') }}</p>
						</h2>

						<div class="switch">
							<v-layout row wrap>
								<v-flex xs6>
									<input type="radio" id="breast_feeding_flag_off" value="" v-model="breast_feeding_flag">
									<label for="breast_feeding_flag_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
								</v-flex>
								<v-flex xs6>
									<input type="radio" id="breast_feeding_flag_on" value="true" v-model="breast_feeding_flag">
									<label for="breast_feeding_flag_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
								</v-flex>
							</v-layout>
							<p v-if="errors['breast_feeding_flag']" class="error--text text-xs-center caption">{{ $i18n.t('CK.select') }}</p>
						</div>

					</div>

				</div>
			</transition>
		</div>

		<div class="interview_wrapper">

			<h2 color="primary" class="text-xs-center headline_mmq">
			{{ $t('pages.Interview001.age') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview001.age', 'ja') }}</p>
			</h2>

			<p v-if="errors['age']" class="error--text text-xs-center caption">{{ $i18n.t('CK.select') }}</p>
			<v-layout row wrap class="pa-2">
				<v-flex xs6 sm4 md3 v-for="(ind, key) in $t('data.age')" class="pa-1" :key="key" v-model="age">
					<div :class="{'val-selected': ind.code == age}" class="agebox pt-3 pb-2 title line100" :key="key" @click="changeAge(ind.code)">
						{{ $t('data.age['+key+'].name') }}<br><span class="caption_ja">{{ $t('data.age['+key+'].name', 'ja') }}</span>
					</div>
				</v-flex>
			</v-layout>

		</div>


		<div class="text-xs-center mt-3" id="next_buttons">
			<v-btn to="/mmq/Treatment" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
			<v-btn @click="validateForm(true)" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
		</div>



</div>
</template>

<style>
.genderbox{text-align:center; background-color:#eaeaea; border-radius:20px;}
.genderbox img{width:100%;height:auto;}
.agebox{text-align:center; background-color:#eaeaea; border-radius:15px; border:2px solid #444;}
</style>

<script>
export default {

	data: function () {
		return {
			scrollTop: 0,
			buttonTop: 0,
			errors: {},
			sex: this.$store.state.mmq.medicine.Interview001.sex,
			age: this.$store.state.mmq.medicine.Interview001.age,
			pregnant_flag: this.$store.state.mmq.medicine.Interview001.pregnant_flag,
			breast_feeding_flag: this.$store.state.mmq.medicine.Interview001.breast_feeding_flag,
			male_sel: false,
			female_sel: false,
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},

		//入力規則チェック
		validateForm(_next){
			var next = _next || false;
			this.errors = {};
			var errors = {};
			if(this.sex == null){
				errors['sex'] = this.$i18n.t('CK.select');
			} else {
				if(this.sex == '女性'){
					if(this.pregnant_flag == null){
						errors['pregnant_flag'] = this.$i18n.t('CK.select');
					}
					if(this.breast_feeding_flag == null){
						errors['breast_feeding_flag'] = this.$i18n.t('CK.select');
					}
				}
			}
			if(this.age == null){
				errors['age'] = this.$i18n.t('CK.select');
			}

			this.errors = errors;
			if(Object.keys(this.errors).length == 0 && next){
				this.$router.push('/mmq/MedicineInterview002')
			}
		},

		changeSex (after) {
			this.sex = after;
			this.init();
		},

		changeAge (after) {
			var ageboxs = document.querySelectorAll(":scope .agebox");
			for (var i = 0; i < ageboxs.length; i++) {
				ageboxs[i].classList.remove("val-selected");
			}
			event.currentTarget.classList.add("val-selected");
			this.age = after;
			this.init();
		},

		init () {
			if(this.sex == this.$i18n.t('data.gender.0.code')){
				this.male_sel = true;
				this.female_sel = false;
			}else if(this.sex == this.$i18n.t('data.gender.1.code')){
				this.male_sel = false;
				this.female_sel = true;
			}
		},
	},

	watch: {
		sex: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview001', 'sex', this.sex,]);
			this.validateForm()
		},
		age: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview001', 'age', this.age,]);
			this.validateForm()
		},
		pregnant_flag: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview001', 'pregnant_flag', this.pregnant_flag,]);
			this.validateForm()
		},
		breast_feeding_flag: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview001', 'breast_feeding_flag', this.breast_feeding_flag,]);
			this.validateForm()
		},
	},

	created () {
		this.init()
	},

	mounted(){
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
    interview_step: require('./MedicineInterviewStep.vue').default,
  }
}
</script>
