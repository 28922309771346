
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/mmq/', component: require('./components/Top.vue').default },
        { path: '/mmq/top', component: require('./components/Top.vue').default },
        { path: '/mmq/language', component: require('./components/Language.vue').default },
        { path: '/mmq/Treatment', component: require('./components/Treatment.vue').default },
        { path: '/mmq/interview001', component: require('./components/Interview001.vue').default },
        { path: '/mmq/interview002', component: require('./components/Interview002.vue').default },
        { path: '/mmq/interview003_1', component: require('./components/Interview003_1.vue').default },
        { path: '/mmq/interview003_2', component: require('./components/Interview003_2.vue').default },
        { path: '/mmq/interview005', component: require('./components/Interview005.vue').default },
        { path: '/mmq/interview006', component: require('./components/Interview006.vue').default },
        { path: '/mmq/interview007', component: require('./components/Interview007.vue').default },
        { path: '/mmq/interview008', component: require('./components/Interview008.vue').default },
        { path: '/mmq/MainComfirm', component: require('./components/MainComfirm.vue').default },
        { path: '/mmq/ResultHome', component: require('./components/ResultHome.vue').default },
        { path: '/mmq/MmqResult', component: require('./components/MmqResult.vue').default },

        { path: '/mmq/MedicineInterview001', component: require('./components/Medicine/MedicineInterview001.vue').default },
        { path: '/mmq/MedicineInterview002', component: require('./components/Medicine/MedicineInterview002.vue').default },
        { path: '/mmq/MedicineInterview003_1', component: require('./components/Medicine/MedicineInterview003_1.vue').default },
        { path: '/mmq/MedicineInterview003_2', component: require('./components/Medicine/MedicineInterview003_2.vue').default },
        { path: '/mmq/MedicineInterview005', component: require('./components/Medicine/MedicineInterview005.vue').default },
        { path: '/mmq/MedicineInterview006', component: require('./components/Medicine/MedicineInterview006.vue').default },
        { path: '/mmq/MedicineInterview008', component: require('./components/Medicine/MedicineInterview008.vue').default },
        { path: '/mmq/MedicineMainComfirm', component: require('./components/Medicine/MedicineMainComfirm.vue').default },
        { path: '/mmq/MedicineResultHome', component: require('./components/Medicine/MedicineResultHome.vue').default },
        { path: '/mmq/MedicineMmqResult', component: require('./components/Medicine/MedicineMmqResult.vue').default },

        { path: '/mmq/History_hospital', component: require('./components/History/History.vue').default },
        { path: '/mmq/History_medicine', component: require('./components/History/MedicineHistory.vue').default },
        
        { path: '/mmq/login', component: require('./components/new_login.vue').default, meta: { noRequiresAuth: true } },
        { path: '/mmq/error', component: require('./components/error.vue').default, meta: { noRequiresAuth: true } },
    ],

    //戻るでスクロール位置を保持するおまじない
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

router.afterEach((to, from) => {
    gtag('config', 'UA-128301163-2', {'page_path': to.path});
})


export default router;
