<template>
<div>
	<v-stepper v-model="step" light class="stepper_out">
		<v-stepper-header>
			<v-stepper-step step="1" :complete="step > 1"></v-stepper-step>
			<v-divider></v-divider>
			<v-stepper-step step="2" :complete="step > 2"></v-stepper-step>
			<v-divider></v-divider>
			<v-stepper-step step="3" :complete="step > 3"></v-stepper-step>
			<v-divider></v-divider>
			<v-stepper-step step="4" :complete="step > 4"></v-stepper-step>
			<v-divider></v-divider>
			<v-stepper-step step="5" :complete="step > 5"></v-stepper-step>
		</v-stepper-header>
	</v-stepper>

	<v-stepper v-model="step" light class="stepper_out">
		<v-stepper-header>
			<v-stepper-step step="6" :complete="step > 6"></v-stepper-step>
			<v-divider></v-divider>
			<v-stepper-step step="7" :complete="step > 7"></v-stepper-step>
		</v-stepper-header>
	</v-stepper>
</div>
</template>

<style>
.stepper_out{background: none !important;box-shadow: none;padding:0;}
.v-stepper, .v-stepper__header{box-shadow:none !important}
.v-stepper__header{height:auto !important;}
.v-stepper__step{padding-top:5px !important;padding-bottom:5px !important;}
.v-stepper__step.none .v-stepper__step__step{background:none !important;}
</style>

<script>
export default {
	props: ['step'],

	data: function () {
		return {

		}
	},

	methods: {
		
	},

	watch: {
		
	}
}
</script>
