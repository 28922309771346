<template>
<div class="main_div">
	<interview_step :step="3"></interview_step>

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/Interview002" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/Interview003_2" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>


	<div class="interview_wrapper">

		<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview003_1.text001') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview003_1.text001', 'ja') }}</p>
		</h2>

		<v-container fill-height>
			<v-layout row wrap align-center>
				<v-flex class="text-xs-center">

					<new_figure ref="taro"
						v-bind:parts_list="parts_list"
						v-bind:parts="parts"
						@parts0="parts0"
						@parts1="parts1"
						@parts2="parts2"
						@parts3="parts3"
						@parts4="parts4"
						@parts5="parts5"
						@parts6="parts6"
						@parts7="parts7"
					></new_figure>

					<div class="ImageMap">
						<v-btn @click.native.stop="dialog_arr['dialog_00'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr0.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr0.name', 'ja') }})</span>
						</v-btn>
						<v-btn @click.native.stop="dialog_arr['dialog_01'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr1.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr1.name', 'ja') }})</span>
						</v-btn>
						<v-btn @click.native.stop="dialog_arr['dialog_02'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr2.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr2.name', 'ja') }})</span>
						</v-btn>
						<v-btn @click.native.stop="dialog_arr['dialog_03'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr3.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr3.name', 'ja') }})</span>
						</v-btn>
						<v-btn @click.native.stop="dialog_arr['dialog_04'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr4.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr4.name', 'ja') }})</span>
						</v-btn>
						<v-btn @click.native.stop="dialog_arr['dialog_05'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr5.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr5.name', 'ja') }})</span>
						</v-btn>
						<v-btn @click.native.stop="dialog_arr['dialog_06'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr6.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr6.name', 'ja') }})</span>
						</v-btn>
						<v-btn @click.native.stop="dialog_arr['dialog_07'] = true" class="title">
							{{ $t('data.parts_area_arr.parts_arr7.name') }}<span class="caption">({{ $t('data.parts_area_arr.parts_arr7.name', 'ja') }})</span>
						</v-btn>
					</div>



				</v-flex>
			</v-layout>
		</v-container>

		<v-layout row justify-center>

			<template v-for="(value, key) in parts_list">
				<v-dialog v-model="dialog_arr['dialog_0'+key]" scrollable max-width="90%">
					<v-card>
						<v-card-title class="title mx-auto">
							{{ $t('data.parts_area_arr.'+value+'.name') }}
							<span class="caption">({{ $t('data.parts_area_arr.'+value+'.code') }})</span>
						</v-card-title>

						<v-divider></v-divider>

						<v-card-text style="height: auto;">
							<v-layout row wrap>
								<v-flex v-for="(ind, key) in $t('data.parts_list.'+value)" :key="ind.code" xs12 sm6 mb-2>
									<v-checkbox v-model="parts" :value="ind.code" :label="ind.name" :hint="$t('data.parts_list.'+value+'.'+key+'.name', 'ja')" persistent-hint></v-checkbox>
								</v-flex>
							</v-layout>
						</v-card-text>

						<v-divider></v-divider>
						<div style="text-align:right;">
							<v-card-actions>
								<v-btn  color="primary" @click.native="dialog_arr['dialog_0'+key] = false" class="subheading">OK&nbsp;<span class="caption">(OK)</span></v-btn>
							</v-card-actions>
						</div>
					</v-card>
				</v-dialog>
			</template>

		</v-layout>

		<div id="positions">
			<h3>{{ $t('pages.Interview003_1.point') }}</h3>
			<v-chip close v-for="(ind, key) in parts" :key="key" outline color="red" @input="removeParts(key)">
			{{ searchTranslationPartsList(ind, 'foreign') }}
			</v-chip>
		</div>

	</div>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn to="/mmq/Interview002" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/Interview003_2" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<style>
#positions{
	margin: 20px 0;
	padding: 15px;
	background-color: #eaeaea;
}
.v-chip.v-chip.v-chip--outline{margin-top: 15px!important;}
</style>

<style scoped>
.v-card__text::-webkit-scrollbar{width:10px;background:#eee;}
.v-card__text::-webkit-scrollbar-thumb{background:#999;}
.v-card__actions {display: block;}
</style>

<script>
export default {
	data: function () {
		return {
			step: 3,
			scrollTop: 0,
			buttonTop: 0,
			parts: this.$store.state.mmq.hospital.Interview003.parts,
			parts_list: [
				'parts_arr0',
				'parts_arr1',
				'parts_arr2',
				'parts_arr3',
				'parts_arr4',
				'parts_arr5',
				'parts_arr6',
				'parts_arr7'
			],
			dialog_arr: {
				dialog_00: false,
				dialog_01: false,
				dialog_02: false,
				dialog_03: false,
				dialog_04: false,
				dialog_05: false,
				dialog_06: false,
				dialog_07: false,
			},
			figureSelected_arr: {},
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},

		//パーツ選択解除
		removeParts (index) {
			this.parts.splice(index, 1)
		},

		//痛みの箇所翻訳を検索して返す（キーを指定していないもの用）
		searchTranslationPartsList(code, lang){
			for(var i in this.parts_list) {
				var line = this.$i18n.t('data.parts_list.'+this.parts_list[i]).filter(function(item, index){
					if (item.code == code) return true;
				});
				if(line.length){
					if(lang == 'ja'){
						return line[0].code;
					}else{
						return line[0].name;
					}
				}
			}
		},

		parts0 () {
			this.dialog_arr.dialog_00 = true;
		},
		parts1 () {
			this.dialog_arr.dialog_01 = true;
		},
		parts2 () {
			this.dialog_arr.dialog_02 = true;
		},
		parts3 () {
			this.dialog_arr.dialog_03 = true;
		},
		parts4 () {
			this.dialog_arr.dialog_04 = true;
		},
		parts5 () {
			this.dialog_arr.dialog_05 = true;
		},
		parts6 () {
			this.dialog_arr.dialog_06 = true;
		},
		parts7 () {
			this.dialog_arr.dialog_07 = true;
		},

	},

	watch: {
		parts: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview003', 'parts', this.parts]);
		},
	},

	mounted: function(){
		this.setOnScroll();
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
		interview_step: require('./InterviewStep.vue').default,
		new_figure: require('./new_figure.vue').default,
	}
}
</script>
