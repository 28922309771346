<template>
<div class="main_div">
	<interview_step :step="8"></interview_step>

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/Interview006" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/Interview008" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

	<div>

		<div class="interview_wrapper">

			<h2 class="text-xs-center headline mt-3">
				{{ $t('pages.Interview007.attendant') }}
				<p class="text-xs-center caption_ja">{{ $t('pages.Interview007.attendant', 'ja') }}</p>
			</h2>
			<div class="switch">
				<v-layout row wrap>
					<v-flex xs6>
						<input type="radio" id="attendant_off" value="" v-model="attendant">
						<label for="attendant_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
					</v-flex>
					<v-flex xs6>
						<input type="radio" id="attendant_on" value="true" v-model="attendant">
						<label for="attendant_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
					</v-flex>
				</v-layout>
			</div>

			<transition name="slide-fade">
				<div v-show="attendant">
					<v-layout row wrap v-show="attendant" class="mt-3">
						<v-flex class="text-xs-center sub_title" pa-2>
							{{ $t('pages.Interview007.connection') }}
							<p class="text-xs-center caption">{{ $t('pages.Interview007.connection', 'ja') }}</p>
						</v-flex>
						<v-flex v-for="(ind, key) in $t('data.connection_arr')" :key="key" xs12 sm5 pa-2 ma-2 class="bt_bg">
							<v-checkbox v-model="connection" :value="ind.code" :label="ind.name" :hint="ind.code" persistent-hint></v-checkbox>
						</v-flex>
					</v-layout>
				</div>
			</transition>

			<transition name="slide-fade">
				<div v-show="attendant">
					<v-layout row wrap v-show="attendant" class="mt-3">
						<div class="pa-2">
							<p>{{ $t('pages.Interview007.telephone_number') }}</p>
							<span class="caption_ja">{{ $t('pages.Interview007.telephone_number', 'ja') }}</span>
						</div>
						<v-flex xs10 pa-2 offset-xs1>
							<v-text-field solo :label="$t('pages.Interview007.telephone_number')" v-model="telephone_number"></v-text-field>
						</v-flex>
					</v-layout>
				</div>
			</transition>

		</div>
		<div class="interview_wrapper">

			<h2 class="text-xs-center headline mt-3">
				{{ $t('pages.Interview007.attendant_jp') }}
				<p class="text-xs-center caption_ja">{{ $t('pages.Interview007.attendant_jp', 'ja') }}</p>
			</h2>
			<div class="switch">
				<v-layout row wrap>
					<v-flex xs6>
						<input type="radio" id="attendant_jp_off" value="" v-model="attendant_jp">
						<label for="attendant_jp_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
					</v-flex>
					<v-flex xs6>
						<input type="radio" id="attendant_jp_on" value="true" v-model="attendant_jp">
						<label for="attendant_jp_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
					</v-flex>
				</v-layout>
			</div>

			<transition name="slide-fade">
				<div v-show="attendant_jp">
					<v-layout row wrap v-show="attendant_jp" class="mt-3">
						<v-flex class="text-xs-center sub_title" pa-2>
							{{ $t('pages.Interview007.connection_jp') }}
							<p class="text-xs-center caption">{{ $t('pages.Interview007.connection_jp', 'ja') }}</p>
						</v-flex>
						<v-flex v-for="(ind, key) in $t('data.connection_arr')" :key="key" xs12 sm5 pa-2 ma-2 class="bt_bg">
							<v-checkbox v-model="connection_jp" :value="ind.code" :label="ind.name" :hint="ind.code" persistent-hint></v-checkbox>
						</v-flex>
					</v-layout>
				</div>
			</transition>

			<transition name="slide-fade">
				<div v-show="attendant_jp">
					<v-layout row wrap v-show="attendant_jp" class="mt-3">
						<div class="pa-2">
							<p>{{ $t('pages.Interview007.telephone_number') }}</p>
							<span class="caption">{{ $t('pages.Interview007.telephone_number', 'ja') }}</span>
						</div>
						<v-flex xs10 pa-2 offset-xs1>
							<v-text-field solo :label="$t('pages.Interview007.telephone_number')" v-model="telephone_number_jp"></v-text-field>
						</v-flex>
					</v-layout>
				</div>
			</transition>

		</div>

	</div>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn to="/mmq/Interview006" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/Interview008" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<script>
export default {
	data: function () {
		return {
			scrollTop: 0,
			buttonTop: 0,
			attendant: this.$store.state.mmq.hospital.Interview007.attendant,
			connection: this.$store.state.mmq.hospital.Interview007.connection,
			telephone_number: this.$store.state.mmq.hospital.Interview007.telephone_number,
			attendant_jp: this.$store.state.mmq.hospital.Interview007.attendant_jp,
			connection_jp: this.$store.state.mmq.hospital.Interview007.connection_jp,
			telephone_number_jp: this.$store.state.mmq.hospital.Interview007.telephone_number_jp,
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},
	},

	watch: {
		attendant: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview007', 'attendant', this.attendant]);
		},
		connection: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview007', 'connection', this.connection]);
		},
		telephone_number: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview007', 'telephone_number', this.telephone_number]);
		},
		attendant_jp: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview007', 'attendant_jp', this.attendant_jp]);
		},
		connection_jp: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview007', 'connection_jp', this.connection_jp]);
		},
		telephone_number_jp: function(){
			this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview007', 'telephone_number_jp', this.telephone_number_jp]);
		},
	},

	mounted(){
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
    interview_step: require('./InterviewStep.vue').default,
  }
}
</script>
