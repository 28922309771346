<template>
<div class="main_div">
	<interview_step :step="2"></interview_step>

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/MedicineInterview001" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn @click="validateForm(true)" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

	<div class="interview_wrapper">

		<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview002.text001') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview002.text001', 'ja') }}</p>
		</h2>

		<p v-if="errors['all']" class="error--text text-xs-center caption">{{ $i18n.t('CK.select') }}</p>

		<v-layout row wrap :class="{'error--text': errors['all']}" class="pa-3">

		<v-flex xs12>
			<h3 class="subheading mt-1">
				{{ $t('pages.Interview002.injury') }}
				<p class="caption mb-3">{{ $t('pages.Interview002.injury', 'ja') }}</p>
			</h3>
			<v-divider></v-divider>
		</v-flex>

			<template v-for="(ind, key) in $t('data.injury')">
				<v-flex :key="'injury_'+key" xs12 sm5 pa-2 ma-2 class="bt_bg">
					<v-checkbox v-model="injury" :value="ind.code" :label="ind.name" :hint="$t('data.injury['+key+'].name', 'ja')" persistent-hint></v-checkbox>
				</v-flex>
			</template>

			<v-flex xs12>
				<h3 class="subheading mt-3">{{ $t('pages.Interview002.disease') }}</h3>
				<p class="caption mb-1">{{ $t('pages.Interview002.disease', 'ja') }}</p>
			</v-flex>
			<v-flex xs12>
				<v-divider></v-divider>
			</v-flex>

			<template v-for="(ind, key) in $t('data.symptoms').slice(0,15)">

				<v-flex :key="'symptoms_'+key" xs12 sm5 pa-2 ma-2 class="bt_bg">
					<v-checkbox v-model="disease" :value="ind.code" :label="ind.name" :hint="$t('data.symptoms['+key+'].name', 'ja')" persistent-hint></v-checkbox>
				</v-flex>

				<template v-if="disease_divide_arr.indexOf(ind.code) >= 0">
					<v-flex :key="'disease'+key" xs12 mb-3>
						<v-divider></v-divider>
					</v-flex>
				</template>

			</template>
		</v-layout>

	</div>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn to="/mmq/MedicineInterview001" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn @click="validateForm(true)" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<script>
export default {
	data: function () {
		return {
			scrollTop: 0,
			buttonTop: 0,
			errors: {},
			injury: this.$store.state.mmq.medicine.Interview002.injury,
			disease: this.$store.state.mmq.medicine.Interview002.disease,
			disease_divide_arr: [
				'熱中症'
			]
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop = document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},
		//入力規則チェック
		validateForm(_next){
			var next = _next || false;
			this.errors = {};
			var errors = {};
			if(!this.injury.length && !this.disease.length){
				errors['all'] = this.$i18n.t('CK.select');
				window.scrollTo(0, 0);
			}

			this.errors = errors;
			if(Object.keys(this.errors).length == 0 && next){
				this.$router.push('/mmq/MedicineInterview003_1')
			}
		},
	},

	watch: {
		injury: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview002', 'injury', this.injury]);
			this.validateForm()
		},
		disease: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview002', 'disease', this.disease]);
			this.validateForm()
		},
	},

	mounted(){
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
    interview_step: require('./MedicineInterviewStep.vue').default,
  }
}
</script>
