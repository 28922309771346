<template>
<div class="main_div">
	<interview_step :step="2"></interview_step>

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/Interview001" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn @click="validateForm(true)" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

	<div class="interview_wrapper">

		<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview002.text001') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview002.text001', 'ja') }}</p>
		</h2>

		<p v-if="errors['all']" class="error--text text-xs-center caption">{{ $i18n.t('CK.select') }}</p>

		<v-layout row wrap class="pa-3">

				<v-flex xs12>
					<h3 class="subheading mt-1">
						{{ $t('pages.Interview002.injury') }}
						<p class="caption mb-1">{{ $t('pages.Interview002.injury', 'ja') }}</p>
					</h3>
					<v-divider></v-divider>
				</v-flex>

				<template v-for="(ind, key) in $t('data.injury')">
					<v-flex :key="'injury_'+key" xs12 sm6>
						<v-checkbox v-model="injury" class="bt_bg" :value="ind.code" :label="ind.name" :hint="$t('data.injury['+key+'].name', 'ja')" persistent-hint></v-checkbox>
					</v-flex>
				</template>

				<v-flex xs12>
					<h3 class="subheading mt-3">
						{{ $t('pages.Interview002.disease') }}
						<p class="caption mb-1">{{ $t('pages.Interview002.disease', 'ja') }}</p>
					</h3>
					<v-divider></v-divider>
				</v-flex>

				<template v-for="(ind, key) in $t('data.symptoms')">

						<v-flex :key="'symptoms_'+key" xs12 sm6>
							<v-checkbox class="bt_bg" v-model="disease" :value="ind.code" :label="ind.name" :hint="$t('data.symptoms['+key+'].name', 'ja')" persistent-hint></v-checkbox>
						</v-flex>

						<template v-if="disease_divide_arr.indexOf(ind.code) >= 0">
							<v-flex :key="'disease'+key" xs12 ma-3>
								<v-divider></v-divider>
							</v-flex>
						</template>

						<template v-if="ind.code == '血尿'">
							<v-flex xs12>
								<h3 class="subheading mt-3">
									{{ $t('pages.Interview002.toxic') }}
									<p class="caption mb-3">{{ $t('pages.Interview002.toxic', 'ja') }}</p>
								</h3>
								<v-divider></v-divider>
							</v-flex>
						</template>

				</template>

		</v-layout>

	</div>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn to="/mmq/Interview001" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn @click="validateForm(true)" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<style>
.bt_bg > .v-input__control > .v-input__slot > .v-label,
.bt_bg > .v-input__control > .v-input__slot > .v-input--selection-controls__input{
	padding-top: 10px;
  padding-bottom: 30px;
  z-index: 100;
}
.bt_bg > .v-input__control > .v-messages{
	margin-top: -30px;
}
</style>

<script>

	export default {
		data: function () {
			return {
				scrollTop: 0,
				buttonTop: 0,
				errors: {},
				injury: this.$store.state.mmq.hospital.Interview002.injury,
				disease: this.$store.state.mmq.hospital.Interview002.disease,
				disease_divide_arr: [
					'熱中症',
					'皮膚の痒み',
					'失神',
					'めまい（回転性）',
					'けいれん（全身）',
					'喘鳴',
					'薬物'
				]
			}
		},

		model: {
		  prop: 'inputValue',
		  event: 'change'
		},

		methods: {
			setOnScroll(){
				var self = this;
				document.onscroll = function(e){
					var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
					self.scrollTop = scrollTop + window.innerHeight - 20;
					self.buttonTop = document.getElementById('next_buttons').offsetTop;
				}
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			},

			//入力規則チェック
			validateForm(_next){
				var next = _next || false;
				this.errors = {};
				var errors = {};
				if(!this.injury.length && !this.disease.length){
					errors['all'] = this.$i18n.t('CK.select');
					window.scrollTo(0, 0);
				}
				this.errors = errors;
				if(Object.keys(this.errors).length == 0 && next){
					this.$router.push('/mmq/Interview003_1')
				}
			},
		},

		watch: {
			injury: function(){
				this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview002', 'injury', this.injury]);
				this.validateForm();
			},
			disease: function(){
				this.$store.commit('changeVal', ['mmq', 'hospital', 'Interview002', 'disease', this.disease]);
				this.validateForm()
			},


		},

		mounted(){
			this.setOnScroll()
		},

		destroyed(){
			document.onscroll = "";
		},

		components: {
	    interview_step: require('./InterviewStep.vue').default,
	  }
	}

</script>
