<template>
	<v-container fluid class="title">

		<div class="navbar_logo"><hartlogo></hartlogo></div>

		<v-layout row wrap>
			<v-flex xs12>
				<div class="navbar_name">{{ userState.customer.customer_name }}</div>
			</v-flex>
		</v-layout>

		<v-divider class="mt-4 mb-4"></v-divider>

		<v-layout row wrap>
			<v-flex xs12>
				<div class="navbar_menu"><a @click="link" href="#">トップへ戻る</a></div>
			</v-flex>
		</v-layout>

		<v-divider class="mt-4 mb-4"></v-divider>

	</v-container>
</template>


<script>
import userStore from '../stores/userStore';

export default {
	data (){
		return {
			userState: userStore.state,
		}
	},
	methods: {
		link() {
			window.location.href = this.userState.customer.url
		}
	},
	components: {
			hartlogo: require('./headHartLogo.vue').default,
	},
}
</script>




<style>
	.navbar_name{
			padding-top: 60px;
			font-size: medium;
	}
	.navbar_menu a{
			font-size: medium;
			text-decoration: none;
	}
</style>
