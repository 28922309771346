<template>

<div class="main_div">

	<h2 class="text-xs-center headline_fix mt-3 display-1">
		{{ $t('pages.ResultHome.text001') }}
		<p class="text-xs-center caption_ja">{{ $t('pages.ResultHome.text001', 'ja') }}</p>
	</h2>
		<v-layout row wrap>
			<v-flex class="interview_wrapper_fix xs12 sm12">
				<v-layout row wrap>
					<v-flex xs10 sm8 offset-sm2 offset-xs1 mb-3 mt-4>
						<button type="button" class="title mybtn monshin pt-3 pb-2 line100" @click="$router.push('/mmq/MedicineMmqResult')">
							{{ $t('pages.ResultHome.text002') }}<br>
							<span class="caption">{{ $t('pages.ResultHome.text002', 'ja') }}</span>
						</button>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>

	<v-container fluid mt-4>
		<v-layout row wrap>
			<v-flex xs12 sm8 offset-sm2 mb-3>
				<button type="button" class="title mybtn gray pt-3 pb-2 line100" @click="resetAll">
					{{ $t('pages.ResultHome.text009') }}<br>
					<span class="caption">{{ $t('pages.ResultHome.text009', 'ja') }}</span>
				</button>
			</v-flex>
		</v-layout>
	</v-container>

</div>
</template>



<style scoped>
.card{border-radius:12px;}
button.mybtn{
	background:#FFF;
	color:#000;
	border-radius:10px;
	width:100%;
	border:1px solid #e53935;
}
button.mybtn.monshin{
	background: -moz-linear-gradient(top, #EF8D80, #E8546B);
	background: -webkit-linear-gradient(top, #EF8D80, #E8546B);
	background: linear-gradient(to bottom, #EF8D80, #E8546B);
	color:#FFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
button.mybtn.gray{background:#777;color:#FFF;border-radius:10px;width:100%;border:none;}
</style>

<script>
import userStore from '../../stores/userStore'

export default {
	data: function () {
		return {
			valid: true,
			completion_dialog: false,
		}
	},
	methods: {
		resetAll(){
			document.location.href = userStore.state.customer.url
		},
		submitbg(){
　　		this.$emit('bg');
		}
	},
	mounted(){
		this.submitbg()
	},
	beforeMount () {

	},
}
</script>
