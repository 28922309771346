<template>
	<v-layout column fill-height justify-center>
		<v-flex xs5>
			<hart_logo_top></hart_logo_top>

			<v-layout row wrap align-content-center justify-center text-xs-center class="start_logo">
				<v-flex xs9 md5 align-self-center justify-center text-xs-center>
					<logo_pink></logo_pink>

					<v-layout row wrap align-content-center justify-center text-xs-center mt-5>
							<v-flex xs7 md4 align-self-center justify-center text-xs-center mt-3>
								<v-btn block color="#26304E" dark large min-height="55px" @click.native="$router.push('/mmq/Language')">START</v-btn>
							</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<style scoped>

	.start_logo{
		margin: 0 auto 30px;
		overflow: hidden;
	}
	.start_logo svg{
		width: 100%;
		height: 100%;
	}
	.start_box{
		margin: 0 auto;
		width: 300px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.left_box{
		color: #8C8C8C;
		float: left;
		width: 40%;
		text-align: right;
		padding-right: 2%;
	}
	.right_box{
		float: right;
		width: 60%;
		padding-left: 2%;
		text-align: left;
	}
	p.aten{
		color: #E8546B;
		font-size: 13px;
		margin-bottom: 0;
	}
	p.aten_J{
		color: #E8546B;
		font-size: 10px;
		margin-bottom: 0;
	}

</style>

<script>

	import userStore from '../stores/userStore';

	export default {
		data: function () {
			return {
				userState: userStore.state,
				facility_name: localStorage.getItem('facility_name'),
			}
		},

		props: ['i18n'],

		components: {
			logo_pink: require('./logo_pink.vue').default,
			hart_logo_top: require('./hartLogo_top.vue').default,
		},

		mounted () {
			this.$parent.top = false;
		},
	}

</script>
