<template>

<div class="main_div">
	<interview_step :step="4"></interview_step>

	<v-dialog v-model="del_dialog" max-width="500">
		<v-card>
			<div class="subheading text-xs-center pt-4">
				{{ $t('pages.Interview003_2.delete') }}
				<p class="text-xs-center caption">{{ $t('pages.Interview003_2.delete', 'ja') }}</p>
			</div>
			<v-divider></v-divider>
			<div class="text-xs-center mt-5 mb-3">
				<span class="headline font-bold">{{ searchTranslationPartsList(del_part.ind.point, 'foreign') }}</span>
				<p class="text-xs-center caption">{{ searchTranslationPartsList(del_part.ind.point, 'ja') }}</p>
			</div>
			<div class="text-xs-center pt-3 pb-3">
				<v-btn round color="secondary" depressed large @click.native="del_dialog = false">{{ $t('common.cancel') }}&nbsp;<span class="caption">({{ $t('common.cancel', 'ja') }})</span></v-btn>
				<v-btn round color="primary" large @click.native="delPart()">{{ $t('common.ok') }}&nbsp;<span class="caption">({{ $t('common.ok', 'ja') }})</span></v-btn>
			</div>
		</v-card>
	</v-dialog>


	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/MedicineInterview003_1" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/MedicineInterview005" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

	<div class="interview_wrapper">

		<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview003_2.verbal_rating_scale') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview003_2.verbal_rating_scale', 'ja') }}</p>
		</h2>

		<v-card v-for="(ind, key) in pain" :key="key" class="ma-3">
			<v-card-title primary-title class="title">
				{{ searchTranslationPartsList(ind.point, 'foreign') }}&nbsp;<span class="caption">({{ searchTranslationPartsList(ind.point, 'ja') }})</span>
				<v-btn depressed fab absolute right dark small color="red lighten-1" @click.native.stop="showDelDialog(ind, key)"><v-icon light>close</v-icon></v-btn>
			</v-card-title>
			<v-divider></v-divider>
			<v-container fluid>
				<p>{{ $t('pages.Interview003_2.verbal_rating_scale') }}</p>
				<p class="caption_ja">{{ $t('pages.Interview003_2.verbal_rating_scale', 'ja') }}</p>
				<v-layout row wrap ma-3>
					<v-flex xs2 offset-xs1 class="face_scale_out">
						<img class="face_scale_selected" v-if="pain[key].verbal_rating_scale == 1" src="/img/face_scale/face01_selected.png">
						<img class="face_scale" :class="['face_scale'+key]" src="/img/face_scale/face01.png" @click="set_verbal_rating_scale(key, 1)">
					</v-flex>
					<v-flex xs2 class="face_scale_out">
						<img class="face_scale_selected" v-if="pain[key].verbal_rating_scale == 2" src="/img/face_scale/face02_selected.png">
						<img class="face_scale" :class="['face_scale'+key]" src="/img/face_scale/face02.png" @click="set_verbal_rating_scale(key, 2)">
					</v-flex>
					<v-flex xs2 class="face_scale_out">
						<img class="face_scale_selected" v-if="pain[key].verbal_rating_scale == 3" src="/img/face_scale/face03_selected.png">
						<img class="face_scale" :class="['face_scale'+key]" src="/img/face_scale/face03.png" @click="set_verbal_rating_scale(key, 3)">
					</v-flex>
					<v-flex xs2 class="face_scale_out">
						<img class="face_scale_selected" v-if="pain[key].verbal_rating_scale == 4" src="/img/face_scale/face04_selected.png">
						<img class="face_scale" :class="['face_scale'+key]" src="/img/face_scale/face04.png" @click="set_verbal_rating_scale(key, 4)">
					</v-flex>
					<v-flex xs2 class="face_scale_out">
						<img class="face_scale_selected" v-if="pain[key].verbal_rating_scale == 5" src="/img/face_scale/face05_selected.png">
						<img class="face_scale" :class="['face_scale'+key]" src="/img/face_scale/face05.png" @click="set_verbal_rating_scale(key, 5)">
					</v-flex>
				</v-layout>
				<p>{{ $t('pages.Interview003_2.kind_of_pain') }}</p>
				<span class="caption_ja">{{ $t('pages.Interview003_2.kind_of_pain', 'ja') }}</span>
				<v-layout row wrap ma-3>
					<v-flex class="mx-auto" xs12 sm10 >
						<v-select solo :items="$t('data.kind_of_pain_arr')" v-model="ind.kind_of_pain" item-text="name" item-value="code" max-height="auto" :label="$t('pages.Interview003_2.kind_of_pain')">
							<template slot="selection" slot-scope="data">
								<div class="select_div" :selected="data.selected" :key="JSON.stringify(data.item)">
									<span class="subheading">{{data.item.name}}</span><br><span class="caption">{{data.item.code}}</span>
								</div>
							</template>
							<template slot="item" slot-scope="data">
								<v-list-tile-content>
									<v-list-tile-title v-html="data.item.name" class="mt-2 title font-weight-regular"></v-list-tile-title>
									<v-list-tile-sub-title v-html="data.item.code" class="mb-2"></v-list-tile-sub-title>
									<v-list-tile-sub-title><v-divider></v-divider></v-list-tile-sub-title>
								</v-list-tile-content>
							</template>
						</v-select>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card>
	</div>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn to="/mmq/MedicineInterview003_1" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/MedicineInterview005" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<style>
.face_scale_out{position: relative;}
.face_scale{width:90%;height:auto;}
.face_scale_selected{position: absolute;top:0;height:auto;width:90%;}
</style>

<style scoped>
.card{border-radius:15px;}
</style>

<script>
export default {
	data: function () {
		return {
			scrollTop: 0,
			buttonTop: 0,
			pain: this.$store.state.mmq.medicine.Interview003.pain,
			parts: this.$store.state.mmq.medicine.Interview003.parts,
			parts_list: [
				'parts_arr0',
				'parts_arr1',
				'parts_arr2',
				'parts_arr3',
				'parts_arr4',
				'parts_arr5',
				'parts_arr6',
				'parts_arr7'
			],
			del_part: {key: null, ind: {}},
			del_dialog: false
		}
	},

	methods: {
		showDelDialog(ind, key){
			this.del_part = {key: key, ind: ind};
			this.del_dialog = true;
		},
		//痛み箇所削除
		delPart(){
			var old_parts = this.parts;
			var new_paets = [];
			for(var i in old_parts) {
				if(old_parts[i] != this.del_part.ind.point){
					new_paets.push(old_parts[i]);
				}
			}
			this.parts = new_paets;

			var old_pain = this.pain;
			var new_pain = [];
			for(var i in old_pain) {
				if(old_pain[i].point != this.del_part.ind.point){
					new_pain.push(old_pain[i]);
				}
			}
			this.pain = new_pain;

			this.del_dialog = false;
		},
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},
		//フェイススケールクリック
		set_verbal_rating_scale(key, val){
			this.pain[key].verbal_rating_scale = val;
		},
		//翻訳を検索して返す（キーを指定していないもの用）
		searchTranslation(arr, code, lang){
			var line = this.$i18n.t('data.'+arr).filter(function(item, index){
				if (item.code == code) return true;
			});
			if(line.length){
				if(lang == 'ja'){
					return line[0].code;
				}else{
					return line[0].name;
				}
			}
		},
		//痛みの箇所翻訳を検索して返す（キーを指定していないもの用）
		searchTranslationPartsList(code, lang){
			for(var i in this.parts_list) {
				var line = this.$i18n.t('data.parts_list.'+this.parts_list[i]).filter(function(item, index){
					if (item.code == code) return true;
				});
				if(line.length){
					if(lang == 'ja'){
						return line[0].code;
					}else{
						return line[0].name;
					}
				}
			}
		},
	},

	beforeCreate: function () {
		//痛み箇所から痛みオブジェクト生成
		var old_pain = this.$store.state.mmq.medicine.Interview003.pain;
		var new_pain = [];
		var new_parts = this.$store.state.mmq.medicine.Interview003.parts;

		//痛み度合い入力済みで選択されていない箇所を削除
		if(new_pain.length !== false){
			for(var i in old_pain) {
				var search = old_pain[i].point;
				if(new_parts.indexOf(search) != -1){
					new_pain.push(old_pain[i]);
				}
			}
		}

		//新しく指定されているものを追加
		for(var i in new_parts) {
			//存在チェック
			var line = new_pain.filter(function(item, index){
				if (item.point == new_parts[i]) return true;
			});

			if(!line.length){
				new_pain.push({
					point: new_parts[i],
					verbal_rating_scale: 1,
					kind_of_pain: null,
				});
			}
		}

		this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview003', 'pain', new_pain]);
	},

	watch: {
		parts: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview003', 'parts', this.parts]);
		},
		pain: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview003', 'pain', this.pain]);
		},
	},

	beforeMount: function () {
		//痛み箇所がなければ次へ
		if(this.$store.state.mmq.medicine.Interview003.pain.length == 0){
			this.$router.push('/mmq/MedicineInterview005')
		}
	},

	mounted(){
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
    interview_step: require('./MedicineInterviewStep.vue').default,
  }
}
</script>
