<template>
	<div>

		<v-flex xs10 text-xs-center class="login_logo">
			<logo></logo>
		</v-flex>

		<hart_logo_top></hart_logo_top>

		<v-flex xs10 text-xs-center class="login_box">

			<v-layout row v-if="showAlert">
				<div class="login_inner">
					<v-alert type="warning" :value="true">ログインできませんでした。正規の方法でアクセスしてください。</v-alert>
				</div>
			</v-layout>

		</v-flex>

	</div>
</template>

<script>

	import userStore from '../stores/userStore'
	import http from '../services/http'

	export default {	
		props: ['i18n'],

		components: {
			logo: require('./logo.vue').default,
			hart_logo_top: require('./hartLogo_top.vue').default
		},

		data() {
			return {
				showAlert: false,
				alertMessage: '',
			}
		},


		methods: {
			login: function() {

				// 前回の問診、履歴表示用データを削除
				this.$store.commit('resetMmq')
				localStorage.clear()

				// 認証　失敗したら/mmq/errorへ
				userStore.login()

				// 問診
				if(window.Laravel.router == '/mmq') {
					localStorage.setItem('type', 'new')
					this.$router.replace(window.Laravel.router)
				}
				// 履歴　データ取得
				else {
					localStorage.setItem('type', 'history')
					var history_params = {
						agency_code: userStore.state.customer.agency_code,
						user_id: userStore.state.customer.user_id,
						sheet_type: userStore.state.customer.sheet_type,
						medical_id: userStore.state.customer.medical_id,
					}

					// vuexに言語を反映、遷移先で言語変更
					this.$store.commit('changeLang', window.Laravel.customer.sheet_lang)
					this.$i18n.locale = window.Laravel.customer.sheet_lang

					http.post('hostory_'+userStore.state.customer.sheet_type, history_params, 
						res => {
							// 階層が違う
							this.$store.state.mmq.sheet_code = res.data.return.sheet_code
							// vuexを文字列化しさらにjsonに書き戻す
							// この際最下層からparseされる
							// Interview○○というキーを探すと大変なので、最下層の項目名で検索し代入する。
							var obj = 
								JSON.parse(JSON.stringify(this.$store.state.mmq[userStore.state.customer.sheet_type]), function(key, value) {
									// 最上位層の場合valueを返さないとundefined化する
									if(key === '') return value

									// もってない値は無視する。でないとundefined化する
									let data = res.data.return[key]
									if(data == undefined || data == null || !data) return value

									// はい／いいえをフラグに変換
									if(data == 'はい') return true
									if(data == 'いいえ') return false

									// pain, pointは配列で来る
									if(typeof data == 'object') return data
									// injury, diseaseで１つしかない場合 配列にしてあげる
									if(data.match(',') == null && (key == 'injury' || key == 'disease' || key == 'credit_card_brand')) return [data]
									// 普通に値を差し替える
									if(data.match(',') == null) return data

									// カンマ区切りなら配列にして差し替える
									return data.split(',')
								})

							// commitしてみる
							if(userStore.state.customer.sheet_type == 'hospital') {
								this.$store.commit('changeHospital', obj)
							}
							else if(userStore.state.customer.sheet_type == 'medicine') {
								this.$store.commit('changeMedicine', obj)
							}

							this.$router.replace(window.Laravel.router)
						}, error => {
							console.log(error)
							this.showAlert = true
							this.alertMessage = 'Wrong access.'
						}
					)
				}

			},
		},

		created: function() {
			this.login()
		},
	}

</script>

<style>
#particles-js {
	width: 100%;
	height: 100%;
	position: fixed;
}
.login_logo{
	width: 400px;
	margin: 30px auto;
	overflow: hidden;
}
.v-footer{
	background: none!important;
}
.login_box{
	background: #FFF;
	width: 500px;
	margin: -60px auto;
	padding-top: 60px;
	padding-bottom: 20px;
	border-radius: 10px;
}
.login_inner{
	overflow: hidden;
	width: 90%;
	height: auto;
	margin: 5px auto;
}
</style>