<template>
    <v-app style="background:none;">

        <div id="particles-js"></div>
        <div id="bg"></div>
        <div id="login_bg" v-if="userState.authenticated"></div>

            <v-navigation-drawer fixed v-if="userState.authenticated" v-model="drawer" app class="TOP">
                <navbar></navbar>
                <!--
                    <input type="button" value="表示" @click="dumpStore()">
                    <div style="white-space: pre;">{{ store_json_text }}</div>
                -->
            </v-navigation-drawer>

            <v-toolbar flat fixed app prominent class="toolsbar" v-show="userState.authenticated">
                <v-flex xs1>
                    <v-toolbar-side-icon dark @click.stop="drawer = !drawer" v-if="userState.authenticated"></v-toolbar-side-icon>
                </v-flex>

                <v-flex v-if="top" xs4 offset-xs3>
                    <hart_logo></hart_logo>
                </v-flex>

                <v-spacer></v-spacer>

                <v-flex xs2 sm1>
                    <v-menu offset-y>
                        <v-btn flat icon color="white" slot="activator"><lang_w></lang_w></v-btn>
                        <v-list id="lang">
                            <v-list-tile v-for="(lang, index) in language_arr" :key="index" @click="changeLocale(lang.value, index)">
                                <v-list-tile-title class="lang_list" :class="{'lang-selected': $i18n.locale == lang.value}" :key="index">{{ lang.text }}</v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                </v-flex>

            </v-toolbar>

            <v-content>
                <transition name="fade" mode="out-in" appear>
                    <router-view @bg="bg_off" @bg2="bg_on"></router-view>
                </transition>
            </v-content>

            <v-footer height="auto" v-if="userState.authenticated">
                <v-card-text class="foot_c text-xs-center">
                    {{ copyright }}
                </v-card-text>
            </v-footer>
            <v-footer height="auto" v-else>
                <v-card-text class="foot_w text-xs-center">
                    MMQ &copy; 2019 — KIT PLANNING Co.,LTD.
                </v-card-text>
            </v-footer>

    </v-app>
</template>

<style>
#bg{
  width: 100%;
  height: 100%;
  position: fixed;
  background: -moz-linear-gradient(top, #EF8D80, #E8546B);
  background: -webkit-linear-gradient(top, #EF8D80, #E8546B);
  background: linear-gradient(to bottom, #EF8D80, #E8546B);
  z-index: -200;
}
#particles-js {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -100;
}
#login_bg{
  width: 100%;
  height: 100%;
  position: fixed;
  background: #F5F4F4;
  z-index: -150;
}

.toolsbar{
  background: -moz-linear-gradient(top, #EF8D80 20px, #E8546B);
  background: -webkit-linear-gradient(top, #EF8D80 20px, #E8546B);
  background: linear-gradient(to bottom, #EF8D80 20px, #E8546B);
  z-index:200;
}

.main_div{padding:16px;position:reletive;}
.logo {
  text-align:center;
  height: 60px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s, transform 0.5s;
}
.title{line-height:normal !important;}
.btn--large .btn__content{padding:0 10px;}
.input-group label{
    font-size: 15px !important;
    overflow: visible;
    max-width: 100%;
}
.input-group--text-field input, .input-group--text-field textarea{
    font-size: 20px !important;
}
#lang a.list__tile{font-size: 24px !important;padding:5px 10px;}
.lang-selected{color:#E53935;font-weight:bold;}

h2{color: #E53935;}

.line100{line-height: 100% !important;}
.font-bold{font-weight: bold !important;}
.error--text{animation:a .6s cubic-bezier(.25,.8,.5,1);}
.error--text label{color: #b71c1c !important;animation:a .6s cubic-bezier(.25,.8,.5,1);}

#next_buttons_fixed{position: fixed;bottom:5px;left:0;width:100%;z-index:101;}

.fade-enter{
    opacity: 0;
    transform: translateX(+5%);
}
.fade-leave-active {
    opacity: 0;
    transform: translateX(-5%);
}

.select_div{color:rgba(0,0,0,.87) !important;}


.switch input {
	display: none;
}
.switch label{
	display: block;
	float: left;
	cursor: pointer;
	min-width: 120px;
	margin: 0;
	padding: 10px;
	background: #555;
	color: #CCC;
	font-size: 16px;
	text-align: center;
	line-height: 1;
	transition: .2s;
    border: 2px solid #555;
    font-weight:bold;
}
.switch label.switch-off{
	border-radius: 5px 0 0 5px;
    float: right;
}
.switch label.switch-on{
	border-radius: 0 5px 5px 0;
}
.switch input[type="radio"]:checked + .switch-on {
	background-color: #F6921E;
	color: #fff;
}
.switch input[type="radio"]:checked + .switch-off {
	background-color: #DDD;
	color: #555;
}
.v-list__tile{height:auto !important;min-height:48px;}
.v-list__tile__sub-title, .v-list__tile__title{
    overflow:visible !important;
    font-weight: normal !important;
}


.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

#lang{
  padding:0 10px;
  overflow-y: scroll;
  height: 80vh;
}
.foot_c{ color: #E8546B; }
.foot_w{ color: #FFF; }


</style>



<script>
import userStore from '../stores/userStore';

export default {
    props: ['i18n'],
    data: function () {
        return {
            userState: userStore.state,
            drawer: null,
            top: true, // TOPのみヘッダロゴ非表示にするため
            store_json_text: null,
            language_arr: [
                {value: 'en', text: 'English', ja: '英語'},
        				{value: 'zh-cn', text: '中国语', ja: '中国語（簡体字）'},
        				{value: 'zh-tw', text: '中國語', ja: '中国語（繁体字）'},
        				{value: 'ko', text: '한국어', ja: '韓国語'},
        				{value: 'th', text: 'ภาษาไทย', ja: 'タイ語'},
        				{value: 'es', text: 'Español', ja: 'スペイン語'},
        				{value: 'de', text: 'Deutsch', ja: 'ドイツ語'},
        				{value: 'ms', text: 'Bahasa Melayu', ja: 'マレー語'},
        				{value: 'pt', text: 'Português', ja: 'ポルトガル語'},
        				{value: 'tl', text: 'Tagalog', ja: 'タガログ語'},
        				{value: 'vi', text: 'Tiếng Việt', ja: 'ベトナム語'},
        				{value: 'fr', text: 'Français', ja: 'フランス語'},
        				{value: 'id', text: 'Bahasa indonesia', ja: 'インドネシア語'},
        				{value: 'it', text: 'italiano', ja: 'イタリア語'},
        				{value: 'ru', text: 'Русский язык', ja: 'ロシア語'},
        				{value: 'tr', text: 'Türkçe', ja: 'トルコ語'},
        				{value: 'ja', text: '日本語', ja: '日本語'},
            ]
        }
    },

    components: {
        navbar: require('./Navbar.vue').default,
        lang_w: require('./lang_w.vue').default,
        hart_logo: require('./headHartLogo.vue').default

    },

    methods: {
        bg_off(){
          document.getElementById('login_bg').style="z-index:-300;";
        },
        bg_on(){
          document.getElementById('login_bg').style="z-index:-150;";
        },

        changeLocale(after, index){
            this.$i18n.locale = after;
            var lang_lists = document.querySelectorAll(":scope .lang_list");
            for (var i = 0; i < lang_lists.length; i++) {
                lang_lists[i].classList.remove("lang-selected");
            }
            lang_lists[index].classList.add("lang-selected");
        },

        dumpStore () {
            this.store_json_text = JSON.stringify(this.$store.state, null, "    ")
        },

        initParticlesJS () {
            /* eslint-disable */
            particlesJS('particles-js', {
              'particles': {
                'number': {
                  'value': 20,
                  'density': {
                    'enable': true,
                    'value_area': 800
                  }
                },
                'color': {
                  'value': '#ffffff'
                },
                'shape': {
                  'type': 'circle',
                  'stroke': {
                    'width': 0,
                    'color': '#000000'
                  },
                  'polygon': {
                    'nb_sides': 5
                  },
                  'image': {
                    'src': 'img/github.svg',
                    'width': 100,
                    'height': 100
                  }
                },
                'opacity': {
                  'value': 0.5,
                  'random': false,
                  'anim': {
                    'enable': false,
                    'speed': 1,
                    'opacity_min': 0.1,
                    'sync': false
                  }
                },
                'size': {
                  'value': 10,
                  'random': true,
                  'anim': {
                    'enable': false,
                    'speed': 80,
                    'size_min': 0.1,
                    'sync': false
                  }
                },
                'line_linked': {
                  'enable': true,
                  'distance': 300,
                  'color': '#ffffff',
                  'opacity': 0.4,
                  'width': 2
                },
                'move': {
                  'enable': true,
                  'speed': 12,
                  'direction': 'none',
                  'random': false,
                  'straight': false,
                  'out_mode': 'out',
                  'bounce': false,
                  'attract': {
                    'enable': false,
                    'rotateX': 600,
                    'rotateY': 1200
                  }
                }
              },
              'interactivity': {
                'detect_on': 'canvas',
                'events': {
                  'onhover': {
                    'enable': false
                  },
                  'onclick': {
                    'enable': false
                  },
                  'resize': false
                },
                'modes': {
                  'grab': {
                    'distance': 800,
                    'line_linked': {
                      'opacity': 1
                    }
                  },
                  'bubble': {
                    'distance': 800,
                    'size': 80,
                    'duration': 2,
                    'opacity': 0.8,
                    'speed': 3
                  },
                  'repulse': {
                    'distance': 400,
                    'duration': 0.4
                  },
                  'push': {
                    'particles_nb': 4
                  },
                  'remove': {
                    'particles_nb': 2
                  }
                }
              },
              'retina_detect': true
            })
          },
    },

    watch: {
        '$i18n.locale': function () {
            this.$store.commit('changeLang', this.$i18n.locale);
        },
        '$route': function (to, from) {
          this.top = to.path !== '/mmq'
          console.log(to.path !== '/mmq')
        }
    },

    name: 'ParticlesJS',

    mounted () {
      require('particles.js')
      this.$nextTick(() => {
        this.initParticlesJS()
      })

      this.top = this.$route.path !== '/mmq'
    },

    computed: {
      copyright() {
        let txt = document.createElement('textarea')
        txt.innerHTML = this.userState.customer.copyright
        return txt.value
      }
    }
}
</script>
