<template>
<div class="main_div">
	<interview_step :step="6"></interview_step>

	<div v-show="scrollTop < buttonTop" class="text-xs-center mt-5" id="next_buttons_fixed">
		<v-btn to="/mmq/MedicineInterview005" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/MedicineInterview008" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>



	<div class="interview_wrapper">

		<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview006.text001') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview006.text001', 'ja') }}</p>
		</h2>

		<v-layout row wrap mb-2 pa-3>
			<v-flex xs12>
				<v-checkbox v-model="basic_medical_cerebral_vascular_disease_flg" :value="basic_medical_cerebral_vascular_disease_flg" :label="$t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg')" :hint="$t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg', 'ja')" persistent-hint></v-checkbox>
			</v-flex>
			<p class="body-2 le_32">
				（{{ $t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg_etc') }}）<br>
				<span class="caption line100">（{{ $t('pages.Interview006.basic_medical_cerebral_vascular_disease_flg_etc', 'ja') }}）</span>
			</p>
		</v-layout>
		<v-layout row wrap mb-2 pa-3>
			<v-flex xs12>
				<v-checkbox v-model="basic_medical_heart_disease_flg" :value="basic_medical_heart_disease_flg" :label="$t('pages.Interview006.basic_medical_heart_disease_flg')" :hint="$t('pages.Interview006.basic_medical_heart_disease_flg', 'ja')" persistent-hint></v-checkbox>
			</v-flex>
			<p class="text-body-2 le_32">
				（{{ $t('pages.Interview006.basic_medical_heart_disease_flg_etc') }}）<br>
				<span class="caption line100">（{{ $t('pages.Interview006.basic_medical_heart_disease_flg_etc', 'ja') }}）</span>
			</p>
		</v-layout>

	</div>

	<div class="interview_wrapper">

		<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview006.basic_medical_food_allergy') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview006.basic_medical_food_allergy', 'ja') }}</p>
		</h2>
		<div class="switch">
			<v-layout row wrap>
				<v-flex xs6>
					<input type="radio" id="basic_medical_food_allergy_off" value="" v-model="basic_medical_food_allergy">
					<label for="basic_medical_food_allergy_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
				</v-flex>
				<v-flex xs6>
					<input type="radio" id="basic_medical_food_allergy_on" value="true" v-model="basic_medical_food_allergy">
					<label for="basic_medical_food_allergy_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
				</v-flex>
			</v-layout>
		</div>

	</div>

	<div class="interview_wrapper">

		<h2 class="text-xs-center headline mt-3">
			{{ $t('pages.Interview006.basic_medical_drug_allergy') }}
			<p class="text-xs-center caption_ja">{{ $t('pages.Interview006.basic_medical_drug_allergy', 'ja') }}</p>
		</h2>
		<v-layout row wrap>
			<v-flex xs12>
				<div class="switch">
					<v-layout row wrap>
						<v-flex xs6>
							<input type="radio" id="basic_medical_drug_allergy_off" value="" v-model="basic_medical_drug_allergy">
							<label for="basic_medical_drug_allergy_off" class="switch-off">{{ $t('common.no') }}<br><span class="caption">{{ $t('common.no', 'ja') }}</span></label>
						</v-flex>
						<v-flex xs6>
							<input type="radio" id="basic_medical_drug_allergy_on" value="true" v-model="basic_medical_drug_allergy">
							<label for="basic_medical_drug_allergy_on" class="switch-on">{{ $t('common.yes') }}<br><span class="caption">{{ $t('common.yes', 'ja') }}</span></label>
						</v-flex>
					</v-layout>
				</div>
			</v-flex>
		</v-layout>
		<transition name="slide-fade">
			<v-layout row wrap v-show="basic_medical_drug_allergy" class="mt-5">
				<v-flex v-for="(ind, key) in $t('data.basic_medical_drug_name_arr')" :key="key" xs12 offset-xs1 sm12>
					<v-checkbox v-model="basic_medical_drug_name" :value="ind.code" :label="ind.name" :hint="ind.code" persistent-hint></v-checkbox>
				</v-flex>
			</v-layout>
		</transition>

	</div>

	<div class="text-xs-center mt-5" id="next_buttons">
		<v-btn to="/mmq/MedicineInterview005" round color="secondary" depressed large><v-icon>navigate_before</v-icon>{{ $t('common.back') }}&nbsp;<span class="caption">({{ $t('common.back', 'ja') }})</span></v-btn>
		<v-btn to="/mmq/MedicineInterview008" round color="primary" large>{{ $t('common.next') }}&nbsp;<span class="caption">({{ $t('common.next', 'ja') }})</span><v-icon>navigate_next</v-icon></v-btn>
	</div>

</div>
</template>

<script>
export default {
	data: function () {
		return {
			scrollTop: 0,
			buttonTop: 0,
			basic_medical_cerebral_vascular_disease_flg: this.$store.state.mmq.medicine.Interview006.basic_medical_cerebral_vascular_disease_flg,
			basic_medical_heart_disease_flg: this.$store.state.mmq.medicine.Interview006.basic_medical_heart_disease_flg,
			basic_medical_food_allergy: this.$store.state.mmq.medicine.Interview006.basic_medical_food_allergy,
			basic_medical_drug_allergy: this.$store.state.mmq.medicine.Interview006.basic_medical_drug_allergy,
			basic_medical_drug_name: this.$store.state.mmq.medicine.Interview006.basic_medical_drug_name,
		}
	},

	methods: {
		setOnScroll(){
			var self = this;
			document.onscroll = function(e){
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				self.scrollTop = scrollTop + window.innerHeight - 20;
				self.buttonTop= document.getElementById('next_buttons').offsetTop;
			}
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			self.scrollTop = scrollTop + window.innerHeight - 20;
			self.buttonTop= document.getElementById('next_buttons').offsetTop;
		},
	},

	watch: {
		basic_medical_cerebral_vascular_disease_flg: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview006', 'basic_medical_cerebral_vascular_disease_flg', this.basic_medical_cerebral_vascular_disease_flg]);
		},
		basic_medical_heart_disease_flg: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview006', 'basic_medical_heart_disease_flg', this.basic_medical_heart_disease_flg]);
		},
		basic_medical_food_allergy: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview006', 'basic_medical_food_allergy', this.basic_medical_food_allergy]);
		},
		basic_medical_drug_allergy: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview006', 'basic_medical_drug_allergy', this.basic_medical_drug_allergy]);
		},
		basic_medical_drug_name: function(){
			this.$store.commit('changeVal', ['mmq', 'medicine', 'Interview006', 'basic_medical_drug_name', this.basic_medical_drug_name]);
		},
	},

	mounted(){
		this.setOnScroll()
	},

	destroyed(){
		document.onscroll = "";
	},

	components: {
        interview_step: require('./MedicineInterviewStep.vue').default,
    }
}
</script>
